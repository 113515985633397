import { useResultsStore } from '@/stores/results-store/store'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { AttomPermit, AttomResult } from '@/utils/types/result-types.ts'
import { createSelector } from 'reselect'
import { propertyResultsByCategory } from './property'

export const attomResults = createSelector(
  [propertyResultsByCategory],
  (results) => (results[RESULT_CATEGORIES.ATTOM] as AttomResult[]) || [],
)

export const attomResultsWithPermits = createSelector(
  [attomResults],
  (results) =>
    results
      ? results.filter((result) => result.properties.buildingPermits.length > 0)
      : [],
)

export const getPermitDescription = (result: AttomPermit) => {
  const { description } = result
  let out = result.description || ''
  if (out.startsWith(': ')) {
    out = description.slice(2)
  }
  return out.toLowerCase()
}

export const useAttomWithPermits = () =>
  useResultsStore(attomResultsWithPermits)
