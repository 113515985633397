import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { HazardPropertyResult } from '@/utils/types/result-types'

export interface HazardPropertyBentoCardProps extends CardProps {
  result: HazardPropertyResult
  size: string
}

const category = RESULT_CATEGORIES.HAZARD_PROPERTY
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export const HazardPropertyBentoCard = ({
  result,
  ...props
}: HazardPropertyBentoCardProps) => {
  const images = result.properties.images
  return (
    <BentoCard
      {...props}
      result={result}
      hideActions={actionsToHide}
      resultCategory={RESULT_CATEGORIES.HAZARD_PROPERTY}
      image={{
        title: result.title,
        source: result.source,
        thumbnail: images[0],
      }}
      totalResults={images?.length}
    />
  )
}
export default HazardPropertyBentoCard
