import type { CollectionSortType } from '@/api/collections'
import { useKeyedState } from '@/hooks/use-keyed-state'
import { CategoryTypes } from '@/utils/types/result-types'
import { useQueryClient } from '@tanstack/react-query'

export const stateKeys = {
  currentPage: 'currentPage',
  nextToken: 'nextToken',
  previousToken: 'previous',
  sort: 'sort',
  searchTerm: 'searchTerm',
  selectedCategories: 'selectedCategories',
}

export const initialValues = {
  [stateKeys.currentPage]: 0,
  [stateKeys.nextToken]: '',
  [stateKeys.previousToken]: '',
  [stateKeys.sort]: '',
  [stateKeys.searchTerm]: '',
  [stateKeys.selectedCategories]: [
    CategoryTypes.IMAGE,
    CategoryTypes.PUBLICATION,
    CategoryTypes.SOCIAL_MEDIA,
    CategoryTypes.DATA,
    CategoryTypes.FILE,
    CategoryTypes.ANALYTIC,
  ],
}

const useField = <T>(key: string) =>
  useKeyedState<T>(key, initialValues[key] as T)

export function useCollectionItemsData() {
  const queryClient = useQueryClient()

  const [currentPage, setCurrentPage] = useField<number>(stateKeys.currentPage)
  const [nextToken, setNextToken] = useField<string>(stateKeys.nextToken)
  const [previousToken, setPreviousToken] = useField<string>(
    stateKeys.previousToken,
  )
  const [sort, setSort] = useField<CollectionSortType>(stateKeys.sort)
  const [searchTerm, setSearchTerm] = useField<string>(stateKeys.searchTerm)
  const [selectedCategories, setSelectedCategories] = useField<CategoryTypes[]>(
    stateKeys.selectedCategories,
  )

  return {
    currentPage,
    setCurrentPage,
    queryClient,
    nextToken,
    setNextToken,
    previousToken,
    setPreviousToken,
    sort,
    setSort,
    searchTerm,
    setSearchTerm,
    selectedCategories,
    setSelectedCategories,
  }
}
