/* eslint-disable camelcase */
import React from 'react'
import { type CardProps, Image, Stack, Table } from '@mantine/core'
import { createStaticMapUrl } from '@/api/mapbox'
import { Icon } from '@/components/lib/Icon.tsx'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view.ts'
import {
  useSelectedPointId,
  useSetPointViewPort,
  useSetSelectedPointId,
} from '@/stores/map-store'
import type { FireResult } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface FireResultProps extends CardProps {
  result: FireResult
  isFullscreen?: boolean
}

export function FireResult({
  result,
  isFullscreen,
  ...props
}: FireResultProps) {
  const [staticMapUrl, setStaticMapUrl] = React.useState<string | null>()
  const setPointViewPort = useSetPointViewPort()
  const selectedPointId = useSelectedPointId()
  const setSelectedPointId = useSetSelectedPointId()
  const { targetRef } = useSubscribeScrollIntoView(result.id, selectedPointId)

  React.useEffect(() => {
    isFullscreen && setSelectedPointId(null)
  }, [isFullscreen, setSelectedPointId])

  React.useLayoutEffect(() => {
    if (targetRef.current) {
      const { width, height } = targetRef.current.getBoundingClientRect()
      setStaticMapUrl(
        createStaticMapUrl({
          geometry: result.geometry,
          size: [width, height],
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    latitude,
    longitude,
    acq_date,
    bright_ti4,
    satellite,
    confidence,
    frp,
  } = result.properties.firms_metadata

  return (
    <ResultBase
      result={result}
      onClick={() => {
        setPointViewPort((result.geometry.coordinates as number[]) ?? [0, 0])
      }}
      withBorder
      radius="sm"
      style={{
        borderColor:
          selectedPointId === result.documentId
            ? '#0091EA'
            : 'var(--mantine-color-gray-2)',
      }}
      data-cy="fireResult"
      ref={targetRef}
      {...props}
    >
      <div
        ref={targetRef}
        style={{
          position: 'relative',
          overflow: 'hidden',
          aspectRatio: `${isFullscreen ? 2.4 : 1.95}`,
          backgroundColor: 'var(--mantine-color-gray-2)',
        }}
      >
        <Image
          alt={result.title}
          src={staticMapUrl}
          fit="cover"
          width="100%"
          height="100%"
          radius="sm"
        />
      </div>

      <Stack gap={0} p="sm">
        <Icon name="mode_heat" weight={500} />
        <Table
          fz="xs"
          withRowBorders={false}
          className={styles['result-table']}
        >
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>Latitude</Table.Td>
              <Table.Td>{latitude}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Longitude</Table.Td>
              <Table.Td>{longitude}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Date & Time</Table.Td>
              <Table.Td>{dayjs(acq_date).format('M/D/YYYY, HH:MM')}Z</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Brightness TI4</Table.Td>
              <Table.Td>{bright_ti4} K</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Satellite</Table.Td>
              <Table.Td>{satellite}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Confidence</Table.Td>
              <Table.Td>{confidence}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>FRP</Table.Td>
              <Table.Td>{frp} MW</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Stack>
    </ResultBase>
  )
}
