/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'
import { notifications } from '@mantine/notifications'
import { getRequest, postRequest, putRequest } from '@/api/danti-requests'
import {
  useFrequencyFilter,
  useMakeSearchFiltersFromCurrentPanelValues,
} from '@/stores/filters-store'
import { ROUTES } from '@/utils/constants'
import type {
  TerrapinConfig,
  TerrapinConfigResponse,
} from '@/utils/types/collection-types'
import type { SearchApiFilterValue } from '@/utils/types/filter-types'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

export type FilterType = 'LOCATION' | 'LOCATIONTYPE'

export interface TerrapinLocation {
  name: string
  filters: {
    type: FilterType
    value: string
  }[]
}

export interface TerrapinConfigParams {
  name?: string
  query?: string
  description?: string
  filters?: SearchApiFilterValue[]
  emailNotification?: string | null
  expiration?: string | null
  frequency?: string | null
  backfill?: boolean
  mode: 'search' | 'feed'
  locations: TerrapinLocation[]
}

export interface UpdateTerrapinParams extends TerrapinConfigParams {
  refId: string
  collectionId?: string
}

export interface CreateTerrapinParams extends TerrapinConfigParams {
  name: string
  query: string
  emailNotification?: string | null
  expiration?: string | null
  filters?: SearchApiFilterValue[]
  backfill?: boolean
  mode: 'search' | 'feed'
}

const initialTerrapinConfig: TerrapinConfig = {
  name: '',
  query: '',
  owner: '',
  description: '',
  created: '',
  modified: '',
  filters: [],
  expiration: '',
  frequency: '',
  email_notification: '',
  backfill: true,
  categories: [],
  mode: 'search',
  pk_id: '',
  batch_id: '',
  collection_id: '',
  locations: [],
}

export function buildTerrapinPayload(
  params: TerrapinConfigParams,
): TerrapinConfigParams {
  const {
    name,
    query,
    emailNotification,
    expiration,
    filters,
    backfill,
    mode,
    locations,
  } = params
  let emailSchedule = ''

  if (emailNotification) {
    const [hours, minutes] = emailNotification.split(':').map(Number)
    // Convert from user-local time in 'H:mm' to UTC 'H:mm'
    emailSchedule =
      'DAILY(' + dayjs().hour(hours).minute(minutes).utc().format('HH:mm') + ')'
  } else {
    emailSchedule = 'NEVER'
  }

  return {
    // Only apply passed values
    ...(filters && { filters }),
    ...(name && { name, description: name }),
    ...(query && { query }),
    ...(emailSchedule && {
      emailNotification: emailSchedule,
    }),
    ...(expiration && { expiration }),
    ...(backfill && { backfill }),
    mode,
    locations,
  }
}

export function useTerrapin() {
  const panelFilters = useMakeSearchFiltersFromCurrentPanelValues()
  const frequency = useFrequencyFilter()

  const createTerrapin = React.useCallback(
    async ({
      name,
      query,
      emailNotification,
      expiration,
      backfill,
      mode,
      locations,
    }: CreateTerrapinParams) => {
      const requestBody = buildTerrapinPayload({
        name,
        query,
        emailNotification,
        expiration,
        frequency,
        backfill,
        filters: panelFilters,
        mode,
        locations,
      })

      const useBatchEndpoint = locations.length > 0

      try {
        const response = await postRequest<{
          success: boolean
          data: { collection_id: string }
        }>(useBatchEndpoint ? '/terrapin/batch' : '/terrapin', requestBody)

        const {
          data: {
            data: { collection_id },
            success,
          },
        } = response

        if (!success) {
          notifications.show({
            loading: false,
            title: 'Save Error',
            message: 'Error while saving search.',
            autoClose: 10000,
            withCloseButton: true,
          })
          return
        }

        notifications.show({
          loading: false,
          title: 'Success',
          message: (
            <>
              The search{' '}
              <Link to={`${ROUTES.collections}/${collection_id}`}>
                “{name}”
              </Link>{' '}
              has been saved to your collections
            </>
          ),
          autoClose: 10000,
          withCloseButton: true,
        })
      } catch {
        notifications.show({
          loading: false,
          title: 'Save Error',
          message: 'Error while saving search.',
          autoClose: 10000,
          withCloseButton: true,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [panelFilters],
  )

  const updateTerrapin = React.useCallback(
    async ({
      refId,
      name,
      query,
      emailNotification,
      expiration,
      backfill,
      mode,
      locations,
    }: UpdateTerrapinParams) => {
      const payload = buildTerrapinPayload({
        name,
        query,
        emailNotification,
        expiration,
        frequency,
        backfill,
        filters: panelFilters,
        mode,
        locations,
      })

      const requestBody = {
        id: refId,
        ...payload,
      }
      try {
        const response = await putRequest<{ success: boolean }>(
          '/terrapin',
          requestBody,
        )
        return response.data
      } catch {
        return false
      }
    },
    [frequency, panelFilters],
  )

  const getTerrapinConfig = React.useCallback(async (refId: string) => {
    try {
      const { data } = await getRequest<TerrapinConfigResponse>(
        `/terrapin?id=${refId}`,
      )
      return data.configs
    } catch {
      return initialTerrapinConfig
    }
  }, [])

  return {
    createTerrapin,
    updateTerrapin,
    getTerrapinConfig,
  }
}
