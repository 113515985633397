import React from 'react'
import { Button, Group, Stack } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { type AddItemParams, useCollectionItems } from '@/api/collection-items'
import { CollectionChooser } from '@/features/Collections/CollectionChooser'
import { useAddToCollection } from '@/hooks/collection/use-add-to-collection'
import { useUserEmail } from '@/stores/user-store'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import {
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import { captureException } from '@sentry/react'
import { openCreateCollectionModal } from './openers'

export interface AddToCollectionModalProps {
  collectionId?: string
  item: ResultWithId
}

const convertToAddItemParams = (
  collectionId: string,
  owner: string,
  result: ResultWithId,
): AddItemParams => {
  let preview: string = result.thumbnail ?? ''

  if (isSocialMediaResult(result)) {
    const imageUrl = result.properties.src_image_url
    if (imageUrl) {
      preview = imageUrl
    }
  }

  return {
    name: result.title,
    desc: result.description ?? '',
    owner,
    tags: result.tags,
    collectionId,
    preview,
    refType: 'DOCUMENT',
    refId: result.id,
    doc: JSON.stringify(result),
    category: result.category,
    workspaceId: collectionId,
  }
}

export function AddToCollectionModal({
  context,
  id,
  innerProps: { item, collectionId },
}: ContextModalProps<AddToCollectionModalProps>) {
  const { add } = useCollectionItems()
  const email = useUserEmail()
  const { collections } = useAddToCollection()

  const [selectedCollectionId, setSelectedCollectionId] = React.useState(
    collectionId ?? collections[0]?.id ?? null,
  )

  async function handleAdd() {
    if (selectedCollectionId) {
      const params = convertToAddItemParams(selectedCollectionId, email, item)
      try {
        await add(params)
        captureEvent(POSTHOG_EVENTS.DETAIL.ADD_TO_COLLECTION, {
          resultCategory: item.properties.resultCategory ?? item.category,
        })
      } catch (error) {
        captureException(error, {
          extra: { itemId: item.id, collectionId: selectedCollectionId },
        })
      } finally {
        context.closeModal(id)
      }
    }
  }

  return (
    <>
      <Stack>
        <Button
          size="xs"
          onClick={openCreateCollectionModal}
          variant="white"
          ml="auto"
        >
          New Collection
        </Button>
      </Stack>
      {collections.length > 0 && (
        <CollectionChooser
          size={300}
          collections={collections}
          selectedCollectionId={selectedCollectionId}
          setSelectedCollectionId={setSelectedCollectionId}
        />
      )}
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button onClick={() => void handleAdd()}>Add</Button>
      </Group>
    </>
  )
}
