import { useNavigate } from 'react-router-dom'
import { Group, Table, Text } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'
import { openResultDetailsModal } from '@/components/modals/openers'
import type { CollectionItem } from '@/utils/types/collection-types.ts'
import {
  CategoryTypes,
  getLabelForResult,
  type ResultWithId,
} from '@/utils/types/raw-result-types'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
} from '@/utils/types/result-types'
import {
  type ColumnFiltersState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import dayjs from 'dayjs'
import { CollectionDetailResultIcon } from '../CollectionDetailResultIcon'
import { CollectionDetailMenu } from './CollectionDetailMenu'
import { FilterCategoryTypeCell } from './HeaderFilterCell'
import { HeaderSortCell } from './HeaderSortCell'

const columnHelper = createColumnHelper<CollectionItem>()

interface ColumnFunctions {
  setSort: (sort: CollectionSortType) => void
  onCategoryToggle: (categoryTypes: CategoryTypes) => void
}

const columns = [
  columnHelper.accessor((row) => row, {
    id: 'name',
    header: (info) => (
      <HeaderSortCell
        setSort={(info.table.options.meta as ColumnFunctions).setSort}
        label="Name"
        sortItems={[
          {
            value: 'alpha-desc',
            label: 'Name descending',
          },
          {
            value: 'alpha-asc',
            label: 'Name ascending',
          },
        ]}
      />
    ),
    size: 400,
    cell: (info) => {
      const item = info.getValue()
      const result = JSON.parse(item.doc) as ResultWithId
      return (
        <Group gap={12} wrap="nowrap">
          <CollectionDetailResultIcon result={result} />
          <Text>{item.name}</Text>
        </Group>
      )
    },
  }),
  columnHelper.accessor('createdOn', {
    header: (info) => (
      <HeaderSortCell
        setSort={(info.table.options.meta as ColumnFunctions).setSort}
        label="Created at"
        sortItems={[
          {
            value: 'date-desc',
            label: 'Date descending',
          },
          {
            value: 'date-asc',
            label: 'Date ascending',
          },
        ]}
      />
    ),
    cell: (info) => (
      <Text py="sm">{dayjs(info.getValue()).format('MM/DD/YY hh:mm A')}</Text>
    ),
  }),
  columnHelper.accessor(
    (row) => {
      const { doc } = row
      const result = JSON.parse(doc) as ResultWithId
      return getLabelForResult(result)
    },
    {
      id: 'kind',
      size: 70,
      cell: (info) => <Text>{info.getValue()}</Text>,
      header: (info) => (
        <FilterCategoryTypeCell
          label="Kind"
          selectedCategories={
            info.table.getState().columnFilters.find((x) => x.id == 'kind')
              ?.value as CategoryTypes[]
          }
          onCategoryToggle={
            (info.table.options.meta as ColumnFunctions).onCategoryToggle
          }
          filterItems={[
            { label: 'Images', value: CategoryTypes.IMAGE },
            { label: 'Articles', value: CategoryTypes.PUBLICATION },
            { label: 'Documents', value: CategoryTypes.DATA },
            { label: 'Social', value: CategoryTypes.SOCIAL_MEDIA },
            { label: 'File', value: CategoryTypes.FILE },
            { label: 'Analytic', value: CategoryTypes.ANALYTIC },
          ]}
        />
      ),
    },
  ),
  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    size: 70,
    cell: (info) => <CollectionDetailMenu item={info.getValue()} />,
  }),
]

export interface CollectionDetailTableProps {
  items: CollectionItem[]
  onClick: (id: string) => void
  setSort: (sort: CollectionSortType) => void
  onCategoryToggle: (categoryTypes: CategoryTypes) => void
  columnFilters?: ColumnFiltersState | undefined
}

export function CollectionDetailTable({
  items,
  setSort,
  onCategoryToggle,
  columnFilters,
}: CollectionDetailTableProps) {
  const table = useReactTable({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (collection) => collection.id,
    state: {
      columnFilters,
    },
    meta: {
      setSort: (sort: CollectionSortType) => {
        setSort(sort)
      },
      onCategoryToggle: (categoryTypes: CategoryTypes) => {
        onCategoryToggle(categoryTypes)
      },
    },
  })
  const navigate = useNavigate()

  const handleOnClick = (item: CollectionItem) => {
    const { doc } = item
    const result = JSON.parse(doc) as ResultWithId

    if (isFireResult(result) || isShipResult(result)) {
      openResultDetailsModal(result)
      return
    }

    if (isImageryResult(result)) {
      navigate(`/collections/${item.collectionId}/items/${item.id}`)
      return
    }

    window.open(result.properties.link, '_blank')
  }

  return (
    <Table highlightOnHover visibleFrom="sm">
      <Table.Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.Th key={header.id} w={header.getSize()} pt={8} pb={8}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </Table.Th>
            ))}
          </Table.Tr>
        ))}
      </Table.Thead>
      <Table.Tbody>
        {table.getRowModel().rows.map((row) => {
          const isFileResult = row.original.category === CategoryTypes.FILE
          return (
            <Table.Tr
              style={{ ...(!isFileResult && { cursor: 'pointer' }) }}
              key={row.id}
              onClick={() => {
                if (!isFileResult) {
                  handleOnClick(items[row.index])
                }
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <Table.Td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
  )
}
