import React from 'react'
import { Group, Popover, Radio, Stack, Text } from '@mantine/core'
import { DatePicker, type DateValue } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon.tsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(relativeTime)

function formatDateFromNow(date: string) {
  return dayjs(date).fromNow(false).replace('in ', '')
}
function formatDateShort(date: string) {
  return dayjs(date).format('MM/DD/YY')
}

const oneDayFromNow = dayjs().add(1, 'day').endOf('day').toDate()
const twoWeeksFromNow = dayjs().add(2, 'weeks').endOf('day').toISOString()
const oneMonthFromNow = dayjs().add(1, 'month').endOf('day').toISOString()
const twoMonthsFromNow = dayjs().add(2, 'months').endOf('day').toISOString()

const NEVER = 'never'

const expirationOptions = [
  { label: '2 weeks', value: twoWeeksFromNow },
  { label: '1 month', value: oneMonthFromNow },
  { label: '2 months', value: twoMonthsFromNow },
  { label: 'Never', value: NEVER },
]

function getExpirationValue(expiration: string) {
  if (expiration && expiration !== NEVER) {
    const found = expirationOptions.find(({ value }) => value === expiration)
    if (found) {
      return found
    }

    const endOfDay = dayjs.utc(expiration).local().toISOString()
    const label = formatDateFromNow(endOfDay)
    return {
      label,
      value: endOfDay,
    }
  }

  return expirationOptions.at(-1)!
}

interface ConfigureExpirationProps {
  initialExpirationDate?: string | null
  expirationDate: string
  setExpirationDate: (expiration: string) => void
}

export function ConfigureExpiration({
  initialExpirationDate,
  expirationDate,
  setExpirationDate,
}: ConfigureExpirationProps) {
  React.useEffect(() => {
    if (!initialExpirationDate) {
      setExpirationDate(expirationOptions[0].value)
    }
  }, [initialExpirationDate, setExpirationDate])

  const valueForDatePicker =
    expirationDate === NEVER
      ? undefined
      : dayjs.utc(expirationDate).local().toDate()

  const handleDateSelect = React.useCallback(
    (date: DateValue) => {
      setExpirationDate(
        date ? dayjs(date).endOf('day').utc().toISOString() : NEVER,
      )
    },
    [setExpirationDate],
  )

  const currentValueLabel = getExpirationValue(expirationDate).label
  const expirationDateFormatted =
    // TODO: i18n/messages
    expirationDate === NEVER
      ? 'Never expires'
      : `Expires in ${currentValueLabel} (on ${formatDateShort(expirationDate)})`

  return (
    <Popover position="bottom">
      <Popover.Target>
        <Group justify="space-between" style={{ cursor: 'pointer' }}>
          <Text size="sm">{expirationDateFormatted}</Text>
          <Icon name="keyboard_arrow_down" />
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <Radio.Group
          dir="column"
          value={expirationDate}
          onChange={setExpirationDate}
          size="sm"
          pb="sm"
        >
          <Stack>
            {initialExpirationDate && (
              <Radio
                value={initialExpirationDate}
                label={formatDateFromNow(initialExpirationDate)}
              />
            )}
            {expirationOptions.map((option) => (
              <Radio
                key={option.label}
                value={option.value}
                label={option.label}
              />
            ))}
          </Stack>
        </Radio.Group>
        <DatePicker
          size="xs"
          firstDayOfWeek={0}
          defaultDate={valueForDatePicker}
          value={valueForDatePicker}
          onChange={handleDateSelect}
          minDate={oneDayFromNow}
          hideOutsideDates
        />
      </Popover.Dropdown>
    </Popover>
  )
}
