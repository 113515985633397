import { type MutableRefObject, useEffect, useRef } from 'react'
import { makeImageryResultPolygons } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import {
  type BaseLayerOption,
  useBaseLayer,
  useSelectedHexGridId,
  useSetHoveredHexGridId,
} from '@/stores/map-store'
import { useCategorizedResults } from '@/stores/results-store'
import { apiUrl } from '@/utils/constants.ts'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { formatMapboxStyle } from './use-base-layer-selection.ts'

type MapboxMapProps = Partial<mapboxgl.MapboxOptions> & {
  showMapControls?: boolean
  baseLayer?: BaseLayerOption
}

export const useMapboxMap = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapContainer: MutableRefObject<HTMLDivElement | null>,
  options?: MapboxMapProps,
) => {
  const { showMapControls = false, baseLayer, ...mapboxProps } = options ?? {}
  const storeBaseLayer = useBaseLayer()
  const selectedHexGridId = useSelectedHexGridId()
  const setHoveredHexGridId = useSetHoveredHexGridId()
  const categorized = useCategorizedResults()

  const { user } = useDantiAuth()

  const userRef = useRef(user)

  useEffect(() => {
    userRef.current = user
  }, [user])

  useEffect(() => {
    if (map.current) {
      // initialize map only once
      return
    }
    if (!mapContainer.current) {
      return
    }
    const imageResultsCenter = (
      categorized.imageResults.length > 0
        ? turf.centroid(makeImageryResultPolygons(categorized.imageResults))
            .geometry.coordinates
        : [-95.712, 37]
    ) as [number, number]

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: formatMapboxStyle(baseLayer ?? storeBaseLayer),
      center: imageResultsCenter,
      zoom: 5,
      pitchWithRotate: false,
      touchPitch: false,
      dragRotate: false,
      touchZoomRotate: false,
      transformRequest: (urlString, resourceType) => {
        const url = new URL(urlString)
        const apiUrlUrl = new URL(apiUrl)
        const isExceptionResource =
          resourceType === 'Tile' || resourceType === 'Image'
        if (isExceptionResource && url.origin === apiUrlUrl.origin) {
          return {
            url: urlString,
            headers: {
              Authorization: `Bearer ${userRef.current?.access_token}`,
              Accept: 'image/png',
            },
          }
        }
        return { url: urlString }
      },
      ...mapboxProps,
    })

    {
      showMapControls &&
        map.current.addControl(
          new mapboxgl.NavigationControl({
            visualizePitch: false,
            showCompass: false,
          }),
          'bottom-left',
        )
    }

    map.current.doubleClickZoom.disable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    storeBaseLayer,
    map,
    mapContainer,
    selectedHexGridId,
    setHoveredHexGridId,
    categorized.imageResults,
    showMapControls,
  ])
}
