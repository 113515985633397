import { useResultsStore } from '@/stores/results-store/store'
import { createSelector } from 'reselect'
import type { Results, ResultsActions } from '../store'
import { flattenResults } from './flatten-results'

export const rootSelector = (r: Results & ResultsActions) => r

export const categorized = createSelector([rootSelector], (r) => r.categorized)
export const currentQueryId = createSelector(
  [rootSelector],
  (r) => r.currentQueryId,
)
export const ids = createSelector([rootSelector], (r) => r.ids)
export const dataResults = createSelector([categorized], (c) => c.dataResults)
export const insightResults = createSelector(
  [categorized],
  (c) => c.insightResults,
)
export const allResults = createSelector([categorized], (c) =>
  flattenResults(c),
)
export const resultsById = createSelector([allResults], (results) =>
  Object.fromEntries(results.map((result) => [result.id, result])),
)

export const useResults = () => useResultsStore(rootSelector)
export const useCategorizedResults = () => useResultsStore(categorized)
export const useCurrentQueryId = () => useResultsStore(currentQueryId)
export const useIds = () => useResultsStore(ids)
export const useDataResults = () => useResultsStore(dataResults)
export const useInsightResults = () => useResultsStore(insightResults)
export const useAllResults = () => useResultsStore(allResults)
export const useResultsById = () => useResultsStore(resultsById)
