import {
  FUTURE_MENU_OPTIONS,
  FUTURE_OPTION_TITLE,
  MENU_OPTION_ICONS,
  MENU_OPTION_KEYS,
  MENU_OPTION_LABELS,
} from '@/features/ResultActions/constants'
import type {
  ActionMenuOption,
  ActionMenuType,
} from '@/features/ResultActions/types'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'
import { useMenuOptionActions } from './use-menu-option-actions'

export interface UseResultMenuActionsParams<Type> {
  result: Type
  hideActions?: ActionMenuOption[]
}

export const useResultMenuActions = <Type>({
  result,
  hideActions = [],
}: UseResultMenuActionsParams<Type>) => {
  const { data: isDownloadable } = useIsDownloadable(result as ResultWithId)
  const isAnImageResult = isImageryResult(result as ResultWithId)

  if (!isAnImageResult) {
    hideActions.push(MENU_OPTION_KEYS.downloadImage)
  }

  const actionKeys = Object.keys(MENU_OPTION_KEYS).filter(
    (key) => !hideActions.includes(key),
  )

  const actions = useMenuOptionActions(result as ResultWithId)

  return actionKeys.map((key: string) => {
    const action = {
      label: MENU_OPTION_LABELS[key as keyof typeof MENU_OPTION_LABELS],
      icon: MENU_OPTION_ICONS[key as keyof typeof MENU_OPTION_ICONS],
    } as ActionMenuType

    if (!isDownloadable && key === MENU_OPTION_KEYS.downloadImage) {
      return { ...action, disabled: true, title: 'Not available for download' }
    }

    if (FUTURE_MENU_OPTIONS.includes(key)) {
      return { ...action, disabled: true, title: FUTURE_OPTION_TITLE }
    }
    return { ...action, onClick: actions[key] }
  })
}
