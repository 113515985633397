import React, { useEffect } from 'react'

export const useSubscribeScrollIntoView = (
  componentId: string,
  activeId: string | null,
) => {
  const targetRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (componentId === activeId) {
      targetRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }

    return () => {}
  }, [componentId, activeId])

  return { targetRef }
}
