import React from 'react'
import {
  ActionIcon,
  Button,
  Group,
  Select,
  Stack,
  TextInput,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { TerrapinStaticMapDisplay } from '@/components/modals/SaveSearchModal/TerrapinLocationPanel/TerrapinStaticMapDisplay.tsx'
import type { TerrapinLocation } from '@/hooks/use-terrapin'
import {
  getLocationType,
  getLocationValue,
  TerrapinLocationType,
  TerrapinLocationValidator,
} from './TerrapinLocation'

export interface AreaOfInterestlocation {
  location: string
  type: TerrapinLocationType
  name: string
}

export interface TerrapinLocationRowProps {
  location: TerrapinLocation
  index: number
  onChange: (index: number, location: TerrapinLocation) => void
  onRemove: (index: number) => void
  onOpenMap: () => void
  setCurrentLocation: (location: TerrapinLocation) => void
}

export function TerrapinLocationRow({
  location,
  index,
  onChange,
  onRemove,
  onOpenMap,
  setCurrentLocation,
}: TerrapinLocationRowProps) {
  const { name } = location
  const type = getLocationType(location)
  const value = getLocationValue(location)

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false)

  const handleOpenMap = () => {
    setCurrentLocation(location)
    onOpenMap()
  }

  if (!type || !value) {
    return <></>
  }

  return (
    <Group align="center">
      <Select
        error={
          Object.values(TerrapinLocationType).includes(type)
            ? undefined
            : 'Unsupported type'
        }
        variant="unstyled"
        w={130}
        data={Object.values(TerrapinLocationType)}
        value={type}
        onChange={(value) =>
          onChange(index, {
            ...location,
            filters: location.filters.map((x) =>
              x.type === 'LOCATIONTYPE'
                ? { type: 'LOCATIONTYPE', value: value as string }
                : x,
            ),
          })
        }
        allowDeselect={false}
      />
      <TextInput
        value={value}
        flex={1}
        error={
          TerrapinLocationValidator[type]
            ? TerrapinLocationValidator[type](value)
            : true
        }
        onChange={(event) =>
          onChange(index, {
            ...location,
            filters: location.filters.map((x) =>
              x.type === 'LOCATION'
                ? { type: 'LOCATION', value: event.currentTarget.value }
                : x,
            ),
          })
        }
      />
      <TextInput
        value={name}
        error={name.trim().length === 0 ? 'Name required' : false}
        flex={1}
        onChange={(event) =>
          onChange(index, {
            ...location,
            name: event.currentTarget.value,
          })
        }
      />
      {TerrapinLocationValidator[type](value) ? (
        <Stack h={80} w={125} bg="#E0E0E0">
          <Icon style={{ margin: 'auto' }} name="warning_amber" />
        </Stack>
      ) : (
        <TerrapinStaticMapDisplay location={location} onClick={handleOpenMap} />
      )}

      {showDeleteConfirmation ? (
        <Group gap="sm" align="top">
          <Button
            size="xs"
            variant="transparent"
            fw={400}
            p={0}
            color="#000000DE"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            size="xs"
            variant="transparent"
            fw={400}
            p={0}
            color="red"
            onClick={() => onRemove(index)}
          >
            Delete
          </Button>
        </Group>
      ) : (
        <ActionIcon
          variant="white"
          color="black"
          mt="xs"
          onClick={() => setShowDeleteConfirmation(true)}
        >
          <Icon name="delete_outline" />
        </ActionIcon>
      )}
    </Group>
  )
}
