import React from 'react'
import { Stack } from '@mantine/core'
import { useCollections } from '@/api/collections'
import type { Collection } from '@/utils/types/collection-types'
import { CollectionListItem } from './CollectionListItem'
import { CollectionListItemModalMenu } from './CollectionListItemModalMenu'

export interface CollectionListProps {}

export function CollectionList() {
  const { collections } = useCollections()
  const [selectedCollection, setSelectedCollection] =
    React.useState<Collection>()

  return (
    <>
      <Stack gap={0} mt="xs" hiddenFrom="sm">
        {collections.map((collection) => (
          <CollectionListItem
            key={collection.id}
            collection={collection}
            onSelect={(collection) => setSelectedCollection(collection)}
          />
        ))}
      </Stack>
      {selectedCollection && (
        <CollectionListItemModalMenu
          collection={selectedCollection}
          onClose={() => setSelectedCollection(undefined)}
        />
      )}
    </>
  )
}
