import { SimpleGrid } from '@mantine/core'
import {
  AttomResult,
  HazardPropertyResult,
  HazardRiskResult,
  RegridResult,
} from '@/components/lib/results/Result'
import { RESULT_CATEGORIES, ROUTES } from '@/utils/constants.ts'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import { PanelBase } from '../'
import type { AnyPropertyType, PropertyPanelProps } from './types'
import usePropertyPanelData from './use-property-panel-data'

const resultTypeRenderer = {
  [RESULT_CATEGORIES.HAZARD_PROPERTY]: HazardPropertyResult,
  [RESULT_CATEGORIES.HAZARD_RISK]: HazardRiskResult,
  [RESULT_CATEGORIES.ATTOM]: AttomResult,
  [RESULT_CATEGORIES.REGRID]: RegridResult,
}

export function PropertyPanel({
  isFullscreen = false,
  position,
}: PropertyPanelProps) {
  const { shouldDisplay, sortedResults, isolateType } =
    usePropertyPanelData(isFullscreen)
  const resultElements = sortedResults.map((result: ResultWithId) => {
    const type = result.properties
      .resultCategory as keyof typeof resultTypeRenderer
    const ResultElement = resultTypeRenderer[type]
    return (
      <ResultElement
        key={result.documentId}
        result={result as AnyPropertyType}
        isFullscreen={isFullscreen}
      />
    )
  })

  return (
    shouldDisplay && (
      <PanelBase
        titleShort="Property Data"
        titleLong="All Property Data"
        isFullscreen={isFullscreen}
        isolateType={isolateType}
        link={ROUTES.allProperty}
        position={position}
      >
        <SimpleGrid
          cols={isFullscreen ? { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 } : 3}
          spacing={isFullscreen ? 'xl' : 'lg'}
          verticalSpacing={isFullscreen ? 'md' : 70}
        >
          {resultElements}
        </SimpleGrid>
      </PanelBase>
    )
  )
}
