import * as React from 'react'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { useSortPreference } from '@/stores/queries-store'
import { usePropertyResults } from '@/stores/results-store'
import { isRecognizedPropertyResult } from '@/utils/types/result-type-checkers.ts'
import { orderBy } from 'lodash'
import type { PropertyPanelData } from './types'

export const usePropertyPanelData = (
  isFullscreen: boolean,
): PropertyPanelData => {
  const sortPreference = useSortPreference()
  const unfilteredPropertyResults = usePropertyResults()
  const filteredPropertyResults =
    useTimelineFilteredResults().categorized.propertyResults.filter(
      isRecognizedPropertyResult,
    )
  const propertyResults = isFullscreen
    ? filteredPropertyResults
    : unfilteredPropertyResults

  const shouldDisplay = isFullscreen || unfilteredPropertyResults.length > 0

  const sortedResults = React.useMemo(
    () => orderBy(propertyResults, sortPreference, 'desc'),
    [propertyResults, sortPreference],
  )

  return {
    shouldDisplay,
    sortedResults,
    isolateType: isRecognizedPropertyResult,
  }
}

export default usePropertyPanelData
