import * as React from 'react'
import {
  useCollectionList,
  useCreateCollection,
  useRefreshCollections,
  useRemoveCollection,
  useShareCollectionWithVendor,
  useUnshareCollectionWithVendor,
  useUpdateCollection,
} from '@/api/collections/hooks'
import type { UnshareCollectionParams } from '@/api/collections/types'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import type { Collection } from '@/utils/types/collection-types.ts'
import { singletonHook } from 'react-singleton-hook'
import { PAGE_SIZE } from './constants'
import type {
  CollectionSortType,
  CreateCollectionParams,
  ShareCollectionParams,
  UpdateCollectionParams,
} from './types'
import { useCollectionsData } from './use-collections-data'

interface UseCollectionsHookResponse {
  collections: Collection[]
  totalCount: number
  isLoading: boolean
  pageSize: number
  currentPage: number

  sort: CollectionSortType
  setSort: (sort: CollectionSortType) => void
  nextPage: () => void
  previousPage: () => void
  create: (params: CreateCollectionParams) => Promise<unknown>
  shareWithVendor: (params: ShareCollectionParams) => Promise<unknown>
  unShareWithVendor: (params: UnshareCollectionParams) => Promise<unknown>
  update: (params: UpdateCollectionParams) => Promise<unknown>
  remove: (id: string) => Promise<unknown>
  refresh: () => void
  search: (term: string) => void
}

export const useCollectionHookImpl = (): UseCollectionsHookResponse => {
  const { isAuthenticated } = useDantiAuth()

  const {
    currentPage,
    nextToken,
    setNextToken,
    previousToken,
    setPreviousToken,
    sort,
    setCurrentPage,
    setSort,
    searchTerm,
    setSearchTerm,
  } = useCollectionsData()

  const { data, isLoading } = useCollectionList({
    nextToken,
    previousToken,
    sort,
    searchTerm,
    pageSize: PAGE_SIZE,
    isAuthenticated,
  })

  const totalCount = React.useMemo(() => data?.total ?? 0, [data])
  const maxPages = React.useMemo(
    () => Math.ceil(totalCount / PAGE_SIZE),
    [totalCount],
  )

  const refreshCollections = useRefreshCollections()
  const createCollection = useCreateCollection()
  const shareCollectionWithVendor = useShareCollectionWithVendor()
  const unShareCollectionWithVendor = useUnshareCollectionWithVendor()
  const updateCollection = useUpdateCollection()
  const removeCollection = useRemoveCollection()

  const nextPage = () => {
    if (!data?.next) {
      return
    }
    setPreviousToken('')
    setNextToken(data.next)
    setCurrentPage((page) => Math.min(maxPages, page + 1))
  }

  const previousPage = () => {
    if (!data?.prev) {
      return
    }
    setNextToken('')
    setPreviousToken(data.prev)
    setCurrentPage((page) => Math.max(0, page - 1))
  }

  const search = React.useCallback((term: string) => {
    setNextToken('')
    setPreviousToken('')
    setCurrentPage(0)
    setSearchTerm(term)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    collections: data?.collections ?? [],
    totalCount: data?.total ?? 0,
    isLoading,
    pageSize: PAGE_SIZE,
    currentPage,
    nextPage,
    previousPage,
    sort,
    setSort,
    create: createCollection,
    shareWithVendor: shareCollectionWithVendor,
    unShareWithVendor: unShareCollectionWithVendor,
    update: updateCollection,
    remove: removeCollection,
    refresh: refreshCollections,
    search,
  }
}

export const defaultValues = {
  collections: [] as Collection[],
  totalCount: 0,
  isLoading: false,
  pageSize: 0,
  currentPage: 0,
  sort: '',
  setSort: () => {},
  nextPage: () => {},
  previousPage: () => {},
  create: async () => {},
  shareWithVendor: async () => {},
  unShareWithVendor: async () => {},
  update: async () => {},
  remove: async () => {},
  refresh: () => {},
  search: () => {},
} as const

export const useCollections = singletonHook<UseCollectionsHookResponse>(
  () => defaultValues,
  useCollectionHookImpl,
)
