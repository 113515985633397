import { useEffect, useState } from 'react'
import {
  ActionIcon,
  Group,
  Indicator,
  Menu,
  Stack,
  Tooltip,
  Transition,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { PostfilterCheckboxButton } from '@/components/lib/results/results-postfilter/PostfilterCheckboxButton.tsx'
import { PostfilterCloudButton } from '@/components/lib/results/results-postfilter/PostfilterCloudButton.tsx'
import { PostFilterQualityButton } from '@/components/lib/results/results-postfilter/PostfilterQualityButton.tsx'
import { useSearchStatus } from '@/features/Search/hooks'
import { useImageResultsPropertyHistogram } from '@/hooks/results/use-image-results-property-histogram.ts'
import {
  useCloudCeil,
  useOmittedRepos,
  useOmittedSensors,
  useOmittedSources,
  useQualityCeil,
  useSetCloudCeil,
  useSetOmittedRepos,
  useSetOmittedSensors,
  useSetOmittedSources,
  useSetQualityCeil,
} from '@/stores/results-filter-store'
import type { SymbolName } from '@/utils/types/material-icons.ts'

export const ImageResultPostfilterPopup = () => {
  const omittedSources = useOmittedSources()
  const omittedRepos = useOmittedRepos()
  const omittedSensors = useOmittedSensors()
  const qualityCeil = useQualityCeil()
  const cloudCeil = useCloudCeil()
  const setOmittedSources = useSetOmittedSources()
  const setOmittedRepos = useSetOmittedRepos()
  const setOmittedSensors = useSetOmittedSensors()
  const setQualityCeil = useSetQualityCeil()
  const setCloudCeil = useSetCloudCeil()

  const { source, repo, sensor } = useImageResultsPropertyHistogram()
  const { isReadyForInteraction } = useSearchStatus()
  const [panelOpen, setPanelOpen] = useState(false)
  const isFiltersActive =
    omittedSources.length > 0 ||
    omittedRepos.length > 0 ||
    (qualityCeil || 4) < 4 ||
    (cloudCeil || 5) < 5 ||
    omittedSensors.length > 0

  useEffect(() => {
    if (!isReadyForInteraction) {
      setPanelOpen(false)
    }
  }, [isReadyForInteraction])

  const makeActionButton = (
    icon: SymbolName,
    tooltip: string,
    isActive: boolean,
    component: React.ReactElement,
  ) => {
    const actionIcon = (
      <Menu shadow="md" width={200}>
        <Tooltip label={tooltip}>
          <Menu.Target>
            <ActionIcon
              disabled={!isReadyForInteraction}
              onClick={() => {
                setPanelOpen(true)
              }}
              bg="#FFF"
              p="4px"
              display="inline-flex"
              style={{
                color: '#666',
                borderRadius: '40px',
                alignItems: 'center',
              }}
            >
              <Icon name={icon} />
            </ActionIcon>
          </Menu.Target>
        </Tooltip>
        <Menu.Dropdown>{component}</Menu.Dropdown>
      </Menu>
    )

    const indicatorColor = isActive ? 'blue' : '#00000000'

    return <Indicator color={indicatorColor}>{actionIcon}</Indicator>
  }
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: panelOpen ? '#D9D9D9' : '',
        width: 'fit-content',
        borderRadius: '28px',
        height: '38px',
        flexShrink: 0,
        verticalAlign: 'center',
        padding: '5px',
      }}
    >
      <Group gap={0}>
        <Transition
          mounted={panelOpen}
          transition="fade"
          duration={200}
          timingFunction="ease"
          keepMounted={true}
        >
          {(styles) => (
            <Stack
              gap="xs"
              justify="none"
              align="flex-start"
              style={{
                ...styles,
              }}
            >
              <Group align="baseline" w="100%" gap={4}>
                {makeActionButton(
                  'satellite_alt',
                  'Sources',
                  omittedSources.length > 0,
                  <PostfilterCheckboxButton
                    label="Image Source"
                    histogrammedOptions={source}
                    omittedOptions={omittedSources}
                    setNewOmittedOptions={setOmittedSources}
                    isMenu
                  />,
                )}
                {makeActionButton(
                  'database',
                  'Repositories',
                  omittedRepos.length > 0,
                  <PostfilterCheckboxButton
                    label="Image Repository"
                    histogrammedOptions={repo}
                    omittedOptions={omittedRepos}
                    setNewOmittedOptions={setOmittedRepos}
                    isMenu
                  />,
                )}
                {makeActionButton(
                  'camera',
                  'Sensors',
                  omittedSensors.length > 0,
                  <PostfilterCheckboxButton
                    label="Image Sensor"
                    histogrammedOptions={sensor}
                    omittedOptions={omittedSensors}
                    setNewOmittedOptions={setOmittedSensors}
                    isMenu
                  />,
                )}
                {makeActionButton(
                  'background_grid_small',
                  'Quality',
                  (qualityCeil || 4) < 4,
                  <PostFilterQualityButton
                    currentCeil={qualityCeil}
                    setCeil={setQualityCeil}
                  />,
                )}
                {makeActionButton(
                  'cloud',
                  'Cloud Cover',
                  (cloudCeil || 5) < 5,
                  <PostfilterCloudButton
                    currentCeil={cloudCeil}
                    setCeil={setCloudCeil}
                  />,
                )}
              </Group>
            </Stack>
          )}
        </Transition>
        <Indicator color={isFiltersActive && !panelOpen ? 'blue' : '#00000000'}>
          <ActionIcon
            className="filterToggle"
            p="4px"
            onClick={() => setPanelOpen(!panelOpen)}
            style={{
              color: '#666',
              borderRadius: '40px',
              background: 'transparent',
            }}
          >
            <Icon name={panelOpen ? 'close' : 'filter_alt'} />
          </ActionIcon>
        </Indicator>
      </Group>
    </div>
  )
}
