import { type MutableRefObject, useCallback, useEffect } from 'react'
import { makeImageryResultsBoundingBox } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useMapBbox } from '@/stores/map-store'
import { useCurrentQueryFilters } from '@/stores/queries-store'
import { useCategorizedResults } from '@/stores/results-store'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type mapboxgl from 'mapbox-gl'

export const useEmbeddedInitialBehavior = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const categorized = useCategorizedResults()
  const currentQueryFilters = useCurrentQueryFilters()
  const mapBbox = useMapBbox()

  const flyToResults = useCallback(
    (results: ImageryResult[]) => {
      const bbox = makeImageryResultsBoundingBox(
        results,
      ) as mapboxgl.LngLatBoundsLike

      map.current?.fitBounds(bbox, {})
    },
    [map],
  )
  useEffect(() => {
    if (categorized.imageResults.length > 0) {
      flyToResults(categorized.imageResults)
    } else if (categorized.dataResults.length > 0) {
      const coordinates = categorized.dataResults[0].geometry
        .coordinates as number[]
      map.current?.flyTo({ center: [coordinates[0], coordinates[1]] })
    }
  }, [
    categorized.imageResults,
    categorized.dataResults,
    currentQueryFilters,
    flyToResults,
    map,
    mapBbox,
  ])
}
