import { getUserAuthToken } from '@/api/danti-requests'
import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'

const HTTP_ENDPOINT = import.meta.env.VITE_GRAPHQL_ROUTER as string

const httpLink = new HttpLink({ uri: HTTP_ENDPOINT })

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${getUserAuthToken()}`,
    },
  }))

  return forward(operation)
})

export const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
})
