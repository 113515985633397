import { DATA_CATEGORIES, type RESULT_CATEGORIES } from '@/utils/constants'
import type { MultiPolygon, Point, Polygon } from 'geojson'
import {
  isFileResult,
  isFireResult,
  isShipResult,
} from './result-type-checkers'

export type AssetType = {
  title: string
  type: string
  description?: string
  href: string
  name?: string
}

export interface AnalyticProperty {
  class_name: string
  geometry: Polygon
  score?: number | null
}

export type JSONValue =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JSONValue }
  | JSONValue[]
  | AnalyticProperty[]
  | Polygon
  | Point
  | undefined

export enum CategoryTypes {
  IMAGE = 'IMAGE',
  DATA = 'DATA',
  PUBLICATION = 'WEB_ARTICLE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  INSIGHT = 'INSIGHT',
  RELATED_IMAGERY = 'RELATED_IMAGERY',
  ANALYTIC = 'ANALYTIC',
  PROPERTY = 'PROPERTY',
  FILE = 'FILE',
}

// TODO: Complete Provider enum with full list of options before using
// export enum Provider {
//   GDELT = 'GDELT',
//   SEERIST = 'SEERIST',
//   LANDSAT = 'LANDSAT',
//   PLANET = 'Planet Labs',
//   GEGD = 'GEGD',
//   FIRMS = 'FIRMS',
//   SPIRE = 'SPIRE',
// }

export interface GenericResult {
  [key: string]: JSONValue
}

export interface RawSearchResult {
  title: string
  subtitle: string
  filetype: string
  category: keyof typeof RESULT_CATEGORIES
  geometry: Polygon | Point | MultiPolygon
  documentId: string
  score: number
  asset: { [key: string]: AssetType }
  // TODO: There is a lot of repetition in these properties, from the root level
  //  to `properties` to some `_d` prefixed properties. We should work on
  //  consolidating these into a coherent object and removing the `_d` prefix.
  //  There is also a lot of inconsistency of data types in similar fields.
  properties: {
    _dId: string
    _dCategory: ValueOf<typeof DATA_CATEGORIES>
    _dPreview?: string
    _dProvider: string
    datetime: string
    link?: string
    resultCategory?: string
    [key: string]: JSONValue
  }
  authoredOn: string
  source: string
  tags: string[]
  thumbnail: string | null
  license?: string
  description?: string
}

export interface ResultWithId extends RawSearchResult {
  id: string
}

export interface DataResult extends ResultWithId {
  id: string
  category: typeof DATA_CATEGORIES.DATA
}

export interface PropertyResult extends Omit<ResultWithId, 'category'> {
  id: string
  category: typeof DATA_CATEGORIES.PROPERTY
}

export interface RawAttomResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'ATTOM'
    datetime: string
    updatedAt: string
    address: {
      oneLine: string
    }
    buildingPermits: {
      buildingPermits: {
        businessName: string
        description: string
        effectiveDate: string
        homeOwnerName: string
        jobValue: number
        permitNumber: string
        status: string
        type: string
      }[]
    }
  }
}
export interface RawHazardPropertyResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'HAZARD'
    _dPreview: string
    datetime: string
    property: {
      address: string
      apn: string
      owner: string
      street_address: string
      use_code: string
      zip: string
    }
    listing_record: {
      list_date: string
      list_price: string
      status: string
    }
    images: {
      images: string[]
    }
    mls_listing_record_details: {
      rm_baths_total: number
      rm_bedrooms_total: number
      in_year_built: number
      rm_baths_partial: number
      if_cooling_features: string
      in_public_remarks: string
    }
  }
}
export interface RawHazardRiskResult extends PropertyResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'HAZARD'
    datetime: string
    address: string
    danti_highlights: {
      [key: string]: {
        risk?: string
        title: string
        description?: string
        score?: string
      }
    }
  }
}
export interface RawRegridResult extends PropertyResult {
  properties: PropertyResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.REGRID
    address: string
    city?: string
    county?: string
    legalDescription?: string
    geoid?: string
    lotSizeAcres?: number
    lotSizeSquareFeet?: number
    parcelNumber?: string
    updatedAt?: string
  }
}
export interface ImageryResult extends ResultWithId {
  id: string
  thumbnail: string
  properties: ResultWithId['properties'] & {
    cloudPercent?: number
    datetime?: string
    georeferenced?: boolean
    gsd?: number | string
    offNadirAngle?: number | string
    orthorectified?: boolean
    productType?: string
    sensorId?: string
    sumAzimuth?: number
    vendorId?: string
    _dQuality: number
    _dCloudCover: number
    _dCollect?: string
    _dSensor?: string
    _dSource?: string
    featureId?: string
  }
  geometry: Polygon | MultiPolygon
  category:
    | typeof DATA_CATEGORIES.IMAGE
    | typeof DATA_CATEGORIES.RELATED_IMAGERY
    | typeof DATA_CATEGORIES.ANALYTIC
}

export function isImageryResult(result: ResultWithId): result is ImageryResult {
  return [
    DATA_CATEGORIES.IMAGE,
    DATA_CATEGORIES.RELATED_IMAGERY,
    DATA_CATEGORIES.ANALYTIC,
  ].includes(result.category)
}

export interface RawAnalyticResult extends ImageryResult {
  id: string
  thumbnail: string
  properties: ImageryResult['properties'] & {
    _dQuality?: number
    _dAnalytic: AnalyticProperty[]
  }
  category: typeof DATA_CATEGORIES.ANALYTIC
}
export interface AnalyticResult extends RawAnalyticResult {
  properties: RawAnalyticResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.ANALYTIC
  }
}

export function isAnalyticResult(
  result: ResultWithId,
): result is AnalyticResult {
  return [DATA_CATEGORIES.ANALYTIC].includes(result.category)
}

export interface RawRelatedImageryResult
  extends Omit<ImageryResult, 'category'> {
  category: typeof DATA_CATEGORIES.RELATED_IMAGERY
}
export interface RelatedImageryResult extends RawRelatedImageryResult {
  properties: RawRelatedImageryResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.RELATED_IMAGERY
  }
}

export function isRelatedImageryResult(
  result: ResultWithId,
): result is RelatedImageryResult {
  return result.category === DATA_CATEGORIES.RELATED_IMAGERY
}

export interface InsightResult extends ResultWithId {
  description: string
  category: typeof DATA_CATEGORIES.INSIGHT
}
export interface InsightResult extends ResultWithId {
  properties: ResultWithId['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.INSIGHT
  }
}

export function isInsightResult(result: ResultWithId): result is InsightResult {
  return result.category === DATA_CATEGORIES.INSIGHT
}

export type EmbeddedImages = { url: string; caption: string }[]
export interface RawPublicationResult extends ResultWithId {
  description: string
  category: typeof DATA_CATEGORIES.PUBLICATION
  properties: ResultWithId['properties'] & {
    _dEmbedded?: EmbeddedImages
    _dSource?: string
    summary?: string
  }
}
export interface PublicationResult extends RawPublicationResult {
  properties: RawPublicationResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.PUBLICATION
  }
}

export function isPublicationResult(
  result: ResultWithId,
): result is PublicationResult {
  return result.category === DATA_CATEGORIES.PUBLICATION
}

export interface RawSocialMediaResult extends ResultWithId {
  description: string
  category: typeof DATA_CATEGORIES.SOCIAL_MEDIA
  asset: { thumbnail: AssetType }
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.SOCIAL_MEDIA
    id: string
    title: string
    source: string
    summary: string
    src_image_url?: string
    start_datetime?: string | null
    end_datetime?: string | null
    created?: string | null
    updated?: string | null
    platform?: string | null
    instruments?: string | null
    constellation?: string | null
    mission?: string | null
    providers?: string[] | null
    gsd?: string | null
    topic_scores_v2?:
      | { name: string; score: number }[]
      | {
          topic_scores_v2:
            | { name: string; score: number }[]
            | { topic_scores_v2: { name: string; score: number }[] | null }
            | null
        }
      | null
    labeled_categories?:
      | { name: string; score: number }[]
      | {
          labeled_categories:
            | { name: string; score: number }[]
            | { labeled_categories: { name: string; score: number }[] | null }
            | null
        }
      | null
    _timestamp?: string | null
    _completed?: string | null
    cluster_id?: string | null
    cluster_size?: number | null
    sentiment?: number | null
    emotions?:
      | string[]
      | { emotions: string[] | { emotions: string[] | null } | null }
      | null
    veracity?: string | null
    lang?: string | null
    filtered_cluster_size?: number | null
  }
}

export interface SocialMediaResult extends RawSocialMediaResult {
  properties: RawSocialMediaResult['properties'] & {
    resultCategory: typeof RESULT_CATEGORIES.SOCIAL_MEDIA
  }
}

export function isSocialMediaResult(
  result: ResultWithId,
): result is SocialMediaResult {
  return result.category === DATA_CATEGORIES.SOCIAL_MEDIA
}

export interface RawFireResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'FIRMS'
    firms_metadata: {
      latitude: number
      longitude: number
      scan: number
      track: number
      acq_date: string
      acq_time: number
      bright_ti4: number
      bright_ti5: number
      frp: number
      instrument: string
      satellite: string
      confidence: string
      version: string
      daynight: string
    }
  }
}
export interface RawVesselResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'SPIRE'
    recordType: string
    datetime: string
    updateTimestamp: string
    staticData: {
      name: string | null
      imo: number | null
      mmsi: number
      shipType?: string
      flag?: string
      callsign?: string
    }
    lastPositionUpdate: {
      latitude: number
      longitude: number
      navigationalStatus: string
      timestamp: string
      updateTimestamp: string
    }
    currentVoyage: {
      destination: string
      draught: number
      eta: string
      timestamp: string
      updateTimestamp: string
    } | null
  }
}
export interface PropertyResult extends Omit<ResultWithId, 'category'> {
  id: string
  category: typeof DATA_CATEGORIES.PROPERTY
}
export interface RawPortResult extends DataResult {
  properties: ResultWithId['properties'] & {
    _dCategory: typeof DATA_CATEGORIES.DATA
    _dProvider: 'SPIRE'
    state: string
    recordType: string
    timestamp: string
    datetime: string
    updateTimestamp: string
    ata: string
    atd: string
    draughtAta: string
    draughtAtd: string
    draughtChange: number
    duration: number
    location: {
      name: string
      centerPoint: {
        latitude: number
        longitude: number
      }
      country: string
      id: string
      type: string
      unlocode: string
    }
    vessel: {
      id: string
      staticData: {
        callsign: string
        imo: number
        mmsi: number
        name: string
        shipType: string
      }
    }
  }
}

export type CategorizedResults = {
  imageResults: (ImageryResult | RelatedImageryResult)[]
  dataResults: DataResult[]
  publicationResults: PublicationResult[]
  socialMediaResults: SocialMediaResult[]
  insightResults: InsightResult[]
  propertyResults: PropertyResult[]
}

export function getLabelForResult(result: ResultWithId) {
  if (isAnalyticResult(result)) {
    return 'Analytic'
  }
  if (isImageryResult(result)) {
    return 'Image'
  }
  if (isSocialMediaResult(result)) {
    return 'Social'
  }
  if (isFireResult(result)) {
    return 'Fire'
  }
  if (isShipResult(result)) {
    return 'Ship'
  }
  if (isFileResult(result)) {
    return 'File'
  }

  return 'News'
}
