import React from 'react'
import { Card, type CardProps } from '@mantine/core'
import {
  CARD_SIZE_KEYS,
  CARD_STYLE_PROPS,
  LARGE_CARD_SIZE,
  MEDIUM_CARD_SIZE,
} from '@/features/BentoResults/constants'
import { ResultActions } from '@/features/ResultActions'
import { MENU_OPTION_KEYS } from '@/features/ResultActions/constants'
import { useMenuOptionActions } from '@/features/ResultActions/hooks'
import type { ActionMenuOption } from '@/features/ResultActions/types'
import type { CollectionItem } from '@/utils/types/collection-types'
import type { ResultWithId } from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'
import {
  type BentoCardContentParams,
  default as BentoCardContent,
} from './BentoCardContent'

import styles from './bento-card.module.css'

export interface BentoCardParams extends CardProps, BentoCardContentParams {
  totalResults?: number
  resultCategory: string
  item?: CollectionItem
  result: ResultWithId
  hideActions?: ActionMenuOption[]
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  className?: string
  size?: string
}

export const BentoCard = React.forwardRef(
  (
    params: BentoCardParams,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      item,
      result,

      socialIcon,
      highlightIcon,
      icon,
      svgIcon,

      content,
      geometry,
      image,

      resultCategory,
      totalResults,
      onMouseEnter,
      onMouseLeave,

      className,
      hideActions = [],
      size = CARD_SIZE_KEYS.medium,
      style,
      ...props
    } = params

    const allActions = useMenuOptionActions(result)

    const cardSize =
      size === CARD_SIZE_KEYS.large ? LARGE_CARD_SIZE : MEDIUM_CARD_SIZE

    return (
      <Card
        ref={forwardedRef}
        className={classes(styles['hidden-panel'], className)}
        onClick={allActions[MENU_OPTION_KEYS.viewDetails]}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        h={cardSize}
        w={cardSize}
        bg={content?.color}
        {...CARD_STYLE_PROPS}
        style={style}
        {...props}
      >
        <BentoCardContent
          {...{
            content,
            geometry,
            highlightIcon,
            icon,
            image,
            size,
            socialIcon,
            svgIcon,
          }}
        />
        <ResultActions
          hideActions={hideActions}
          result={result}
          className={styles.reveal}
          iconClassName={styles.clickable}
        />
        {totalResults && totalResults > 1 && (
          <>
            <div className={styles['number-container']} />
            <span className={styles['number']}>{totalResults}</span>
          </>
        )}
      </Card>
    )
  },
)
BentoCard.displayName = 'BentoCard'

export default BentoCard
