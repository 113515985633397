import {
  ActionIcon,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useResultMenuActions } from '@/features/ResultActions/hooks'
import type { ActionMenuOption } from '@/features/ResultActions/types'
import { classes } from '@/utils/ui-helpers'

import styles from './result-actions.module.css'

export interface ResultActionsProps<Type> {
  hideActions: ActionMenuOption[]
  result: Type
  className?: string
  iconClassName?: string
}

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation()
}

export function ResultActions<Type>({
  hideActions,
  result,
  className,
  iconClassName,
}: ResultActionsProps<Type>) {
  const actions = useResultMenuActions({ result, hideActions })
  return (
    <Menu trigger="hover" shadow="md">
      <MenuTarget data-cy="resultActionMenu">
        <ActionIcon
          variant="white"
          className={classes(iconClassName ?? styles.clickable, className)}
          onClick={stopPropagation}
        >
          <Icon name="more_horiz" size={24} />
        </ActionIcon>
      </MenuTarget>
      <MenuDropdown>
        {actions.map(
          ({ label, icon, onClick: actionOnClick, ...passProps }) => (
            <MenuItem
              key={label}
              {...passProps}
              leftSection={<Icon name={icon} />}
              onClick={actionOnClick}
              p="xs"
              className={styles['menu-hover']}
            >
              {label}
            </MenuItem>
          ),
        )}
      </MenuDropdown>
    </Menu>
  )
}
