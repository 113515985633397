import React from 'react'
import { createStaticMapUrl } from '@/api/mapbox'
import { useMapboxMap } from '@/components/lib/map-search/hooks/use-mapbox-map'
import { MAP_BASE_LAYERS } from '@/utils/constants'
import * as turf from '@turf/turf'
import type mapboxgl from 'mapbox-gl'
import pluralize from 'pluralize'
import {
  addSourcesToMap,
  formatAvailableDays,
  formatDateYearFirst,
  getInitialSelectedResult,
  makeTimelapseResults,
  pruneAndGroupTimelapseResults,
  toggleImageLayerVisibility,
} from './helpers'
import type { DateKey, UseTimelapseProps } from './types'
import { useTileUrls } from './use-tile-urls'

export function useTimelapse({
  resultId,
  imageResults = [],
}: UseTimelapseProps) {
  const { bounds, sortedImageLayers } = makeTimelapseResults(
    imageResults,
    resultId,
  )
  const tileUrls = useTileUrls(
    sortedImageLayers.map((layer) => ({
      url: layer.url,
      isImageSource: layer.isImageSource,
    })),
  )
  const { sortedResults, resultsByDay } = pruneAndGroupTimelapseResults(
    sortedImageLayers,
    tileUrls,
  )
  const [selectedResult, setSelectedResult] = React.useState(
    getInitialSelectedResult(sortedResults, imageResults[0], resultId),
  )

  const daysAvailable = Object.keys(resultsByDay) as DateKey[]
  const totalResults = sortedResults.length
  const totalDays = daysAvailable.length
  const firstDay = daysAvailable[0]
  const lastDay = daysAvailable.at(-1)

  const sortedItemIndex = sortedResults.findIndex(
    ({ result }) => result.id === selectedResult.id,
  )
  const realInitialIndex = sortedItemIndex === -1 ? 0 : sortedItemIndex
  const [selectedIndex, setSelectedIndex] = React.useState(realInitialIndex)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const previousIsPlaying = React.useRef(isPlaying)
  const [isLooping, setIsLooping] = React.useState(true)
  const [selectedDay, setSelectedDay] = React.useState(firstDay)

  const handleTimeChange = React.useCallback(
    (index: number) => {
      const newResult = sortedResults[index].result
      const day = formatDateYearFirst(new Date(newResult.timestamp))
      if (day !== selectedDay) {
        setSelectedDay(day)
      }
      setSelectedResult(newResult)
      setSelectedIndex(index)
    },
    [selectedDay, sortedResults],
  )

  const mapContainer = React.useRef(null)
  const map = React.useRef<mapboxgl.Map | null>(null)
  useMapboxMap(map, mapContainer, {
    baseLayer: MAP_BASE_LAYERS.dantiStreets,
    interactive: false,
  })

  const minimap = selectedResult
    ? createStaticMapUrl({
        geometry: selectedResult.geometry,
        baseMap: MAP_BASE_LAYERS.dantiStreets,
        size: [150, 100],
        attribution: false,
      })
    : ''

  // Add initial sources
  React.useEffect(() => {
    if (map.current) {
      addSourcesToMap({
        map: map.current,
        imageLayers: sortedImageLayers,
        bounds,
      })
    }
  }, [sortedImageLayers, bounds])

  // Set map bounds to the selected result
  React.useLayoutEffect(() => {
    if (map.current && selectedResult) {
      const centroid = turf.centroid(bounds.polygon).geometry
        .coordinates as mapboxgl.LngLatLike

      const { zoom } = map.current.cameraForBounds(
        bounds.bbox as mapboxgl.LngLatLike,
        { padding: 20 },
      )!
      map.current.jumpTo({ center: centroid, zoom })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds])

  // Update the images when the slider value changes
  React.useEffect(() => {
    if (map.current) {
      toggleImageLayerVisibility({
        map: map.current,
        selectedIndex,
        totalResults,
      })
    }
  }, [selectedIndex, totalResults])

  // Handle "play" functionality
  React.useEffect(() => {
    let interval: NodeJS.Timeout
    const shouldStop = !isLooping && selectedIndex === totalResults - 1
    // Stop if looping is off and the previous value of `isPlaying` was true
    if (shouldStop && previousIsPlaying.current) {
      previousIsPlaying.current = false
      setIsPlaying(false)
      return
    }
    if (isPlaying) {
      const nextIndex = (selectedIndex + 1) % sortedResults.length
      // If the play button was just clicked, update the time immediately and
      // allow the user to begin play from the last image with looping off
      if (!previousIsPlaying.current) {
        previousIsPlaying.current = true
        handleTimeChange(nextIndex)
      }

      interval = setInterval(() => {
        handleTimeChange(nextIndex)
      }, 500)
    }
    return () => interval && clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, selectedIndex, sortedResults])

  const markValues = sortedResults.map(({ result }, index) => ({
    value: index,
    label: formatDateYearFirst(new Date(result.timestamp)),
  }))
  const valueFormatter = (value: number) =>
    formatAvailableDays(
      formatDateYearFirst(new Date(sortedResults[value]?.result.timestamp)),
    )
  const dataSummary =
    totalResults > 0
      ? `${totalResults} images from ${totalDays} unique ` +
        `${pluralize('day', totalDays)} between ` +
        `${formatAvailableDays(firstDay)} and ${formatAvailableDays(lastDay)}`
      : 'No images available'

  return {
    resultsByDay,
    isPlaying,
    isLooping,
    selectedDay,
    selectedIndex,
    totalResults,
    mapContainer,
    minimap,
    markValues,
    messages: {
      dataSummary,
    },
    actions: {
      setIsPlaying,
      setIsLooping,
      handleTimeChange,
      valueFormatter,
    },
  }
}
