import { MultiSelect } from '@mantine/core'
import { useProvidersFilter, useSetProviders } from '@/stores/filters-store'
import { Provider } from '@/utils/types/filter-types.ts'

export function ProvidersStatic() {
  const providers = useProvidersFilter()
  const setProviders = useSetProviders()

  const updateProviders = (selectedProviders: string[]) => {
    const providersArray: string[] = selectedProviders.map((provider) => {
      const providerKey = Object.keys(Provider).find((key) => key === provider)
      return providerKey as keyof typeof Provider
    })
    setProviders(providersArray)
  }

  const providerLabels = Object.entries(Provider)
    .filter(([value]) => ['PLANET', 'GEGD', 'UP42', 'BLACKSKY'].includes(value))
    .map(([value, label]) => ({ value, label }))

  return (
    <MultiSelect
      mt="sm"
      placeholder={providers.length === 0 ? 'Any' : ''}
      value={providers}
      onChange={updateProviders}
      data={providerLabels}
      searchable
      multiple
    />
  )
}
