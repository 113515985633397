import { useIdFilterResults } from '@/hooks/results/result-filter-utilities.ts'
import { useHexgridSelectedIds } from '@/stores/results-filter-store'
import {
  type Results,
  useCategorizedResults,
  useCurrentQueryId,
  useIds,
} from '@/stores/results-store'
import type { ResultWithId } from '@/utils/types/result-types.ts'

export const useHexgridFilteredResults = (
  isolateType?: (result: ResultWithId) => boolean,
): Results => {
  const currentQueryId = useCurrentQueryId()
  const ids = useIds()
  const categorized = useCategorizedResults()

  const hexgridSelectedIds = useHexgridSelectedIds()

  const resultsBundle: Results = { ids, categorized, currentQueryId }

  return useIdFilterResults(
    (r) =>
      hexgridSelectedIds && hexgridSelectedIds.length > 0
        ? hexgridSelectedIds.includes(r.id)
        : isolateType
          ? isolateType(r)
          : true,
    resultsBundle,
  )
}
