import { POINT_LAYER_NAMES } from '@/components/lib/map-search/Point/use-point-data.ts'
import type mapboxgl from 'mapbox-gl'
import type { MutableRefObject } from 'react'

export const addPointLayers = (
  map: MutableRefObject<mapboxgl.Map | null>,
  showPointData: boolean,
) => {
  if (!map.current) {
    return
  }

  const layers = map.current?.getStyle().layers
  // Find the index of the first symbol layer in the map style.
  let firstSymbolId
  for (const layer of layers) {
    if (layer.type === 'symbol') {
      firstSymbolId = layer.id
      break
    }
  }

  if (map.current?.getLayer(POINT_LAYER_NAMES.NEWS)) {
    return
  } else {
    map.current?.addLayer(
      {
        id: POINT_LAYER_NAMES.NEWS,
        type: 'symbol',
        source: 'news-point',
        layout: {
          visibility: showPointData ? 'visible' : 'none',
          'icon-image': 'Point Data Icon-News',
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
      firstSymbolId,
    )
  }

  if (map.current?.getLayer(POINT_LAYER_NAMES.FIRE)) {
    return
  } else {
    map.current?.addLayer(
      {
        id: POINT_LAYER_NAMES.FIRE,
        type: 'symbol',
        source: 'fire-point',
        layout: {
          visibility: showPointData ? 'visible' : 'none',
          'icon-image': 'Point Data Icon-Fire',
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
      firstSymbolId,
    )
  }

  if (map.current?.getLayer(POINT_LAYER_NAMES.SHIP)) {
    return
  } else {
    map.current?.addLayer(
      {
        id: POINT_LAYER_NAMES.SHIP,
        type: 'symbol',
        source: 'ship-point',
        layout: {
          visibility: showPointData ? 'visible' : 'none',
          'icon-image': 'Point Data Icon-Ship',
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
      firstSymbolId,
    )
  }

  if (map.current?.getLayer(POINT_LAYER_NAMES.SOCIAL)) {
    return
  } else {
    map.current?.addLayer(
      {
        id: POINT_LAYER_NAMES.SOCIAL,
        type: 'symbol',
        source: 'social-point',
        layout: {
          visibility: showPointData ? 'visible' : 'none',
          'icon-image': 'Point Data Icon-Social',
          'icon-size': 1,
          'icon-allow-overlap': true,
        },
      },
      firstSymbolId,
    )
  }
}
