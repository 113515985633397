import * as React from 'react'

interface LocalNavigation<T> {
  toNext?: () => void
  toPrevious?: () => void
  selectItem: (item: T, index: number) => void
  current?: T
  localIndex?: number
}

export const nullMethod = () => {}

export const useLocalNavigation = <T>(items: T[]): LocalNavigation<T> => {
  const [localIndex, setLocalIndex] = React.useState(0)
  const navigateCallback = (offset: number) => () => {
    setLocalIndex(localIndex + offset)
  }

  return items.length === 0
    ? {
        selectItem: nullMethod,
      }
    : {
        current: items[localIndex],
        selectItem: (_, index: number) => setLocalIndex(index),
        localIndex,
        toPrevious: localIndex > 0 ? navigateCallback(-1) : undefined,
        toNext: localIndex < items.length - 1 ? navigateCallback(1) : undefined,
      }
}
