import { modals } from '@mantine/modals'
import type { SaveSearchSettingsModalParams } from '@/components/modals'

export function openSaveSearchModal({
  refId,
  params,
}: SaveSearchSettingsModalParams): void {
  modals.openContextModal({
    modal: 'saveSearch',
    title: (
      <span style={{ fontSize: '32px', fontWeight: 700 }}>
        {params?.mode === 'feed' ? 'Configure your feed' : 'Save your search'}
      </span>
    ),
    size: 'xl',
    centered: true,
    innerProps: { refId, params },
  })
}
