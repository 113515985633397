import React from 'react'
import { Stack } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useConfigureSavedSearch } from '@/components/modals/SaveSearchModal/SaveSearchPanel/use-configure-saved-search.ts'
import { TerrapinStaticMapDisplay } from '@/components/modals/SaveSearchModal/TerrapinLocationPanel/TerrapinStaticMapDisplay.tsx'
import type {
  CreateTerrapinParams,
  TerrapinLocation,
} from '@/hooks/use-terrapin'
import { SaveSearchPanel } from './SaveSearchPanel'
import { TerrapinLocationPanel } from './TerrapinLocationPanel'

export interface SaveSearchSettingsModalParams {
  params?: CreateTerrapinParams
  refId?: string
  isNew?: boolean
}

enum SaveSearchModalPanel {
  SAVE_SEARCH = 'SAVE_SEARCH',
  TERRAPIN_LOCATION = 'TERRAPIN_LOCATION',
  TERRAPIN_MAP = 'TERRAPIN_MAP',
}

export function SaveSearchModal({
  context,
  id,
  innerProps: { refId, params: savedConfig },
}: ContextModalProps<SaveSearchSettingsModalParams>) {
  const {
    queryString,
    setQueryString,
    name,
    setName,
    isSaving,
    handleSave,
    expirationDate,
    setExpirationDate,
    emailNotification,
    setEmailNotification,
    backfill,
    setBackfill,
    locations,
    setLocations,
  } = useConfigureSavedSearch({ savedConfig, refId })

  const [panel, setPanel] = React.useState(SaveSearchModalPanel.SAVE_SEARCH)
  const [currentLocation, setCurrentLocation] =
    React.useState<TerrapinLocation | null>(null)

  // AreaOfInterest
  const [fileName, setFileName] = React.useState<string>()

  const handleLocationsSave = (locations: TerrapinLocation[]) => {
    setLocations(locations)
    setPanel(SaveSearchModalPanel.SAVE_SEARCH)
  }

  const handleTerrapinMapBack = () => {
    setCurrentLocation(null)
    setPanel(SaveSearchModalPanel.TERRAPIN_LOCATION)
  }

  return (
    <Stack>
      {panel == SaveSearchModalPanel.SAVE_SEARCH && (
        <SaveSearchPanel
          queryString={queryString}
          setQueryString={setQueryString}
          name={name}
          setName={setName}
          isSaving={isSaving}
          handleSave={handleSave}
          expirationDate={expirationDate}
          setExpirationDate={setExpirationDate}
          emailNotification={emailNotification}
          setEmailNotification={setEmailNotification}
          backfill={backfill}
          setBackfill={setBackfill}
          locations={locations}
          setLocations={setLocations}
          fileName={fileName}
          setFileName={setFileName}
          refId={refId}
          savedConfig={savedConfig}
          onEditLocations={() =>
            setPanel(SaveSearchModalPanel.TERRAPIN_LOCATION)
          }
          onClose={() => context.closeModal(id)}
        />
      )}
      {panel == SaveSearchModalPanel.TERRAPIN_LOCATION && (
        <TerrapinLocationPanel
          onOpenMap={() => setPanel(SaveSearchModalPanel.TERRAPIN_MAP)}
          locations={locations}
          onSave={handleLocationsSave}
          onCancel={() => setPanel(SaveSearchModalPanel.SAVE_SEARCH)}
          setCurrentLocation={setCurrentLocation}
        />
      )}
      {panel == SaveSearchModalPanel.TERRAPIN_MAP && currentLocation && (
        <TerrapinStaticMapDisplay
          location={currentLocation}
          fullScreen
          onBack={handleTerrapinMapBack}
        />
      )}
    </Stack>
  )
}
