import { type CardProps, Group, Stack, Table, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import type { AttomResult as AttomResultType } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface AttomResultProps extends CardProps {
  result: AttomResultType
  isFullscreen?: boolean
}

type Permit = {
  status: string
  permitNumber: string
  effectiveDate: string
  description: string
  businessName?: string
  homeOwnerName?: string
  jobValue?: number
  type: string
}

interface FormattedAttomData {
  address: string
  updatedAt: string
  permits: Permit[]
}

function formatPermit(permit: Permit) {
  return {
    status: permit.status,
    permitNumber: permit.permitNumber,
    effectiveDate: dayjs(permit.effectiveDate).format('YYYY-MM-DD'),
    description: permit.description,
    businessName: permit.businessName,
    homeOwnerName: permit.homeOwnerName,
    jobValue: permit.jobValue,
    type: permit.type,
  }
}

function formatAttomData(result: AttomResultType): FormattedAttomData {
  const { properties } = result
  const { buildingPermits } = properties
  return {
    updatedAt: dayjs(properties.updatedAt).format('YYYY-MM-DD'),
    address: properties.address,
    ...(buildingPermits && {
      permits: buildingPermits.map(formatPermit),
    }),
  }
}

export function AttomResult({
  result,
  isFullscreen,
  ...props
}: AttomResultProps) {
  const { address, updatedAt, permits = [] } = formatAttomData(result)

  return permits.map((permit, index) => (
    <ResultBase
      key={index}
      result={result}
      onClick="viewDetails"
      withBorder
      radius="sm"
      style={{ borderColor: 'var(--mantine-color-gray-2)' }}
      data-cy="shipResult"
      {...props}
    >
      <Stack gap="xs" p="sm">
        <Group gap="xs">
          <Icon name="gpp_good" title="Attom result" />
          <Text size="sm">
            <b>{permit.type} Permit</b>
          </Text>
        </Group>
        <Group gap="xs">
          <Text size="sm">{address}</Text>
          <Text size="sm" color="gray">
            {updatedAt}
          </Text>
        </Group>
        <Table
          fz="xs"
          withRowBorders={false}
          className={styles['result-table']}
        >
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>Permit number</Table.Td>
              <Table.Td>{permit.permitNumber ?? '--'}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Permit date</Table.Td>
              <Table.Td>{permit.effectiveDate ?? '--'}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>description</Table.Td>
              <Table.Td>
                <Text size="xs" lineClamp={1}>
                  {permit.description ?? '--'}
                </Text>
              </Table.Td>
            </Table.Tr>
            {permit.jobValue && (
              <Table.Tr>
                <Table.Td>Job value</Table.Td>
                <Table.Td>${permit.jobValue}</Table.Td>
              </Table.Tr>
            )}
            {permit.businessName && (
              <Table.Tr>
                <Table.Td>Business name</Table.Td>
                <Table.Td>{permit.businessName}</Table.Td>
              </Table.Tr>
            )}
            {permit.homeOwnerName && (
              <Table.Tr>
                <Table.Td>Home owner name</Table.Td>
                <Table.Td>{permit.homeOwnerName}</Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </Stack>
    </ResultBase>
  ))
}
