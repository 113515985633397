import { useSingleImageMap } from '@/features/SingleObjectView/hooks'
import type { ImageryResult } from '@/utils/types/result-types.ts'

import styles from './hero.module.css'

interface SingleImageMapProps {
  item: ImageryResult
}

export function SingleImageMap({ item }: SingleImageMapProps) {
  const { imgSource, toggleLayer, mapContainer } = useSingleImageMap(item)

  return (
    <div className={styles['single-image-map']}>
      <div
        ref={mapContainer}
        className={`map-container ${styles['map-container']}`}
      />
      <img
        src={imgSource}
        width={100}
        alt="Map"
        className={styles['map']}
        onClick={toggleLayer}
      />
    </div>
  )
}
