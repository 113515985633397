import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  BackgroundImage,
  Box,
  Container,
  em,
  Stack,
  Title,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { images } from '@/assets/images'
import { SearchBar } from '@/features/Search'
import { useSetStoredLocation } from '@/stores/queries-store'

import styles from './explore.module.css'
import searchStyles from '@/features/Search/search-bar.module.css'

const intervalDuration = 10000
const fadeTransition = 1000
const delayFadeOut = intervalDuration - fadeTransition

export function Explore() {
  const location = useLocation()
  const isLogIn = location.pathname === '/log-in'
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)
  const setStoredLocation = useSetStoredLocation()

  const [imageIndex, setImageIndex] = useState(0)
  const backgroundRef = useRef<HTMLImageElement>(null)
  const placeholder =
    'Search by any query or sample-data type, address, coordinates, city, property, features...'

  useEffect(() => {
    setStoredLocation(null)
    const backgroundInterval = setInterval(() => {
      setImageIndex((index) => index + 1)
    }, intervalDuration)

    return () => {
      clearInterval(backgroundInterval)
    }
  }, [setStoredLocation])

  useLayoutEffect(() => {
    if (backgroundRef.current) {
      const timeout = setTimeout(() => {
        backgroundRef.current?.classList.add(styles['fadeOut'])
      }, delayFadeOut)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [imageIndex])

  return (
    <Container size="100%" p={0} className={searchStyles.explorePage}>
      <Box mx="auto" className={styles.container}>
        <Stack h="calc(100vh - 230px)" justify="flex-end">
          <Title
            size={isMobile ? 55 : 100}
            c="white"
            lh={1}
            p="md"
            mb="1rem"
            style={{
              textShadow: '3px 3px 7px #0006',
              letterSpacing: '-0.03em',
            }}
          >
            Search Earth&apos;s data
            <br />
            like never before.
          </Title>
          {!isLogIn && (
            <SearchBar
              placeholder={placeholder}
              style={{
                textShadow: 'none',
                width: '100%',
                minWidth: 300,
              }}
            />
          )}
        </Stack>
        <Fragment key={imageIndex}>
          <BackgroundImage
            src={images[(imageIndex + 1) % images.length]}
            pos="absolute"
            h="100%"
            top={0}
            left={0}
            style={{
              zIndex: -1,
              backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
            }}
          />
          <BackgroundImage
            src={images[imageIndex % images.length]}
            ref={backgroundRef}
            pos="absolute"
            h="100%"
            top={0}
            left={0}
            style={{
              zIndex: -1,
              opacity: 1,
              backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
            }}
          />
          <div
            style={{
              backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
              position: 'absolute',
              inset: 0,
              zIndex: -1,
            }}
          />
        </Fragment>
      </Box>
    </Container>
  )
}

export default Explore
