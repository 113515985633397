import { Icon } from '@/components/lib/Icon'
import { DetailCard } from '@/features/ResultCards/ReferenceCards/components'
import { ICONS, RESULT_CATEGORY_COLORS } from '@/utils/constants'
import type { SymbolName } from '@/utils/types/material-icons'
import type { AttomPermit } from '@/utils/types/result-types'

export const AttomPermitReferenceCard = ({
  permit,
}: {
  permit: AttomPermit
}) => (
  <DetailCard
    icon={<Icon name={ICONS.construction as SymbolName} />}
    title={permit.type.toLowerCase()}
    iconColor={RESULT_CATEGORY_COLORS.attom}
    leftDetail={permit.effectiveDate}
  />
)
