import { type MutableRefObject, useCallback, useEffect } from 'react'
import { safeSetLayoutProperty } from '@/components/lib/map-search/hooks/util/safe-map-setters.ts'
import { useActiveLayers } from '@/stores/map-store'
import type { SearchMetadata } from '@/utils/types/message-types.ts'
import * as turf from '@turf/turf'
import type { Feature } from 'geojson'
import type mapboxgl from 'mapbox-gl'

export const useLocationResponseLayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
  showExtractions: boolean,
  results: SearchMetadata | null,
) => {
  const activeLayers = useActiveLayers()
  const sourceId = 'location-response-source'

  const buildLayer = useCallback(
    (geojsonFeature: Feature) => {
      const geometryType = geojsonFeature.geometry.type
      const layoutProperties =
        geometryType === 'Point' || geometryType === 'MultiPoint'
          ? {
              'icon-image': 'Point Data Icon-Query',
              'icon-size': 1,
            }
          : null
      const paintProperties =
        geometryType === 'Polygon' || geometryType === 'MultiPolygon'
          ? {
              'fill-color': '#F2970A',
              'fill-opacity': 0.2,
              'fill-outline-color': '#F2970A',
            }
          : null
      const layer = {
        id: 'location-response-layer',
        type:
          geometryType === 'Point' || geometryType === 'MultiPoint'
            ? 'symbol'
            : 'fill',
        source: sourceId,
        layout: {
          visibility: showExtractions ? 'visible' : 'none',
          ...layoutProperties,
        },
        paint: {
          ...paintProperties,
        },
      }
      return {
        ...layer,
      }
    },
    [showExtractions],
  )

  const styleDataCallback = useCallback(() => {
    if (!results) {
      return
    }

    const geojsonFeature = turf.feature(results.metadata.geometry)
    const layer = buildLayer(geojsonFeature)
    const locationResponseSource = map.current?.getSource(
      sourceId,
    ) as mapboxgl.GeoJSONSource
    if (locationResponseSource) {
      locationResponseSource.setData(geojsonFeature)
    } else {
      map?.current?.addSource(sourceId, {
        type: 'geojson',
        data: geojsonFeature,
      })
    }
    if (map?.current?.getLayer('location-response-layer')) {
      return
    } else {
      map?.current?.addLayer({ ...layer } as
        | mapboxgl.SymbolLayer
        | mapboxgl.FillLayer)
    }
  }, [buildLayer, map, results])

  useEffect(() => {
    if (results) {
      map.current?.on('styledata', styleDataCallback)
    }
  }, [map, styleDataCallback, results])

  useEffect(
    function layerToggling() {
      void safeSetLayoutProperty(
        map,
        'location-response-layer',
        'visibility',
        showExtractions ? 'visible' : 'none',
      )
    },
    [map, activeLayers, showExtractions],
  )
}
