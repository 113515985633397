import { Group, Text } from '@mantine/core'
import type * as React from 'react'
import { SOVHeroContent, type SOVHeroContentProps } from './HeroContent'
import { SOVHeroNav } from './HeroNav'

import styles from './hero.module.css'

export interface SOVHeroProps<Type> extends SOVHeroContentProps<Type> {
  item: Type
  toNext?: () => void
  toPrevious?: () => void
  metaDetails?: string[]
  metaAction?: React.ReactNode
}

export function SOVHero<T>(props: SOVHeroProps<T>) {
  const { toNext, toPrevious, metaDetails = [], metaAction } = props

  return (
    <>
      <Group className={styles['hero-container']}>
        <SOVHeroContent {...props} />
        <SOVHeroNav {...{ toNext, toPrevious }} />
      </Group>
      <Group className={styles['hero-meta-row']}>
        <div className={styles['sov-meta-entries']}>
          <Text size="sm">
            {metaDetails.map((detail, index) => (
              <span key={index} data-testid="meta-row">
                <span data-testid="meta-label" key={index}>
                  {detail}
                </span>
                {index < metaDetails.length - 1 && (
                  <span data-testid="meta-label"> | </span>
                )}
              </span>
            ))}
          </Text>
        </div>
        <div className={styles['sov-meta-right-entry']}>{metaAction}</div>
      </Group>
    </>
  )
}
