import type { HEX_CATEGORIES } from '@/stores/map-store'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import booleanIntersects from '@turf/boolean-intersects'
import * as turf from '@turf/turf'
import type { HexGrid } from './make-bare-hex-grid.ts'

type AddResultToGridProps = {
  result: ResultWithId
  hexGrid: HexGrid
}

export const addResultToGrid: (props: AddResultToGridProps) => HexGrid = ({
  result,
  hexGrid,
}) => {
  if (!hexGrid.features) {
    return hexGrid
  }
  const updatedFeatures = hexGrid.features.map((hexFeature) => {
    return booleanIntersects(result.geometry, hexFeature)
      ? {
          ...hexFeature,
          properties: {
            ...hexFeature.properties,
            resultIds: {
              ...hexFeature.properties.resultIds,
              [result.category]: [
                ...(hexFeature.properties.resultIds[
                  result.category as ValueOf<typeof HEX_CATEGORIES>
                ] ?? []),
                result.id,
              ],
            },
            resultCount: hexFeature.properties.resultCount + 1,
          },
        }
      : hexFeature
  })

  return turf.featureCollection(updatedFeatures)
}
