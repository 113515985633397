import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { AttomPermitReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components'
import { useLocalNavigation } from '@/features/SingleObjectView/hooks'
import { getPermitDescription } from '@/stores/results-store'
import {
  ICONS,
  RESULT_CATEGORIES,
  RESULT_CATEGORY_COLORS,
} from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { SymbolName } from '@/utils/types/material-icons'
import type {
  AttomPermit,
  AttomResult,
  ResultWithId,
} from '@/utils/types/result-types'

export function AttomObjectView({ item: rawItem }: { item: ResultWithId }) {
  const item = rawItem as AttomResult
  const permits = item.properties.buildingPermits || []

  const { toPrevious, toNext, current, selectItem } =
    useLocalNavigation<AttomPermit>(permits)

  if (!current) {
    return null
  }

  return (
    <SingleObjectViewTemplate<AttomResult, AttomPermit>
      {...{
        item,
        hideActions: HIDE_ACTIONS_BY_CATEGORY[RESULT_CATEGORIES.ATTOM],
        hero: {
          details: {
            iconName: ICONS.construction as SymbolName,
            color: RESULT_CATEGORY_COLORS.attom,
            title: current.type.toLowerCase(),
            leftDetail: current.permitNumber,
            rightDetail: current.effectiveDate,
            leftDetailsColumn: [
              getPermitDescription(current),
              `Business Name: ${current.businessName || '--'}`,
              `Status: ${current.status || '--'}`,
            ],
            rightDetailsColumn: ['Owner', current.homeOwnerName],
          },
          metaDetails: [formatDatetime(item.authoredOn)],
          toNext,
          toPrevious,
        },

        content: {
          title: `Summary of Permits and Hazards for ${item.properties.address}`,
        },

        carousel: {
          title: 'All Permits',
          items: permits,
          renderCard: (permit: AttomPermit) => (
            <AttomPermitReferenceCard permit={permit} />
          ),
          selectItem,
          grid: true,
        },
      }}
    />
  )
}
