import { postRequest } from '@/api/danti-requests'
import type { GoogleMapsResponse } from '@/api/google-maps/types'

export async function geocodeAddress(value: string) {
  const { data } = await postRequest<string>('/provider/googlemaps', {
    address: value,
    // eslint-disable-next-line camelcase
    api_request_type: 'geocode',
  })
  const { results } = JSON.parse(atob(data)) as GoogleMapsResponse
  return results
}
