import { type MutableRefObject, useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'
import { usePrevious } from '@mantine/hooks'
import type { HexGrid } from '@/components/lib/map-search/hooks/util/make-bare-hex-grid.ts'
import {
  useGridCellProperties,
  useHoveredHexGridId,
  useSelectedHexGridId,
} from '@/stores/map-store'
import { useSetHexgridSelectedIds } from '@/stores/results-filter-store'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { MapHexgridInfoTooltip } from '../MapHexgridInfoTooltip.tsx'

export const useCellSelectionBehavior = (
  map: MutableRefObject<mapboxgl.Map | null>,
  hexGrid: HexGrid,
) => {
  const selectedHexGridId = useSelectedHexGridId()
  const hoveredHexGridId = useHoveredHexGridId()
  const gridCellProperties = useGridCellProperties()
  const setHexgridSelectedIds = useSetHexgridSelectedIds()
  const previousSelectedHexGridId = usePrevious(selectedHexGridId)
  const previousHoveredHexGridId = usePrevious(hoveredHexGridId)

  const hexGridInfoMarker = useRef<mapboxgl.Marker | null>(null)

  useEffect(() => {
    if (selectedHexGridId === previousSelectedHexGridId) {
      return
    }
    if (previousSelectedHexGridId) {
      map.current?.setFeatureState(
        { source: 'hexgrid', id: previousSelectedHexGridId },
        { selected: false },
      )
    }
    if (selectedHexGridId) {
      map.current?.setFeatureState(
        { source: 'hexgrid', id: selectedHexGridId },
        { selected: true },
      )

      const feature = hexGrid.features.find((f) => f.id === selectedHexGridId)
      if (feature) {
        setHexgridSelectedIds([
          ...feature.properties.resultIds['IMAGE'],
          ...feature.properties.resultIds['DATA'],
          ...feature.properties.resultIds['SOCIAL_MEDIA'],
          ...feature.properties.resultIds['WEB_ARTICLE'],
        ])
      }
    } else {
      setHexgridSelectedIds(null)
    }
  }, [
    hexGrid.features,
    map,
    previousSelectedHexGridId,
    selectedHexGridId,
    setHexgridSelectedIds,
  ])

  useEffect(() => {
    if (previousHoveredHexGridId) {
      map.current?.setFeatureState(
        { source: 'hexgrid', id: previousHoveredHexGridId },
        { hovered: false },
      )
      if (hexGridInfoMarker.current) {
        const div = hexGridInfoMarker.current.getElement()
        hexGridInfoMarker.current.remove()
        if (div) {
          div.remove()
        }
        hexGridInfoMarker.current = null
      }
    }
    if (hoveredHexGridId) {
      map.current?.setFeatureState(
        { source: 'hexgrid', id: hoveredHexGridId },
        { hovered: true },
      )

      const feature =
        hexGrid.features &&
        hexGrid.features.find((f) => f.id === hoveredHexGridId)
      if (feature) {
        const bbox = turf.bbox(feature)
        const longitude = (bbox[0] + bbox[2]) / 2
        const latitude = bbox[3]

        const makeTooltip = () => {
          const div = document.createElement('div')
          const root = createRoot(div)
          root.render(
            <MapHexgridInfoTooltip
              imageCount={feature.properties.resultIds['IMAGE'].length}
            />,
          )
          return div
        }

        if (hexGridInfoMarker.current) {
          hexGridInfoMarker.current.setLngLat([longitude, latitude])
        } else {
          const marker = new mapboxgl.Marker(makeTooltip(), {
            anchor: 'bottom',
          })
          marker.setLngLat([longitude, latitude])
          marker.addTo(map.current!)
          hexGridInfoMarker.current = marker
        }
      }
    }
  }, [
    gridCellProperties,
    hexGrid,
    hexGridInfoMarker,
    hoveredHexGridId,
    map,
    previousHoveredHexGridId,
  ])
}
