import {
  Button,
  px,
  Spoiler,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon'
import { messages } from '@/features/InsightPanel/messages'
import type { InsightsPanelProps } from '@/features/InsightPanel/types'
import { classes, wordBreak } from '@/utils/ui-helpers'
import ReactMarkdown from 'react-markdown'

import styles from './insight-panel.module.css'

const LINES_TO_SHOW = 5

export function EmbeddedInsightPanel({ result }: InsightsPanelProps) {
  const [isOpen, { toggle }] = useDisclosure()
  const hide = () => setTimeout(toggle, 200)

  const theme = useMantineTheme()
  const lineHeight =
    Number(theme.lineHeights.md) * (theme.scale * Number(px('1rem')))

  // Calculate height as `LINES_TO_SHOW` lines minus the height of gap
  const spoilerHeight =
    LINES_TO_SHOW * lineHeight - Number(px(theme.spacing.sm))

  const buttonProps = {
    className: styles.button,
    size: 'sm',
    variant: 'transparent',
  }

  return (
    <Stack
      component="div"
      gap="sm"
      p="md"
      style={{
        border: '1px solid var(--mantine-color-gray-2)',
        borderRadius: 4,
      }}
    >
      <Text className={styles.titleLabel}>{messages.ANSWER}</Text>
      <Text className={styles.title} lineClamp={3}>
        {wordBreak(result.title)}
      </Text>
      <Spoiler
        data-cy="insights-panel"
        maxHeight={spoilerHeight}
        className={classes(styles.spoiler, isOpen ? '' : styles.spoilerClosed)}
        showLabel={
          <Button onClick={toggle} {...buttonProps}>
            {messages.READ_MORE} <Icon name="arrow_drop_down" />
          </Button>
        }
        hideLabel={
          <Button component="a" onClick={hide} {...buttonProps}>
            {messages.HIDE} <Icon name="arrow_drop_up" />
          </Button>
        }
      >
        <Text
          lineClamp={isOpen ? 0 : LINES_TO_SHOW}
          className={styles.markdown}
          span
        >
          <ReactMarkdown>{result.description}</ReactMarkdown>
        </Text>
      </Spoiler>
    </Stack>
  )
}
