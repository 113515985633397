import React from 'react'
import { Box, Image } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { ResultImage } from '@/components/lib/ResultImage'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/result-types'

export interface CollectionDetailResultIconProps {
  result: ResultWithId
}

export const CollectionDetailResultIcon = ({
  result,
}: CollectionDetailResultIconProps) => {
  const [showImage, setShowImage] = React.useState(
    Boolean(result.properties.src_image_url ?? result.thumbnail ?? false),
  )

  if (isImageryResult(result)) {
    return <ResultImage item={result} style={{ height: 24, width: 24 }} />
  }

  if (isSocialMediaResult(result)) {
    return showImage ? (
      <Image
        src={result.properties.src_image_url ?? result.thumbnail}
        onError={() => setShowImage(false)}
        w={24}
        h={24}
        fit="fill"
      />
    ) : (
      <Icon name="space_dashboard" filled />
    )
  }

  if (isFireResult(result)) {
    return (
      <Box bg="#D32F2F" style={{ borderRadius: '50%' }} pt={3} px={4}>
        <Icon
          name="local_fire_department"
          style={{ color: 'white' }}
          size={18}
        />
      </Box>
    )
  }

  if (isShipResult(result)) {
    return (
      <Box bg="#00A4B0" style={{ borderRadius: '50%' }} pt={3} px={4}>
        <Icon name="anchor" style={{ color: 'white' }} size={18} />
      </Box>
    )
  }

  return <Icon name="space_dashboard" filled />
}
