import { Link } from 'react-router-dom'
import { Anchor, Group, Title } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { ImageResultPostfilterPopup } from '@/components/lib/results/results-postfilter/ImageResultPostfilterPopup'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import type React from 'react'

interface CompactPanelHeaderProps {
  titleShort: string
  link: string
  menu?: React.ReactNode
  position?: 'left' | 'center' | 'right'
}

export function CompactPanelHeader({
  titleShort,
  link,
  position,
}: CompactPanelHeaderProps) {
  function handleLinkClick() {
    const page = link.split('/')[1]
    captureEvent(POSTHOG_EVENTS.EXPLORE.ALL_OF_TYPE, { category: page })
  }

  return (
    <Group mb="sm" justify="space-between">
      <Anchor
        component={Link}
        to={link}
        onClick={handleLinkClick}
        underline="never"
        c="var(--mantine-text-color)"
      >
        <Title size={position === 'center' ? 24 : 20}>
          <Group gap={0}>
            {titleShort}
            <Icon
              name="chevron_right"
              size={position === 'center' ? 24 : 20}
              weight={700}
            />
          </Group>
        </Title>
      </Anchor>
      {link === '/images' && <ImageResultPostfilterPopup />}
    </Group>
  )
}
