import {
  type CardProps,
  Chip,
  Group,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import type { HazardRiskResult as HazardRiskResultType } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface HazardRiskResultProps extends CardProps {
  result: HazardRiskResultType
  isFullscreen?: boolean
}

interface FormattedHazardRiskData {
  address: string
  risks: {
    title: string
    risk?: string
    score?: string
    description?: string
  }[]
}

function formatHazardRiskData(
  result: HazardRiskResultType,
): FormattedHazardRiskData {
  const { properties } = result
  return {
    address: properties.address,
    risks: Object.values(properties.risks),
  }
}

export function HazardRiskResult({
  result,
  isFullscreen,
  ...props
}: HazardRiskResultProps) {
  const theme = useMantineTheme()
  const { address, risks } = formatHazardRiskData(result)

  const riskColors = {
    'Very Low': theme.colors.green[0],
    Low: theme.colors.lime[0],
    Moderate: theme.colors.yellow[0],
    High: theme.colors.orange[0],
    'Very High': theme.colors.red[0],
  } as { [key: string]: string }

  const renderedResults = risks.map((riskItem, index) => {
    const { title, score, risk, description } = riskItem
    return (
      <ResultBase
        key={index}
        result={result}
        onClick="viewDetails"
        withBorder
        radius="sm"
        style={{ borderColor: 'var(--mantine-color-gray-2)' }}
        data-cy="shipResult"
        {...props}
      >
        <Stack gap="xs" p="sm">
          {risk && (
            <Group>
              <Chip size="xs" color={riskColors[risk]}>
                {risk}
              </Chip>
            </Group>
          )}
          <Group gap="xs">
            <Text size="md">{title}</Text>
          </Group>
          <Table
            fz="xs"
            withRowBorders={false}
            className={styles['result-table']}
          >
            <Table.Tbody>
              {score && (
                <Table.Tr>
                  <Table.Td>Score</Table.Td>
                  <Table.Td>{score}</Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
          {address && (
            <Group gap="xs">
              <Text size="xs" color="gray">
                {address}
              </Text>
            </Group>
          )}
          {description && (
            <Group gap="xs">
              <Text size="sm" color="gray">
                Description
              </Text>
              <Text size="xs" color="gray">
                {description}
              </Text>
            </Group>
          )}
        </Stack>
      </ResultBase>
    )
  })
  return renderedResults
}
