import type { QueriesState } from '@/stores/queries-store/types'
import { createStore } from '@/utils/create-store.ts'
import type { SearchApiFilterValue } from '@/utils/types/filter-types.ts'
import {
  QuerySourceKeys,
  QuerySourceState,
  type QueryStatus,
} from '@/utils/types/message-types.ts'

export const initialState: Partial<QueriesState> = {
  currentQuery: '',
  currentQueryFilters: [],
  currentQueryStart: null,
  queryStatus: Object.fromEntries(
    Object.values(QuerySourceKeys).map((k) => [
      k,
      QuerySourceState.NOT_STARTED,
    ]),
  ) as QueryStatus,
  insightFeedbackSubmitted: false,
  totalProcessedResults: 0,
  sortPreference: 'score',
  storedLocation: null,
}

export const useQueryStore = createStore<QueriesState>({
  storageKey: 'QueryStore',
  version: 0.03,
  initialState: initialState,
  actions: (set, get): Partial<QueriesState> => ({
    clear: () =>
      set({
        currentQuery: '',
        currentQueryStart: null,
        queryStatus: initialState.queryStatus,
        totalProcessedResults: 0,
      }),
    setInsightFeedbackSubmitted: (submitted: boolean) =>
      set({ insightFeedbackSubmitted: submitted }),
    setQuery: (query: string) =>
      set({
        currentQuery: query,
        queryStatus: initialState.queryStatus,
      }),
    setQueryFilters: (apiFilters: SearchApiFilterValue[]) =>
      set({ currentQueryFilters: apiFilters }),
    setQueryStarted: () =>
      set({ currentQueryStart: new Date(), totalProcessedResults: 0 }),
    setSearchMetadata: (lastSearchMetadata) =>
      set({ searchMetadata: lastSearchMetadata }),
    setSortPreference: (sortPreference) => set({ sortPreference }),
    setStatus: (key: QuerySourceKeys, state: QuerySourceState) => {
      const currentStatus = get().queryStatus as QueryStatus
      // Load query given status only if query is not already in progress
      if (
        ![QuerySourceState.COMPLETED, QuerySourceState.ERROR].includes(
          currentStatus[key],
        )
      ) {
        set({ queryStatus: { ...currentStatus, [key]: state } })
      }
    },
    setStoredLocation: (location: QueriesState['storedLocation']) =>
      set({ storedLocation: location }),
    setTotalProcessedResults: (count: number) =>
      set({ totalProcessedResults: count }),
  }),
})
