import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/utils/constants'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import { useOverviewUrl } from './use-overview-url'

export const useNavigateToResult = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => {
    navigate(ROUTES.resultsItem.replace(':itemId', result.id))
  }
}

export const useNavigateToResultCallback = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => () => {
    navigate(ROUTES.resultsItem.replace(':itemId', result.id))
  }
}

export const useNavigateToMapItem = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => {
    navigate(ROUTES.mapItem.replace(':itemId', result.id))
  }
}

export const useNavigateToMapItemCallback = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => () => {
    navigate(ROUTES.mapItem.replace(':itemId', result.id))
  }
}

export const useNavigateToSearchResults = () => {
  const navigate = useNavigate()
  const overviewUrl = useOverviewUrl()
  return () => {
    navigate(overviewUrl)
  }
}
