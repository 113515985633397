import { makeOrthoLayer } from '@/hooks/ortho-imagery/use-ortho-imagery-sources'
import type { ImageryResult } from '@/utils/types/raw-result-types'
import {
  isBlackSky,
  isSentinel,
  isUp42,
} from '@/utils/types/result-type-checkers'
import * as turf from '@turf/turf'
import type { Geometry, Polygon, Position } from 'geojson'
import type mapboxgl from 'mapbox-gl'
import {
  initialSortedResults,
  initialTimelapseResults,
  sourceIdPrefix,
} from './constants'
import type {
  Bounds,
  DateKey,
  ImageLayer,
  SortedAndGroupedTimelapseResults,
} from './types'

export function formatAvailableDays(day?: string) {
  if (!day) {
    return ''
  }
  const [year, month, date] = day.split('-')
  return `${month}-${date}-${year}`
}

export function formatDateYearFirst(date: Date): DateKey {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()

  return `${year}-${month}-${day}`
}

function getBounds(geometry: Geometry) {
  const bbox = turf.bbox(geometry)
  const [minX, minY, maxX, maxY] = bbox
  const coords = [
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
  ] as Position[]
  const polygonArray = [...coords, [minX, maxY]] as Position[]
  const polygon = turf.feature({
    type: 'Polygon',
    coordinates: [polygonArray],
  })
  return { polygon, bbox, coords }
}

function makeImageLayer(
  result: ImageryResult & { timestamp: number },
): ImageLayer | undefined {
  const orthoLayer = makeOrthoLayer(result)
  if (!orthoLayer) {
    return
  }
  const isImageSource =
    isUp42(orthoLayer.source) ||
    isBlackSky(orthoLayer.source) ||
    isSentinel(orthoLayer.source)

  return {
    result,
    isImageSource,
    url: orthoLayer.tileUrl,
    geometry: orthoLayer.geometry,
  }
}

// Returns map bounds and sorted image layers
export function makeTimelapseResults(
  results: ImageryResult[],
  resultId?: string,
): SortedAndGroupedTimelapseResults {
  const selectedResult =
    (resultId && results.find((result) => result.id === resultId)) ?? results[0]
  if (!selectedResult) {
    return structuredClone(initialTimelapseResults)
  }
  const bounds = getBounds(selectedResult.geometry)
  const area = turf.area(bounds.polygon)

  return (
    results
      // Filter out results that don't intersect enough with the selected result
      .filter((result) => {
        const intersection = turf.intersect(
          turf.featureCollection([
            bounds.polygon,
            turf.feature(result.geometry as Polygon),
          ]),
        )
        const overlapPct = intersection ? turf.area(intersection) / area : 0
        return overlapPct > 0.25
      })
      // Sort by timestamp
      .sort((a, b) => {
        const aDate = new Date(a.properties.datetime)
        const bDate = new Date(b.properties.datetime)
        return aDate.getTime() - bDate.getTime()
      })
      // Convert to image layers and attach initially calculated bounds
      .reduce((accumulator, result) => {
        const { sortedImageLayers } = accumulator
        const date = new Date(result.properties.datetime)
        const stampedResult = { ...result, timestamp: date.getTime() }

        const imageLayer = makeImageLayer(stampedResult)

        if (imageLayer) {
          sortedImageLayers.push(imageLayer)
        }

        return {
          bounds,
          sortedImageLayers: sortedImageLayers,
        }
        // Use a clone to avoid mutating the original object
      }, structuredClone(initialTimelapseResults))
  )
}

export function pruneAndGroupTimelapseResults(
  layers: ImageLayer[],
  urls: Array<{ data?: string }>,
) {
  return layers.reduce((accumulator, layer, index) => {
    const { sortedResults, resultsByDay } = accumulator
    const url = urls[index].data
    if (url) {
      sortedResults.push({
        ...layer,
        url: url,
      })
      const day = formatDateYearFirst(new Date(layer.result.timestamp))
      if (resultsByDay[day]) {
        resultsByDay[day].push(layer.result)
      } else {
        resultsByDay[day] = [layer.result]
      }
    }
    return accumulator
  }, structuredClone(initialSortedResults))
}

export function getInitialSelectedResult(
  sortedResults: ImageLayer[],
  firstResult: ImageryResult,
  resultId?: string,
) {
  const resultById =
    resultId && sortedResults.length > 0
      ? sortedResults.find(({ result }) => result.id === resultId)
      : null
  const mostRelevantImage = firstResult
    ? {
        ...firstResult,
        timestamp: new Date(firstResult.properties.datetime).getTime(),
      }
    : null
  const initialSelectedResult =
    resultById?.result ?? mostRelevantImage ?? sortedResults[0]?.result
  return initialSelectedResult ?? null
}

// Takes either an index range or a list of sourceIds to remove from the map
export function removeSourcesFromMap(props: {
  map: mapboxgl.Map
  range?: [number, number]
  sourceIds?: string[]
}) {
  const { map, range, sourceIds } = props
  let arrayOfIds: string[] = []

  if (sourceIds) {
    arrayOfIds = sourceIds
  } else if (range) {
    const [from, to] = range
    arrayOfIds = Array.from({ length: to - from + 1 }, (_, index) => {
      const realIndex = index + from

      const sourceId = `${sourceIdPrefix}${realIndex}`
      return sourceId
    })
  }

  arrayOfIds.forEach((sourceId) => {
    const layerId = `${sourceId}_layer`

    if (map.getSource(sourceId)) {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId)
      }
      map.removeSource(sourceId)
    }
  })
}

export function addSourcesToMap(props: {
  map: mapboxgl.Map
  imageLayers: ImageLayer[]
  bounds: Bounds
}) {
  const { map, imageLayers, bounds } = props

  const mapSources = imageLayers
    .map((image, index) => {
      const sourceId = `${sourceIdPrefix}${index}`
      const { url } = image

      if (!map || !url) {
        return
      }

      let sourceParams: mapboxgl.RasterSource | mapboxgl.ImageSourceRaw
      if (image.isImageSource) {
        const [minX, minY, maxX, maxY] = bounds.bbox
        const coordinates = [
          [minX, maxY],
          [maxX, maxY],
          [maxX, minY],
          [minX, minY],
        ]

        sourceParams = {
          type: 'image',
          url,
          coordinates,
        }
      } else {
        sourceParams = {
          type: 'raster',
          tiles: [url],
          bounds: bounds.bbox,
        }
      }

      return () => {
        removeSourcesFromMap({ map, sourceIds: [sourceId] })
        map.addSource(sourceId, sourceParams).addLayer({
          id: `${sourceId}_layer`,
          type: 'raster',
          source: sourceId,
          paint: {
            'raster-opacity': 0,
          },
        })
      }
    })
    .filter(Boolean)

  map.on('load', () => mapSources.forEach((addSource) => addSource()))
}

export function toggleImageLayerVisibility({
  map,
  selectedIndex,
  totalResults,
}: {
  map: mapboxgl.Map
  selectedIndex: number
  totalResults: number
}) {
  const twoBeforeIndex = (selectedIndex + totalResults - 2) % totalResults
  const previousIndex = (selectedIndex + totalResults - 1) % totalResults
  const indices = [twoBeforeIndex, previousIndex, selectedIndex]

  const layerIds = indices.map((index) => `${sourceIdPrefix}${index}_layer`)
  const otherLayers = Array.from(
    { length: totalResults },
    (_, index) => !indices.includes(index) && `${sourceIdPrefix}${index}_layer`,
  ).filter(Boolean)

  function setTransitionDuration(layerId: string, duration: number) {
    map.setPaintProperty(layerId, 'raster-opacity-transition', {
      duration,
    })
  }
  function setOpacity(layerId: string, opacity: number) {
    map.setPaintProperty(layerId, 'raster-opacity', opacity)
  }

  // Reset other layers
  otherLayers.forEach((layerId) => {
    if (map.getLayer(layerId)) {
      setTransitionDuration(layerId, 0)
      setOpacity(layerId, 0)
    }
  })

  // Reset previous-previous layer
  if (map.getLayer(layerIds[0])) {
    setTransitionDuration(layerIds[0], 0)
  }
  // Start fadeout for previous layer
  if (map.getLayer(layerIds[1])) {
    setTransitionDuration(layerIds[1], 500)
    setOpacity(layerIds[1], 0)
  }
  // Pop in "current" layer
  if (map.getLayer(layerIds[2])) {
    map.moveLayer(layerIds[2], layerIds[1])
    setTransitionDuration(layerIds[2], 0)
    setOpacity(layerIds[2], 1)
  }
}
