import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Autocomplete } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useSetStoredLocation } from '@/stores/queries-store'

import styles from '@/features/Search/search-bar.module.css'

interface UnifiedSearchProps {
  style?: React.CSSProperties
  placeholder?: string
  handleInlineHotkeyPress: (event: KeyboardEvent | React.KeyboardEvent) => void
  doTextSearch: (value: string) => Promise<string>
}

export const UnifiedSearch = React.forwardRef(
  (
    { doTextSearch, handleInlineHotkeyPress, placeholder }: UnifiedSearchProps,
    ref,
  ) => {
    const setStoredLocation = useSetStoredLocation()
    const navigate = useNavigate()
    const unifiedRef = React.useRef<HTMLInputElement>(null)
    const [unifiedValue, setUnifiedValue] = React.useState('')

    const handleSetQuery = (value: string) => {
      if (!value) {
        return
      }
      setStoredLocation(null)
      void doTextSearch(value)
      unifiedRef.current?.blur()
      setUnifiedValue('')

      const params = new URLSearchParams({ search: value })
      const pathname = window.location.pathname.toLowerCase()

      if (pathname.startsWith('/map')) {
        navigate(`/map?${params.toString()}`)
      } else {
        navigate(`/overview?${params.toString()}`)
      }
    }

    const handleSubmit = (
      event?: React.FormEvent<HTMLFormElement> | KeyboardEvent,
    ) => {
      event?.preventDefault()
      if (unifiedValue.trim()) {
        handleSetQuery(unifiedValue)
      }
    }
    const focus = () => unifiedRef.current?.focus()

    React.useImperativeHandle(ref, () => ({
      handleSubmit,
      focus,
    }))

    const canSubmit = !!unifiedValue.trim()

    return (
      <form onSubmit={handleSubmit} className={styles.unifiedSearchForm}>
        <Autocomplete
          size="md"
          id="search"
          radius="1rem"
          w="100%"
          classNames={{ input: styles.searchInput }}
          leftSection={<Icon name="search" />}
          value={unifiedValue}
          onChange={setUnifiedValue}
          onOptionSubmit={handleSetQuery}
          onKeyDown={handleInlineHotkeyPress}
          placeholder={`${
            placeholder ?? 'Search'
          } (CTRL/CMD+K to switch Search Mode)`}
          ref={unifiedRef}
        />
        <ActionIcon
          variant="transparent"
          c="gray"
          type="submit"
          className={canSubmit ? styles.sendButton : styles.sendButtonDisabled}
          disabled={!canSubmit}
        >
          <Icon name="send" filled={true} />
        </ActionIcon>
      </form>
    )
  },
)

UnifiedSearch.displayName = 'UnifiedSearch'

export default UnifiedSearch
