import { useEffect } from 'react'
import { createStaticMapUrl } from '@/api/mapbox'
import { useAnalyticLayer } from '@/components/lib/map-search/hooks/use-analytic-layer'
import { formatMapboxStyle } from '@/components/lib/map-search/hooks/use-base-layer-selection'
import { useMapboxMap } from '@/components/lib/map-search/hooks/use-mapbox-map'
import { useSingleOrthoLayer } from '@/hooks/ortho-imagery/use-single-ortho-layer'
import { useBaseLayer, useSetBaseLayer } from '@/stores/map-store'
import { MAP_BASE_LAYERS } from '@/utils/constants.ts'
import type { ImageryResult } from '@/utils/types/result-types'
import type mapboxgl from 'mapbox-gl'
import { useKeyedRef } from './use-keyed-ref'

export const refKeys = {
  map: 'map' as const,
  mapContainer: 'mapContainer' as const,
}

export const useSingleImageMap = (item: ImageryResult) => {
  const mapContainer = useKeyedRef<HTMLDivElement | null>(
    refKeys.mapContainer,
    null,
  )
  const map = useKeyedRef<mapboxgl.Map | null>(refKeys.map, null)
  useMapboxMap(map, mapContainer)
  useSingleOrthoLayer(map, item)
  useAnalyticLayer(map, item)
  const baseLayer = useBaseLayer()
  const setBaseLayer = useSetBaseLayer()

  useEffect(() => {
    map.current?.setStyle(formatMapboxStyle(baseLayer))
  }, [baseLayer, map])

  const imgSource = createStaticMapUrl({
    geometry: item.geometry,
    baseMap:
      baseLayer === MAP_BASE_LAYERS.dantiStreets
        ? MAP_BASE_LAYERS.satellite
        : MAP_BASE_LAYERS.dantiStreets,
    size: [100, 100],
  })
  const toggleLayer = () => {
    void setBaseLayer(
      baseLayer === MAP_BASE_LAYERS.dantiStreets
        ? MAP_BASE_LAYERS.satellite
        : MAP_BASE_LAYERS.dantiStreets,
    )
  }
  return {
    imgSource,
    toggleLayer,
    mapContainer,
  }
}
