import { useEffect } from 'react'
import { useHotkeys } from '@mantine/hooks'
import type {
  ChatMessage,
  SearchMetadata,
  StatusMessage,
} from '@/utils/types/message-types'
import type { RawSearchResult } from '@/utils/types/result-types'
import { singletonHook } from 'react-singleton-hook'
import { useDantiWSConnection } from './use-danti-ws-connection'
import { useDantiWSMessages } from './use-danti-ws-messages'
import { useDantiWSQuery } from './use-danti-ws-query'

export const useDantiWSImpl = () => {
  const connection = useDantiWSConnection()

  const { queryIdRef, setQuery } = useDantiWSQuery({
    sendMessage: connection.sendMessage,
  })

  const messages = useDantiWSMessages({ queryIdRef })

  const { hasSocket, isOpen, isConnecting } = connection
  const onMessage = messages.handleMessage
  const onOpen = setQuery

  useEffect(() => {
    // don't reconnect the socket on token change if we already have an open connection
    if (hasSocket && (isOpen || isConnecting)) {
      return
    }
    connection.createWebsocket({ onMessage, onOpen })
  }, [
    connection.createWebsocket, // this prereq tells when there is a new connection instance
    onMessage,
    onOpen,
    hasSocket,
    isOpen,
    isConnecting,
  ])

  useHotkeys([['mod+shift+U', connection.closeSocket]])

  return {
    sendMessage: connection.sendMessage,
    lastResults: messages.lastResults,
    flushLastResults: messages.flushLastResults,
    lastStatusMessages: messages.lastStatusMessages,
    flushLastStatusMessages: messages.flushLastStatusMessages,
    lastSearchMetadata: messages.lastSearchMetadata,
    flushLastSearchMetadata: messages.flushLastSearchMetadata,
    lastChatMessages: messages.lastChatMessages,
    flushLastChatMessages: messages.flushLastChatMessages,
    closeSocket: connection.closeSocket,
  }
}

export const defaultWebsocket = {
  sendMessage: () => {},
  lastResults: [],
  flushLastResults: () => {},
  lastStatusMessages: [],
  flushLastStatusMessages: () => {},
  lastSearchMetadata: null,
  flushLastSearchMetadata: () => {},
  lastChatMessages: [],
  flushLastChatMessages: () => {},
  closeSocket: () => {},
}

export interface DantiWSHook {
  sendMessage: (action: string, message: Record<string, any>) => void
  lastResults: RawSearchResult[]
  flushLastResults: () => void
  lastStatusMessages: StatusMessage[]
  flushLastStatusMessages: () => void
  lastSearchMetadata: SearchMetadata | null
  flushLastSearchMetadata: () => void
  lastChatMessages: ChatMessage[]
  flushLastChatMessages: () => void
  closeSocket: () => void
}

export const useDantiWS = singletonHook<DantiWSHook>(
  () => defaultWebsocket,
  useDantiWSImpl,
)
