import { Box, Button, Divider, Group, Stack, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { useHistory } from '@/api/history'
import { Icon } from '../lib/Icon'

export function RemoveAllHistoryModal({
  context,
  id: modalId,
}: ContextModalProps) {
  const { deleteHistory } = useHistory()

  const handleRemoveAll = () => {
    deleteHistory({ all: true })
    context.closeModal(modalId)
  }

  return (
    <Box>
      <Divider mb="md" />
      <Stack gap="sm">
        <Text style={{ fontWeight: 'bold' }}>This cannot be undone.</Text>
      </Stack>
      <Group justify="space-between" mt="md">
        <Button
          size="xs"
          variant="transparent"
          color="black"
          onClick={() => context.closeModal(modalId)}
          leftSection={<Icon size="xs" name="clear" />}
          style={{ fontWeight: 400 }}
        >
          CANCEL
        </Button>
        <Button
          size="xs"
          color="red"
          onClick={handleRemoveAll}
          leftSection={<Icon size="sm" name="delete_outline" />}
        >
          YES, DELETE ALL
        </Button>
      </Group>
    </Box>
  )
}
