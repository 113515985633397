import { Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons'
import type { ReactNode } from 'react'

import styles from './hero.module.css'

export interface SOVHeroDetailsProps {
  details?: {
    title: string
    icon?: ReactNode
    iconName?: SymbolName
    color: string
    leftDetail?: string
    rightDetail?: string
    leftDetailsColumn?: ReactNode[]
    rightDetailsColumn?: ReactNode[]
  }
}

export function SOVHeroDetails({ details }: SOVHeroDetailsProps) {
  if (!details) {
    return null
  }
  return (
    <div className={styles['sov-hero-detail-bg']}>
      <div className={styles['sov-hero-detail-card']}>
        <div className={styles['sov-hero-details']}>
          <div
            className={styles['sov-hero-icon']}
            style={{ backgroundColor: details.color }}
          >
            {details.icon}
            {details.iconName && <Icon name={details.iconName} />}
          </div>
          <div className={styles['sov-hero-title']}>{details.title}</div>
          <div className={styles['sov-hero-details-left']}>
            {details.leftDetail}
          </div>
          <div className={styles['sov-hero-details-right']}>
            {details.rightDetail}
          </div>
          <div className={styles['sov-hero-detail-columns']}>
            <div className={styles['sov-hero-details-left-column']}>
              {(details.leftDetailsColumn || []).map((detail, index) => (
                <Text key={index} lineClamp={1}>
                  {detail}
                </Text>
              ))}
            </div>
            <div className={styles['sov-hero-details-right-column']}>
              {(details.rightDetailsColumn || []).map((detail, index) => (
                <Text key={index} lineClamp={1}>
                  {detail}
                </Text>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
