import type { CollectionSortType } from '@/api/collections/types'
import { useKeyedState } from '@/hooks/use-keyed-state'
import { useQueryClient } from '@tanstack/react-query'

export const stateKeys = {
  currentPage: 'currentPage',
  nextToken: 'nextToken',
  previousToken: 'previous',
  sort: 'sort',
  searchTerm: 'searchTerm',
}

export const initialValues = {
  [stateKeys.currentPage]: 0,
  [stateKeys.nextToken]: '',
  [stateKeys.previousToken]: '',
  [stateKeys.sort]: '',
  [stateKeys.searchTerm]: '',
}

const useField = <T>(key: string) =>
  useKeyedState<T>(key, initialValues[key] as T)

export function useCollectionsData() {
  const queryClient = useQueryClient()

  const [currentPage, setCurrentPage] = useField<number>(stateKeys.currentPage)
  const [nextToken, setNextToken] = useField<string>(stateKeys.nextToken)
  const [previousToken, setPreviousToken] = useField<string>(
    stateKeys.previousToken,
  )
  const [sort, setSort] = useField<CollectionSortType>(stateKeys.sort)
  const [searchTerm, setSearchTerm] = useField<string>(stateKeys.searchTerm)

  return {
    currentPage,
    setCurrentPage,
    queryClient,
    nextToken,
    setNextToken,
    previousToken,
    setPreviousToken,
    sort,
    setSort,
    searchTerm,
    setSearchTerm,
  }
}
