import { useNavigate } from 'react-router-dom'
import { Group, Table, Text } from '@mantine/core'
import { useCollections } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import type { Collection } from '@/utils/types/collection-types.ts'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import dayjs from 'dayjs'
import { CollectionMenu } from '../CollectionMenu'
import { HeaderSortCell } from './HeaderSortCell'

const columnHelper = createColumnHelper<Collection>()

const columns = [
  columnHelper.accessor('name', {
    header: () => (
      <HeaderSortCell
        label="Collection Name"
        sortItems={[
          {
            value: 'alpha-desc',
            label: 'Name descending',
          },
          {
            value: 'alpha-asc',
            label: 'Name ascending',
          },
        ]}
      />
    ),
    cell: (info) => {
      const isTerrapin = Boolean(info.row.original.refId)
      return (
        <Group gap={12}>
          <Icon
            name={isTerrapin ? 'folder_special' : 'space_dashboard'}
            filled
          />
          <Text>{info.getValue()}</Text>
        </Group>
      )
    },
  }),
  columnHelper.accessor('lastActivity', {
    header: () => (
      <HeaderSortCell
        label="Last updated"
        sortItems={[
          {
            value: 'date-desc',
            label: 'Date descending',
          },
          {
            value: 'date-asc',
            label: 'Date ascending',
          },
        ]}
      />
    ),
    cell: (info) => (
      <Text p={6}>{dayjs(info.getValue()).format('MM/DD/YY hh:mm A')}</Text>
    ),
  }),
  columnHelper.accessor('refId', {
    header: () => <Text fw={500} fz={12}>Kind</Text>,
    cell: (info) => {
      const isTerrapin = Boolean(info.row.original.refId)
      return (
        <Group gap={12}>
          <Text>{isTerrapin ? 'Feed' : 'Collection'}</Text>
        </Group>
      )
    },
  }),
  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    cell: (info) => (
      <CollectionMenu collection={info.getValue()} orientation="horizontal" />
    ),
  }),
]

export function CollectionTable() {
  const { collections } = useCollections()
  const navigate = useNavigate()

  const table = useReactTable({
    data: collections,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (collection) => collection.id,
  })

  return (
    <Table highlightOnHover visibleFrom="sm">
      <Table.Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.Th key={header.id} pt={8} pb={8}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </Table.Th>
            ))}
          </Table.Tr>
        ))}
      </Table.Thead>
      <Table.Tbody>
        {table.getRowModel().rows.map((row) => (
          <Table.Tr
            style={{ cursor: 'pointer' }}
            key={row.id}
            onClick={() => navigate(`/collections/${row.id}`)}
          >
            {row.getVisibleCells().map((cell) => (
              <Table.Td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Table.Td>
            ))}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
