import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Menu,
  SegmentedControl,
  Stack,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import {
  type CollectionSortType,
  handleCollectionLocationDownload,
} from '@/api/collections'
import { ConditionalTooltip } from '@/components/core/ConditionalTooltip'
import SortToggleButton from '@/components/filters/SortToggleButton'
import { openFileUploadModal } from '@/components/lib/FileUpload/opener'
import { Icon } from '@/components/lib/Icon'
import { openShareModal } from '@/components/modals/openers'
import { openSaveSearchModal } from '@/components/modals/SaveSearchModal/opener'
import { formatLocalNotificationSchedule } from '@/components/modals/SaveSearchModal/SaveSearchPanel/use-configure-email-notifications'
import { Pager } from '@/components/pagination'
import { useTerrapin } from '@/hooks/use-terrapin'
import { useSetFilters } from '@/stores/filters-store'
import { useAvatarDetails } from '@/stores/user-store'
import { parseFilters } from '@/utils/search-filters/parse-filters'
import type { Collection } from '@/utils/types/collection-types'
import type { CategoryTypes } from '@/utils/types/raw-result-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import pluralize from 'pluralize'

dayjs.extend(utc)

export type ViewType = 'grid' | 'list'

export interface CollectionDetailHeaderProps {
  collection: Collection
  viewType: ViewType
  setViewType: (value: ViewType) => void
  currentPage: number
  pageSize: number
  totalCount: number
  nextPage: () => void
  previousPage: () => void
  search: (term: string) => void
  selectedCategories: CategoryTypes[]
  toggleSelectedCategory: (categoryTypes: CategoryTypes) => void
  sort: CollectionSortType
  setSort: (type: CollectionSortType) => void
  filter?: string | undefined
}

export function CollectionDetailHeader({
  collection,
  viewType,
  setViewType,
  currentPage,
  pageSize,
  totalCount,
  nextPage,
  previousPage,
  search,
  selectedCategories,
  sort,
  setSort,
  toggleSelectedCategory,
  filter,
}: CollectionDetailHeaderProps) {
  const [searchTerm, setSearchTerm] = React.useState('')
  const navigate = useNavigate()
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300)
  const { getTerrapinConfig } = useTerrapin()
  const setFilters = useSetFilters()
  const { email } = useAvatarDetails()

  const shareLabel = React.useMemo(() => {
    const count = collection.users.length - 1

    if (count === 0) {
      return 'Share'
    } else if (count == 1) {
      return 'Shared with 1 person'
    } else {
      return `Shared with ${count} people`
    }
  }, [collection])

  const disableShare = React.useMemo(() => {
    const role = collection.users.find((user) => user.email === email)?.role
    if (!role) {
      return true
    }
    return role !== 'creator' && role !== 'owner'
  }, [collection, email])

  React.useEffect(() => {
    search(debouncedSearchTerm)
  }, [search, debouncedSearchTerm])

  const openSettings = () => {
    const refId = collection.refId
    if (refId) {
      getTerrapinConfig(refId)
        .then((response) => {
          const filters = parseFilters(response.filters.toString())
          setFilters(filters)
          const localScheduledTime = formatLocalNotificationSchedule(
            response.email_notification,
          )

          openSaveSearchModal({
            refId,
            isNew: false,
            params: {
              name: response.name,
              query: response.query,
              emailNotification: localScheduledTime,
              expiration: response.expiration,
              backfill: response.backfill,
              mode: 'feed',
              locations: response.locations,
            },
          })
        })
        .catch((error) => {
          console.error('Error fetching search config:', error)
        })
    }
  }

  return (
    <Stack w="100%">
      <Group justify="space-between">
        <Group>
          <ActionIcon
            onClick={() => navigate(-1)}
            radius="xl"
            left={5}
            p="0"
            hiddenFrom="md"
            variant="outline"
            color="#0000008A"
            style={{
              zIndex: 1,
              color: '#666',
              borderColor: '#BDBDBD',
            }}
          >
            <Icon name="arrow_back" />
          </ActionIcon>
          <Title c="#00000099" size={18}>
            Collections
          </Title>
        </Group>

        <Group gap="xs">
          <Title c="#00000099" size={18}>
            {`${totalCount} ${pluralize('item', totalCount)}`}
          </Title>
          <Tooltip
            label={shareLabel}
            withArrow
            position="bottom"
            color="rgba(97, 97, 97, 0.90)"
          >
            <ActionIcon
              color="#0000008F"
              onClick={() => openShareModal(collection)}
              variant="subtle"
              hidden={!collection.refId}
            >
              <Icon name="people_alt" />
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label="Feed Settings"
            withArrow
            position="bottom"
            color="rgba(97, 97, 97, 0.90)"
          >
            <ActionIcon
              color="#0000008F"
              onClick={() => openSettings()}
              variant="subtle"
              hidden={!collection.refId}
            >
              <Icon name="settings" />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
      <Title c="##303030" size={48}>
        {collection.name}
      </Title>
      <Group gap="sm">
        <TextInput
          placeholder="Search"
          withAsterisk
          leftSection={<Icon name="search" />}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          miw={300}
        />
        <SegmentedControl
          bg="#E0E0E0"
          size="xs"
          value={viewType}
          onChange={(v) => setViewType(v as ViewType)}
          styles={{
            label: {
              height: 28,
            },
          }}
          data={[
            {
              value: 'list',
              label: <Icon name="view_list" color="black" />,
            },
            {
              value: 'grid',
              label: <Icon name="grid_view" />,
            },
          ]}
        />
        <Menu position="bottom-start" trigger="click-hover">
          <Menu.Target>
            <Button
              size="xs"
              style={{ fontWeight: '500', borderColor: '#0091EA' }}
              leftSection={<Icon name="add_circle_outline" size="xs" filled />}
              variant="filled"
            >
              ACTIONS
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Stack gap="xs">
              {totalCount > 0 && (
                <Menu.Item
                  leftSection={<Icon name="download" />}
                  onClick={() =>
                    void handleCollectionLocationDownload({
                      collectionId: collection.id,
                      collectionName: collection.name,
                      totalCount,
                      filter,
                      searchTerm,
                    })
                  }
                >
                  Download Location Data
                </Menu.Item>
              )}
              <Menu.Item
                leftSection={<Icon name="file_upload" />}
                onClick={() => openFileUploadModal(null)}
              >
                Upload a file
              </Menu.Item>
            </Stack>
          </Menu.Dropdown>
        </Menu>
        <ConditionalTooltip
          label={
            disableShare
              ? "You don't have access to share this collection"
              : undefined
          }
        >
          <Button
            color="#000000DE"
            size="xs"
            leftSection={<Icon name="people_alt" />}
            style={{ borderColor: '#000000DE' }}
            variant="outline"
            disabled={disableShare}
            onClick={() => openShareModal(collection)}
          >
            SHARE
          </Button>
        </ConditionalTooltip>
        <SortToggleButton
          sort={sort}
          selectedCategories={selectedCategories}
          onSortChange={setSort}
          onCategoryToggle={toggleSelectedCategory}
        />
        <Box flex={1} />
        {selectedCategories.length > 0 && (
          <Pager
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={totalCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        )}
      </Group>

      <Divider />
    </Stack>
  )
}
