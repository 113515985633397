import { Divider, Modal, NavLink, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { openDeleteCollectionModal } from '@/components/modals/openers'
import type { CollectionItem } from '@/utils/types/collection-types'

export interface CollectionDetailListItemModalMenuProps {
  item: CollectionItem
  onClose: () => void
}

export function CollectionDetailListItemModalMenu({
  item,
  onClose,
}: CollectionDetailListItemModalMenuProps) {
  const { name } = item

  return (
    <Modal.Root opened={true} onClose={onClose} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Text c="#000000DE" size="18px" style={{ fontWeight: 500 }}>
              {name}
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={0}>
            <Divider mb="md" />
            <NavLink
              leftSection={<Icon name="delete" />}
              label="Remove from Collection"
              px={0}
              pb={0}
              c="#D32F2F"
              onClick={(event) => {
                event.stopPropagation()
                openDeleteCollectionModal(item.id)
              }}
            />
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
