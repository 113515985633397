import { ingestTransforms } from '@/stores/data-transforms'
import { DATA_CATEGORIES } from '@/utils/constants'
import {
  type CategorizedResults,
  isDataResult,
  isImageryResult,
  isInsightResult,
  isPropertyResult,
  isPublicationResult,
  isSocialMediaResult,
  type RawSearchResult,
  type ResultWithId,
} from '@/utils/types/result-types'
import { uniqBy } from 'lodash'

export const MAX_IMAGE_RESULTS = 200
export const MAX_PUBLICATION_RESULTS = 300

// TODO: Remove this entirely and use `result.properties._dId` as canonical ID source everywhere
export function addIdToResult(result: RawSearchResult): ResultWithId {
  return {
    ...result,
    id: result.properties._dId,
    documentId: result.properties._dId,
  }
}

export interface GroupedResults extends CategorizedResults {
  ids: string[]
}

export function groupResultsByType(results: ResultWithId[]) {
  const grouped = results.reduce(
    (categorized, result) => {
      categorized.ids.push(result.id)
      if (isImageryResult(result)) {
        // TODO: Hack to limit results before we have a paginated API
        if (categorized.imageResults.length < MAX_IMAGE_RESULTS) {
          categorized.imageResults.push(
            ingestTransforms[DATA_CATEGORIES.IMAGE](result),
          )
        }
      } else if (isDataResult(result)) {
        categorized.dataResults.push(
          ingestTransforms[DATA_CATEGORIES.DATA](result),
        )
      } else if (isPublicationResult(result)) {
        // TODO: Hack to limit results before we have a paginated API
        if (categorized.publicationResults.length < MAX_PUBLICATION_RESULTS) {
          categorized.publicationResults.push(
            ingestTransforms[DATA_CATEGORIES.PUBLICATION](result),
          )
        }
      } else if (isSocialMediaResult(result)) {
        categorized.socialMediaResults.push(
          ingestTransforms[DATA_CATEGORIES.SOCIAL_MEDIA](result),
        )
      } else if (isInsightResult(result)) {
        categorized.insightResults.push(
          ingestTransforms[DATA_CATEGORIES.INSIGHT](result),
        )
      } else if (isPropertyResult(result)) {
        categorized.propertyResults.push(
          ingestTransforms[DATA_CATEGORIES.PROPERTY](result),
        )
      }
      return categorized
    },
    <GroupedResults>{
      ids: [],
      imageResults: [],
      dataResults: [],
      publicationResults: [],
      socialMediaResults: [],
      insightResults: [],
      analyticResults: [],
      propertyResults: [],
    },
  )

  return grouped
}

export function filterAndGroupResults(
  results: RawSearchResult[],
  categorized: CategorizedResults,
) {
  const existing = Object.values(categorized).flat() as ResultWithId[]
  const resultsWithIds = results.map(addIdToResult)
  const sortedResults = [...resultsWithIds, ...existing].sort(
    (a, b) => b.score - a.score,
  )
  const uniqueResults = uniqBy(sortedResults, (result) => result.id)

  return groupResultsByType(uniqueResults)
}
