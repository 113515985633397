import React, { type MutableRefObject } from 'react'
import { createRoot } from 'react-dom/client'
import { MapPointInfoTooltip } from '@/components/lib/map-search/MapPointInfoTooltip.tsx'
import { POINT_LAYER_NAMES } from '@/components/lib/map-search/Point/use-point-data.ts'
import { useSetSelectedPointId } from '@/stores/map-store'
import { useAllResults } from '@/stores/results-store'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import mapboxgl from 'mapbox-gl'

import './styles.css'

export const usePointInteractionListeners = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const results = useAllResults()
  const setSelectedPointId = useSetSelectedPointId()
  React.useEffect(() => {
    map.current?.on(
      'mouseover',
      [
        POINT_LAYER_NAMES.NEWS,
        POINT_LAYER_NAMES.FIRE,
        POINT_LAYER_NAMES.SHIP,
        POINT_LAYER_NAMES.SOCIAL,
      ],
      (event) => {
        event.target.getCanvas().style.cursor = 'pointer'
      },
    )

    map.current?.on(
      'click',
      [
        POINT_LAYER_NAMES.NEWS,
        POINT_LAYER_NAMES.FIRE,
        POINT_LAYER_NAMES.SHIP,
        POINT_LAYER_NAMES.SOCIAL,
      ],
      (event: mapboxgl.MapLayerMouseEvent) => {
        event.target.getCanvas().style.cursor = 'pointer'
        if (event.features) {
          const id = event.features[0].properties?.['resultId'] as string | null
          const result = results.find(
            (result: ResultWithId) => result.id === id,
          )
          if (!result) {
            return
          }
          const coords = result?.geometry?.coordinates as [number, number]
          setSelectedPointId(result.id)
          const makeTooltip = () => {
            const div = document.createElement('div')
            const root = createRoot(div)
            root.render(MapPointInfoTooltip({ result }))
            return div
          }

          if (map.current) {
            new mapboxgl.Popup({ closeButton: false })
              .setLngLat(coords)
              .setDOMContent(makeTooltip())
              .addTo(map.current)
          }
        }
      },
    )
    map.current?.on(
      'mouseout',
      [
        POINT_LAYER_NAMES.NEWS,
        POINT_LAYER_NAMES.FIRE,
        POINT_LAYER_NAMES.SHIP,
        POINT_LAYER_NAMES.SOCIAL,
      ],
      (event) => {
        event.target.getCanvas().style.cursor = 'default'
      },
    )
  }, [map, results, setSelectedPointId])
}
