import { type FC, type MouseEventHandler, useRef, useState } from 'react'
import { Box, Stack } from '@mantine/core'
import { BAR_FILL_COLOR } from '@/components/lib/results/results-timeline/bar-fill-color'
import { ResultsTimelineBarGraph } from '@/components/lib/results/results-timeline/ResultsTimelineBarGraph.tsx'
import { ResultsTimelineLoading } from '@/components/lib/results/results-timeline/ResultsTimelineLoading.tsx'
import { useSearchStatus } from '@/features/Search/hooks'
import { useSetTimelineSelectedIds } from '@/stores/results-filter-store'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import type BaseBrush from '@visx/brush/lib/BaseBrush'
import type { Bounds, Point } from '@visx/brush/lib/types'
import { scaleOrdinal } from '@visx/scale'
import type { BarGroupBar, SeriesPoint } from '@visx/shape/lib/types'
import { useTooltip } from '@visx/tooltip'
import { isNumber, max, min, range, uniq } from 'lodash'
import {
  useTimelineHistogram,
  type VisualizationDatum,
} from './hooks/use-timeline-histogram.ts'
import type { TimelineDoctypes, TooltipData } from './results-tooltip-data.ts'
import { ResultsTimelineTooltip } from './ResultsTimelineTooltip.tsx'

export const FOCUS_HIST_HEIGHT = 60
export const INNER_MARGIN = 10
export const CONTEXT_HIST_HEIGHT = 25
export const SVG_TOP_PADDING = 20
const TIMELINE_INSET = 32

export type ResultTimelineBarFillHandler = (
  bar: Omit<BarGroupBar<TimelineDoctypes>, 'value' | 'key'> & {
    bar: SeriesPoint<VisualizationDatum>
    key: TimelineDoctypes
  },
) => string

type ResultsTimelineProps = {
  width: number
  isSmall: boolean
  isolateType?: (result: ResultWithId) => boolean
}

export const ResultsTimeline: FC<ResultsTimelineProps> = ({
  width,
  isSmall,
  isolateType,
}) => {
  const [selectedBinIndexes, setSelectedBinIndexes] = useState<number[]>([])
  const [startDate] = useState<Date | undefined>()
  const [endDate] = useState<Date | undefined>()
  const [selectionStartIndex, setSelectionStartIndex] = useState<
    number | null
  >()
  const [selectionEndIndex, setSelectionEndIndex] = useState<number | null>()
  const [brushStart, setBrushStart] = useState<Point | null>(null)

  const setTimelineSelectedIds = useSetTimelineSelectedIds()
  const { isReadyForInteraction } = useSearchStatus()

  const focusHistogramConfig = useTimelineHistogram({
    startDate,
    endDate,
    xMax: width,
    yMax: FOCUS_HIST_HEIGHT,
    isolateType,
  })

  const brushRef = useRef<BaseBrush>(null)

  const barTooltipParams = useTooltip<TooltipData>()
  const dateHoverTooltipParams = useTooltip<{ xDate: Date }>()

  const syncSelections = () => {
    if (selectedBinIndexes.length > 0) {
      const ids = focusHistogramConfig.mergedHistogramResult
        .filter((_, index) => selectedBinIndexes.includes(index))
        .flatMap((bin) => bin.map((r) => r.id))

      setTimelineSelectedIds(ids)
      return
    }

    if (selectionStartIndex && selectionEndIndex) {
      const ids = focusHistogramConfig.mergedHistogramResult
        .filter(
          (_, index) =>
            index >= selectionStartIndex && index <= selectionEndIndex,
        )
        .flatMap((bin) => bin.map((r) => r.id))
      setTimelineSelectedIds(ids)
      return
    }

    setTimelineSelectedIds(null)
  }

  const clearSelection = () => {
    setSelectionStartIndex(null)
    setSelectionEndIndex(null)
    if (brushRef.current) {
      brushRef.current.reset()
    }
  }

  const onBarClick: (binIndex: number) => MouseEventHandler<SVGRectElement> =
    (binIndex) => (event) => {
      clearSelection()

      if (event.shiftKey && selectedBinIndexes.length > 0) {
        const start =
          min(selectedBinIndexes)! < binIndex
            ? min(selectedBinIndexes)!
            : binIndex
        const end =
          max(selectedBinIndexes)! > binIndex
            ? max(selectedBinIndexes)!
            : binIndex + 1
        const newBinIndexes = range(start, end)
        setSelectedBinIndexes(uniq([...selectedBinIndexes, ...newBinIndexes]))
        syncSelections()
        return
      }

      if (event.metaKey) {
        if (selectedBinIndexes.includes(binIndex)) {
          setSelectedBinIndexes(
            selectedBinIndexes.filter((index) => index !== binIndex),
          )
          syncSelections()
        } else {
          setSelectedBinIndexes([...selectedBinIndexes, binIndex])
        }
        return
      }

      if (selectedBinIndexes.includes(binIndex)) {
        setSelectedBinIndexes([])
        syncSelections()
      } else {
        setSelectedBinIndexes([binIndex])
        syncSelections()
      }
    }

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) {
      setSelectionStartIndex(null)
      setSelectionEndIndex(null)
      syncSelections()
      return
    }

    if (!brushStart) {
      return
    }

    const scaleStep = focusHistogramConfig.dateBandScale.step()
    const startIndex = Math.floor(domain.x0 / scaleStep)
    const endIndex = Math.floor(domain.x1 / scaleStep)

    setSelectionStartIndex(startIndex)
    setSelectionEndIndex(endIndex)
    setSelectedBinIndexes([])
    syncSelections()
  }

  const makeBarFillColor: ResultTimelineBarFillHandler = (
    bar: Omit<BarGroupBar<TimelineDoctypes>, 'value' | 'key'> & {
      bar: SeriesPoint<VisualizationDatum>
      key: TimelineDoctypes
    },
  ): string => {
    const isIndividuallySelected = selectedBinIndexes.includes(bar.index)
    const isRangeSelected =
      isNumber(selectionStartIndex) &&
      isNumber(selectionEndIndex) &&
      bar.index - 1 >= selectionStartIndex &&
      bar.index - 1 <= selectionEndIndex

    return isIndividuallySelected || isRangeSelected
      ? BAR_FILL_COLOR[bar.key].selected
      : BAR_FILL_COLOR[bar.key].normal
  }

  const colorScale = scaleOrdinal<TimelineDoctypes, string>({
    domain: ['IMAGE', 'PUBLICATION', 'SOCIAL_MEDIA', 'DATA', 'PROPERTY'],
    range: [
      BAR_FILL_COLOR['IMAGE'].normal,
      BAR_FILL_COLOR['PUBLICATION'].normal,
      BAR_FILL_COLOR['SOCIAL_MEDIA'].normal,
      BAR_FILL_COLOR['DATA'].normal,
      BAR_FILL_COLOR['PROPERTY'].normal,
    ],
  })

  if (width === 0) {
    return null
  }

  return (
    <Stack
      w={width}
      style={{
        position: 'relative',
        paddingTop: 16,
        paddingBottom: 4,
        backgroundColor: isReadyForInteraction ? 'white' : '#F5F5F5',
      }}
    >
      <Box style={{ position: 'relative' }}>
        {isReadyForInteraction ? (
          <ResultsTimelineBarGraph
            width={width}
            focusHistogramConfig={focusHistogramConfig}
            onBrushStart={(start) => setBrushStart(start)}
            onBrushEnd={() => setBrushStart(null)}
            onChange={onBrushChange}
            onClick={() => setSelectedBinIndexes([])}
            innerRef={brushRef}
            clearSelection={clearSelection}
            x={(bin) => (bin.x0 ? bin.x0?.toISOString() : '')}
            color={colorScale}
            makeOnBarClick={onBarClick}
            small={isSmall}
            pointerEvents={brushStart === null}
            makeBarFillColor={makeBarFillColor}
            barTooltipParams={barTooltipParams}
            dateHoverTooltipParams={dateHoverTooltipParams}
          />
        ) : (
          <ResultsTimelineLoading
            width={width - TIMELINE_INSET}
            small={isSmall}
            numBars={50}
          />
        )}
      </Box>
      <ResultsTimelineTooltip
        timeBins={focusHistogramConfig.timeBins}
        dateFormatter={focusHistogramConfig.timeBucketConfig.displayStringFn}
        useTooltipParams={barTooltipParams}
      />
    </Stack>
  )
}
