import { extractCompanyName } from '@/hooks/results/result-filter-utilities.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { appEnv } from '@/utils/constants.ts'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types.ts'
import { isSentinelResult } from '@/utils/types/result-type-checkers.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { MultiPolygon } from 'geojson'

const normalizeUrl = (url: string) => {
  const normalizedUrl = new URL(url)
  normalizedUrl.hostname = `api.${appEnv}.danti.ai`
  return normalizedUrl.toString()
}

export const makeSentinelOrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  const featureId = (r.properties.featureId as string) || r.id

  return {
    source: 'SENTINEL_HUB' as const,
    id: featureId,
    tileUrl: normalizeUrl(r.properties._dPreview as string),
    authoredOn: r.authoredOn,
    geometry: r.geometry as MultiPolygon,
    documentId: r.documentId,
    imageResult: r,
    groupKey: featureId,
    score: r.score,
    formattedSource: `${extractCompanyName(r)}`,
  }
}

export const useSentinel = () => {
  const imageResults = usePostfilterImageResults()

  return imageResults.filter(isSentinelResult).map(makeSentinelOrthoLayer)
}
