import { useCallback } from 'react'
import { useKeyedState } from '@/hooks/use-keyed-state'
import {
  type ChatMessage,
  isChatResponse,
  isResultMessage,
  isSearchMetadataMessage,
  isStatusMessage,
  type SearchMetadata,
  type SocketMessage,
  type StatusMessage,
} from '@/utils/types/message-types'
import type { RawSearchResult } from '@/utils/types/result-types'

export const stateKeys = {
  lastResults: 'lastResults',
  lastStatusMessages: 'lastStatusMessages',
  lastSearchMetadata: 'lastSearchMetadata',
  lastChatMessages: 'lastChatMessages',
}

export const useDantiWSMessages = ({
  queryIdRef,
}: {
  queryIdRef: { current: string | null }
}) => {
  const [lastResults, setLastResults] = useKeyedState<RawSearchResult[]>(
    stateKeys.lastResults,
    [],
  )
  const [lastStatusMessages, setLastStatusMessages] = useKeyedState<
    StatusMessage[]
  >(stateKeys.lastStatusMessages, [])
  const [lastSearchMetadata, setLastSearchMetadata] =
    useKeyedState<SearchMetadata | null>(stateKeys.lastSearchMetadata, null)
  const [lastChatMessages, setLastChatMessages] = useKeyedState<ChatMessage[]>(
    stateKeys.lastChatMessages,
    [],
  )

  const handleMessage = useCallback(
    (data: SocketMessage) => {
      if (data.queryId && data.queryId !== queryIdRef.current) {
        console.log('Ignoring message for old query')
        return
      }

      if (isResultMessage(data)) {
        setLastResults((state) => [...data.results, ...state])
      } else if (isStatusMessage(data)) {
        setLastStatusMessages((state) => [data, ...state])
      } else if (isSearchMetadataMessage(data)) {
        setLastSearchMetadata(data)
      } else if (isChatResponse(data)) {
        setLastChatMessages((state) => [data, ...state])
      }
    },
    [
      queryIdRef,
      setLastResults,
      setLastChatMessages,
      setLastSearchMetadata,
      setLastStatusMessages,
    ],
  )

  return {
    handleMessage,
    lastResults,
    lastStatusMessages,
    lastSearchMetadata,
    lastChatMessages,
    flushLastResults: () => setLastResults([]),
    flushLastStatusMessages: () => setLastStatusMessages([]),
    flushLastSearchMetadata: () => setLastSearchMetadata(null),
    flushLastChatMessages: () => setLastChatMessages([]),
  }
}
