import type { CollectionSortType } from '@/api/collections'
import {
  create,
  list,
  remove,
  share,
  unshare,
  update,
} from '@/api/collections/api'
import type {
  CreateCollectionParams,
  ShareCollectionParams,
  UnshareCollectionParams,
  UpdateCollectionParams,
} from '@/api/collections/types'
import type { Collection } from '@/utils/types/collection-types.ts'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useRefreshCollections } from './use-refresh-collections'
export { useRefreshCollections } from './use-refresh-collections'

export interface UseCollectionsHookResponse {
  collections: Collection[]
  totalCount: number
  isLoading: boolean
  pageSize: number
  currentPage: number

  sort: CollectionSortType
  setSort: (sort: CollectionSortType) => void
  nextPage: () => void
  previousPage: () => void
  create: (params: CreateCollectionParams) => Promise<void>
  shareWithVendor: (params: ShareCollectionParams) => Promise<void>
  update: (params: UpdateCollectionParams) => Promise<void>
  remove: (id: string) => Promise<void>
  refresh: () => void
  search: (term: string) => void
}

export interface UseCollectionListParams {
  isAuthenticated: boolean
  nextToken: string
  pageSize: number
  previousToken: string
  sort: CollectionSortType
  searchTerm: string
}

export const useCollectionList = ({
  isAuthenticated,
  nextToken,
  previousToken,
  sort,
  searchTerm,
  pageSize,
}: UseCollectionListParams) => {
  return useQuery({
    queryKey: ['collections', nextToken, previousToken, sort, searchTerm],
    queryFn: () =>
      list({
        limit: pageSize,
        next: nextToken,
        prev: previousToken,
        sort,
        term: searchTerm,
      }),
    enabled: isAuthenticated,
  })
}

export type CreateMutateAction = (
  params: CreateCollectionParams,
) => Promise<unknown>

export type Mutation = {
  mutateAsync: (
    params: any,
    options: { onSuccess: () => void },
  ) => Promise<unknown>
}

export const makeApiEvent =
  <T>(mutation: Mutation, { onSuccess }: { onSuccess: () => void }) =>
  async (params: T) => {
    const response = await mutation.mutateAsync(params, { onSuccess })
    return response
  }

export const useCreateCollection = (): CreateMutateAction => {
  const refreshCollections = useRefreshCollections()
  const createMutation = useMutation({ mutationFn: create })
  return makeApiEvent<CreateCollectionParams>(createMutation, {
    onSuccess: refreshCollections,
  })
}

export type ShareMutateAction = (
  params: ShareCollectionParams,
) => Promise<unknown>
export const useShareCollectionWithVendor = (): ShareMutateAction => {
  const refreshCollections = useRefreshCollections()
  const shareMutation = useMutation({ mutationFn: share })
  return makeApiEvent<ShareCollectionParams>(shareMutation, {
    onSuccess: refreshCollections,
  })
}

export type UnshareMutateAction = (
  params: UnshareCollectionParams,
) => Promise<unknown>
export const useUnshareCollectionWithVendor = (): UnshareMutateAction => {
  const refreshCollections = useRefreshCollections()
  const unshareMutation = useMutation({ mutationFn: unshare })
  return makeApiEvent<UnshareCollectionParams>(unshareMutation, {
    onSuccess: refreshCollections,
  })
}

export type UpdateMutateAction = (
  params: UpdateCollectionParams,
) => Promise<unknown>
export const useUpdateCollection = (): UpdateMutateAction => {
  const refreshCollections = useRefreshCollections()
  const updateMutation = useMutation({ mutationFn: update })
  return makeApiEvent<UpdateCollectionParams>(updateMutation, {
    onSuccess: refreshCollections,
  })
}

export type RemoveMutateAction = (id: string) => Promise<unknown>
export const useRemoveCollection = (): RemoveMutateAction => {
  const refreshCollections = useRefreshCollections()
  const removeCollectionMutation = useMutation({ mutationFn: remove })
  return makeApiEvent<string>(removeCollectionMutation, {
    onSuccess: refreshCollections,
  })
}
