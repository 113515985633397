import React from 'react'
import {
  ActionIcon,
  Button,
  Checkbox,
  Divider,
  FileButton,
  Group,
  InputWrapper,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
  TextInput,
} from '@mantine/core'
import { useProviders } from '@/components/filters/providers/use-providers'
import { Icon } from '@/components/lib/Icon'
import { handleSearchLocationDownload } from '@/components/modals/SaveSearchModal/SaveSearchPanel/helpers.ts'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import type {
  CreateTerrapinParams,
  TerrapinLocation,
} from '@/hooks/use-terrapin'
import {
  useLocationTypeFilter,
  useSetProviders,
  useTimePresetFilter,
} from '@/stores/filters-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { capitalize } from 'lodash'
import Papa from 'papaparse'
import type { TerrapinLocationCsvRecord } from '../TerrapinLocationPanel'
import { ConfigureImageOptions } from './ConfigureImageOptions'
import { ConfigureTimings } from './ConfigureTimings'
import { ConfigurationTableProps as tableProps } from './use-configure-saved-search'

enum TabTitle {
  PROVIDER_SELECTOR = 'provider-selector',
  IMAGE_OPTIONS = 'image-options',
}

const TABS = {
  PROVIDER_SELECTOR: TabTitle.PROVIDER_SELECTOR,
  IMAGE_OPTIONS: TabTitle.IMAGE_OPTIONS,
} as const

function convertToLocation(
  record: TerrapinLocationCsvRecord,
): TerrapinLocation {
  const { location, name, type } = record
  return {
    name,
    filters: [
      {
        type: 'LOCATION',
        value: location,
      },
      {
        type: 'LOCATIONTYPE',
        value: type,
      },
    ],
  }
}

function validateCsvRecord(
  result: TerrapinLocationCsvRecord,
  index: number,
): TerrapinLocationCsvRecord | undefined {
  const { location, type } = result
  if (!location || !type) {
    return
  }

  const name =
    (result.name ?? '').trim() === '' ? `Location ${index + 1}` : result.name

  return {
    location,
    type,
    name,
  }
}

export interface SaveSearchPanelProps {
  refId: string | undefined
  backfill: boolean
  setBackfill: (value: boolean) => void
  queryString: string
  setQueryString: (value: string) => void
  name: string
  setName: (value: string) => void
  emailNotification: string | null
  setEmailNotification: (value: string | null) => void
  expirationDate: string
  setExpirationDate: (value: string) => void
  locations: TerrapinLocation[]
  setLocations: (locations: TerrapinLocation[]) => void
  isSaving: boolean
  handleSave: (closeModal: () => void) => void
  savedConfig: CreateTerrapinParams | undefined
  fileName?: string
  setFileName: (fileName?: string) => void
  onEditLocations: () => void
  onClose: () => void
}

export const SaveSearchPanel = ({
  queryString,
  setQueryString,
  name,
  setName,
  isSaving,
  handleSave,
  expirationDate,
  setExpirationDate,
  emailNotification,
  setEmailNotification,
  backfill,
  setBackfill,
  locations,
  savedConfig,
  setLocations,
  fileName,
  setFileName,
  onEditLocations,
  onClose,
  refId,
}: SaveSearchPanelProps) => {
  const timePreset = useTimePresetFilter()
  const [activeTab, setActiveTab] = React.useState<TabTitle>(
    TABS.PROVIDER_SELECTOR,
  )

  const { providers, selectedProviders } = useProviders()

  const providerLabels = Object.entries(providers).map(([value, label]) => ({
    value,
    label,
  }))

  const setProviders = useSetProviders()

  const updateProviders = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (selectedProviders.includes(value)) {
      setProviders(selectedProviders.filter((p) => p !== value))
    } else {
      setProviders([...selectedProviders, value])
    }
  }

  const [isFileInvalid, setIsFileInvalid] = React.useState(false)
  const [file, setFile] = React.useState<File | null>(null)

  const noBackfillTimeSelected = backfill && timePreset === 'None'
  const isFeed = savedConfig?.mode === 'feed'
  const isSaveDisabled =
    !name || !queryString || isSaving || noBackfillTimeSelected
  const isPolygonSaveDisabled = !name || isSaving
  const isPolygonSearch = useLocationTypeFilter() === 'polygon'
  const showDynamicProviders = useFeatureFlag(
    FEATURE_FLAG_VALUES.dynamicProviders,
  )
  const enablePlaces = useFeatureFlag(FEATURE_FLAG_VALUES.places)

  React.useEffect(() => {
    if (!file) {
      return
    }
    setFileName(file.name)

    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const records = (results.data as TerrapinLocationCsvRecord[]).map(
          validateCsvRecord,
        )

        const invalidRecords = records.filter((x) => x === undefined)

        if (invalidRecords.length > 0) {
          setIsFileInvalid(true)
          setFile(null)
          return
        }

        setLocations(records.filter(Boolean).map(convertToLocation))

        onEditLocations()
      },
      error: (error) => {
        console.error('Error while parsing the file:', error)
      },
    })
  }, [file, setLocations, setIsFileInvalid, onEditLocations, setFileName])

  const handleCloseFile = () => {
    setFile(null)
    setFileName(undefined)
    setLocations([])
    setIsFileInvalid(false)
  }

  return (
    <>
      <Stack gap="md">
        <Divider />
        {!isPolygonSearch && (
          <TextInput
            leftSection={<Icon name="search" />}
            label="Search query"
            value={queryString}
            onChange={(event) => setQueryString(event.target.value)}
            placeholder="Enter the search query for your feed"
            pattern="^[0-9a-zA-Z]{1,}"
            data-cy="save-search-query"
            required
            withAsterisk
          />
        )}
        <TextInput
          label="Name of this feed"
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={savedConfig?.query}
          data-cy="save-search-name"
          required
          withAsterisk
        />
        {isFeed && (
          <>
            {showDynamicProviders ? (
              <Tabs value={activeTab} activateTabWithKeyboard={false}>
                <Tabs.Panel value={TABS.PROVIDER_SELECTOR}>
                  <Text size="sm" fw="bold" mb="xs">
                    Include the following providers in your search
                  </Text>
                  <SimpleGrid
                    cols={{ base: 2, sm: 3, lg: 4 }}
                    spacing={6}
                    style={{
                      border: '1px solid var(--mantine-color-gray-3)',
                      borderRadius: 'var(--mantine-radius-sm)',
                      alignItems: 'start',
                      padding: '10px',
                      maxHeight: '200px',
                      overflowY: 'scroll',
                    }}
                  >
                    {providerLabels.map(({ value }) => (
                      <Checkbox
                        checked={selectedProviders.includes(value)}
                        value={value}
                        label={value
                          .split('_')
                          .map((v) => capitalize(v))
                          .join(' ')}
                        key={value}
                        onChange={updateProviders}
                      >
                        {value}
                      </Checkbox>
                    ))}
                  </SimpleGrid>
                  <Button
                    size="xs"
                    mt="sm"
                    onClick={() => setActiveTab(TABS.IMAGE_OPTIONS)}
                  >
                    Image options
                  </Button>
                </Tabs.Panel>
                <Tabs.Panel value="image-options">
                  <Button
                    variant="transparent"
                    p={0}
                    c="gray"
                    leftSection={<Icon name="arrow_circle_left" />}
                    onClick={() => setActiveTab(TABS.PROVIDER_SELECTOR)}
                    title="Back"
                  >
                    Back to providers
                  </Button>
                  <ConfigureImageOptions tableProps={tableProps} />
                </Tabs.Panel>
              </Tabs>
            ) : (
              <ConfigureImageOptions tableProps={tableProps} />
            )}
            <ConfigureTimings
              savedConfig={savedConfig}
              tableProps={tableProps}
              expirationDate={expirationDate}
              setExpirationDate={setExpirationDate}
              emailNotification={emailNotification}
              setEmailNotification={setEmailNotification}
              backfill={backfill}
              setBackfill={setBackfill}
            />
            {enablePlaces && (
              <Stack gap="sm">
                <Group align="center" gap={0}>
                  {locations.length > 0 ? (
                    <Button
                      size="xs"
                      style={{ alignSelf: 'start' }}
                      onClick={() => onEditLocations()}
                    >
                      EDIT LOCATIONS
                    </Button>
                  ) : (
                    <InputWrapper
                      error={
                        isFileInvalid
                          ? 'Invalid file format, please try again'
                          : undefined
                      }
                      styles={{ error: { marginTop: 10 } }}
                    >
                      <FileButton onChange={setFile} accept="text/csv">
                        {(props) => (
                          <Button
                            size="xs"
                            style={{ alignSelf: 'start' }}
                            leftSection={
                              <Icon
                                name="add_circle_outline"
                                size="xs"
                                filled
                              />
                            }
                            {...props}
                          >
                            UPLOAD LOCATIONS
                          </Button>
                        )}
                      </FileButton>
                    </InputWrapper>
                  )}
                  {refId && (
                    <Button
                      size="xs"
                      style={{ alignSelf: 'start', marginLeft: 10 }}
                      onClick={() => {
                        void handleSearchLocationDownload(refId)
                      }}
                    >
                      DOWNLOAD LOCATIONS
                    </Button>
                  )}
                  {fileName && (
                    <Group
                      align="center"
                      gap="sm"
                      mt={locations.length > 0 ? 0 : -20}
                      ml={locations.length > 0 ? 10 : 0}
                    >
                      <Text size="sm">{fileName}</Text>
                      <ActionIcon
                        variant="white"
                        color="black"
                        onClick={() => handleCloseFile()}
                      >
                        <Icon name="highlight_off" />
                      </ActionIcon>
                    </Group>
                  )}
                </Group>
                <Text size="xs">
                  Upload a spreadsheet of locations in latitude/longitude,
                  polygon or MGRS format.
                </Text>
                <Text size="xs" mt={-8}>
                  Use template provided{' '}
                  <a
                    style={{ color: '#0091EA' }}
                    href="https://imagery-1724-stage.s3.us-west-2.amazonaws.com/templates/areas-of-interests-template.csv"
                  >
                    here
                  </a>
                </Text>
              </Stack>
            )}
          </>
        )}
      </Stack>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" size="xs" color="#000000DE" onClick={onClose}>
          CANCEL
        </Button>
        <Button
          size="xs"
          loading={isSaving}
          disabled={isPolygonSearch ? isPolygonSaveDisabled : isSaveDisabled}
          onClick={() => handleSave(onClose)}
        >
          SAVE
        </Button>
      </Group>
    </>
  )
}
