import * as React from 'react'
import type { CategoryTypes } from '@/utils/types/result-types'

export function useCollectionItemsActions({
  next,
  prev,
  setNextToken,
  setPreviousToken,
  setCurrentPage,
  setSearchTerm,
  maxPages,
  selectedCategories,
  setSelectedCategories,
}: {
  next?: string
  prev?: string
  selectedCategories: CategoryTypes[]
  setNextToken: React.Dispatch<React.SetStateAction<string>>
  setPreviousToken: React.Dispatch<React.SetStateAction<string>>
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  setSelectedCategories: React.Dispatch<React.SetStateAction<CategoryTypes[]>>
  maxPages: number
}) {
  const nextPage = () => {
    if (next) {
      setPreviousToken('')
      setNextToken(next)
      setCurrentPage((page) => Math.min(maxPages, page + 1))
    }
  }

  const previousPage = () => {
    if (prev) {
      setNextToken('')
      setPreviousToken(prev)
      setCurrentPage((page) => Math.max(0, page - 1))
    }
  }

  const search = React.useCallback((term: string) => {
    setNextToken('')
    setPreviousToken('')
    setCurrentPage(0)
    setSearchTerm(term)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleSelectedCategory = (category: CategoryTypes) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories((selected) =>
        [...selected].filter((item) => item != category),
      )
    } else {
      setSelectedCategories((selected) => [...selected, category])
    }
  }

  return {
    nextPage,
    previousPage,
    search,
    toggleSelectedCategory,
  }
}
