import React from 'react'
import { notifications } from '@mantine/notifications'
import { postSearchRequest } from '@/api/danti-requests'
import { useDantiWS } from '@/api/danti-ws'
import { useSetQueryStarted } from '@/stores/queries-store'
import { useSetCurrentQueryId } from '@/stores/results-store'
import { makeStacIdFilter } from '@/utils/search-filters/make-stac-id-filter.ts'
import { CategoryTypes } from '@/utils/types/result-types.ts'
import { singletonHook } from 'react-singleton-hook'

function useRelatedImagerySearchImpl() {
  const setCurrentQueryId = useSetCurrentQueryId()
  const setQueryStarted = useSetQueryStarted()
  const { lastResults } = useDantiWS()

  const [notificationId, setNotificationId] = React.useState<string | null>(
    null,
  )
  const [notificationTimeout, setNotificationTimeout] =
    React.useState<ReturnType<typeof setTimeout> | null>(null)

  const doRelatedImagerySearch = React.useCallback(
    async (documentId: string): Promise<void> => {
      notifications.clean()

      const id = notifications.show({
        loading: true,
        title: 'Related imagery search started',
        message:
          'This might take a minute. You will be notified when results are available.',
        autoClose: false,
        withCloseButton: false,
      })

      setNotificationId(id)

      const filters = []
      filters.push(makeStacIdFilter(documentId))

      // Now using ipa.<workload>.danti.ai/search as the endpoint instead of the
      // previous api.<workload>.danti.ai/neural/search/proxy
      const response = await postSearchRequest<{ queryId: string }>('/search', {
        query: ' ',
        filters: filters,
      })

      setCurrentQueryId(response.data.queryId)
      setQueryStarted()

      setNotificationTimeout(
        setTimeout(() => {
          notifications.update({
            id: id,
            title: 'No results',
            message:
              'Sorry, we looked everywhere but were unable to find any related imagery.',
            color: 'red',
            loading: false,
            autoClose: 10000,
            withCloseButton: true,
          })
        }, 30000),
      )
    },
    [setCurrentQueryId, setQueryStarted],
  )

  React.useEffect(() => {
    const hasRelatedImagery = lastResults.some(
      (result) => result.category === CategoryTypes.RELATED_IMAGERY,
    )
    if (hasRelatedImagery && notificationTimeout && notificationId) {
      clearTimeout(notificationTimeout)
      setNotificationTimeout(null)
      notifications.update({
        id: notificationId,
        title: 'Done!',
        message: 'Related imagery search results are available',
        color: 'green',
        loading: false,
        autoClose: 3000,
        withCloseButton: true,
      })
    }
  }, [lastResults, notificationId, notificationTimeout])

  return {
    notificationId,
    doRelatedImagerySearch,
  }
}

export const useRelatedImagerySearch = singletonHook<{
  notificationId: string | null
  doRelatedImagerySearch: (documentId: string) => Promise<void>
}>(
  () => ({
    notificationId: null,
    doRelatedImagerySearch: () => Promise.resolve(),
  }),
  useRelatedImagerySearchImpl,
)
