import type { HexGrid } from '@/components/lib/map-search/hooks/util/make-bare-hex-grid.ts'
import type { FeatureCollection } from 'geojson'
import type { GeoJSONSource, Map } from 'mapbox-gl'
import type { MutableRefObject } from 'react'

type AddSourcesProps = {
  map: MutableRefObject<Map | null>
  extentsData?: FeatureCollection | null
  hexGrid?: HexGrid | null
  regridCollection?: FeatureCollection | null
}

export const addSources: (props: AddSourcesProps) => void = ({
  map,
  extentsData,
  hexGrid,
  regridCollection
}) => {
  if (extentsData) {
    const extents = map.current?.getSource('extents') as GeoJSONSource
    if (extents) {
      extents.setData(extentsData)
    } else {
      map.current?.addSource('extents', {
        type: 'geojson',
        data: extentsData,
      })
    }
  }

  if (regridCollection) {
    const extents = map.current?.getSource('regrid') as GeoJSONSource
    if (extents) {
      extents.setData(regridCollection)
    } else {
      map.current?.addSource('regrid', {
        type: 'geojson',
        data: regridCollection,
      })
    }
  }

  if (hexGrid) {
    const hexGridSource = map.current?.getSource('hexgrid') as GeoJSONSource
    if (hexGridSource) {
      hexGridSource.setData(hexGrid)
    } else {
      map.current?.addSource('hexgrid', {
        type: 'geojson',
        data: hexGrid,
      })
    }
  }
}
