import React from 'react'
import { Stack } from '@mantine/core'
import type { CollectionItem } from '@/utils/types/collection-types'
import { CollectionDetailListItem } from './CollectionDetailListItem'
import { CollectionDetailListItemModalMenu } from './CollectionDetailListItemModalMenu'

export interface CollectionDetailListProps {
  items: CollectionItem[]
}

export function CollectionDetailList({ items }: CollectionDetailListProps) {
  const [selectedItem, setSelectedItem] = React.useState<CollectionItem>()

  return (
    <>
      <Stack gap={0} mt="xs" hiddenFrom="sm">
        {items.map((item) => (
          <CollectionDetailListItem
            key={item.id}
            item={item}
            onSelect={(item) => setSelectedItem(item)}
          />
        ))}
      </Stack>
      {selectedItem && (
        <CollectionDetailListItemModalMenu
          item={selectedItem}
          onClose={() => setSelectedItem(undefined)}
        />
      )}
    </>
  )
}
