import { useResultsStore } from './store'

export const useSetCurrentQueryId = () =>
  useResultsStore((s) => s.setCurrentQueryId)
export const useClearResults = () => useResultsStore((s) => s.clearResults)
export const useAddResults = () => useResultsStore((s) => s.addResults)
export const useAddRelatedImageryResults = () =>
  useResultsStore((s) => s.addRelatedImageryResults)
export const useClearResultsOfTypes = () =>
  useResultsStore((s) => s.clearResultsOfTypes)
