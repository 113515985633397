import { useResultsStore } from '@/stores/results-store/store';
import { isRecognizedPropertyResult } from '@/utils/types/result-type-checkers'
import { categorized } from '../results';
import { createSelector } from 'reselect';

export const propertyResults = createSelector([categorized], (c) => c.propertyResults.filter(
  isRecognizedPropertyResult
))

export const propertyResultsByCategory = createSelector([propertyResults], (results) =>
  results.reduce(
    (object: { [key: string]: unknown[] }, result) => {
      const category = result.properties.resultCategory as string
      if (!object[category]) {
        object[category] = []
      }
      object[category].push(result)
      return object
    },
    {},
  )
)

export const usePropertyResults = () => useResultsStore(propertyResults)
export const usePropertyResultsByCategory = () => useResultsStore(propertyResultsByCategory)

