import React, { type PropsWithChildren } from 'react'
import { openSessionConfirmationModal } from '@/components/modals/openers'
import { REFRESH_USER_QUERY_PARAM } from '@/features/AppRouting/constants'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useSetProfile } from '@/stores/user-store'
import {
  oidcAuthority,
  oidcClientId,
  oidcRedirectUri,
  ROUTES,
} from '@/utils/constants'
import { posthogIdentify } from '@/utils/posthog'
import { type User, WebStorageStateStore } from 'oidc-client-ts'
import {
  AuthProvider,
  type AuthProviderProps,
  useAuth,
} from 'react-oidc-context'

function AuthListener({ children }: PropsWithChildren) {
  const auth = useAuth()
  const { isLoading, user, refreshUser } = useDantiAuth()
  const setProfile = useSetProfile()

  const [hasLoaded, setHasLoaded] = React.useState(false)

  const urlParams = new URLSearchParams(window.location.search)

  const requiresUserRefresh = urlParams.get(REFRESH_USER_QUERY_PARAM) === 'true'

  React.useEffect(() => {
    if (!auth) {
      return
    }

    auth.events.addAccessTokenExpired(() => {
      if (window.location.pathname !== ROUTES.login) {
        console.info('Access token expired')
        openSessionConfirmationModal()
      }
    })
  }, [auth])

  async function handleRefreshUser() {
    await refreshUser()
    urlParams.delete(REFRESH_USER_QUERY_PARAM)

    window.location.search = urlParams.toString()
  }

  React.useEffect(() => {
    if (requiresUserRefresh) {
      void handleRefreshUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiresUserRefresh])

  React.useEffect(() => {
    if (!isLoading && user) {
      console.groupCollapsed('Setting auth user:')
      console.dir(user)
      console.groupEnd()
      setProfile(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, user])

  React.useEffect(() => {
    if (!isLoading && !hasLoaded) {
      setHasLoaded(true)
    }
  }, [hasLoaded, setHasLoaded, isLoading])

  return hasLoaded ? children : <span>Loading...</span>
}

const oidcConfig: AuthProviderProps =
  oidcAuthority && oidcClientId && oidcRedirectUri
    ? {
        authority: oidcAuthority,
        // eslint-disable-next-line camelcase
        client_id: oidcClientId,
        // eslint-disable-next-line camelcase
        redirect_uri: oidcRedirectUri,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        automaticSilentRenew: true,
        onRemoveUser: () => {
          window.location.pathname = ROUTES.login
        },
      }
    : {}

export const DantiAuthProvider = ({ children }: PropsWithChildren) => {
  const setProfile = useSetProfile()

  const onSigninCallback = (user: void | User) => {
    if (user) {
      setProfile(user)
      posthogIdentify(user)
    }
  }

  return (
    <AuthProvider
      {...{
        ...oidcConfig,
        onSigninCallback,
      }}
    >
      <AuthListener>{children}</AuthListener>
    </AuthProvider>
  )
}
