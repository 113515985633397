import React from 'react'
import { ResultMenu } from '@/components/lib/results/Result/ResultMenu'
import type { CollectionItem } from '@/utils/types/collection-types'
import {
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/raw-result-types'
import { isFileResult, isNewsResult } from '@/utils/types/result-type-checkers'

export interface CollectionDetailMenuProps {
  item: CollectionItem
}

export const CollectionDetailMenu = ({ item }: CollectionDetailMenuProps) => {
  const { doc } = item
  const result = JSON.parse(doc) as ResultWithId

  const disableNavigate = React.useMemo(
    () =>
      isNewsResult(result) ||
      isSocialMediaResult(result) ||
      isFileResult(result),
    [result],
  )

  return (
    <ResultMenu
      item={item}
      result={result}
      container="table"
      disableNavigate={disableNavigate}
    />
  )
}
