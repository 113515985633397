import { type MantineStyleProp, Select } from '@mantine/core'
import { useSetSortPreference, useSortPreference } from '@/stores/queries-store'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'

interface SortSelectProps {
  style?: MantineStyleProp
}

const SORT_EVENTS = {
  authoredOn: POSTHOG_EVENTS.EXPLORE.SORT_DATE,
  score: POSTHOG_EVENTS.EXPLORE.SORT_RELEVANCE,
} as const

export function SortSelect({ style }: SortSelectProps) {
  const sortPreference = useSortPreference()
  const setSortPreference = useSetSortPreference()

  function handleSortChange(value: string | null) {
    if (value) {
      const typedValue = value as keyof typeof SORT_EVENTS
      captureEvent(SORT_EVENTS[typedValue])
      setSortPreference(typedValue)
      return
    }
    setSortPreference('score')
  }

  return (
    <Select
      size="xs"
      value={sortPreference}
      onChange={handleSortChange}
      data={[
        { label: 'Date', value: 'authoredOn' },
        { label: 'Relevance', value: 'score' },
      ]}
      maw={115}
      data-cy="sort-results"
      style={style}
    />
  )
}
