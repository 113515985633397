import {
  GOV_BENTO_ORDER,
  PROPERTY_BENTO_ORDER,
} from '@/features/BentoResults/constants'
import type { BentoCardData } from '@/features/BentoResults/types'
import {
  useAttomWithPermits,
  useHazardPropertyResultsWithImages,
  useNewsWithImages,
  useNewsWithoutImages,
  usePropertyResults,
  useResultsByResultCategory,
  useSocialWithImages,
  useSocialWithoutImages,
} from '@/stores/results-store'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { ResultWithId } from '@/utils/types/result-types'
import addResultToCardData from './add-result-to-card-data'

export function useBentoResults() {
  const cardData = [] as {
    type: string
    result: ResultWithId
    totalResults?: number
  }[]
  const results = useResultsByResultCategory()
  const isProperty = usePropertyResults().length > 0
  const newsWithImages = useNewsWithImages()
  const newsWithoutImages = useNewsWithoutImages()
  const socialWithImages = useSocialWithImages()
  const socialWithoutImages = useSocialWithoutImages()
  const effectiveResults = {
    ...results,
    [RESULT_CATEGORIES.HAZARD_PROPERTY]: useHazardPropertyResultsWithImages(),
    [RESULT_CATEGORIES.PUBLICATION]: newsWithImages,
    [RESULT_CATEGORIES.SOCIAL_MEDIA]: socialWithImages,
    [RESULT_CATEGORIES.ATTOM]: useAttomWithPermits(),
  } as Record<string, ResultWithId[]>

  const addResult = (category: string, index: number, includeTotal: boolean) =>
    addResultToCardData(
      cardData,
      category,
      index,
      effectiveResults[category],
      results[category],
      includeTotal,
    )

  const order = isProperty
    ? PROPERTY_BENTO_ORDER
    : (GOV_BENTO_ORDER as string[])
  order.forEach((category: string) => {
    if (effectiveResults[category]?.length > 0) {
      addResult(category, 0, true)
    }
  })
  let index = 1

  const bentoSize = 5
  while (cardData.length < bentoSize && index < bentoSize) {
    order.forEach((category: string) => {
      if (cardData.length >= bentoSize) {
        return
      }
      if (effectiveResults[category]?.length > index) {
        addResult(category, index, false)
      }
    })
    index += 1
  }
  if (cardData.length < bentoSize) {
    index = 0
    const iconCategoryResults = {
      [RESULT_CATEGORIES.PUBLICATION]: newsWithoutImages,
      [RESULT_CATEGORIES.SOCIAL_MEDIA]: socialWithoutImages,
    } as Record<string, ResultWithId[]>

    const iconCategoryOrder = order.filter(
      (category: string) => iconCategoryResults[category]?.length > 0,
    )
    while (cardData.length < bentoSize && index < bentoSize) {
      iconCategoryOrder.forEach((category: string) => {
        if (iconCategoryResults[category]?.length > index) {
          addResultToCardData(
            cardData,
            category,
            index,
            iconCategoryResults[category],
            results[category],
            false,
          )
        }
      })
      index += 1
    }
  }
  return cardData as BentoCardData[]
}
