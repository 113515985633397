import { SCORE_COLORS } from '@/utils/constants'
import { RISK_ICONS } from '@/utils/svg-icons'
import type { HazardRisk, HazardRiskResult } from '@/utils/types/result-types'

export const getHighestRisks = (result: HazardRiskResult) => {
  const { risks } = result.properties
  const riskKeys = Object.keys(risks)
  const sortedRiskKeys = riskKeys.sort((a: string, b: string) => {
    const scoreA = risks[a]?.score || ''
    const scoreB = risks[b]?.score || ''
    return scoreB.localeCompare(scoreA)
  })
  const highestRisk = risks[sortedRiskKeys[0]]
  return riskKeys.filter((riskKey) => {
    const risk = result.properties.risks[riskKey]
    return risk.score === highestRisk.score
  })
}

export const getRiskColor = (risk: { score?: string }) =>
  risk.score ? SCORE_COLORS[risk.score] : SCORE_COLORS.F

export const getRiskCardDetails = (risk: HazardRisk) => ({
  title: risk.title,
  icon: RISK_ICONS[risk.key as keyof typeof RISK_ICONS],
  iconColor: getRiskColor(risk),
  leftDetail: risk.score,
  rightDetail: risk.risk,
})
