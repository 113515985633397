import { notifications } from '@mantine/notifications'
import { useDantiWS } from '@/api/danti-ws'
import { useClearChat } from '@/stores/chat-store'
import { useResetFilters } from '@/stores/filters-store'
import {
  useClearSearchHereMarker,
  useClearSearchPolygon,
  useSetIsDrawing,
  useSetLastSearchPolygon,
  useStopDrawingPolygon,
} from '@/stores/map-draw-store'
import {
  useClearActiveLayers,
  useClearOrthoLayerGroupIds,
  useSelectedHexGridId,
  useSetHoveredHexGridId,
  useSetMapBbox,
  useToggleSelectedHexGridId,
} from '@/stores/map-store'
import {
  useClearQuery,
  useSetInsightFeedbackSubmitted,
} from '@/stores/queries-store'
import { useResetPostfilters } from '@/stores/results-filter-store'
import { useClearResults, useSetCurrentQueryId } from '@/stores/results-store'

export function useClearAll() {
  const clearSearchPolygon = useClearSearchPolygon()
  const clearSearchHereMarker = useClearSearchHereMarker()
  const stopDrawingPolygon = useStopDrawingPolygon()
  const setIsDrawing = useSetIsDrawing()
  const setLastSearchPolygon = useSetLastSearchPolygon()
  const toggleSelectedHexGridId = useToggleSelectedHexGridId()
  const selectedHexGridId = useSelectedHexGridId()
  const setHoveredHexGridId = useSetHoveredHexGridId()
  const clearOrthoLayerIds = useClearOrthoLayerGroupIds()
  const setGeoLocation = useSetMapBbox()
  const clearActiveLayers = useClearActiveLayers()
  const clearResults = useClearResults()
  const setCurrentQueryId = useSetCurrentQueryId()
  const clearQuery = useClearQuery()
  const resetPostFilters = useResetPostfilters()
  const { closeSocket } = useDantiWS()
  const setInsightFeedbackSubmitted = useSetInsightFeedbackSubmitted()
  const clearChat = useClearChat()
  const resetFilters = useResetFilters()

  // First: closes socket to stop receiving results
  // Clears all search state: results, query, filters, chat, and map
  function clearAll(preservePolygon = false) {
    notifications.clean()
    closeSocket()
    clearResults()
    setCurrentQueryId(null)
    clearQuery()
    resetFilters()
    clearChat()
    stopDrawingPolygon()
    setIsDrawing(false)
    if (selectedHexGridId) {
      toggleSelectedHexGridId(selectedHexGridId)
    }
    setHoveredHexGridId(null)
    setGeoLocation(null)

    clearOrthoLayerIds()

    if (!preservePolygon) {
      clearSearchPolygon()
      setLastSearchPolygon(null)
    }
    setInsightFeedbackSubmitted(false)
    clearSearchHereMarker()
    clearActiveLayers()
    resetPostFilters()
  }

  return clearAll
}
