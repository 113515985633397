import { useMemo } from 'react'
import { useHexgridFilteredResults } from '@/hooks/results/use-hexgrid-filtered-results.ts'
import type {
  RawSearchResult,
  ResultWithId,
} from '@/utils/types/result-types.ts'
import { scaleBand, scaleLinear, scaleTime } from '@visx/scale'
import { type Bin, bin, extent, max } from '@visx/vendor/d3-array'
import type { ScaleBand, ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale'
import { throttle } from 'lodash'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'
import {
  getConfigForRange,
  type TimeBucketConfig,
} from '../results-timeline-utilities.ts'

const extractDate = (r: RawSearchResult) => new Date(r.authoredOn)

export type VisualizationDatum = {
  x0: Date | undefined
  x1: Date | undefined
  IMAGE: number
  DATA: number
  PUBLICATION: number
  SOCIAL_MEDIA: number
  PROPERTY: number
}

export interface TimelineHistogramConfig {
  visualizationData: VisualizationDatum[]
  mergedHistogramResult: Bin<ResultWithId, Date>[]
  dateBandScale: ScaleBand<string>
  dateContinuousScale: ScaleTime<number, number>
  countScale: ScaleLinear<number, number>
  timeBins: Date[]
  timeBucketConfig: TimeBucketConfig
}

export const useTimelineHistogram = (props: {
  startDate: Date | undefined
  endDate: Date | undefined
  xMax: number
  yMax: number
  isolateType?: (result: ResultWithId) => boolean
}): TimelineHistogramConfig => {
  const { categorized } = useHexgridFilteredResults(props.isolateType)
  const results = [
    ...categorized.imageResults,
    ...categorized.publicationResults,
    ...categorized.socialMediaResults,
    ...categorized.dataResults,
    ...categorized.propertyResults,
  ].filter((result) => props.isolateType?.(result) ?? true)
  const deps = useDeepCompareMemoize([
    props.startDate,
    props.endDate,
    props.xMax,
    props.yMax,
    results,
  ])

  const makeTimelineHistogramConfig = throttle(() => {
    const dateExtent: [Date, Date] =
      props.startDate && props.endDate
        ? [props.startDate, props.endDate]
        : (extent(results, extractDate) as [Date, Date])

    const timeBucketConfig = getConfigForRange(dateExtent[0], dateExtent[1])
    // console.log(
    //   `Using ${timeBucketConfig.bucketUnit} buckets. ${JSON.stringify(
    //     dateExtent,
    //   )}`,
    // )
    const paddedExtent: [Date, Date] = [
      timeBucketConfig.offsetFn(dateExtent[0], -1),
      timeBucketConfig.offsetFn(dateExtent[1], 1),
    ]
    const timeBins = timeBucketConfig.rangeFn(paddedExtent[0], paddedExtent[1])

    const dateBandScale = scaleBand<string>({
      domain: timeBins.map((s) => s.toISOString()),
      range: [0, props.xMax],
      paddingInner: 0.2,
    })

    const dateContinuousScale = scaleTime<number>({
      domain: paddedExtent,
      range: [0, props.xMax],
    })

    const histogramGenerator = bin<ResultWithId, Date>()
      .value((r) => new Date(r.authoredOn))
      .domain(paddedExtent)
      .thresholds(timeBins)

    const mergedHistogramResult = histogramGenerator(results)

    const countScale = scaleLinear<number>({
      domain: [0, max(mergedHistogramResult, (d) => d.length) as number],
      range: [props.yMax, 0],
    })

    const resultsByType = {
      IMAGE: histogramGenerator(categorized.imageResults),
      DATA: histogramGenerator(categorized.dataResults),
      PUBLICATION: histogramGenerator(categorized.publicationResults),
      SOCIAL_MEDIA: histogramGenerator(categorized.socialMediaResults),
      INSIGHT: histogramGenerator(categorized.insightResults),
      PROPERTY: histogramGenerator(categorized.propertyResults),
    }

    const visualizationData = mergedHistogramResult.map((bin, index) => {
      return {
        IMAGE: resultsByType.IMAGE[index]?.length || 0,
        DATA: resultsByType.DATA[index]?.length || 0,
        PUBLICATION: resultsByType.PUBLICATION[index]?.length || 0,
        SOCIAL_MEDIA: resultsByType.SOCIAL_MEDIA[index]?.length || 0,
        INSIGHT: resultsByType.INSIGHT[index]?.length || 0,
        PROPERTY: resultsByType.PROPERTY[index]?.length || 0,
        x0: bin.x0,
        x1: bin.x1,
      }
    })

    return {
      visualizationData,
      mergedHistogramResult,
      dateBandScale,
      dateContinuousScale,
      countScale,
      timeBins,
      timeBucketConfig,
    }
  }, 500)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<TimelineHistogramConfig>(
    makeTimelineHistogramConfig as () => TimelineHistogramConfig,
    deps,
  )
}
