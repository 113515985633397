import { useLocation } from 'react-router-dom'
import { Paper } from '@mantine/core'
import { SearchBar } from '@/features/Search'
import { useCurrentQuery } from '@/stores/queries-store'
import type { PropsWithChildren } from 'react'

import styles from './footer.module.css'

export function Footer({ children }: PropsWithChildren) {
  const { pathname } = useLocation()
  const isExplore = pathname === '/'
  const isLogIn = pathname === '/log-in'
  const currentQuery = useCurrentQuery()
  if (isExplore || isLogIn) {
    return null
  }

  return (
    <>
      {children}
      <Paper
        pos="fixed"
        bg="#000b"
        fz={14}
        top={0}
        left={0}
        right={0}
        shadow="xs"
        radius={0}
        style={{ zIndex: 3 }}
      >
        <footer className={styles['commercial-footer']}>
          <SearchBar
            placeholder={
              currentQuery.trim() ||
              'Search for a place or event and a city/country, lat/long or MGRS.'
            }
          />
        </footer>
      </Paper>
    </>
  )
}
