import { Anchor } from '@mantine/core'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { SocialMediaReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { useResultNavigation } from '@/features/SingleObjectView/hooks'
import { getSocialImages } from '@/stores/results-store'
import { RESULT_CATEGORIES, ROUTES, SOCIAL_COLORS } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type {
  ResultWithId,
  SocialMediaResult,
} from '@/utils/types/result-types'
import { startCase } from 'lodash'

const category = RESULT_CATEGORIES.SOCIAL_MEDIA
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function SocialMediaObjectView({
  item: rawItem,
}: {
  item: ResultWithId
}) {
  const item = rawItem as SocialMediaResult
  const { items, toPrevious, toNext, selectItem } = useResultNavigation(item)

  const source = item.properties.source.toLowerCase()
  const capitalizedSource = startCase(source)

  const images = getSocialImages(item)
  const image =
    images.length > 0
      ? {
          thumbnail: images[0].url,
          title: source,
          source: images[0].url,
        }
      : null

  return (
    <SingleObjectViewTemplate<SocialMediaResult, SocialMediaResult>
      {...{
        item,
        hideActions,
        hero: {
          socialIconName: source,
          color: SOCIAL_COLORS[source] || 'blue',
          toPrevious,
          toNext,
          thumbnailImage: image,
          metaDetails: [capitalizedSource, formatDatetime(item.authoredOn)],
          metaAction: (
            <Anchor
              href={item.properties.link}
              target="_blank"
              rel="noreferrer"
            >
              Source
            </Anchor>
          ),
        },
        content: { details: item.properties.summary },
        carousel: {
          items,
          renderCard: (post) => <SocialMediaReferenceCard post={post} />,
          title: 'Related Posts',
          selectItem,
          categoryUrl: ROUTES.social,
          categoryLabel: 'All Posts',
        },
      }}
    />
  )
}
