import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SEARCH_STYLE } from '@/features/Search/constants'
import { useSetStoredLocation } from '@/stores/queries-store'
import { useAutoCompleteValues } from './use-autocomplete-values'
import { useInputFields } from './use-input-fields'
import { useLocationField } from './use-location-field'

interface UseSplitSearchProps {
  doSplitSearch: (
    formattedAddress: string,
    query: string,
    subject: string,
    location: {
      formattedAddress: string
      placeId: string
      country: string
      adminAreaLevel1: string
      adminAreaLevel2: string
    },
  ) => Promise<string>
  ref: React.Ref<unknown>
}

export const useSplitSearch = ({ doSplitSearch, ref }: UseSplitSearchProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const setStoredLocation = useSetStoredLocation()

  const inputs = useInputFields()
  const autoComplete = useAutoCompleteValues(inputs.locationValue)
  const locationProps = useLocationField(inputs, autoComplete)

  const isExplore = pathname === '/'

  const resetValues = () => {
    inputs.setSubjectValue('')
    autoComplete.setErrorMessage('')
  }

  const clearLocationValue = () => {
    inputs.setLocationValue('')
    autoComplete.setSuggestionSelected(false)
    setStoredLocation(null)
  }

  const splitSearchArgs = [
    autoComplete.formattedAddress,
    inputs.locationValue,
    inputs.subjectValue,
    autoComplete.searchArgs,
  ] as const

  const handleSetQuery = (value: string) => {
    if (!value) {
      return
    }
    void doSplitSearch(...splitSearchArgs)
    inputs.blur()
    resetValues()
    const params = new URLSearchParams({ search: value })
    const pathname = window.location.pathname.toLowerCase()

    if (pathname.startsWith('/map')) {
      navigate(`/map?${params.toString()}`)
    } else {
      navigate(`/overview?${params.toString()}`)
    }
  }

  const handleSubmit = (searchStyle: SEARCH_STYLE) => {
    if (
      searchStyle == SEARCH_STYLE.Location &&
      !autoComplete.suggestionSelected
    ) {
      autoComplete.setErrorMessage('Please select a suggested location.')
      return
    }

    const items = [
      inputs.locationValue.trim(),
      inputs.subjectValue ? `${inputs.subjectValue}` : '',
    ].filter((x) => x.length > 0)

    if (items.length > 0) {
      handleSetQuery(items.join(' - '))
    }
    clearLocationValue()
  }

  React.useImperativeHandle(ref, () => ({
    handleSubmit,
    focus: inputs.focus,
  }))

  return {
    inputs,
    autoComplete,
    locationProps,
    handleSetQuery,
    clearLocationValue,
    isExplore,
    handleSubmit,
  }
}
