import { Stack, Text } from '@mantine/core'
import { InsightFeedbackPanel } from '@/features/InsightPanel/components/InsightFeedback'
import type { InsightsPanelProps } from '@/features/InsightPanel/types'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import ReactMarkdown from 'react-markdown'

import styles from './insight-panel.module.css'

export function InsightPanel({ result }: InsightsPanelProps) {
  const isFeedbackEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.insightFeedback)

  return (
    <Stack component="div" p={0}>
      <Text className={styles.markdown} span>
        <ReactMarkdown>{result.description}</ReactMarkdown>
      </Text>
      {isFeedbackEnabled && <InsightFeedbackPanel />}
    </Stack>
  )
}
