import { useNavigate } from 'react-router-dom'
import { Card, Group, Pill, SimpleGrid, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

import { useCollections } from '@/api/collections'
import { CollectionMenu } from './CollectionMenu'

export function CollectionGrid() {
  const { collections } = useCollections()
  const navigate = useNavigate()

  return (
    <SimpleGrid cols={3} spacing="md" flex={1} mt="md">
      {collections.map((collection) => (
        <Card
          key={collection.id}
          p="sm"
          withBorder
          radius="md"
          bg="#FAFAFA"
          style={{ cursor: 'pointer' }}
        >
          <Stack justify="space-between" mah={200}>
            <Stack
              gap="xs"
              onClick={() => navigate(`/collections/${collection.id}`)}
            >
              <Group wrap="nowrap" gap="sm" align="start">
                <Icon
                  name={collection.refId ? 'folder_special' : 'folder'}
                  style={{
                    color: '#1F5CB9',
                  }}
                  size={32}
                />
                <Stack gap="xs" align="start">
                  <Text c="#424242" lineClamp={1} fz={18} fw="bold">
                    {collection.name}
                  </Text>
                  <Group gap={4}>
                    <Icon name="update" color="gray" size="xs" />
                    <Text size="xs" c="dimmed">
                      {collection.lastActivity
                        ? `${dayjs(collection.lastActivity).format('MM/DD/YY hh:mm A')}`
                        : `${dayjs(collection.createdOn).format('MM/DD/YY hh:mm A')}`}
                    </Text>
                  </Group>
                </Stack>
                <div style={{ marginLeft: 'auto' }}>
                  <CollectionMenu
                    collection={collection}
                    orientation="vertical"
                  />
                </div>
              </Group>
              <Group>
                {collection.status === 'NEW' && (
                  <Pill key="NEW" size="xs" bg="#F1C21B" fw="bold">
                    NEW
                  </Pill>
                )}
              </Group>
            </Stack>
          </Stack>
        </Card>
      ))}
    </SimpleGrid>
  )
}
