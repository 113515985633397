import { useMapStore } from './store'

export const useSetActiveLayers = () => useMapStore((s) => s.setActiveLayers)
export const useSetBaseLayer = () => useMapStore((s) => s.setBaseLayer)
export const useSetCurrentMapBounds = () =>
  useMapStore((s) => s.setCurrentMapBounds)
export const useToggleSelectedHexGridId = () =>
  useMapStore((s) => s.toggleSelectedHexGridId)
export const useSetHoveredHexGridId = () =>
  useMapStore((s) => s.setHoveredHexGridId)
export const useSetSelectedPointId = () =>
  useMapStore((s) => s.setSelectedPointId)
export const useSetSelectedExtentIds = () =>
  useMapStore((s) => s.setSelectedExtentIds)
export const useSetHoveredExtentIds = () =>
  useMapStore((s) => s.setHoveredExtentIds)
export const useSetGridCellProperties = () =>
  useMapStore((s) => s.setGridCellProperties)
export const useSetActiveOrthoLayerGroupIds = () =>
  useMapStore((s) => s.setActiveOrthoLayerGroupIds)
export const useToggleOrthoLayerGroupId = () =>
  useMapStore((s) => s.toggleOrthoLayerGroupId)
export const useClearActiveLayers = () =>
  useMapStore((s) => s.clearActiveLayers)
export const useClearOrthoLayerGroupIds = () =>
  useMapStore((s) => s.clearOrthoLayerGroupIds)
export const useSetHoveredLayerId = () =>
  useMapStore((s) => s.setHoveredLayerId)
export const useSetMapBbox = () => useMapStore((s) => s.setMapBbox)
export const useSetPointViewPort = () => useMapStore((s) => s.setPointViewPort)
export const useZoomToOrthoLayer = () => useMapStore((s) => s.zoomToOrthoLayer)

export const useActiveLayers = () => useMapStore((s) => s.activeLayers)
export const useActiveOrthoLayerGroupIds = () =>
  useMapStore((s) => s.activeOrthoLayerGroupIds)
export const useBaseLayer = () => useMapStore((s) => s.baseLayer)
export const useCurrentMapBounds = () => useMapStore((s) => s.currentMapBounds)
export const useGridCellProperties = () =>
  useMapStore((s) => s.gridCellProperties)
export const useHoveredExtentIds = () => useMapStore((s) => s.hoveredExtentIds)
export const useHoveredHexGridId = () => useMapStore((s) => s.hoveredHexGridId)
export const useHoveredLayerId = () => useMapStore((s) => s.hoveredLayerId)
export const useMapBbox = () => useMapStore((s) => s.mapBbox)
export const usePointViewPort = () => useMapStore((s) => s.pointViewPort)
export const useSelectedExtentIds = () =>
  useMapStore((s) => s.selectedExtentIds)
export const useSelectedHexGridId = () =>
  useMapStore((s) => s.selectedHexGridId)
export const useSelectedPointId = () => useMapStore((s) => s.selectedPointId)
export const useZoomOrthoLayerGroupId = () =>
  useMapStore((s) => s.zoomOrthoLayerGroupId)
