import type { PublicationResult } from '@/utils/types/result-types'
import { createSelector } from 'reselect';
import { useResultsStore } from '../store'
import { getNewsImages } from './get-news-images';
import { categorized } from './results'


export const publicationResults = createSelector([categorized], (c) => c.publicationResults)
export const newsWithImages = createSelector([publicationResults], (results) => (
  results.filter((result) => getNewsImages(result).length > 0)
))
export const newsWithoutImages = createSelector([publicationResults], (results) => (
  results.filter((result) => getNewsImages(result).length === 0)
))

export const usePublicationResults = () => useResultsStore(publicationResults)
export const useNewsWithImages = () => useResultsStore(newsWithImages)
export const useNewsWithoutImages = () => useResultsStore(newsWithoutImages)

export const getNewsDetailCardProps = (post: PublicationResult) => ({
  iconName: 'newspaper' as const,
  highlightIcon: true,
  iconColor: '#ECEFF1',
  datetime: post.authoredOn,
  title: post.title,
})
