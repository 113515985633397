import { Text } from '@mantine/core'
import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { MultiPolygon, Point, Polygon } from 'geojson'

import styles from './reference-cards.module.css'

interface GeometryCardProps {
  datetime: string
  geometry: Point | Polygon | MultiPolygon
  latitude?: number
  longitude?: number
  title: React.ReactNode
  color?: string
}

export function GeometryCard({
  geometry,
  color,
  title,
  latitude,
  longitude,
  datetime,
}: GeometryCardProps) {
  return (
    <div className={styles['sov-geometry-category-card']}>
      <GeometryStaticDisplay
        geometry={geometry}
        className={styles['listing-geometry']}
        color={color}
      />
      <div className={styles['listing-datetime-row']}>
        <Text className={styles['date']}>{formatDate(datetime)}</Text>
        <Text className={styles['time']}>{formatTime(datetime)}</Text>
      </div>
      <Text>{title}</Text>
      <div>
        <Text className={styles['latitude']} mr="sm">
          {latitude}
        </Text>
        <Text className={styles['longitude']}>{longitude}</Text>
      </div>
    </div>
  )
}
