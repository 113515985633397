import React from 'react'
import { type CardProps, Group, Stack, Table, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view.ts'
import {
  useSelectedPointId,
  useSetPointViewPort,
  useSetSelectedPointId,
} from '@/stores/map-store'
import {
  isVesselResult,
  type ShipResult as ShipResultType,
} from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface ShipResultProps extends CardProps {
  result: ShipResultType
  isFullscreen?: boolean
}

interface FormattedShipData {
  imo: number | null
  mmsi: number
  name: string | null
  latitude: number
  longitude: number
  timestamp: string
  navigationalStatus: string
  destination: string
}

function formatShipData(result: ShipResultType): FormattedShipData {
  if (isVesselResult(result)) {
    const { imo, mmsi, name } = result.properties.staticData
    const { latitude, longitude, timestamp, navigationalStatus } =
      result.properties.lastPositionUpdate
    const { destination = '--' } = result.properties.currentVoyage ?? {}
    return {
      imo,
      mmsi,
      name,
      latitude,
      longitude,
      timestamp,
      navigationalStatus,
      destination,
    }
  } else {
    const { timestamp, state, location, vessel } = result.properties
    const { imo, mmsi, name } = vessel.staticData
    const { latitude, longitude } = location.centerPoint

    return {
      imo,
      mmsi,
      name,
      latitude,
      longitude,
      timestamp,
      navigationalStatus: state,
      destination: location.name,
    }
  }
}

export function ShipResult({
  result,
  isFullscreen,
  ...props
}: ShipResultProps) {
  const {
    imo,
    mmsi,
    name,
    latitude,
    longitude,
    timestamp,
    navigationalStatus,
    destination,
  } = formatShipData(result)
  const setPointViewPort = useSetPointViewPort()
  const selectedPointId = useSelectedPointId()
  const setSelectedPointId = useSetSelectedPointId()
  const { targetRef } = useSubscribeScrollIntoView(result.id, selectedPointId)

  React.useEffect(() => {
    isFullscreen && setSelectedPointId(null)
  }, [isFullscreen, setSelectedPointId])

  return (
    <ResultBase
      result={result}
      onClick={() => {
        setPointViewPort((result.geometry.coordinates as number[]) ?? [0, 0])
      }}
      withBorder
      radius="sm"
      style={{
        borderColor:
          selectedPointId === result.documentId
            ? '#0091EA'
            : 'var(--mantine-color-gray-2)',
      }}
      ref={targetRef}
      data-cy="shipResult"
      {...props}
    >
      <Stack gap="xs" p="sm">
        <Group gap="xs">
          <Icon name="anchor" weight={500} />{' '}
          <Text fz="xs" fw="bold" c="black">
            {name ?? 'Unknown Vessel'}
          </Text>
        </Group>
        <Table
          fz="xs"
          withRowBorders={false}
          className={styles['result-table']}
        >
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>IMO</Table.Td>
              <Table.Td>{imo ?? '--'}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>MMSI</Table.Td>
              <Table.Td>{mmsi ?? '--'}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Latitude</Table.Td>
              <Table.Td>{latitude ?? '--'}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Longitude</Table.Td>
              <Table.Td>{longitude ?? '--'}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Navigational Status</Table.Td>
              <Table.Td>{navigationalStatus ?? 'Not defined'}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Timestamp</Table.Td>
              <Table.Td>{dayjs(timestamp).format('M/D/YYYY, HH:mm')}Z</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Destination</Table.Td>
              <Table.Td>{destination ?? 'Unknown'}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Stack>
    </ResultBase>
  )
}
