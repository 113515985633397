import * as React from 'react'
import { createStaticMapUrl } from '@/api/mapbox'
import type { MultiPolygon, Point, Polygon } from 'geojson'

export const useGeometryStaticDisplay = (
  geometry?: Point | Polygon | MultiPolygon,
  color?: string | undefined,
) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [staticMapUrl, setStaticMapUrl] = React.useState<string | null>(null)

  React.useLayoutEffect(() => {
    if (ref.current && geometry) {
      const { width, height } = ref.current.getBoundingClientRect()
      setStaticMapUrl(
        createStaticMapUrl({
          geometry,
          size: [width, height],
          color,
        }),
      )
    }
  }, [ref, geometry, color])

  return { ref, staticMapUrl }
}
export default useGeometryStaticDisplay
