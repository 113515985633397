import { useLocation } from 'react-router-dom'
import { Group, Paper } from '@mantine/core'
import { HeaderAuthenticated } from './HeaderAuthenticated'
import { HeaderUnauthenticated } from './HeaderUnauthenticated'

import styles from './header.module.css'

export interface HeaderProps {
  opened: boolean
  toggle: () => void
}

export function Header({ opened, toggle }: HeaderProps) {
  const { pathname } = useLocation()
  const isLogIn = pathname === '/log-in'

  return (
    <Paper
      pos="fixed"
      bg="#000b"
      fz={14}
      top={0}
      left={0}
      right={0}
      shadow="xs"
      radius={0}
      style={{ zIndex: 3 }}
    >
      <header className={styles['header']}>
        <Group justify="space-between" py="0.25rem" w="100%">
          {isLogIn ? (
            <HeaderUnauthenticated />
          ) : (
            <HeaderAuthenticated opened={opened} toggle={toggle} />
          )}
        </Group>
      </header>
    </Paper>
  )
}
