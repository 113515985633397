import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import MapSearchPanel from '@/components/lib/map-search/MapSearchPanel.tsx'
import { useResultsById } from '@/stores/results-store'
import type { ResultWithId } from '@/utils/types/raw-result-types.ts'

export function MapSearch() {
  const { itemId } = useParams()
  const resultsById = useResultsById()
  const [item, setItem] = React.useState<ResultWithId | undefined>()

  React.useEffect(() => {
    itemId ? setItem(resultsById[itemId]) : setItem(undefined)
  }, [itemId, resultsById])

  return (
    <Fragment>
      <MapSearchPanel item={item} />
    </Fragment>
  )
}

export default MapSearch
