import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { getSocialImages } from '@/stores/results-store'
import { RESULT_CATEGORIES, SOCIAL_COLORS } from '@/utils/constants'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { SocialMediaResult } from '@/utils/types/result-types.ts'
import { SocialIcon } from 'react-social-icons'

const category = RESULT_CATEGORIES.SOCIAL_MEDIA
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface SocialBentoCardProps extends CardProps {
  result: SocialMediaResult
  size: string
  totalResults: number
}

export const SocialBentoCard = ({ result, ...props }: SocialBentoCardProps) => {
  const { datetime } = result.properties
  const datetimeDay = formatDate(datetime)
  const datetimeTime = formatTime(datetime)
  const { source } = result.properties
  const images = getSocialImages(result) || []
  return (
    <BentoCard
      result={result}
      content={{
        heading: source,
        subHeading: (
          <>
            {datetimeDay}
            <br />
            {datetimeTime}
          </>
        ),
        color: SOCIAL_COLORS[source] || 'blue',
      }}
      hideActions={actionsToHide}
      resultCategory={category}
      socialIcon={<SocialIcon network={source.toLowerCase()} />}
      image={
        images.length > 0
          ? {
              source: images[0].url,
              title: source,
              thumbnail: images[0].url,
            }
          : undefined
      }
      {...props}
    />
  )
}
export default SocialBentoCard
