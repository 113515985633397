import React from 'react'
import { Button, Divider, Group, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { TerrapinLocation } from '@/hooks/use-terrapin'
import { validateLocation } from './TerrapinLocation'
import { TerrapinLocationRow } from './TerrapinLocationRow'

export interface AreasOfInterestPanelProps {
  locations: TerrapinLocation[]
  onCancel: () => void
  onSave: (records: TerrapinLocation[]) => void
  onOpenMap: () => void
  setCurrentLocation: (location: TerrapinLocation) => void
}

export function TerrapinLocationPanel({
  onCancel,
  onSave,
  onOpenMap,
  setCurrentLocation,
  ...props
}: AreasOfInterestPanelProps) {
  const [locations, setLocations] = React.useState(props.locations)

  const disableSave = React.useMemo(() => {
    const invalidLocations = locations.map(validateLocation).filter(Boolean)
    return invalidLocations.length > 0
  }, [locations])

  const handleRemove = (index: number) => {
    const updatedLocations = [...locations]
    updatedLocations.splice(index, 1)

    setLocations(updatedLocations)
  }

  const handleLocationChange = (
    index: number,
    newLocation: TerrapinLocation,
  ) => {
    setLocations((previous) =>
      previous.map((location, locationIndex) =>
        index === locationIndex ? newLocation : location,
      ),
    )
  }

  return (
    <Stack px={12}>
      <Divider />
      <Text size="sm">
        Verify that the locations from your file are correct.
      </Text>
      <Stack>
        {locations.map((location, index) => (
          <TerrapinLocationRow
            key={index}
            location={location}
            index={index}
            onChange={handleLocationChange}
            onRemove={handleRemove}
            onOpenMap={onOpenMap}
            setCurrentLocation={setCurrentLocation}
          />
        ))}
      </Stack>
      <Group justify="space-between" mt="md">
        <Button
          size="xs"
          leftSection={<Icon name="navigate_before" />}
          variant="outline"
          onClick={() => onCancel()}
        >
          CANCEL AND GO BACK
        </Button>
        <Button
          size="xs"
          variant="filled"
          disabled={disableSave}
          onClick={() => void onSave(locations)}
        >
          SAVE CHANGES
        </Button>
      </Group>
    </Stack>
  )
}
