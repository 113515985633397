import { ThumbnailFallbackCard } from '@/features/ResultCards/ReferenceCards/components'
import { getSocialImages } from '@/stores/results-store'
import { SOCIAL_COLORS } from '@/utils/constants'
import type { SocialMediaResult } from '@/utils/types/result-types'
import { SocialIcon } from 'react-social-icons'

export const SocialMediaReferenceCard = ({
  post,
}: {
  post: SocialMediaResult
}) => {
  const source = post.properties.source.toLowerCase()
  const postColor =
    SOCIAL_COLORS[post.properties.source.toLowerCase()] || 'blue'

  const images = getSocialImages(post)
  const image =
    images.length > 0
      ? {
          thumbnail: images[0].url,
          title: source,
          source: images[0].url,
        }
      : null
  return (
    <ThumbnailFallbackCard
      image={image}
      title={post.title}
      icon={<SocialIcon network={source} />}
      iconColor={postColor}
      datetime={post.authoredOn}
    />
  )
}
