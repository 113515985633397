import { useMemo } from 'react'
import { makeBlackSkyOrthoLayer } from '@/hooks/ortho-imagery/use-blacksky.ts'
import { isGegd, makeGegdOrthoLayer } from '@/hooks/ortho-imagery/use-gegd.ts'
import {
  isPlanet,
  makePlanetOrthoLayer,
} from '@/hooks/ortho-imagery/use-planet.ts'
import { makeSentinelOrthoLayer } from '@/hooks/ortho-imagery/use-sentinel.ts'
import { makeUP42OrthoLayer } from '@/hooks/ortho-imagery/use-up42.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { useSortPreference } from '@/stores/queries-store'
import type {
  OrthoImageryLayer,
  OrthoImageryLayerGroup,
} from '@/utils/types/ortho-imagery-types.ts'
import {
  isBlackSkyResult,
  isSentinelResult,
  isUp42Result,
} from '@/utils/types/result-type-checkers.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import * as Sentry from '@sentry/react'
import * as turf from '@turf/turf'
import type { MultiPolygon } from 'geojson'
import { groupBy, sortBy } from 'lodash'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'

export const makeOrthoLayer = (
  ir: ImageryResult,
): OrthoImageryLayer | undefined => {
  if (!ir.thumbnail && Object.keys(ir.asset).length === 0) {
    return
  }
  if (isGegd(ir)) {
    return makeGegdOrthoLayer(ir)
  }
  if (isPlanet(ir)) {
    return makePlanetOrthoLayer(ir)
  }
  if (isUp42Result(ir)) {
    return makeUP42OrthoLayer(ir)
  }
  if (isBlackSkyResult(ir)) {
    return makeBlackSkyOrthoLayer(ir)
  }
  if (isSentinelResult(ir)) {
    return makeSentinelOrthoLayer(ir)
  }

  Sentry.captureMessage(
    `Could not find ortho layer provider for Image ID ${ir.documentId}`,
  )
  return undefined
}

export const useOrthoImagerySources = () => {
  const imageResults = usePostfilterImageResults()
  const sortPreference = useSortPreference()

  const orthoImages: OrthoImageryLayer[] = imageResults
    .map((oi) => makeOrthoLayer(oi))
    .filter(Boolean)

  const orthoLayers: OrthoImageryLayer[] = useDeepCompareMemoize([
    ...orthoImages,
  ])
  // Creates Mosiac of Layers
  const orthoLayerGroups: OrthoImageryLayerGroup[] = useMemo(() => {
    const groups = groupBy(orthoLayers, (ol) => ol.groupKey)

    const sortedResults = sortBy(
      Object.keys(groups).map((groupKey) => {
        const layers = groups[groupKey]
        const skySatCollectLayer = layers.find((l) =>
          l.tileUrl.includes('SkySatCollect'),
        )
        if (skySatCollectLayer) {
          return {
            source: skySatCollectLayer.source,
            formattedSource: skySatCollectLayer.formattedSource,
            layers: [skySatCollectLayer],
            id: groupKey,
            documentId: skySatCollectLayer.documentId,
            authoredOn: skySatCollectLayer.authoredOn,
            score: skySatCollectLayer.score,
            geometry: skySatCollectLayer.geometry,
          }
        }
        return {
          source: layers[0].source,
          formattedSource: layers[0].formattedSource,
          layers,
          id: groupKey,
          documentId: layers[0].documentId,
          authoredOn: layers[0].authoredOn,
          score: layers[0].score,
          geometry: layers
            .map((l) => l.geometry)
            .reduce((result, nextGeometry) => {
              try {
                return turf.union(
                  turf.featureCollection([
                    turf.feature(nextGeometry),
                    turf.feature(result),
                  ]),
                )?.geometry as MultiPolygon
              } catch (error) {
                Sentry.captureException(error, { extra: { layers, groupKey } })
                console.error('Failed to union geometries', error)
                return result
              }
            }, turf.multiPolygon([]).geometry),
        }
      }),
      (olg) => olg[sortPreference],
    )
    return sortedResults.reverse()
  }, [orthoLayers, sortPreference])

  return {
    orthoLayerGroups,
    orthoLayers,
  }
}
