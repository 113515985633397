import * as React from 'react'
import { type CardProps, Group, Image, Stack, Table, Text } from '@mantine/core'
import { createStaticMapUrl } from '@/api/mapbox'
import { Icon } from '@/components/lib/Icon.tsx'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import type { RegridResult as RegridResultType } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type { Polygon } from 'geojson'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface RegridResultProps extends CardProps {
  result: RegridResultType
  isFullscreen?: boolean
}

interface FormattedRegridData {
  address: string
  latitude: number
  longitude: number
  lotSizeAcres?: number
  lotSizeSquareFeet?: number
  city?: string
  county?: string
  geoid?: string
  parcelNumber?: string
  legalDescription?: string
  updatedAt?: string
}

function formatRegridData(result: RegridResultType): FormattedRegridData {
  const { properties } = result
  const [latitude, longitude] = (result.geometry as Polygon).coordinates[0][0]
  return {
    address: properties.address,
    lotSizeAcres: properties.lotSizeAcres,
    lotSizeSquareFeet: properties.lotSizeSquareFeet,
    city: properties.city,
    county: properties.county,
    geoid: properties.geoid,
    parcelNumber: properties.parcelNumber,
    legalDescription: properties.legalDescription,
    latitude,
    longitude,
    updatedAt: dayjs(properties.updatedAt).utc().format('MM/DD/YYYY HH:mm'),
  }
}

export function RegridResult({
  result,
  isFullscreen,
  ...props
}: RegridResultProps) {
  const {
    address,
    latitude,
    longitude,
    city,
    county,
    geoid,
    parcelNumber,
    legalDescription,
    lotSizeAcres,
    lotSizeSquareFeet,
    updatedAt,
  } = formatRegridData(result)
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [staticMapUrl, setStaticMapUrl] = React.useState<string | null>(null)
  React.useLayoutEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect()
      setStaticMapUrl(
        createStaticMapUrl({
          geometry: result.geometry,
          size: [width, height],
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ResultBase
      result={result}
      onClick="viewDetails"
      withBorder
      radius="sm"
      style={{ borderColor: 'var(--mantine-color-gray-2)' }}
      data-cy="regridResult"
      {...props}
    >
      <Stack gap="xs" p="sm">
        <div
          ref={ref}
          style={{
            position: 'relative',
            overflow: 'hidden',
            aspectRatio: `${isFullscreen ? 2.4 : 1.95}`,
            backgroundColor: 'var(--mantine-color-gray-2)',
          }}
        >
          <Image
            alt={result.title}
            src={staticMapUrl}
            fit="cover"
            width="100%"
            height="100%"
            radius="sm"
          />
        </div>
        <Group gap="xs">
          <Icon name="house" title="Regrid Result" />
          <Text size="sm">Parcel Record</Text>
        </Group>
        <Group gap="xs">
          <Text size="md">
            <b>{address}</b>
          </Text>
          {updatedAt && (
            <Text size="xs" color="gray">
              {updatedAt}
            </Text>
          )}
        </Group>
        <Table
          fz="xs"
          withRowBorders={false}
          className={styles['result-table']}
        >
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>Latitude</Table.Td>
              <Table.Td>{latitude}</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>Longitude</Table.Td>
              <Table.Td>{longitude}</Table.Td>
            </Table.Tr>
            {lotSizeAcres && (
              <Table.Tr>
                <Table.Td>Lot Size (Acres)</Table.Td>
                <Table.Td>{lotSizeAcres}</Table.Td>
              </Table.Tr>
            )}
            {lotSizeSquareFeet && (
              <Table.Tr>
                <Table.Td>Lot Size (Square Feet)</Table.Td>
                <Table.Td>{lotSizeSquareFeet}</Table.Td>
              </Table.Tr>
            )}
            {city && (
              <Table.Tr>
                <Table.Td>City</Table.Td>
                <Table.Td>{city}</Table.Td>
              </Table.Tr>
            )}
            {county && (
              <Table.Tr>
                <Table.Td>County</Table.Td>
                <Table.Td>{county}</Table.Td>
              </Table.Tr>
            )}
            {geoid && (
              <Table.Tr>
                <Table.Td>Geoid</Table.Td>
                <Table.Td>{geoid}</Table.Td>
              </Table.Tr>
            )}
            {parcelNumber && (
              <Table.Tr>
                <Table.Td>Parcel Number</Table.Td>
                <Table.Td>{parcelNumber}</Table.Td>
              </Table.Tr>
            )}
            {legalDescription && (
              <Table.Tr>
                <Table.Td>Legal Description</Table.Td>
                <Table.Td>{legalDescription}</Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </Stack>
    </ResultBase>
  )
}
