import { type MutableRefObject, useEffect } from 'react'
import type { HexGrid } from '@/components/lib/map-search/hooks/util/make-bare-hex-grid.ts'
import { setHexGridFills } from '@/components/lib/map-search/hooks/util/set-hex-grid-fills.ts'
import { useBaseLayer } from '@/stores/map-store'
import type mapboxgl from 'mapbox-gl'

export const formatMapboxStyle = (baseLayer: string) =>
  `mapbox://styles/${baseLayer}`

export const useBaseLayerSelection = (
  map: MutableRefObject<mapboxgl.Map | null>,
  hexGrid: HexGrid,
) => {
  const baseLayer = useBaseLayer()
  useEffect(() => {
    map.current?.once('styledata', () => {
      setHexGridFills(map, hexGrid)
    })
    map.current?.setStyle(formatMapboxStyle(baseLayer))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseLayer, map])
}
