import { useIdFilterResults } from '@/hooks/results/result-filter-utilities.ts'
import { useHexgridFilteredResults } from '@/hooks/results/use-hexgrid-filtered-results.ts'
import { useTimelineSelectedIds } from '@/stores/results-filter-store'
import type { Results } from '@/stores/results-store'

export const useTimelineFilteredResults = (): Results => {
  const hexGridResults = useHexgridFilteredResults()
  const timelineSelectedIds = useTimelineSelectedIds()

  return useIdFilterResults(
    (r) => (timelineSelectedIds ? timelineSelectedIds.includes(r.id) : true),
    hexGridResults,
  )
}
