import React from 'react'
import { postSearchRequest } from '@/api/danti-requests'
import { useDantiWS } from '@/api/danti-ws'
import { useClearAll } from '@/hooks/use-clear-all'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useAddChatMessage } from '@/stores/chat-store'
import {
  type Filters,
  useGetFilters,
  useSetFilters,
} from '@/stores/filters-store'
import { useSetActiveLayers, useSetMapBbox } from '@/stores/map-store'
import {
  useCurrentQuery,
  useSetQuery,
  useSetQueryFilters,
  useSetQueryStarted,
  useSetSearchMetadata,
  useSetStatus,
  useSetTotalProcessedResults,
  useTotalProcessedResults,
} from '@/stores/queries-store'
import {
  useAddRelatedImageryResults,
  useAddResults,
  useSetCurrentQueryId,
} from '@/stores/results-store'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { makeApiFilters } from '@/utils/search-filters/make-api-filters'
import { makeSemanticQueryFilters } from '@/utils/search-filters/make-semantic-filters'
import { CategoryTypes, type RawSearchResult } from '@/utils/types/result-types'
import { useQueryClient } from '@tanstack/react-query'
import * as turf from '@turf/turf'
import type { Position } from 'geojson'

const MAX_RESULTS_TO_PROCESS = 5000

export function useSearch() {
  const {
    lastResults,
    flushLastResults,
    lastStatusMessages,
    flushLastStatusMessages,
    lastSearchMetadata,
    flushLastSearchMetadata,
    lastChatMessages,
    flushLastChatMessages,
    closeSocket,
  } = useDantiWS()
  const queryClient = useQueryClient()
  const { isAuthenticated } = useDantiAuth()

  const setQuery = useSetQuery()
  const setQueryFilters = useSetQueryFilters()
  const setStatus = useSetStatus()
  const setSearchMetadata = useSetSearchMetadata()
  const totalProcessedResults = useTotalProcessedResults()
  const setTotalProcessedResults = useSetTotalProcessedResults()

  const setMapBbox = useSetMapBbox()
  const setActiveLayers = useSetActiveLayers()
  const addChatMessage = useAddChatMessage()
  const addResults = useAddResults()
  const addRelatedImageryResults = useAddRelatedImageryResults()
  const setCurrentQueryId = useSetCurrentQueryId()
  const setFilters = useSetFilters()
  const filters = useGetFilters()
  const currentQuery = useCurrentQuery()
  const setQueryStarted = useSetQueryStarted()

  const clearAll = useClearAll()

  React.useEffect(() => {
    if (totalProcessedResults > MAX_RESULTS_TO_PROCESS) {
      console.warn('Reached maximum result processing limit.')
      closeSocket()
      setCurrentQueryId(null)
    }
    if (lastResults.length > 0) {
      const separatedResults = lastResults.reduce(
        (accumulator, result) => {
          if (result.category === CategoryTypes.RELATED_IMAGERY) {
            accumulator.related.push(result)
          } else {
            accumulator.results.push(result)
          }
          return accumulator
        },
        {
          related: [] as RawSearchResult[],
          results: [] as RawSearchResult[],
        },
      )
      addResults(separatedResults.results)
      addRelatedImageryResults(separatedResults.related)

      flushLastResults()
      setTotalProcessedResults(totalProcessedResults + lastResults.length)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastResults])

  React.useEffect(() => {
    if (lastChatMessages.length > 0) {
      lastChatMessages.forEach(addChatMessage)
      flushLastChatMessages()
    }
  }, [addChatMessage, flushLastChatMessages, lastChatMessages])

  React.useEffect(() => {
    if (lastSearchMetadata) {
      setSearchMetadata(lastSearchMetadata)
      flushLastSearchMetadata()
    }
  }, [lastSearchMetadata, flushLastSearchMetadata, setSearchMetadata])

  React.useEffect(() => {
    if (lastStatusMessages.length > 0) {
      lastStatusMessages.forEach((lastStatusMessage) =>
        setStatus(lastStatusMessage.source, lastStatusMessage.state),
      )
      flushLastStatusMessages()
    }
  }, [flushLastStatusMessages, lastStatusMessages, setStatus])

  const doSearch = React.useCallback(
    async (params: { query: string; filters: Partial<Filters> }) => {
      if (!isAuthenticated) {
        throw new Error('Attempted to search before login')
      }

      clearAll(params.query === 'polygon')
      setTotalProcessedResults(0)

      setFilters(params.filters)
      const apiFilters = makeApiFilters(params.filters)

      setQuery(params.query)
      setQueryFilters(apiFilters)
      setQueryStarted()

      console.log(
        `Running search for ${params.query} with filters ${JSON.stringify(
          apiFilters,
        )}`,
      )

      // Now using ipa.<workload>.danti.ai/search as the endpoint instead of the
      // previous api.<workload>.danti.ai/neural/search/proxy
      const response = await postSearchRequest<{ queryId: string }>('/search', {
        query: params.query,
        filters: apiFilters,
      })

      setCurrentQueryId(response.data.queryId)
      if (
        params.filters.locationType === 'polygon' &&
        params.filters.location
      ) {
        setMapBbox(
          turf.bbox(
            turf.polygon([JSON.parse(params.filters.location) as Position[]]),
          ),
        )
      }
      setActiveLayers('Point')
      // Slight delay on backend when this shows up in opensearch
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey: ['history'] })
        void queryClient.invalidateQueries({ queryKey: ['history', 0, ''] })
      }, 1000)

      return response.data.queryId
    },
    // TODO: investigate why setActiveLayers is not included
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isAuthenticated,
      queryClient,
      clearAll,
      setTotalProcessedResults,
      setQuery,
      setQueryFilters,
      setQueryStarted,
      setCurrentQueryId,
      setMapBbox,
    ],
  )

  const doTextSearch = React.useCallback(
    async (searchString: string) => {
      const semanticFilters = makeSemanticQueryFilters(searchString)

      console.log(`Have incoming filter values`, {
        semanticFilters,
      })

      return doSearch({ query: searchString, filters: semanticFilters })
    },
    [doSearch],
  )

  const doLocationSearch = React.useCallback(
    async (location: string, locationType: string) => {
      const locationFilter: Partial<Filters> = { location, locationType }
      return doSearch({ query: '', filters: locationFilter })
    },
    [doSearch],
  )

  const doSplitSearch = React.useCallback(
    async (
      location: string,
      formatted: string,
      query: string,
      geocodeValues: {
        formattedAddress: string
        placeId: string
        country: string
        adminAreaLevel1: string
        adminAreaLevel2: string
      },
    ) => {
      const combinedQuery = `${formatted}${query ? ` - ${query}` : ''}`
      const { locationType, keywords } = makeSemanticQueryFilters(combinedQuery)
      const filters = {
        location,
        locationType,
        keywords,
        ...geocodeValues,
      }

      return doSearch({ query: combinedQuery, filters })
    },
    [doSearch],
  )

  const doLastSearchWithFilters = React.useCallback(async () => {
    const filterPayload = makeApiFilters(filters)
    captureEvent(POSTHOG_EVENTS.EXPLORE.ADVANCED_SEARCH, {
      filters: JSON.stringify(filterPayload),
    })
    return doSearch({ query: currentQuery, filters })
  }, [currentQuery, doSearch, filters])

  return {
    doTextSearch,
    doLocationSearch,
    doSplitSearch,
    doLastSearchWithFilters,
  }
}
