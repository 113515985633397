import { getRequest } from '@/api/danti-requests'
import {
  MAXAR_API_SOURCE_MAP,
  MAXAR_MGP_PATH,
  NEARSPACE_CATALOG_ID_MATCH,
  PROXY_REQUIRED,
  SOURCE_MAP,
} from '@/api/thumbnail/constants'
import type { AnalyticResponse } from '@/api/thumbnail/types'
import { apiUrl, appEnv } from '@/utils/constants'

export async function getProxiedUrl(params: {
  thumbnail: string | URL
  source: string
}): Promise<string | undefined> {
  // check if this item should be proxied
  const url = new URL(params.thumbnail)
  if (!PROXY_REQUIRED.has(url.hostname) && params.source != 'Danti Analytic') {
    return url.toString()
  }

  const source = params.source.startsWith('NUDL ') ? 'NUDL' : params.source
  // pull the correct proxy path based on the provider
  let provider = SOURCE_MAP.get(source)
  if (!provider) {
    console.error(`couldn't find provider for ${source}`)
    return undefined
  }

  if (provider == 'maxar') {
    const apiVersion = MAXAR_API_SOURCE_MAP.get(url.hostname)
    if (!apiVersion) {
      return undefined
    }

    // FIXME: for now attempt translate the Maxar MGP API URL to a eAPI URL using the item ID
    //  the provier proxy does not currently support api.content.maxar.com
    provider += '/e1so' // FIXME: replace with: provider += `/${apiVersion}`
    if (apiVersion === 'api') {
      const results = MAXAR_MGP_PATH.exec(url.pathname)
      if (results && results[1]) {
        url.pathname = `/e1so/collections/wv04/items/${results[1]}/assets/browse.tif`
      }
    }
  } else if (provider == 'analytic') {
    const signingUrl = new URL(apiUrl)
    signingUrl.pathname = `/provider/${provider}${url.pathname}`
    signingUrl.search = url.search

    const response = await getRequest<AnalyticResponse>(signingUrl.toString())

    return response.data.url
  } else if (provider == 'vexcel') {
    const thumbnailUrl = new URL(apiUrl)

    // The ortho proxy works slightly differently than the others, since the args are mostly in the path
    // rather than the search string
    thumbnailUrl.pathname = `/provider/vexcel${url.pathname}`
    thumbnailUrl.search = url.search
    return thumbnailUrl.toString()
  } else if (provider == 'nearspace') {
    const results = NEARSPACE_CATALOG_ID_MATCH.exec(url.pathname)
    if (results && results[1]) {
      url.pathname = `${results[1]}`
    }
  } else if (provider === 'sentinelhub') {
    const thumbnailUrl = url
    thumbnailUrl.pathname = url.pathname
    thumbnailUrl.search = url.search
    thumbnailUrl.hostname = `api.${appEnv}.danti.ai`
    return thumbnailUrl.toString()
  }

  // rewrite the thumbnail url to the proxy provider hostname and path prefix
  const thumbnailUrl = new URL(apiUrl)
  thumbnailUrl.pathname = `/provider/${provider}${url.pathname}`
  thumbnailUrl.search = url.search

  return thumbnailUrl.toString()
}
