import { Text } from '@mantine/core'
import { ResultImage, type ResultImageObject } from '@/features/ResultImage'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { ResultWithId } from '@/utils/types/result-types'

import styles from './reference-cards.module.css'

export interface ImageCardProps {
  datetime: string
  item: ResultWithId
}
export function ImageCard({ item, datetime }: ImageCardProps) {
  return (
    <div key={item.id} className={styles['image-category-card']}>
      <ResultImage
        item={item as ResultImageObject}
        className={styles['related-image']}
        style={{ cursor: 'pointer' }}
      />
      <div className={styles['listing-datetime-row']}>
        <Text className={styles['date']}>{formatDate(datetime)}</Text>
        <Text className={styles['time']}>{formatTime(datetime)}</Text>
      </div>
    </div>
  )
}
