/**
 * Set of hostnames that require authentication using the provider proxy.
 */
export const PROXY_REQUIRED = new Set<string>([
  'tiles.planet.com',
  'api.gic.org',
  'eapi.maxar.com',
  'e1so.eapi.maxar.com',
  'api.content.maxar.com',
  'nudl-tst.xc.nga.mil',
  'api.nearspacelabs.net',
  'api.dev.danti.ai',
  'api.prod.danti.ai',
])

/**
 * Lookup map from result provider names to provider proxy path prefixes.
 */
export const SOURCE_MAP = new Map<string, string>([
  ['Planet Labs', 'planet'],
  ['VEXCEL', 'vexcel'],
  ['MAXAR', 'maxar'],
  ['NUDL', 'nudl'],
  ['Danti Analytic', 'analytic'],
  ['NEARSPACE', 'nearspace'],
  ['G-EGD', 'gegd'],
  ['SENTINEL_HUB', 'sentinelhub'],
])

/**
 * Lookup map from hostname to provider proxy path prefix based on Maxar API.
 */
export const MAXAR_API_SOURCE_MAP = new Map<string, string>([
  ['eapi.maxar.com', 'e1so'],
  ['e1so.eapi.maxar.com', 'e1so'],
  ['api.content.maxar.com', 'api'],
])

/**
 * Regular expression to extract the item ID from the Maxar Geospatial Platform
 * catalog api. The hostname for this API is `api.content.maxar.com`.
 */
export const MAXAR_MGP_PATH = new RegExp(
  /\/catalog\/collections\/.+\/items\/([\da-z-]+)\//,
  'i',
)

/**
 * Regular expression to extract the item ID from the Nearspace provider api.
 * The hostname for this API is `api.nearspacelabs.net`
 */
export const NEARSPACE_CATALOG_ID_MATCH = new RegExp(
  /\/tile\/basemap\/([\dT_/]+)/,
  'i',
)

/**
 * Regular expression to extract the mime-type from a Content-Type header.
 */
export const MIME = new RegExp(/^\s*([^\s;]*)/)

export const PNG_PROVIDERS = new Set<string>([
  'provider/nudl',
  'provider/maxar',
  'provider/gegd',
  'provider/sentinelhub',
])
