import type {
  AttomPermit as Permit,
  AttomResult as AttomResultType,
} from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export function useSortedPermits(result: AttomResultType) {
  const permits = result.properties.buildingPermits || []
  return permits.sort((a: Permit, b: Permit) => {
    return dayjs(b.effectiveDate).unix() - dayjs(a.effectiveDate).unix()
  })
}

export default useSortedPermits
