import {
  FirePanel,
  ImagePanel,
  NewsPanel,
  PropertyPanel,
  ShipPanel,
  SocialPanel,
} from '@/components/lib/results/Panel'
import { AcceptShare } from '@/pages/AcceptShare'
import { AccessDenied } from '@/pages/AccessDenied'
import { Help } from '@/pages/Account/Help'
import { CollectionDetail } from '@/pages/CollectionDetail'
import { Collections } from '@/pages/Collections'
import { Error } from '@/pages/Error'
import { Explore } from '@/pages/Explore'
import { HistoryDetail } from '@/pages/HistoryDetail'
import { ItemDetail } from '@/pages/ItemDetail'
import { MapSearch } from '@/pages/MapSearch'
import { NotFound } from '@/pages/NotFound'
import { PlanSelection } from '@/pages/PlanSelection'
import { Redirect } from '@/pages/Redirect'
import { ResultsOverview } from '@/pages/ResultsOverview'
import { Timeline } from '@/pages/Timeline'
import { type FeatureFlagKeys, REDIRECT_PATH, ROUTES } from '@/utils/constants'

export const REFRESH_USER_QUERY_PARAM = 'refreshUser'

export const FEATURE_LOCKS = {
  MAP_SEARCH: 'MAP_SEARCH',
  RESULTS_OVERVIEW: 'RESULTS_OVERVIEW',
} as const
export type FeatureLock = keyof typeof FEATURE_LOCKS

export const routeComponents = {
  [ROUTES.login]: Explore,
  [REDIRECT_PATH]: Redirect,
  [ROUTES.accessDenied]: AccessDenied,
  [ROUTES.error]: Error,
  [ROUTES.collectionDetail]: CollectionDetail,
  [ROUTES.collections]: Collections,
  [ROUTES.resultsItem]: ItemDetail,
  [ROUTES.overview]: ResultsOverview,
  [ROUTES.images]: ImagePanel,
  [ROUTES.news]: NewsPanel,
  [ROUTES.fires]: FirePanel,
  [ROUTES.ships]: ShipPanel,
  [ROUTES.social]: SocialPanel,
  [ROUTES.allProperty]: PropertyPanel,
  [ROUTES.history]: HistoryDetail,
  [ROUTES.root]: Explore,
  [ROUTES.acceptShareToken]: AcceptShare,
  [ROUTES.collectionItemDetail]: ItemDetail,
  [ROUTES.terrapinItem]: ItemDetail,
  [ROUTES.map]: MapSearch,
  [ROUTES.mapItem]: MapSearch,
  [ROUTES.help]: Help,
  [ROUTES.planSelection]: PlanSelection,
  [ROUTES.timeline]: Timeline,
  [ROUTES.notFound]: NotFound,
}

export const routeFeatures = {
  [ROUTES.resultsItem]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.overview]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.images]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.news]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.fires]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.ships]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.social]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.allProperty]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.history]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.map]: FEATURE_LOCKS.MAP_SEARCH,
  [ROUTES.mapItem]: FEATURE_LOCKS.RESULTS_OVERVIEW,
  [ROUTES.timeline]: FEATURE_LOCKS.RESULTS_OVERVIEW,
}

export const fullscreenRoutes = new Set([
  ROUTES.images,
  ROUTES.news,
  ROUTES.fires,
  ROUTES.ships,
  ROUTES.social,
  ROUTES.allProperty,
])

export const searchableRoutes = new Set([
  ROUTES.resultsItem,
  ROUTES.overview,
  ROUTES.images,
  ROUTES.news,
  ROUTES.fires,
  ROUTES.ships,
  ROUTES.social,
  ROUTES.allProperty,
  ROUTES.history,
])

export const ROOT_ROUTES = [
  ROUTES.login,
  REDIRECT_PATH,
  ROUTES.accessDenied,
  ROUTES.error,
  ROUTES.help,
  ROUTES.notFound,
]

export const APP_ROUTES = [
  ROUTES.collectionDetail,
  ROUTES.collections,
  ROUTES.resultsItem,
  ROUTES.overview,
  ROUTES.images,
  ROUTES.news,
  ROUTES.fires,
  ROUTES.ships,
  ROUTES.social,
  ROUTES.allProperty,
  ROUTES.history,
  ROUTES.root,
  ROUTES.acceptShareToken,
  ROUTES.collectionItemDetail,
  ROUTES.terrapinItem,
  ROUTES.map,
  ROUTES.mapItem,
  ROUTES.timeline,
]

export const ROUTES_BY_PATH = Object.fromEntries(
  [...ROOT_ROUTES, ...APP_ROUTES, ROUTES.planSelection].map((path: string) => [
    path,
    {
      Component: routeComponents[path],
      feature: routeFeatures[path] as FeatureFlagKeys,
      isFullscreen: fullscreenRoutes.has(path),
      isSearchable: searchableRoutes.has(path),
    },
  ]),
)
