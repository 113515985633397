export const TimePreset = {
  Last24Hours: 'Last24Hours',
  Last7Days: 'Last7Days',
  Last30Days: 'Last30Days',
  Last90Days: 'Last90Days',
  Custom: 'Custom',
  None: 'None',
} as const
export type TimePreset = (typeof TimePreset)[keyof typeof TimePreset]

export const Sensor = {
  EO: 'Electro Optical',
  SAR: 'Radar (SAR)',
  RADAR: 'Radar',
  INFRAREDTHERMAL: 'Infrared Thermal',
  INFRARED: 'Infrared',
  LIDAR: 'LIDAR',
  HYPERSPECTRAL: 'Hyperspectral',
  MULTISPECTRAL: 'Multispectral',
  MICROWAVE: 'Microwave',
  ACOUSTIC: 'Acoustic',
} as const
export type Sensor = (typeof Sensor)[keyof typeof Sensor]

export const Provider = {
  GEGD: 'GEGD',
  ATTOM: 'Attom',
  HAZARD: 'Hazard Hub',
  CAPELLA: 'Capella',
  MAXAR: 'Maxar',
  PLANET: 'Planet Labs',
  UMBRA: 'Umbra',
  VEXCEL: 'Vexcel',
  NUDL: 'NUDL',
  NEARSPACE: 'Near Space',
  BLACKSKY: 'BlackSky',
  UP42: 'UP42',
} as const
export type Provider = (typeof Provider)[keyof typeof Provider]

export type DateValue = Date | null

export interface DateRangeApiFilterValue {
  type: 'TIMEFRAME'
  start: string
  end: string
}

export interface SensorApiFilterValue {
  type: 'SENSOR'
  sensors: string[]
}

export interface ProviderApiFilterValue {
  type: 'PROVIDER'
  ids: string[]
}

export interface QualityApiFilterValue {
  type: 'QUALITY'
  gsd: string
}

export interface CloudCoverApiFilterValue {
  type: 'CLOUDCOVER'
  coverage: number
}

export interface NadirApiFilterValue {
  type: 'NADIR'
  angle: string
}

export interface OrientationApiFilterValue {
  type: 'ORIENTATION'
  args: { start: number; end: number }[]
}

export interface LocationApiFilterValue {
  type: 'LOCATION'
  value: string
}

export interface LocationTypeApiFilterValue {
  type: 'LOCATIONTYPE'
  value: string
}

export interface StacIdApiFilterValue {
  type: 'STACID'
  stacId: string
}

export interface KeywordApiFilterValue {
  type: 'KEYWORD'
  keywords: string[]
}

export interface GeocodeApiFilterValue {
  type: 'GEOCODE'
  subtype: string
  value: string
}

export type SearchApiFilterValue =
  | DateRangeApiFilterValue
  | SensorApiFilterValue
  | ProviderApiFilterValue
  | QualityApiFilterValue
  | CloudCoverApiFilterValue
  | NadirApiFilterValue
  | OrientationApiFilterValue
  | LocationApiFilterValue
  | LocationTypeApiFilterValue
  | StacIdApiFilterValue
  | KeywordApiFilterValue
  | GeocodeApiFilterValue
