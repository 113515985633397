import type mapboxgl from 'mapbox-gl'
import type { MutableRefObject } from 'react'

export const addLayers = (
  map: MutableRefObject<mapboxgl.Map | null>,
  showExtents: boolean,
  showGrid: boolean,
) => {
  if (!map.current) {
    return
  }

  if (map.current?.getLayer('extents-fills')) {
    return
  }

  const layers = map.current?.getStyle().layers
  // Find the index of the first symbol layer in the map style.
  let firstSymbolId
  for (const layer of layers) {
    if (layer.type === 'symbol') {
      firstSymbolId = layer.id
      break
    }
  }

  if (map.current?.getSource('extents')) {
    map.current?.addLayer(
      {
        id: 'extents-fills',
        type: 'fill',
        source: 'extents',
        layout: {
          visibility: showExtents ? 'visible' : 'none',
        },
        paint: {
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0,
            0.1,
          ],
          'fill-outline-color': [
            'case',
            ['==', ['get', 'category'], 'PROPERTY'],
            '#0091EA',
            '#202020',
          ],
        },
      },
      firstSymbolId,
    )

    map.current?.addLayer(
      {
        id: 'extents-outlines',
        type: 'line',
        source: 'extents',
        layout: {
          visibility: showExtents ? 'visible' : 'none',
        },
        paint: {
          'line-color': [
            'case',
            ['==', ['get', 'category'], 'PROPERTY'],
            '#C2185B',
            '#202020',
          ],
          'line-opacity': 0.8,
        },
      },
      firstSymbolId,
    )
  }

  if (map.current?.getSource('hexgrid')) {
    map.current?.addLayer(
      {
        id: 'hexgrid-fills',
        type: 'fill',
        source: 'hexgrid',
        layout: { visibility: showGrid ? 'visible' : 'none' },
        paint: { 'fill-opacity': 0.5 },
      },
      firstSymbolId,
    )
    map.current?.addLayer(
      {
        id: 'hexgrid-outlines',
        type: 'line',
        source: 'hexgrid',
        layout: { visibility: showGrid ? 'visible' : 'none' },
        paint: {
          'line-color': '#202020',
          'line-opacity': 0,
          'line-width': 3,
        },
      },
      firstSymbolId,
    )
  }

  if (map.current?.getSource('regrid')) {
    map.current?.addLayer(
      {
        id: 'extent-point',
        type: 'symbol',
        source: 'regrid',
        minzoom: 6,
        layout: {
          visibility: showExtents ? 'visible' : 'none',
          'icon-image': 'Point Data Icon-Property',
          'icon-size': 0.3,
          'icon-allow-overlap': true,
        },
        paint: {
          'icon-color': '#C2185B',
        },
      },
      firstSymbolId,
    )
  }
}
