import {
  ActionIcon,
  Center,
  Group,
  Menu,
  Paper,
  Text,
  Tooltip,
} from '@mantine/core'
import { FilterToggleButton } from '@/components/filters/FilterToggleButton'
import { MemoFilterPanel } from '@/components/lib/FilterPanel'
import { openSaveSearchModal } from '@/components/modals/SaveSearchModal/opener'
import { useCurrentQuery } from '@/stores/queries-store'
import { Icon } from '../lib/Icon'

import styles from '@/components/layout/queryHeader.module.css'

export function QueryHeader(props: {
  fixed?: boolean
  headerStyle?: React.CSSProperties
}) {
  const currentQuery = useCurrentQuery()

  return (
    <Paper
      pos={props.fixed ? 'fixed' : 'relative'}
      w={props.fixed ? '100%' : 'calc(100% + (2 * var(--mantine-spacing-xl)))'}
      bg="var(--inherit-white-main, #FFF)"
      bottom="1px solid var(--divider, rgba(0, 0, 0, 0.12))"
      fz={14}
      shadow="xs"
      radius={0}
      mx="-xl"
      mt="-lg"
      style={{ zIndex: props.fixed ? 1 : 0 }}
      data-cy="query-header"
    >
      <style>{`body { --danti-head-height: 120px; }`}</style>
      <header
        className={styles['queryHeader']}
        style={{ ...props.headerStyle }}
      >
        <Group justify="space-between" py="0.25rem" w="100%">
          <Group h="100%" gap="5">
            <Center pt={18} pb={18}>
              <Tooltip label={currentQuery} position="bottom" withArrow>
                <Text
                  fw={700}
                  data-cy="query-header-query"
                  lineClamp={1}
                  maw="700px"
                  px={{ sm: 0, base: 50 }}
                >
                  Q: {currentQuery}
                </Text>
              </Tooltip>
            </Center>
          </Group>
          <Group>
            <FilterToggleButton data-cy="query-header-filter-toggle" />
            <Menu trigger="hover" shadow="md" position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="white"
                  color="00000000"
                  className={styles['clickable']}
                >
                  <Icon name="more_horiz" size="xl" />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  key="share"
                  leftSection={<Icon name="ios_share" />}
                  disabled={true}
                  p="xs"
                  className={styles['menu-hover']}
                >
                  Share
                </Menu.Item>
                <Menu.Item
                  key="feed"
                  leftSection={<Icon name="history" />}
                  onClick={() =>
                    openSaveSearchModal({
                      isNew: true,
                      params: {
                        query: currentQuery,
                        name: currentQuery,
                        emailNotification: '',
                        mode: 'feed',
                        locations: [],
                      },
                    })
                  }
                  p="xs"
                  className={styles['menu-hover']}
                >
                  Start a feed
                </Menu.Item>
                <Menu.Item
                  key="save"
                  leftSection={<Icon name="save" />}
                  onClick={() =>
                    openSaveSearchModal({
                      isNew: true,
                      params: {
                        query: currentQuery,
                        name: currentQuery,
                        emailNotification: '',
                        mode: 'search',
                        locations: [],
                      },
                    })
                  }
                  p="xs"
                  className={styles['menu-hover']}
                >
                  Save this search
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </header>
      <MemoFilterPanel data-cy="query-header-filter-panel" />
    </Paper>
  )
}
