import { Route } from 'react-router-dom'
import Account from '@/pages/Account/Account'
import { Password } from '@/pages/Account/Password'
import { Profile } from '@/pages/Account/Profile'
import { UserAdmin } from '@/pages/Account/UserAdmin/UserAdmin'
import { ROUTES } from '@/utils/constants'

export const AccountRoutes = (
  <>
    <Route
      path={ROUTES.account}
      data-testid="authenticated-routes"
      element={<Account />}
    >
      <Route
        path={ROUTES.password}
        data-testid="authenticated-routes"
        element={<Password />}
      />
      <Route
        path={ROUTES.users}
        data-testid="authenticated-routes"
        element={<UserAdmin />}
      />
      <Route
        path={ROUTES.account}
        data-testid="authenticated-routes"
        element={<Profile />}
      />
    </Route>
  </>
)
