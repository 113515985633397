import type {
  DateValue,
  SearchApiFilterValue,
  TimePreset,
} from '@/utils/types/filter-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export interface FilterValues {
  [key: string]:
    | string
    | number
    | DateValue
    | string[]
    | boolean
    | { start: number; end: number }[]
    | ((filter: Partial<FilterValues>) => void)
    | ((timePreset: TimePreset, start: DateValue, end: DateValue) => void)
    | ((sensor: string) => void)
    | ((providers: string[]) => void)
    | ((location: string, locationType: string) => void)
    | (() => void)
  timePreset: TimePreset
  customTimeStart: DateValue
  customTimeEnd: DateValue
  sensors: string[]
  providers: string[]
  quality: string
  cloudCover: number
  angle: string
  orientations: { start: number; end: number }[]
  location: string
  locationType: string
  keywords: string[]
  isFilterExpanded: boolean
  frequency: string
  formattedAddress: string
  placeId: string
  country: string
  adminAreaLevel1: string
  adminAreaLevel2: string
}

export interface Filters extends FilterValues {
  setFilters: (filters: Partial<FilterValues>) => void
  makeSearchFiltersFromCurrentPanelValues: () => SearchApiFilterValue[]
  setTimePreset: (
    timePreset: TimePreset,
    start: DateValue,
    end: DateValue,
  ) => void
  toggleSensors: (sensor: string) => void
  setProviders: (providers: string[]) => void
  setLocation: (location: string, locationType: string) => void
  resetFilters: () => void
  toggleFilterPanel: () => void
  setEmailUpdateFrequency: (frequency: string) => void
}
