import { useResultsStore } from '@/stores/results-store/store'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { HazardPropertyResult } from '@/utils/types/result-types'
import { createSelector } from 'reselect'
import { propertyResultsByCategory } from './property'

export const hazardPropertyResults = createSelector(
  [propertyResultsByCategory],
  (results) => {
    return results[RESULT_CATEGORIES.HAZARD_PROPERTY] as HazardPropertyResult[]
  },
)

export const hazardPropertyResultsWithImages = createSelector(
  [hazardPropertyResults],
  (results) => {
    if (!results) {
      return []
    }
    return results.filter((result) => {
      const { images } = result.properties
      return images && images.length > 0
    })
  },
)

export const getPropertyDataFields = (item: HazardPropertyResult) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const listPrice = item.properties.listPrice
    ? USDollar.format(Number.parseInt(item.properties.listPrice))
    : 'N/A'
  return [
    { title: 'bedrooms', value: item.properties.bedroomsTotal },
    { title: 'bathrooms', value: item.properties.bathsTotal },
    { title: 'type', value: item.properties.useCode },
    { title: 'hvac', value: item.properties.coolingFeatures },
    { title: 'list sale price', value: listPrice },
    { title: 'list date', value: item.properties.listDate },
    {},
  ]
}

export const useHazardPropertyResultsWithImages = () =>
  useResultsStore(hazardPropertyResultsWithImages)
