import React from 'react'
import { Chip, Group, Popover } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  useCustomTimeEndFilter,
  useCustomTimeStartFilter,
  useSetTimePreset,
  useTimePresetFilter,
} from '@/stores/filters-store'
import { type DateValue, TimePreset } from '@/utils/types/filter-types.ts'

import '@mantine/dates/styles.css'

const timeFrames = [
  { label: 'Past 24 hours', value: TimePreset.Last24Hours },
  { label: 'Past 7 days', value: TimePreset.Last7Days },
  { label: 'Past 30 days', value: TimePreset.Last30Days },
  { label: 'Past 90 days', value: TimePreset.Last90Days },
]

export function Timeframe() {
  const setTimePreset = useSetTimePreset()
  const timePreset = useTimePresetFilter()
  const customTimeStart = useCustomTimeStartFilter()
  const customTimeEnd = useCustomTimeEndFilter()

  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false)
  const popupRef = React.useRef<HTMLInputElement>(null)

  const timeStart = customTimeStart ? new Date(customTimeStart) : null
  const timeEnd = customTimeEnd ? new Date(customTimeEnd) : null
  const isCustomTimeSet = Boolean(
    timePreset === TimePreset.Custom && (timeStart || timeEnd),
  )

  function handleChange([start, end]: DateValue[]) {
    setTimePreset(TimePreset.Custom, start ?? null, end ?? null)
    if (start && end) {
      popupRef.current?.click()
    }
  }

  return (
    <Chip.Group>
      <Group gap="xs">
        {timeFrames.map(({ label, value }) => (
          <Chip
            key={value}
            variant={timePreset === value ? 'filled' : 'outline'}
            value={value}
            size="xs"
            onClick={(event) =>
              setTimePreset(event.currentTarget.value as TimePreset, null, null)
            }
          >
            {label}
          </Chip>
        ))}
        <Popover
          position="bottom"
          shadow="md"
          withArrow
          opened={isDatePickerOpen}
        >
          <Popover.Target>
            <Chip
              size="xs"
              variant={isCustomTimeSet ? 'filled' : 'outline'}
              checked={isCustomTimeSet}
              ref={popupRef}
              onClick={() => setIsDatePickerOpen((state) => !state)}
            >
              <Icon name="calendar_month" size={16} />
              <span style={{ marginLeft: '0.25rem' }}>
                {timeStart || timeEnd
                  ? `${timeStart?.toLocaleDateString() ?? ''}${
                      timeStart && timeEnd ? ` - ` : ''
                    }${timeEnd?.toLocaleDateString() ?? ''}`
                  : 'Custom'}
              </span>
            </Chip>
          </Popover.Target>
          <Popover.Dropdown>
            <DatePicker
              type="range"
              size="xs"
              allowSingleDateInRange
              aria-label="Custom date range"
              value={[timeStart, timeEnd]}
              onChange={handleChange}
              mx="auto"
              maw={400}
            />
          </Popover.Dropdown>
        </Popover>
      </Group>
    </Chip.Group>
  )
}
