import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Group, Menu, Text } from '@mantine/core'
import { type HistoryItem, useHistory } from '@/api/history'
import { HeaderMenuItem } from '@/components/layout/Header/HeaderMenuItem'
import { Icon } from '@/components/lib/Icon'
import { useSearch } from '@/features/Search/hooks'
import { ROUTES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'

import styles from './header.module.css'

export function HeaderLinkHistory() {
  const navigate = useNavigate()
  const { recentItems } = useHistory()
  const { doTextSearch } = useSearch()
  const location = useLocation()
  const [historyOpen, setHistoryOpen] = React.useState(false)

  React.useEffect(() => {
    setHistoryOpen(location.pathname === '/history')
  }, [location])

  function handleSetQuery(value: string) {
    if (!value) {
      return
    }
    void doTextSearch(value)

    const params = new URLSearchParams({ search: value })
    const pathname = window.location.pathname.toLowerCase()

    if (pathname.startsWith('/map')) {
      navigate(`/map?${params.toString()}`)
    } else {
      navigate(`/overview?${params.toString()}`)
    }
  }

  const handleItemClick = (item: HistoryItem) => {
    captureEvent(POSTHOG_EVENTS.HISTORY.RERUN_SEARCH)
    void handleSetQuery(item.query)
  }

  return (
    <Menu
      trigger="hover"
      transitionProps={{ duration: 100 }}
      onOpen={() => setHistoryOpen(true)}
      onClose={() => setHistoryOpen(location.pathname === '/history')}
      offset={18}
      position="bottom-start"
    >
      <Menu.Target>
        <Group
          gap={5}
          h="2.5rem"
          wrap="nowrap"
          p="sm"
          className={styles['link']}
          style={{
            cursor: 'pointer',
            borderBottom: historyOpen
              ? '6px solid var(--mantine-color-white)'
              : '6px solid #fff0',
            borderTop: '6px solid #fff0',
          }}
        >
          <Icon name="history" style={{ color: 'white' }} />
          <Text size="md" c="white">
            History
          </Text>
        </Group>
      </Menu.Target>
      <Menu.Dropdown
        bg="rgba(0, 0, 0, 0.733)"
        mah={500}
        maw={400}
        styles={{
          dropdown: {
            border: 'none',
            backdropFilter: 'blur(1.5px)',
            overflowY: 'auto',
          },
        }}
      >
        <HeaderMenuItem
          key="all_history"
          onClick={() => {
            captureEvent(POSTHOG_EVENTS.HEADER.CLICKED, {
              headerItem: 'All History',
            })
            navigate(ROUTES.history)
          }}
        >
          All History
        </HeaderMenuItem>
        <Menu.Divider mx="sm" my="xs" />
        {recentItems.map((item, index) => (
          <HeaderMenuItem key={index} onClick={() => handleItemClick(item)}>
            {item.query}
          </HeaderMenuItem>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
