import { Button, Menu } from '@mantine/core'
import { type CollectionSortType, useCollections } from '@/api/collections'

export interface HeaderSortCellProps {
  label: string
  sortItems: {
    value?: CollectionSortType
    label: string
  }[]
}

export function HeaderSortCell({ label, sortItems }: HeaderSortCellProps) {
  const { setSort } = useCollections()

  const handleClick = (value?: CollectionSortType) => {
    if (value) {
      setSort(value)
    }
  }

  return (
    <Menu shadow="md" width={200} position="bottom-start">
      <Menu.Target>
        <Button
          size="xs"
          radius="xl"
          bg="#FAFAFA"
          c="black"
          fw={500}
        >
          {label}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {sortItems.map(({ value, label }) => (
          <Menu.Item key={label} onClick={() => handleClick(value)}>
            {label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
