import { ActionIcon, Box, Divider, Group, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { CollectionItem } from '@/utils/types/collection-types'
import type { ResultWithId } from '@/utils/types/result-types'
import dayjs from 'dayjs'
import { CollectionDetailResultIcon } from '../CollectionDetailResultIcon'

export interface CollectionDetailListItemProps {
  item: CollectionItem
  onSelect: (item: CollectionItem) => void
}

export function CollectionDetailListItem({
  item,
  onSelect,
}: CollectionDetailListItemProps) {
  const { name, createdOn } = item
  const result = JSON.parse(item.doc) as ResultWithId

  return (
    <Stack gap="xs" px="sm">
      <Group gap={6} wrap="nowrap">
        <CollectionDetailResultIcon result={result} />
        <Text c="#000000DE" size="16px" lineClamp={1}>
          {name}
        </Text>
        <Box flex={1} />
        <ActionIcon
          variant="white"
          onClick={(event) => {
            event.stopPropagation()
            onSelect(item)
          }}
          data-cy="collection-menu"
          bg="transparent"
        >
          <Icon name="more_vert" style={{ color: '#000000' }} />
        </ActionIcon>
      </Group>
      <Group gap="xs" align="center">
        <Text c="#00000099" size="12px">
          {dayjs(createdOn).format('MM/DD/YY hh:mm A')}
        </Text>
      </Group>
      <Divider my="xs" />
    </Stack>
  )
}
