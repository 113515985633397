import { HeaderMenuItem } from '@/components/layout/Header/HeaderMenuItem'
import { feedbackModule } from '@/hooks/use-sentry-metadata-syncing'
import { isLocal } from '@/utils/constants'
import type React from 'react'

async function handleClick() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
  const form = await feedbackModule.createForm()
  form.appendToDom()
  form.open()
}

export function SentryFeedbackButton(
  props: React.ComponentProps<typeof HeaderMenuItem>,
) {
  return (
    !isLocal && (
      <HeaderMenuItem
        onClick={() => void handleClick()}
        {...props}
        iconName="campaign"
      >
        Feedback
      </HeaderMenuItem>
    )
  )
}
