import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Text } from '@mantine/core'
import { getRequest } from '@/api/danti-requests'
import { addIdToResult } from '@/api/results'
import { renderers } from '@/features/SingleObjectView/views'
import { useAddResults, useResultsById } from '@/stores/results-store'
import type { CollectionItem } from '@/utils/types/collection-types'
import type { RawSearchResult, ResultWithId } from '@/utils/types/result-types'

export function SingleObjectView() {
  const navigate = useNavigate()

  const resultsById = useResultsById()
  const { itemId, collectionId, terrapinItemId } = useParams()
  const [item, setItem] = React.useState<ResultWithId>()
  const [itemNotAvailable, setItemNotAvailable] = React.useState(false)

  const addResults = useAddResults()

  async function fetchItem(id: string) {
    const { data } = await getRequest<RawSearchResult>(`/results/${id}`)
    const result = addIdToResult(data)
    addResults([result])
    setItem(result)
  }

  async function fetchCollectionItem(collectionId: string, itemId: string) {
    const response = await getRequest<CollectionItem>(
      `/collection/${collectionId}/items/${itemId}`,
    )
    setItem(JSON.parse(response.data.doc) as ResultWithId)
  }

  React.useEffect(() => {
    if (terrapinItemId) {
      void fetchItem(terrapinItemId)
      return
    }

    if (collectionId && itemId) {
      void fetchCollectionItem(collectionId, itemId)
      return
    }

    if (itemId) {
      const item = itemId ? resultsById[itemId] : undefined
      if (item) {
        setItem(item)
      } else {
        setItemNotAvailable(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, collectionId, terrapinItemId])

  React.useEffect(() => {
    if (itemId) {
      const item = resultsById[itemId]
      if (item) {
        setItemNotAvailable(false)
        setItem(item)
      }
    }
  }, [resultsById, itemId])

  if (itemNotAvailable) {
    return <Text>Item not available</Text>
  }
  if (!item) {
    return <Text>Loading...</Text>
  }
  const resultCategory = item.properties.resultCategory as string

  const Renderer = renderers[resultCategory as keyof typeof renderers]
  const onGoBack = () => navigate(-1)
  return <Renderer item={item} onGoBack={onGoBack} />
}
