import { Flex } from '@mantine/core'
import { HandleRedirect } from '@/features/AppRouting/components'
import { PricingTable } from '@/features/Payments'
import { useHasActiveSubscription } from '@/hooks/use-has-active-subscription.ts'

export function PlanSelection() {
  const { hasActiveSubscription, isOwner } = useHasActiveSubscription()
  if (hasActiveSubscription || !isOwner) {
    return (
      <HandleRedirect to="/" data-testid="plan-selection-handle-redirect" />
    )
  }

  return (
    <Flex justify="center" align="center" direction="column" flex="1">
      <h1>Select your plan</h1>
      <PricingTable />
    </Flex>
  )
}
