import React from 'react'
import {
  Box,
  Button,
  Divider,
  Group,
  Menu,
  SegmentedControl,
  Stack,
  TextInput,
  Title,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useCollections } from '@/api/collections'
import SortToggleButton from '@/components/filters/SortToggleButton'
import { openFileUploadModal } from '@/components/lib/FileUpload/opener'
import { Icon } from '@/components/lib/Icon'
import { openCreateCollectionModal } from '@/components/modals/openers'
import { openSaveSearchModal } from '@/components/modals/SaveSearchModal/opener'
import { Pager } from '@/components/pagination'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'

export type ViewType = 'grid' | 'list'

export interface CollectionHeaderProps {
  viewType: ViewType
  setViewType: (value: ViewType) => void
}

export function CollectionHeader({
  viewType,
  setViewType,
}: CollectionHeaderProps) {
  const {
    currentPage,
    pageSize,
    totalCount,
    nextPage,
    previousPage,
    search,
    sort,
    setSort,
  } = useCollections()

  const [searchTerm, setSearchTerm] = React.useState('')
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300)

  React.useEffect(() => {
    if (debouncedSearchTerm !== '') {
      captureEvent(POSTHOG_EVENTS.COLLECTIONS.SEARCH, {
        searchQuery: debouncedSearchTerm,
      })
    }

    search(debouncedSearchTerm)
  }, [search, debouncedSearchTerm])

  return (
    <Stack w="100%" gap={12}>
      <Title c="#424242" size={60}>
        Collections
      </Title>
      <Group gap="sm">
        <TextInput
          placeholder="Search"
          withAsterisk
          leftSection={<Icon name="search" />}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          miw={300}
          styles={{
            root: {
              maxHeight: '32px',
            },
            wrapper: {
              height: '32px',
            },
            input: {
              height: '32px',
            },
          }}
        />
        <SegmentedControl
          bg="#E0E0E0"
          size="xs"
          value={viewType}
          onChange={(v) => setViewType(v as ViewType)}
          styles={{
            label: {
              height: 28,
            },
          }}
          data={[
            {
              value: 'list',
              label: <Icon name="view_list" />,
            },
            {
              value: 'grid',
              label: <Icon name="grid_view" />,
            },
          ]}
        />
        <Menu position="bottom-start" trigger="click-hover">
          <Menu.Target>
            <Button
              size="xs"
              style={{ fontWeight: '500', borderColor: '#0091EA' }}
              leftSection={<Icon name="add_circle_outline" size="xs" filled />}
              variant="filled"
            >
              ACTIONS
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Stack gap="xs">
              <Menu.Item
                leftSection={<Icon name="space_dashboard" filled />}
                onClick={openCreateCollectionModal}
              >
                New collection
              </Menu.Item>

              <Menu.Item
                leftSection={<Icon name="update" />}
                onClick={() =>
                  openSaveSearchModal({
                    isNew: false,
                    params: {
                      mode: 'feed',
                      name: '',
                      query: '',
                      locations: [],
                    },
                  })
                }
              >
                New feed
              </Menu.Item>
              <Menu.Item
                leftSection={<Icon name="upload_file" />}
                onClick={() => openFileUploadModal(null)}
              >
                Upload a file
              </Menu.Item>
            </Stack>
          </Menu.Dropdown>
        </Menu>
        <SortToggleButton sort={sort} onSortChange={setSort} />
        <Box flex={1} />
        <Pager
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={totalCount}
          nextPage={nextPage}
          previousPage={previousPage}
        />
      </Group>
      <Divider />
    </Stack>
  )
}
