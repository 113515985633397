import {
  openAddToCollectionModal,
  openAnalyzeItemModal,
  openDownloadItemModal,
  openResultDetailsModal,
  openShareCollectionModal,
} from '@/components/modals/openers'
import type { SymbolName } from '@/utils/types/material-icons'
import {
  type ImageryResult,
  isSocialMediaResult,
} from '@/utils/types/raw-result-types'
import {
  isFileResult,
  isNewsResult,
  isUp42Result,
} from '@/utils/types/result-type-checkers'
import {
  isAttomResult,
  isFireResult,
  isHazardPropertyResult,
  isHazardRiskResult,
  isImageryResult,
  isRegridResult,
  isShipResult,
  type ResultWithId,
} from '@/utils/types/result-types'

export interface ActionMenuType {
  label: string
  icon: SymbolName
  onClick: (result: ResultWithId) => void
  title?: string
  disabled?: boolean
}

export const actionMenuOptions: Record<string, ActionMenuType> = {
  addToCollection: {
    label: 'Add to Collection',
    icon: 'add',
    onClick: (result: ResultWithId) => {
      openAddToCollectionModal(result)
    },
  },
  viewDetails: {
    label: 'View Details',
    icon: 'info',
    onClick: (result: ResultWithId) => {
      openResultDetailsModal(result)
    },
  },
  visitArticle: {
    label: 'Visit Article',
    icon: 'open_in_new',
    onClick: (result: ResultWithId) => {
      window.open(result.properties.link, '_blank')
    },
  },
  downloadImage: {
    label: 'Download',
    icon: 'download',
    onClick: (result: ResultWithId) => {
      if (isImageryResult(result)) {
        openDownloadItemModal(result)
      }
    },
    disabled: true,
  },
  viewOnMap: {
    label: 'View on Map',
    icon: 'location_pin',
    onClick: (result: ResultWithId) => {
      window.location.pathname = `/map/${result.id}`
    },
  },
  findRelatedImages: {
    label: 'Find Related Images',
    icon: 'image_search',
    onClick: (result: ResultWithId) => {
      void result
    },
  },
  sendToAnalytics: {
    label: 'Analyze',
    icon: 'chart_data',
    onClick: (result: ResultWithId) => {
      openAnalyzeItemModal(result as ImageryResult)
    },
    title: 'Coming Soon',
    disabled: true,
  },
  share: {
    label: 'Share',
    icon: 'ios_share',
    onClick: (result: ResultWithId) => {
      openShareCollectionModal(result.id)
    },
    title: 'Coming Soon',
    disabled: true,
  },
} as const
export type ActionMenuOption = keyof typeof actionMenuOptions

export const getHideActionsForResult = (
  result: ResultWithId,
): ActionMenuOption[] => {
  if (isSocialMediaResult(result)) {
    return ['downloadImage', 'share', 'sendToAnalytics', 'viewOnMap']
  }

  if (isImageryResult(result)) {
    return ['findRelatedImages', 'visitArticle', 'downloadItem']
  }

  if (isFileResult(result)) {
    return ['findRelatedImages', 'visitArticle', 'downloadItem']
  }

  if (isNewsResult(result)) {
    return ['downloadImage', 'viewOnMap', 'sendToAnalytics']
  }

  if (isAttomResult(result)) {
    return [
      'addToCollection',
      'findRelatedImages',
      'visitArticle',
      'downloadImage',
      'share',
      'sendToAnalytics',
      'viewOnMap',
    ]
  }

  if (isShipResult(result)) {
    return [
      'findRelatedImages',
      'visitArticle',
      'downloadImage',
      'share',
      'sendToAnalytics',
      'viewOnMap',
    ]
  }

  if (isRegridResult(result)) {
    return [
      'addToCollection',
      'findRelatedImages',
      'visitArticle',
      'downloadImage',
      'share',
      'sendToAnalytics',
      'viewOnMap',
    ]
  }

  if (isHazardRiskResult(result)) {
    return [
      'addToCollection',
      'findRelatedImages',
      'visitArticle',
      'downloadImage',
      'share',
      'sendToAnalytics',
      'viewOnMap',
    ]
  }

  if (isHazardPropertyResult(result)) {
    return [
      'addToCollection',
      'findRelatedImages',
      'visitArticle',
      'downloadImage',
      'share',
      'sendToAnalytics',
      'viewOnMap',
    ]
  }

  if (isFireResult(result)) {
    return [
      'findRelatedImages',
      'visitArticle',
      'downloadImage',
      'share',
      'sendToAnalytics',
      'viewOnMap',
    ]
  }

  if (isUp42Result(result)) {
    return ['viewOnMap']
  }

  return []
}

export function isActionMenuOption(
  value?: string | (() => void),
): value is ActionMenuOption {
  return (value &&
    typeof value !== 'function' &&
    Object.keys(actionMenuOptions).includes(value)) as boolean
}
