import { useEffect, useState } from 'react'
import { Button, Group, MultiSelect, Stack, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { getUserAuthToken, postServiceRequest } from '@/api/danti-requests'
import { normalizeDownloadParams } from '@/api/helper'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import * as Sentry from '@sentry/react'

export function DownloadItemModal({
  context,
  id: modalId,
  innerProps: { item },
}: ContextModalProps<{ item: ImageryResult }>) {
  const [selectedAssets, setSelectedAssets] = useState<string[]>([])
  const [assets, setAssets] = useState<string[]>([])

  useEffect(() => {
    setAssets(Object.keys(item.asset))
  }, [item])

  async function handleDownloadClick() {
    const token = getUserAuthToken()
    try {
      await postServiceRequest(
        '/download/',
        {
          id: item.properties._dId,
          assets: selectedAssets,
          provider: normalizeDownloadParams(item.properties._dProvider),
          source: item.properties._dSource
            ? normalizeDownloadParams(item.properties._dSource)
            : '',
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      context.closeModal(modalId)
      notifications.clean()
      notifications.show({
        loading: true,
        title: 'Transfer requested',
        message:
          'This may take a moment. You will be notified via email when the download is ready.',
        autoClose: 10000,
        withCloseButton: true,
      })
      captureEvent(POSTHOG_EVENTS.DETAIL.DOWNLOAD, {
        resultCategory: item.properties.resultCategory ?? item.category,
      })
    } catch (error) {
      Sentry.captureException(error)
      context.closeModal(modalId)
      notifications.clean()
      notifications.show({
        loading: false,
        title: 'Transfer Error',
        message: 'Error in transfer. Please try again',
        autoClose: 10000,
        withCloseButton: true,
      })
    }
  }

  function handleClick(values: string[]) {
    setSelectedAssets([...values])
  }

  return (
    <>
      <Stack gap="sm">
        <Text>
          GeoTIFF files must be transferred from a provider before they are
          ready to download. Choose which product type you want to download
          below.
        </Text>
        <MultiSelect
          label="Choose Product Type"
          placeholder="Pick values"
          data={assets}
          maxDropdownHeight={200}
          comboboxProps={{
            position: 'bottom',
            middlewares: { flip: false, shift: false },
          }}
          onChange={(values) => handleClick(values)}
          required
          withAsterisk
        />
      </Stack>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button type="submit" onClick={() => void handleDownloadClick()}>
          Request Transfer
        </Button>
      </Group>
    </>
  )
}
