import type { Filters } from '@/stores/filters-store'
import {
  type SearchApiFilterValue,
  TimePreset,
} from '@/utils/types/filter-types.ts'
import { makeCloudCoverFilter } from './make-cloud-cover-filter.ts'
import { makeGeocodeFilter } from './make-geocode-filter.ts'
import { makeKeywordsFilter } from './make-keywords-filter.ts'
import { makeLocationFilter } from './make-location-filter.ts'
import { makeLocationTypeFilter } from './make-location-type-filter.ts'
import { makeNadirFilter } from './make-nadir-filter.ts'
import { makeOrientationsFilter } from './make-orientation-filter.ts'
import { makeProviderTypeFilter } from './make-provider-type-filter.ts'
import { makeQualityFilter } from './make-quality-filter.ts'
import { makeSensorTypeFilter } from './make-sensor-filter.ts'
import { makeTimeFilter } from './make-time-filter.ts'

export const makeApiFilters = (
  store: Partial<Filters>,
): SearchApiFilterValue[] => {
  const filters: SearchApiFilterValue[] = []

  // Timeframe
  if (
    store.timePreset &&
    (store.timePreset !== TimePreset.None ||
      (store.customTimeStart && store.customTimeEnd))
  ) {
    const timeFilter = makeTimeFilter({
      timePreset: store.timePreset || TimePreset.Last30Days,
      customTimeStart: store.customTimeStart || new Date(),
      customTimeEnd: store.customTimeEnd,
    })
    if (timeFilter) {
      filters.push(timeFilter)
    }
  }

  // Sensor Type
  if (store.sensors && store.sensors.length > 0) {
    const sensorTypeFilter = makeSensorTypeFilter(store.sensors)
    if (sensorTypeFilter) {
      filters.push(sensorTypeFilter)
    }
  }

  // Provider
  if (store.providers && store.providers.length > 0) {
    const providerTypeFilter = makeProviderTypeFilter(store.providers)
    if (providerTypeFilter) {
      filters.push(providerTypeFilter)
    }
  }

  // Quality
  if (store.quality && store.quality !== '5000') {
    const qualityFilter = makeQualityFilter(store.quality)
    if (qualityFilter) {
      filters.push(qualityFilter)
    }
  }

  // Cloud Cover
  if (
    store.cloudCover !== undefined &&
    store.cloudCover !== null &&
    store.cloudCover !== 100
  ) {
    const cloudCoverFilter = makeCloudCoverFilter(store.cloudCover)
    if (cloudCoverFilter) {
      filters.push(cloudCoverFilter)
    }
  }

  // Orientation
  if (store.orientations && store.orientations.length > 0) {
    const orientationsFilter = makeOrientationsFilter(store.orientations)
    if (orientationsFilter) {
      filters.push(orientationsFilter)
    }
  }

  // Nadir
  if (store.angle && store.angle !== '50') {
    const nadirFilter = makeNadirFilter(store.angle)
    if (nadirFilter) {
      filters.push(nadirFilter)
    }
  }

  // Location
  if (store.location && store.location !== '') {
    const locationFilter = makeLocationFilter(store.location)
    if (locationFilter) {
      filters.push(locationFilter)
    }
  }

  // Location Type
  if (store.locationType && store.locationType !== '') {
    const locationTypeFilter = makeLocationTypeFilter(store.locationType)
    if (locationTypeFilter) {
      filters.push(locationTypeFilter)
    }
  }

  // Keywords Type
  if (store.keywords && store.keywords.length > 0) {
    const keywordsFilter = makeKeywordsFilter(store.keywords)
    if (keywordsFilter) {
      filters.push(keywordsFilter)
    }
  }

  // Geocode Formatted Address Filter
  if (store.formattedAddress) {
    const formattedAddressFilter = makeGeocodeFilter(
      'formattedAddress',
      store.formattedAddress,
    )
    if (formattedAddressFilter) {
      filters.push(formattedAddressFilter)
    }
  }

  // Geocode PlaceID Filter
  if (store.placeId) {
    const placeIdFilter = makeGeocodeFilter('placeId', store.placeId)
    if (placeIdFilter) {
      filters.push(placeIdFilter)
    }
  }

  // Geocode Country Filter
  if (store.country) {
    const countryFilter = makeGeocodeFilter('country', store.country)
    if (countryFilter) {
      filters.push(countryFilter)
    }
  }

  // Geocode Admin Area Level 1 Filter
  if (store.adminAreaLevel1) {
    const adminAreaLevel1Filter = makeGeocodeFilter(
      'adminAreaLevel1',
      store.adminAreaLevel1,
    )
    if (adminAreaLevel1Filter) {
      filters.push(adminAreaLevel1Filter)
    }
  }

  // Geocode Admin Area Level 2 Filter
  if (store.adminAreaLevel2) {
    const adminAreaLevel2Filter = makeGeocodeFilter(
      'adminAreaLevel2',
      store.adminAreaLevel2,
    )
    if (adminAreaLevel2Filter) {
      filters.push(adminAreaLevel2Filter)
    }
  }

  return filters
}
