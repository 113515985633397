import { type CardProps, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { ResultBase } from '@/components/lib/results/Result/ResultBase'
import type { CollectionItem } from '@/utils/types/collection-types'
import type { FileResult } from '@/utils/types/result-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface FileResultProps extends CardProps {
  item?: CollectionItem
  result: FileResult
  isFullscreen?: boolean
}

export function FileResult({
  result,
  item,
  isFullscreen,
  ...props
}: FileResultProps) {
  const fileType = result.title.replace(/^.*\./, '')

  return (
    <ResultBase
      result={result}
      item={item}
      disableNavigate={true}
      onClick="viewDetails"
      data-cy="fileResult"
      {...props}
    >
      <Stack
        gap={0}
        align="center"
        justify="center"
        bg="gray.2"
        style={{
          aspectRatio: `${isFullscreen ? 2.4 : 1.95}`,
          borderRadius: 'var(--mantine-radius-md)',
          cursor: 'pointer',
        }}
      >
        <Icon name="upload_file" size="3rem" />
        <Text fz="md" fw="bold" data-no-hover>
          {fileType}
        </Text>
      </Stack>

      <Stack gap={0} mt="xs">
        {dayjs(result.authoredOn).utc().format('MM/DD/YY h:mm a').toUpperCase()}
        <Text fz="xs" fw="bold">
          {result.title}
        </Text>
      </Stack>
    </ResultBase>
  )
}
