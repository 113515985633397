import { type CardProps, Group, Image, Stack, Table, Text } from '@mantine/core'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import type { HazardPropertyResult as HazardPropertyResultType } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface HazardPropertyResultProps extends CardProps {
  result: HazardPropertyResultType
  isFullscreen?: boolean
}

interface FormattedHazardPropertyData {
  address: string
  useCode: string
  description?: string
  images?: string[]
  previewUrl?: string
  baths?: number
  bedrooms?: number
  yearBuilt?: number
  // stories: number,
  partialBaths?: number
  // rooms: number,
  airConditioning?: string
  listDate?: string
  listingStatus?: string
}

function formatHazardPropertyData(
  result: HazardPropertyResultType,
): FormattedHazardPropertyData {
  const { properties } = result
  return {
    address: properties.address,
    useCode: properties.useCode,
    baths: properties.bathsTotal,
    bedrooms: properties.bedroomsTotal,
    yearBuilt: properties.yearBuilt,
    partialBaths: properties.bathsPartial,
    airConditioning: properties.coolingFeatures,
    description: properties.publicRemarks,
    previewUrl: properties._dPreview,
    listDate:
      properties.listDate &&
      dayjs(properties.listDate).utc().format('YYYY-MM-DD'),
    listingStatus: properties.listStatus,
    images: properties.images,
  }
}

export function HazardPropertyResult({
  result,
  isFullscreen,
  ...props
}: HazardPropertyResultProps) {
  const {
    address,
    useCode,
    previewUrl,
    baths,
    bedrooms,
    yearBuilt,
    partialBaths,
    airConditioning,
    description,
    // images,
  } = formatHazardPropertyData(result)

  return (
    <ResultBase
      result={result}
      onClick="viewDetails"
      withBorder
      radius="sm"
      style={{ borderColor: 'var(--mantine-color-gray-2)' }}
      data-cy="shipResult"
      {...props}
    >
      <Stack gap="xs" p="sm">
        {previewUrl && (
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              aspectRatio: 1.95,
              backgroundColor: 'var(--mantine-color-gray-1)',
            }}
          >
            <Image
              alt={result.title}
              src={previewUrl}
              fit="cover"
              width="100%"
              height="100%"
              radius="sm"
            />
          </div>
        )}
        <Group gap="xs">
          <Text size="md" fw="bold">
            {address}
          </Text>
          <Text size="sm" color="gray">
            {useCode}
          </Text>
        </Group>
        <Table
          fz="xs"
          withRowBorders={false}
          className={styles['result-table']}
        >
          <Table.Tbody>
            {baths && (
              <Table.Tr>
                <Table.Td>Baths</Table.Td>
                <Table.Td>{baths}</Table.Td>
              </Table.Tr>
            )}
            {bedrooms && (
              <Table.Tr>
                <Table.Td>Bedrooms</Table.Td>
                <Table.Td>{bedrooms}</Table.Td>
              </Table.Tr>
            )}
            {yearBuilt && (
              <Table.Tr>
                <Table.Td>Year Built</Table.Td>
                <Table.Td>{yearBuilt}</Table.Td>
              </Table.Tr>
            )}
            {partialBaths && (
              <Table.Tr>
                <Table.Td>Partial Baths</Table.Td>
                <Table.Td>{partialBaths}</Table.Td>
              </Table.Tr>
            )}
            {airConditioning && (
              <Table.Tr>
                <Table.Td>Air Conditioning</Table.Td>
                <Table.Td>{airConditioning}</Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
        <Group gap="xs">
          <Text size="sm" color="gray" lineClamp={3}>
            {description}
          </Text>
        </Group>
      </Stack>
    </ResultBase>
  )
}
