import type { HexGridCellProperties } from '@/stores/map-store'
import type { MutableRefObject } from 'react'

let listenersAdded = false

type AddInteractionListenersProps = {
  map: MutableRefObject<mapboxgl.Map | null>
  toggleSelectedHexGridId: (id: number | null) => void
  setHoveredHexGridId: (id: number | null) => void
  setSelectedExtentIds: (ids: string[]) => void
  setHoveredExtentIds: (ids: string[]) => void
  setHoveredLayerId: (id: string | null) => void
  toggleOrthoLayerGroupId: (id: string) => void
}
export const addInteractionListeners: (
  props: AddInteractionListenersProps,
) => void = ({
  map,
  toggleSelectedHexGridId,
  setHoveredHexGridId,
  setHoveredExtentIds,
  setHoveredLayerId,
  toggleOrthoLayerGroupId,
}) => {
  if (listenersAdded) {
    return
  }
  const clickCallback = (event: mapboxgl.MapLayerMouseEvent) => {
    if (event.features && event.features[0]) {
      const hexId = (event.features[0].properties as HexGridCellProperties).id

      if (hexId) {
        toggleSelectedHexGridId(hexId)
      }
    }
  }

  map.current?.on(
    'mouseover',
    'hexgrid-fills',
    (event: mapboxgl.MapLayerMouseEvent) => {
      event.target.getCanvas().style.cursor = 'pointer'
      if (event.features && event.features[0]) {
        const hexId = (event.features[0].properties as HexGridCellProperties).id
        setHoveredHexGridId(hexId)
      }
    },
  )

  map.current?.on(
    'mouseleave',
    'hexgrid-fills',
    (event: mapboxgl.MapLayerMouseEvent) => {
      event.target.getCanvas().style.cursor = ''
      setHoveredHexGridId(null)
    },
  )

  map.current?.on('click', 'hexgrid-fills', (event) => clickCallback(event))

  map.current?.on(
    'mouseover',
    'extents-outlines',
    (event: mapboxgl.MapLayerMouseEvent) => {
      event.target.getCanvas().style.cursor = 'pointer'
      if (event.features) {
        const ids = event.features
          .map((f) => f.properties?.['documentId'] as string | undefined)
          .filter((f): f is string => !!f)
        setHoveredExtentIds(ids)
      }
    },
  )

  map.current?.on('mousemove', 'extents-fills', (event) => {
    event.target.getCanvas().style.cursor = 'pointer'

    if (event.features) {
      const id =
        event.features
          .map((f) => f.properties?.['groupKey'] as string | undefined)
          .find((f): f is string => !!f) ?? null

      setHoveredLayerId(id)
    }
  })

  map.current?.on('mouseout', 'extents-fills', (event) => {
    event.target.getCanvas().style.cursor = 'default'

    setHoveredLayerId(null)
  })

  map.current?.on('click', 'extents-fills', (event) => {
    if (event.features) {
      const id =
        event.features
          .map((f) => f.properties?.['groupKey'] as string | undefined)
          .find((f): f is string => !!f) ?? null

      if (!id) {
        return
      }

      toggleOrthoLayerGroupId(id)
    }
  })

  listenersAdded = true
}
