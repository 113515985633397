import { type MutableRefObject, useCallback, useEffect } from 'react'
import { makeResultPinPoints } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { safeSetLayoutProperty } from '@/components/lib/map-search/hooks/util/safe-map-setters.ts'
import { addPointLayers } from '@/components/lib/map-search/Point/add-point-layers.ts'
import { addPointSources } from '@/components/lib/map-search/Point/add-point-sources.ts'
import { usePointInteractionListeners } from '@/components/lib/map-search/Point/use-point-interaction-listeners.ts'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { useActiveLayers, usePointViewPort } from '@/stores/map-store'
import {
  type FireResult as FireResultType,
  isFireResult,
  isShipResult,
  type ResultWithId,
  type ShipResult as ShipResultType,
} from '@/utils/types/result-types.ts'
import type mapboxgl from 'mapbox-gl'

export enum POINT_LAYER_NAMES {
  NEWS = 'news-point-layer',
  FIRE = 'fire-point-layer',
  SHIP = 'ship-point-layer',
  SOCIAL = 'social-point-layer',
}

export const usePointData = (
  map: MutableRefObject<mapboxgl.Map | null>,
  view: 'Embedded' | 'Explore',
  showPointData: boolean,
) => {
  const activeLayers = useActiveLayers()
  const pointViewPort = usePointViewPort()
  const {
    categorized: { publicationResults, dataResults, socialMediaResults },
  } = useTimelineFilteredResults()
  usePointInteractionListeners(map)

  const shipResults: ShipResultType[] = dataResults.filter(isShipResult)
  const fireResults: FireResultType[] = dataResults.filter(isFireResult)

  function makePointList(results: ResultWithId[]) {
    return view === 'Embedded'
      ? makeResultPinPoints(results.slice(0, 3))
      : makeResultPinPoints(results)
  }
  const fireData = makePointList(fireResults)
  const shipData = makePointList(shipResults)
  const socialData = makePointList(socialMediaResults)
  const newsData = makePointList(publicationResults)

  const styleDataCallback = useCallback(() => {
    addPointSources({
      map,
      fireData,
      shipData,
      socialData,
      newsData,
    })
    addPointLayers(map, showPointData)
  }, [fireData, map, newsData, shipData, showPointData, socialData])

  useEffect(() => {
    if (map.current?.isStyleLoaded()) {
      addPointSources({
        map,
        fireData,
        shipData,
        newsData,
        socialData,
      })
    }
  }, [fireData, map, shipData, socialData, newsData, view, activeLayers])

  useEffect(() => {
    map.current?.on('styledata', styleDataCallback)
  }, [map, styleDataCallback])

  useEffect(
    function layerToggling() {
      void safeSetLayoutProperty(
        map,
        POINT_LAYER_NAMES.NEWS,
        'visibility',
        showPointData ? 'visible' : 'none',
      )
      void safeSetLayoutProperty(
        map,
        POINT_LAYER_NAMES.SHIP,
        'visibility',
        showPointData ? 'visible' : 'none',
      )
      void safeSetLayoutProperty(
        map,
        POINT_LAYER_NAMES.SOCIAL,
        'visibility',
        showPointData ? 'visible' : 'none',
      )
      void safeSetLayoutProperty(
        map,
        POINT_LAYER_NAMES.FIRE,
        'visibility',
        showPointData ? 'visible' : 'none',
      )
    },
    [map, activeLayers, showPointData, view],
  )

  useEffect(() => {
    if (!pointViewPort) {
      return
    }
    map.current?.flyTo({
      center: [pointViewPort[0], pointViewPort[1]],
      zoom: 15,
    })
  }, [map, pointViewPort])
}
