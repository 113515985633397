import { extractCompanyName } from '@/hooks/results/result-filter-utilities.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types.ts'
import { isUp42Result } from '@/utils/types/result-type-checkers.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { Polygon } from 'geojson'

export const makeUP42OrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  const featureId = (r.properties.featureId as string) || r.id
  return {
    source: 'UP42' as const,
    id: featureId,
    tileUrl: r.properties._dPreview as string,
    authoredOn: r.authoredOn,
    geometry: r.geometry as Polygon,
    documentId: r.documentId,
    imageResult: r,
    groupKey: featureId,
    score: r.score,
    formattedSource: `${extractCompanyName(r)}`,
  }
}

export const useUP42 = () => {
  const imageResults = usePostfilterImageResults()

  return imageResults.filter(isUp42Result).map(makeUP42OrthoLayer)
}
