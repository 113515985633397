import { Anchor, Group, Text } from '@mantine/core'

import styles from './view-template.module.css'

export interface SOVCarouselProps<ItemType> {
  title: string
  items: ItemType[]
  renderCard: (item: ItemType, index: number) => JSX.Element
  selectItem: (item: ItemType, index: number) => void
  grid?: boolean
  categoryUrl?: string
  categoryLabel?: string
}

export function SOVCarousel<T>({
  title,
  items,
  renderCard,
  selectItem,
  grid = false,
  categoryUrl,
  categoryLabel,
}: SOVCarouselProps<T>) {
  if (items.length === 0) {
    return null
  }
  const handleClick = (item: T, index: number) => () => selectItem(item, index)
  return (
    <div className={styles['sov-carousel']}>
      <Text className={styles['sov-category-title']} w="100%">
        {title}
      </Text>
      {categoryUrl && categoryLabel && (
        <Anchor href={categoryUrl} className={styles['sov-category-link']}>
          {categoryLabel}
        </Anchor>
      )}
      <div className={styles['sov-category-listing']}>
        <Group
          wrap={grid ? 'wrap' : 'nowrap'}
          style={{ alignItems: 'start' }}
          gap="0px"
        >
          {(grid ? items : items.slice(0, 4)).map((item, index) => (
            <div
              className={styles['sov-category-card']}
              key={index}
              onClick={handleClick(item, index)}
            >
              {renderCard(item, index)}
            </div>
          ))}
        </Group>
      </div>
    </div>
  )
}
