import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Group, Stack, Text, Title } from '@mantine/core'
import { getRequest } from '@/api/danti-requests'
import { createStaticMapUrl } from '@/api/mapbox'
import { addIdToResult } from '@/api/results'
import { DantiNoResultMark } from '@/components/lib/DantiNoResultMark.tsx'
import { DataDisplay } from '@/components/lib/DataDisplay.tsx'
import { Icon } from '@/components/lib/Icon.tsx'
import { SingleImageMap } from '@/components/lib/map-search/SingleImageMap.tsx'
import { ResultImage } from '@/components/lib/ResultImage.tsx'
import {
  openAddToCollectionModal,
  openAnalyzeItemModal,
  openDownloadItemModal,
} from '@/components/modals/openers.tsx'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable.ts'
import { useAddResults, useAllResults } from '@/stores/results-store'
import type { CollectionItem } from '@/utils/types/collection-types'
import { isVexcel } from '@/utils/types/result-type-checkers.ts'
import {
  type ImageryResult,
  isImageryResult,
  type RawSearchResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import { wordBreak } from '@/utils/ui-helpers.tsx'
import dayjs from 'dayjs'

interface ItemDetailProps {
  onGoBack?: () => void
}

function ItemDetailComponent({
  item,
  onGoBack,
}: ItemDetailProps & { item: ImageryResult }) {
  const { data: isDownloadable } = useIsDownloadable(item, true)

  return (
    <>
      <Stack>
        <Title size="h1" fw="bold" c="#3e3f42" mb="sm">
          {wordBreak(item.title ?? 'Unknown')}
        </Title>
        {item.subtitle && (
          <Text size="xl" c="#6b6c6f" mb="md">
            {item.subtitle}
          </Text>
        )}
        <Text size="md" fw="bold" c="#3e3f42" mb="sm">
          {dayjs(item.authoredOn).format('MM/DD/YYYY [at] h:mmA [GMT]Z')}
        </Text>
      </Stack>

      <Group
        justify="space-between"
        pb="md"
        style={{ borderBottom: '1px solid #acb4c1' }}
      >
        <Button
          variant="outlined"
          leftSection={<Icon name="arrow_left_alt" />}
          onClick={onGoBack}
        >
          Back
        </Button>
        <Group gap="sm">
          <Button
            variant="outlined"
            onClick={() => openAddToCollectionModal(item)}
          >
            Add to Collection
          </Button>
          <Button
            variant="primary"
            onClick={() => openDownloadItemModal(item)}
            disabled={!isDownloadable}
          >
            {isDownloadable === undefined
              ? 'Checking Ability to Download'
              : isDownloadable
                ? 'Download'
                : 'Download Unavailable'}
          </Button>
          <Button
            variant="primary"
            onClick={() => openAnalyzeItemModal(item)}
            disabled={!isDownloadable}
          >
            {isDownloadable === undefined
              ? 'Checking Ability to Analyze'
              : isDownloadable
                ? 'Analyze'
                : 'Analysis Unavailable'}
          </Button>
        </Group>
      </Group>

      <Stack mt="lg">
        {isVexcel(item) ? (
          item.properties._dPreview ? (
            <div>
              <ResultImage
                item={item}
                style={{
                  width: '100%',
                  height: '600px',
                }}
                data-cy="single-image"
              />
            </div>
          ) : (
            <DantiNoResultMark />
          )
        ) : (
          <SingleImageMap isFullScreen={false} item={item} />
        )}
        <Group>
          <div style={{ flex: 1 }}>
            <DataDisplay data={item.properties} />
          </div>
          <div
            style={{
              width: '33%',
              marginLeft: '32px',
              alignSelf: 'flex-start',
            }}
          >
            <Text
              style={{
                fontSize: '12px',
                color: '#6b6c6f',
                textTransform: 'uppercase',
              }}
            >
              Map Reference
            </Text>
            <img
              src={createStaticMapUrl({ geometry: item.geometry })}
              alt="Map"
              style={{ width: '100%', marginTop: '8px' }}
            />
          </div>
        </Group>
      </Stack>
    </>
  )
}

export default function ItemDetail({ onGoBack }: ItemDetailProps) {
  const navigate = useNavigate()
  const { itemId, collectionId, terrapinItemId } = useParams()
  const results = useAllResults()
  const [item, setItem] = React.useState<ResultWithId>()
  const addResults = useAddResults()

  async function fetchItem(id: string) {
    const { data } = await getRequest<RawSearchResult>(`/results/${id}`)
    const result = addIdToResult(data)
    addResults([result])
    setItem(result)
  }

  async function fetchCollectionItem(collectionId: string, itemId: string) {
    const response = await getRequest<CollectionItem>(
      `/collection/${collectionId}/items/${itemId}`,
    )
    setItem(JSON.parse(response.data.doc) as ResultWithId)
  }

  React.useEffect(() => {
    if (terrapinItemId) {
      void fetchItem(terrapinItemId)
      return
    }

    if (collectionId && itemId) {
      void fetchCollectionItem(collectionId, itemId)
      return
    }

    if (itemId) {
      const item = results.find(({ id }) => id === itemId)
      if (item) {
        setItem(item)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return item && isImageryResult(item) ? (
    <ItemDetailComponent
      item={item}
      onGoBack={onGoBack ?? (() => navigate(-1))}
    />
  ) : (
    <Text>Loading...</Text>
  )
}
