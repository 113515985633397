import { PAGE_SIZE } from '@/api/collection-items/constants'
import type {
  AddItemParams,
  DeleteItemParams,
} from '@/api/collection-items/types'
import type { CollectionSortType } from '@/api/collections'
import type { CollectionItem } from '@/utils/types/collection-types'
import type { CategoryTypes } from '@/utils/types/result-types'
import { useCollectionItemsActions } from './use-collection-items-actions'
import { useCollectionItemsData } from './use-collection-items-data'
import { useCollectionItemsMutations } from './use-collection-items-mutations'
import { useCollectionItemsQuery } from './use-collection-items-query'

interface UseCollectionItemsHookResponse {
  items: CollectionItem[]
  totalCount: number
  isLoading: boolean
  pageSize: number
  currentPage: number
  selectedCategories: CategoryTypes[]
  filter?: string

  sort: CollectionSortType
  setSort: (sort: CollectionSortType) => void
  nextPage: () => void
  previousPage: () => void
  add: (params: AddItemParams) => Promise<void>
  remove: (params: DeleteItemParams) => Promise<void>
  search: (term: string) => void
  toggleSelectedCategory: (category: CategoryTypes) => void
}

export function useCollectionItems(
  collectionId?: string,
): UseCollectionItemsHookResponse {
  const data = useCollectionItemsData()

  const { next, prev, filter, items, isLoading, totalCount, maxPages } =
    useCollectionItemsQuery({ collectionId, ...data })

  const { addItem, removeItem } = useCollectionItemsMutations(data.queryClient)

  const { nextPage, previousPage, search, toggleSelectedCategory } =
    useCollectionItemsActions({
      next,
      prev,
      maxPages,
      ...data,
    })

  return {
    items,
    totalCount,
    isLoading,
    pageSize: PAGE_SIZE,
    currentPage: data.currentPage,
    sort: data.sort,
    filter,
    selectedCategories: data.selectedCategories,
    setSort: data.setSort,
    nextPage,
    previousPage,
    add: addItem,
    remove: removeItem,
    search,
    toggleSelectedCategory,
  }
}
