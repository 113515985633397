import { analyticResults } from '@/stores/results-store/selectors/analytic'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type { ResultWithId } from '@/utils/types/result-types'
import { createSelector } from 'reselect'
import { useResultsStore } from '../store'
import { fireResults } from './fire'
import { imageResults } from './image'
import { publicationResults } from './news'
import { propertyResultsByCategory } from './property'
import { shipResults } from './ship'
import { socialMediaResults } from './social'

export * from './fire'
export * from './flatten-results'
export * from './get-news-images'
export * from './get-social-images'
export * from './image'
export * from './image'
export * from './news'
export * from './property'
export * from './results'
export * from './ship'
export * from './ship-views'
export * from './social'

export const resultsByResultCategory = createSelector(
  [
    fireResults,
    shipResults,
    imageResults,
    publicationResults,
    socialMediaResults,
    analyticResults,
    propertyResultsByCategory,
  ],
  (fires, ships, images, news, social, analytic, propertyResults) =>
    ({
      [RESULT_CATEGORIES.FIRE]: fires,
      [RESULT_CATEGORIES.SHIP]: ships,
      [RESULT_CATEGORIES.IMAGE]: images,
      [RESULT_CATEGORIES.PUBLICATION]: news,
      [RESULT_CATEGORIES.SOCIAL_MEDIA]: social,
      [RESULT_CATEGORIES.ANALYTIC]: analytic,
      ...propertyResults,
    }) as Record<string, ResultWithId[]>,
)

export const useResultsByResultCategory = () =>
  useResultsStore(resultsByResultCategory)
