import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { useSortedPermits } from '@/features/BentoResults/hooks/use-sorted-permits'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import {
  ICONS,
  RESULT_CATEGORIES,
  RESULT_CATEGORY_COLORS,
} from '@/utils/constants'
import type { AttomResult } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.ATTOM
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface AttomBentoCardProps extends CardProps {
  result: AttomResult
  size: string
}

export const AttomBentoCard = ({ result, ...props }: AttomBentoCardProps) => {
  const permits = useSortedPermits(result)
  return (
    <BentoCard
      {...props}
      result={result}
      hideActions={actionsToHide}
      resultCategory={category}
      content={{
        heading: 'Permits',
        subHeading: permits[0].type,
        color: RESULT_CATEGORY_COLORS.attom,
      }}
      icon={ICONS.construction}
      totalResults={permits.length}
    />
  )
}

export default AttomBentoCard
