import { useCallback, useEffect, useRef } from 'react'
import { useCurrentQueryId } from '@/stores/results-store'

export const useDantiWSQuery = ({
  sendMessage,
}: {
  sendMessage: (action: string, message: Record<string, any>) => Promise<void>
}) => {
  const currentQueryId = useCurrentQueryId()

  const queryIdRef = useRef<string | null>(currentQueryId)

  const setQuery = useCallback(() => {
    void sendMessage('query', {
      queryId: currentQueryId,
    })
  }, [currentQueryId, sendMessage])

  useEffect(() => {
    if (currentQueryId) {
      queryIdRef.current = currentQueryId
      setQuery()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQueryId])

  return {
    currentQueryId,
    queryIdRef,
    setQuery,
  }
}
