import { MantineProvider, Stack, Text } from '@mantine/core'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import type React from 'react'

type MapPointInfoTooltipProps = {
  result: ResultWithId
}

export const MapPointInfoTooltip: React.FC<MapPointInfoTooltipProps> = ({
  result,
}) => {
  return (
    <MantineProvider>
      <div style={{ pointerEvents: 'none' }}>
        <Stack style={{ alignItems: 'left', padding: '16px' }} gap={1}>
          <Text fw="500" fs="13px">
            {dayjs(result?.properties.datetime).utc().format('MMMM DD, YYYY')}
          </Text>
          <Text fw="400" lts="0.4px" fs="12px">
            {result?.title}
          </Text>
        </Stack>
      </div>
    </MantineProvider>
  )
}
