import { ActionIcon, Flex, Popover, Radio, Stack, Text } from '@mantine/core'
import { TimeInput } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon'
import {
  type ConfigureEmailNotificationsProps,
  useConfigureEmailNotifications,
} from './use-configure-email-notifications'

export function ConfigureEmailNotifications({
  emailNotification,
  setEmailNotification,
}: ConfigureEmailNotificationsProps) {
  const {
    formattedTime,
    handleSelectNone,
    messages,
    timeInputRef,
    handleTimeChange,
    showPlatformTimePicker,
  } = useConfigureEmailNotifications({
    emailNotification,
    setEmailNotification,
  })

  return (
    <Popover position="bottom" width="target">
      <Popover.Target>
        <Flex justify="space-between" style={{ cursor: 'pointer' }}>
          <Text size="sm">{formattedTime}</Text>
          <Icon name="keyboard_arrow_down" />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Radio
            checked={!emailNotification}
            onChange={handleSelectNone}
            label={messages.none}
          />
          <TimeInput
            ref={timeInputRef}
            aria-label="Email notification schedule"
            value={emailNotification ?? ''}
            onChange={handleTimeChange}
            rightSection={
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={showPlatformTimePicker}
              >
                <Icon name="schedule" />
              </ActionIcon>
            }
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
