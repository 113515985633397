import type { SymbolName } from '@/utils/types/material-icons'

export type NavItemId =
  | 'explore'
  | 'map'
  | 'history'
  | 'collections'
  | 'timeline'

interface NavItem {
  id: NavItemId
  icon: SymbolName
  label: string
  path: string
}

export const navItems: NavItem[] = [
  {
    id: 'explore',
    icon: 'search',
    label: 'Explore',
    path: '/',
  },
  {
    id: 'map',
    icon: 'map',
    label: 'Map',
    path: '/map',
  },
  {
    id: 'timeline',
    icon: 'timeline',
    label: 'Timeline',
    path: '/timeline',
  },
  {
    id: 'collections',
    icon: 'collections_bookmark',
    label: 'Collections',
    path: '/collections',
  },
  {
    id: 'history',
    icon: 'history',
    label: 'History',
    path: '/history',
  },
]
