import { type MutableRefObject, useCallback, useEffect } from 'react'
import { fetchSignedS3URL } from '@/api/thumbnail'
import { makeImageBounds } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useOrthoImagerySources } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import {
  MAP_LAYER_OPTIONS,
  useActiveLayers,
  useActiveOrthoLayerGroupIds,
  useHoveredLayerId,
} from '@/stores/map-store'
import type {
  OrthoImageryLayer,
  OrthoImageryLayerGroup,
} from '@/utils/types/ortho-imagery-types.ts'
import {
  isBlackSky,
  isSentinel,
  isUp42,
} from '@/utils/types/result-type-checkers'
import * as turf from '@turf/turf'
import type { Feature, MultiPolygon } from 'geojson'
import type mapboxgl from 'mapbox-gl'

const GROUP_EXTENT_SOURCE_ID = 'highlighted_group_extent'
const RESULT_SOURCE_PREFIX = 'orthoresult_'

const createSourceId = (id: string) => `${RESULT_SOURCE_PREFIX}${id}`

export const useOrthoLayers = (map: MutableRefObject<mapboxgl.Map | null>) => {
  const activeOrthoLayerGroupIds = useActiveOrthoLayerGroupIds()
  const hoveredLayerId = useHoveredLayerId()
  const { orthoLayerGroups } = useOrthoImagerySources()
  const activeLayers = useActiveLayers()
  const syncLayers = useCallback(() => {
    if (!map.current) {
      return
    }
    const expectedOrthoLayers: Record<string, OrthoImageryLayer> =
      activeOrthoLayerGroupIds
        .map((groupId) => orthoLayerGroups.find((olg) => groupId === olg.id))
        .filter((olg): olg is OrthoImageryLayerGroup => !!olg)
        .flatMap((olg) => olg.layers)
        .reduce(
          (accumulator, current) => ({
            ...accumulator,
            ...(current.id &&
              current.tileUrl && {
                [current.id]: current,
              }),
          }),
          {},
        )

    const currentMapSources = Object.entries(map.current.getStyle().sources)
      .filter(
        (pairArray): pairArray is [string, mapboxgl.RasterSource] =>
          pairArray[1].type === 'raster' || pairArray[1].type === 'image',
      )
      .filter(([id, _]) => id?.startsWith(RESULT_SOURCE_PREFIX))
      .map(([id, rs]) => ({ ...rs, id: id.slice(RESULT_SOURCE_PREFIX.length) }))

    const sourcesToRemove = activeLayers.includes(MAP_LAYER_OPTIONS.EXTENTS)
      ? currentMapSources.filter((rs) => !expectedOrthoLayers[rs.id])
      : currentMapSources

    sourcesToRemove.forEach((rs) => {
      const id = createSourceId(rs.id)
      map.current?.removeLayer(`${id}_layer`)
      map.current?.removeSource(id)
    })

    const existingTileIds = Object.fromEntries(
      currentMapSources.map((current) => [current.id, current]),
    )

    // Filter out the layers that are already on the map and add the new ones
    Object.entries(expectedOrthoLayers)
      .filter(([id]) => !existingTileIds[id])
      .forEach(([_orthoLayerId, orthoLayer]) => {
        const sourceId = createSourceId(orthoLayer.id)
        const isImageSource =
          isUp42(orthoLayer.source) || isBlackSky(orthoLayer.source)
        if (isImageSource) {
          const [minX, minY, maxX, maxY] = turf.bbox(orthoLayer.geometry)
          const coords = [
            [minX, maxY],
            [maxX, maxY],
            [maxX, minY],
            [minX, minY],
          ]

          void fetchSignedS3URL(orthoLayer.tileUrl).then((url) => {
            map.current?.addSource(sourceId, {
              type: 'image',
              url: url,
              coordinates: coords,
            })
            map.current?.addLayer({
              type: 'raster',
              source: sourceId,
              id: `${sourceId}_layer`,
            })
          })
        } else if (isSentinel(orthoLayer.source)) {
          const coords = makeImageBounds(orthoLayer.geometry)
          map.current?.addSource(sourceId, {
            type: 'image',
            url: orthoLayer.tileUrl,
            coordinates: coords,
          })
          map.current?.addLayer({
            type: 'raster',
            source: sourceId,
            id: `${sourceId}_layer`,
          })
        } else {
          map.current?.addSource(sourceId, {
            type: 'raster',
            tiles: [orthoLayer.tileUrl],
            bounds: turf.bbox(orthoLayer.imageResult.geometry),
          })
          map.current?.addLayer({
            type: 'raster',
            source: sourceId,
            id: `${sourceId}_layer`,
          })
        }
      })
  }, [activeOrthoLayerGroupIds, map, orthoLayerGroups, activeLayers])

  const waitForMapStyle = useCallback(() => {
    if (!map.current?.isStyleLoaded()) {
      setTimeout(() => waitForMapStyle(), 100)
      return
    }
    syncLayers()
  }, [map, syncLayers])

  useEffect(() => {
    waitForMapStyle()
  }, [waitForMapStyle])

  useEffect(() => {
    map.current?.on('styledata', () => {
      if (!map.current?.getSource(GROUP_EXTENT_SOURCE_ID)) {
        map.current?.addSource(GROUP_EXTENT_SOURCE_ID, {
          type: 'geojson',
          data: turf.featureCollection([]),
        })

        map.current?.addLayer({
          type: 'line',
          id: `${GROUP_EXTENT_SOURCE_ID}_layer`,
          source: GROUP_EXTENT_SOURCE_ID,
          paint: {
            'line-width': 2,
            'line-color': '#11CC00',
            'line-opacity': 0.8,
          },
        })
      }
    })
  }, [map])

  useEffect(() => {
    const groupToHighlight = orthoLayerGroups.find(
      (lg) => lg.id === hoveredLayerId,
    )
    const source = map.current?.getSource(
      GROUP_EXTENT_SOURCE_ID,
    ) as mapboxgl.GeoJSONSource
    if (source) {
      source.setData(
        turf.feature(
          groupToHighlight?.geometry ?? null,
        ) as Feature<MultiPolygon>,
      )
    }
  }, [hoveredLayerId, map, orthoLayerGroups])
}
