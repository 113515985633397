import {
  openAddToCollectionModal,
  openDownloadItemModal,
} from '@/components/modals/openers.tsx'
import {
  useNavigateToMapItem,
  useNavigateToResult,
} from '@/features/AppRouting/hooks'
import { MENU_OPTION_KEYS } from '@/features/ResultActions/constants'
import type { ClickEventHandler } from '@/features/ResultActions/types'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'

export const useMenuOptionActions = (
  result: ResultWithId,
): { [key: string]: ClickEventHandler } => {
  const createActionClickHandler =
    (action: (result: ResultWithId) => void, label: string) =>
    (event: React.MouseEvent) => {
      event.stopPropagation()
      captureEvent(POSTHOG_EVENTS.RESULT_MENU.CLICKED, {
        actionName: label,
        category: result.properties.resultCategory as string,
      })
      action(result)
    }
  const navigateToResult = useNavigateToResult()
  const navigateToMapItem = useNavigateToMapItem()
  return {
    [MENU_OPTION_KEYS.addToCollection]: createActionClickHandler(
      openAddToCollectionModal,
      MENU_OPTION_KEYS.addToCollection,
    ),
    [MENU_OPTION_KEYS.visitArticle]: createActionClickHandler(
      (result: ResultWithId) => {
        window.open(result.properties.link, '_blank')
      },
      MENU_OPTION_KEYS.visitArticle,
    ),
    [MENU_OPTION_KEYS.downloadImage]: createActionClickHandler(
      (result: ResultWithId) => {
        if (isImageryResult(result)) {
          openDownloadItemModal(result)
        }
      },
      MENU_OPTION_KEYS.downloadImage,
    ),
    [MENU_OPTION_KEYS.viewOnMap]: createActionClickHandler(
      navigateToMapItem,
      MENU_OPTION_KEYS.viewOnMap,
    ),
    [MENU_OPTION_KEYS.viewDetails]: createActionClickHandler(
      navigateToResult,
      MENU_OPTION_KEYS.viewDetails,
    ),
  }
}
