import * as React from 'react'
import type { LoadedRisk } from '@/features/SingleObjectView/types'
import type { HazardRiskResult, ResultWithId } from '@/utils/types/result-types'

export const useHazardRiskList = (rawItem: ResultWithId) => {
  const [riskList, setRiskList] = React.useState<LoadedRisk[]>([])
  const item = rawItem as HazardRiskResult
  const allRisks = item.properties.risks

  React.useEffect(() => {
    setRiskList(
      Object.keys(allRisks)
        .sort((riskA: string, riskB: string) => {
          if (!allRisks[riskB].score) {
            return -1
          }
          return (allRisks[riskA].score || 'F').localeCompare(
            allRisks[riskB].score || 'F',
          )
        })
        .map((risk) => ({ ...allRisks[risk], key: risk })),
    )
  }, [allRisks])
  return riskList
}
