import { Checkbox, Group, Table } from '@mantine/core'
import { Timeframe } from '@/components/filters/Timeframe.tsx'
import type { TerrapinConfigParams } from '@/hooks/use-terrapin'
import { ConfigureEmailNotifications } from './ConfigureEmailNotifications'
import { ConfigureExpiration } from './ConfigureExpiration'
import type { ConfigurationTablePropsType } from './use-configure-saved-search'

interface ConfigureTimingsProps {
  savedConfig?: TerrapinConfigParams
  refId?: string
  tableProps: ConfigurationTablePropsType
  expirationDate: string
  setExpirationDate: (expiration: string) => void
  emailNotification: string | null
  setEmailNotification: (value: string | null) => void
  backfill: boolean
  setBackfill: (value: boolean) => void
}

export function ConfigureTimings(props: ConfigureTimingsProps) {
  const {
    savedConfig,
    tableProps,
    expirationDate,
    setExpirationDate,
    emailNotification,
    setEmailNotification,
    backfill,
    setBackfill,
  } = props
  return (
    <Table>
      <Table.Thead>
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Th colSpan={2} px={0}>
            Updates and expiration
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody px="sm">
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Td {...tableProps.firstColumn}>Saved search duration</Table.Td>
          <Table.Td {...tableProps.secondColumn}>
            <ConfigureExpiration
              initialExpirationDate={savedConfig?.expiration}
              expirationDate={expirationDate}
              setExpirationDate={setExpirationDate}
            />
          </Table.Td>
        </Table.Tr>
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Td {...tableProps.firstColumn}>Email notifications</Table.Td>
          <Table.Td {...tableProps.secondColumn}>
            <ConfigureEmailNotifications
              emailNotification={emailNotification}
              setEmailNotification={setEmailNotification}
            />
          </Table.Td>
        </Table.Tr>
        <Table.Tr style={tableProps.rowStyle}>
          <Table.Td {...tableProps.firstColumn}>
            <Group gap={8}>
              <Checkbox
                size="xs"
                checked={backfill}
                onChange={() => void setBackfill(!backfill)}
              />
              Backfill with historic data
            </Group>
          </Table.Td>
          <Table.Td {...tableProps.secondColumn}>
            <Timeframe />
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>
  )
}
