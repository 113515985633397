import { useAddToCollection } from '@/hooks/collection/use-add-to-collection'
import { useQueryClient } from '@tanstack/react-query'

export const useRefreshCollections = () => {
  const queryClient = useQueryClient()
  const { refresh } = useAddToCollection()

  return () => {
    void queryClient.invalidateQueries({
      queryKey: ['collections'],
    })
    refresh()
  }
}
