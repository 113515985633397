import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { useHighestRiskType } from '@/features/BentoResults/hooks/use-highest-risk-type'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { RESULT_CATEGORIES, SCORE_COLORS } from '@/utils/constants'
import { RISK_ICONS } from '@/utils/svg-icons'
import type { HazardRiskResult } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.HAZARD_RISK
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface HazardRiskBentoCardProps extends CardProps {
  result: HazardRiskResult
  size: string
}

export const HazardRiskBentoCard = ({
  result,
  ...props
}: HazardRiskBentoCardProps) => {
  const { risks } = result.properties
  const topRiskKey = useHighestRiskType(result)
  const highestRisk = risks[topRiskKey] as { title: string; score: string }
  return (
    <BentoCard
      {...props}
      result={result}
      hideActions={actionsToHide}
      resultCategory={category}
      svgIcon={RISK_ICONS[topRiskKey]}
      content={{
        heading: 'Risks',
        subHeading: highestRisk.title,
        color: SCORE_COLORS[highestRisk.score],
      }}
      totalResults={Object.keys(risks).length}
    />
  )
}

export default HazardRiskBentoCard
