import { useCallback, useRef } from 'react'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { graphWs } from '@/utils/constants.ts'
import type { SocketMessage } from '@/utils/types/message-types.ts'

export const useDantiWSConnection = () => {
  const socket = useRef<WebSocket | null>(null)
  const { user } = useDantiAuth()

  const sendMessage = useCallback(
    async (action: string, message: Record<string, any>) => {
      if (socket.current == undefined) {
        console.log('socket is undefined')
      } else {
        const messageAction = { ...message, action }
        console.debug('Sending message over WebSocket', messageAction)
        const readyState = socket.current?.readyState
        if (readyState === WebSocket.CONNECTING) {
          // if connecting, wait for connection to be established
          await new Promise<void>((accept, reject) => {
            if (socket.current) {
              socket.current.addEventListener('open', () => accept())
              socket.current.addEventListener('error', () => reject())
            }
          })
          socket.current.send(JSON.stringify(messageAction))
        } else if (readyState === socket.current?.OPEN) {
          socket.current.send(JSON.stringify(messageAction))
        }
      }
    },
    [],
  )

  const createWebsocket = useCallback(
    ({
      onMessage,
      onOpen,
    }: {
      onMessage: (_: SocketMessage) => void
      onOpen: () => void
    }) => {
      if (!user?.access_token) {
        return
      }
      const url = `${graphWs}?token=${user.access_token}`
      socket.current = new WebSocket(url)
      socket.current?.addEventListener('open', () => {
        console.time('socket open time')
        console.log('websocket opened')
        onOpen()
      })
      socket.current?.addEventListener('error', (event) => {
        console.log('websocket error', event)
        console.timeEnd('socket open time')
      })
      socket.current?.addEventListener('close', () => {
        console.log('websocket closed')
        console.timeEnd('socket open time')
      })
      socket.current?.addEventListener('message', (event: { data: string }) => {
        const data = JSON.parse(event.data) as SocketMessage
        onMessage(data)
      })
    },
    [user?.access_token],
  )

  const readyState = socket.current?.readyState

  return {
    sendMessage,
    createWebsocket,
    closeSocket: () => socket.current?.close(),
    isOpen: readyState === WebSocket.OPEN,
    isConnecting: readyState === WebSocket.CONNECTING,
    hasSocket: socket.current !== undefined,
  }
}
