import type { FeatureCollection } from 'geojson'
import type { GeoJSONSource, Map } from 'mapbox-gl'
import type { MutableRefObject } from 'react'

type AddSourcesProps = {
  map: MutableRefObject<Map | null>
  newsData?: FeatureCollection | null
  fireData?: FeatureCollection | null
  shipData?: FeatureCollection | null
  socialData?: FeatureCollection | null
}

export const addPointSources: (props: AddSourcesProps) => void = ({
  map,
  newsData,
  fireData,
  shipData,
  socialData,
}) => {
  if (newsData) {
    const newsDataSource = map.current?.getSource('news-point') as GeoJSONSource
    if (newsDataSource) {
      newsDataSource.setData(newsData)
    } else {
      map.current?.addSource('news-point', {
        type: 'geojson',
        data: newsData,
      })
    }
  }

  if (fireData) {
    const fireDataSource = map.current?.getSource('fire-point') as GeoJSONSource
    if (fireDataSource) {
      fireDataSource.setData(fireData)
    } else {
      map.current?.addSource('fire-point', {
        type: 'geojson',
        data: fireData,
      })
    }
  }

  if (shipData) {
    const shipDataSource = map.current?.getSource('ship-point') as GeoJSONSource
    if (shipDataSource) {
      shipDataSource.setData(shipData)
    } else {
      map.current?.addSource('ship-point', {
        type: 'geojson',
        data: shipData,
      })
    }
  }

  if (socialData) {
    const socialDataSource = map.current?.getSource(
      'social-point',
    ) as GeoJSONSource
    if (socialDataSource) {
      socialDataSource.setData(socialData)
    } else {
      map.current?.addSource('social-point', {
        type: 'geojson',
        data: socialData,
      })
    }
  }
}
