import React from 'react'
import { useOrientationsFilter, useSetFilters } from '@/stores/filters-store'
import { PieChart } from 'react-minimal-pie-chart'

const allSegments = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']

function segmentsToRanges(segments: string[]) {
  if (segments.length === 8) {
    return []
  }

  return segments.map((segment) => {
    const index = allSegments.indexOf(segment)
    if (index === 0) {
      return { start: 337.5, end: 22.5 }
    }
    return { start: index * 45 - 22.5, end: index * 45 + 22.5 }
  })
}

function orientationStateToSegments(
  orientations: { start: number; end: number }[],
) {
  if (orientations.length === 0) {
    return allSegments
  }

  const selectedSegments: string[] = orientations.map((range) => {
    switch (range.start) {
      case 337.5:
      case 0:
        return 'N'
      case 22.5:
        return 'NE'
      case 67.5:
        return 'E'
      case 112.5:
        return 'SE'
      case 157.5:
        return 'S'
      case 202.5:
        return 'SW'
      case 247.5:
        return 'W'
      case 292.5:
        return 'NW'
      default:
        return 'N'
    }
  })

  return selectedSegments
}

export function Orientation() {
  const orientations = useOrientationsFilter()
  const [selectedSegments, setSelectedSegments] = React.useState(
    orientationStateToSegments(orientations),
  )
  const setFilters = useSetFilters()

  React.useEffect(() => {
    setSelectedSegments(orientationStateToSegments(orientations))
  }, [orientations])

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <PieChart
        style={{ paddingTop: '0.75rem', height: '80%', marginBottom: '-2rem' }}
        label={({ dataEntry }) => dataEntry.title}
        labelStyle={{
          pointerEvents: 'none',
          fontSize: '6px',
          fontFamily: 'Roboto, sans-serif',
          fill: '#fff',
        }}
        data={allSegments.map((direction) => ({
          title: direction,
          value: 12.5,
          color: selectedSegments.includes(direction) ? '#1f5cb9' : '#6586B9',
        }))}
        labelPosition={60}
        // This is part of the designs that is commented out below.
        // labelPosition={72}
        // lineWidth={55}
        segmentsShift={2}
        radius={48}
        startAngle={-112.5}
        onClick={(_, index) => {
          const clickedSegment = allSegments[index]
          const newSelection = selectedSegments.includes(clickedSegment)
            ? selectedSegments.filter((x) => x !== clickedSegment)
            : [...selectedSegments, clickedSegment]
          setSelectedSegments(newSelection)
          setFilters({ orientations: segmentsToRanges(newSelection) })
        }}
      />
      {/* Coming Soon! This is in the designs but I'm not sure what it does yet.
      <div
        style={{
          backgroundColor: '#666',
          borderRadius: '50%',
          position: 'absolute',
          bottom: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(37%)',
          aspectRatio: '1/1',
          height: '35%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        All
      </div>*/}
    </div>
  )
}
