import { useNavigate } from 'react-router-dom'
import { Button, Group } from '@mantine/core'
import { DantiWordMark } from '@/components/lib/DantiWordMark'
import { useClearAll } from '@/hooks/use-clear-all'
import { useDantiAuth } from '@/hooks/use-danti-auth'

export function HeaderUnauthenticated() {
  const clearAll = useClearAll()
  const navigate = useNavigate()
  const { logIn } = useDantiAuth()

  return (
    <Group justify="space-between" py="0.25rem" w="100%">
      <DantiWordMark
        height={29}
        style={{
          marginRight: '1rem',
          cursor: 'pointer',
        }}
        onClick={() => {
          clearAll()
          navigate('/')
        }}
        color="white"
      />
      <Button variant="primary" onClick={logIn}>
        Log In
      </Button>
    </Group>
  )
}
