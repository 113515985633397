import { type MutableRefObject, useCallback, useEffect } from 'react'
import {
  makeImageryResultsBoundingBox,
  makePolygonBoundingBox,
} from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useMapBbox } from '@/stores/map-store'
import { useCurrentQueryFilters } from '@/stores/queries-store'
import { useCategorizedResults } from '@/stores/results-store'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import type mapboxgl from 'mapbox-gl'

export const useInitialBehavior = (
  map: MutableRefObject<mapboxgl.Map | null>,
  item?: ResultWithId,
) => {
  const categorized = useCategorizedResults()
  const currentQueryFilters = useCurrentQueryFilters()
  const mapBbox = useMapBbox()

  const fitBoundingBox = useCallback(
    (bbox: mapboxgl.LngLatBoundsLike) => {
      map.current?.fitBounds(bbox, {})
    },
    [map],
  )

  const flyToPointCenter = useCallback(
    (result: ResultWithId) => {
      const coordinates = result.geometry.coordinates as number[]
      map.current?.flyTo({ center: [coordinates[0], coordinates[1]], zoom: 12 })
    },
    [map],
  )

  useEffect(() => {
    if (item) {
      switch (item.geometry.type) {
        case 'Polygon': {
          const bbox = makePolygonBoundingBox(item)
          fitBoundingBox(bbox as mapboxgl.LngLatBoundsLike)
          break
        }
        case 'Point':
          flyToPointCenter(item)
          break
        case 'MultiPolygon': {
          const bbox = makePolygonBoundingBox(item)
          fitBoundingBox(bbox as mapboxgl.LngLatBoundsLike)
          break
        }
        default:
          console.error('Unsupported geometry type')
          return
      }
    } else {
      if (categorized.imageResults.length > 0) {
        const bbox = makeImageryResultsBoundingBox(categorized.imageResults)
        fitBoundingBox(bbox as mapboxgl.LngLatBoundsLike)
      }
      if (categorized.dataResults.length > 0) {
        flyToPointCenter(categorized.dataResults[0])
      }
    }
  }, [
    categorized.dataResults,
    categorized.imageResults,
    currentQueryFilters,
    fitBoundingBox,
    flyToPointCenter,
    item,
    map,
    mapBbox,
  ])
}
