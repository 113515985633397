/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { TerrapinLocation } from '@/hooks/use-terrapin'
import { check } from '@placemarkio/check-geojson'

export enum TerrapinLocationType {
  latLong = 'latLong',
  polygon = 'polygon',
  mgrs = 'mgrs',
}

export interface TerrapinLocationCsvRecord {
  location: string
  type: string
  name: string
}

export const getLocationType = ({ filters }: TerrapinLocation) =>
  filters.find((x) => x.type === 'LOCATIONTYPE')?.value as TerrapinLocationType

export const getLocationValue = ({ filters }: TerrapinLocation) =>
  filters.find((x) => x.type === 'LOCATION')?.value

interface GeoJsonHint {
  message: string
}

// Valid Format Example: [37.96914313143725, 48.6319126435765]
function isValidPoint(point: number[]): string | undefined {
  if (point.length !== 2) {
    return 'Point requires latitude and longitude'
  }

  const invalidNumber = point.filter((x) => Number.isNaN(x))

  if (invalidNumber.length > 0) {
    return 'One of the coordinates is not a number'
  }

  const [latitude, longitude] = point

  if (latitude < -90 || latitude > 90) {
    return 'Invalid latitude'
  }

  if (longitude < -180 || longitude > 180) {
    return 'Invalid longitude'
  }
}

// Valid Format Example:
function isValidLatLng(value: string): string | undefined {
  try {
    const coordinates = JSON.parse(value) as number[]

    try {
      check(
        JSON.stringify({
          type: 'Point',
          coordinates,
        }),
      )
    } catch (error) {
      const issues = (error as any).issues as GeoJsonHint[]
      if (issues && issues.length > 0) {
        return issues[0].message
      }
    }

    return isValidPoint(coordinates)
  } catch {
    return 'Invalid format'
  }
}

function isValidPolygon(value: string): string | undefined {
  try {
    const polygon = JSON.parse(value) as number[][][]

    try {
      check(
        JSON.stringify({
          type: 'Polygon',
          coordinates: polygon,
        }),
      )
    } catch (error) {
      const issues = (error as any).issues as GeoJsonHint[]
      if (issues && issues.length > 0) {
        return issues[0].message
      }
    }

    const invalidPoints = polygon[0]
      .map(isValidPoint)
      .filter((x) => x !== undefined)

    if (invalidPoints.length > 0) {
      return invalidPoints[0]
    }
  } catch {
    return 'Invalid format'
  }
}

// Valid Format Example: 33TWN00070000
function isValidMGRS(mgrs: string): string | undefined {
  // Check the overall length of the MGRS string (should be between 7 and 15 characters)
  if (mgrs.length < 7 || mgrs.length > 15) {
    return 'Invalid length'
  }

  // Extract different parts of the MGRS string
  const zoneNumber = Number.parseInt(mgrs.slice(0, 2), 10)
  const latitudeBand = mgrs.charAt(2)
  const gridSquare = mgrs.slice(3, 5)
  const coordinates = mgrs.slice(5)

  // Validate the zone number (should be between 01 and 60)
  if (Number.isNaN(zoneNumber) || zoneNumber < 1 || zoneNumber > 60) {
    return 'Invalid zone number'
  }

  // Validate the latitude band (should be a letter between C-X excluding I and O)
  if (!/^[C-HJ-NP-X]$/.test(latitudeBand)) {
    return 'Invalid latitude band'
  }

  // Validate the grid square (should be two letters)
  if (!/^[A-Z]{2}$/.test(gridSquare)) {
    return 'Invalid grid square'
  }

  // Validate the coordinates (should be an even number of digits, max 10 digits)
  if (!/^\d{2}(\d{2}){0,4}$/.test(coordinates)) {
    return 'Invalid coordinates'
  }
}

export const TerrapinLocationValidator = {
  [TerrapinLocationType.latLong]: isValidLatLng,
  [TerrapinLocationType.polygon]: isValidPolygon,
  [TerrapinLocationType.mgrs]: isValidMGRS,
}

// Returns an error message (string) if location is not valid
export const validateLocation = (
  location: TerrapinLocation,
): string | undefined => {
  const type = getLocationType(location)
  const value = getLocationValue(location)

  if (!type || !value) {
    return 'Location type / value not found'
  }

  switch (type) {
    case TerrapinLocationType.latLong:
      return isValidLatLng(value)
    case TerrapinLocationType.polygon:
      return isValidPolygon(value)
    case TerrapinLocationType.mgrs:
      return isValidMGRS(value)
  }
}
