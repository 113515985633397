import { providerObject } from '@/components/filters/providers/utils'
import { graphql } from '@/gql'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useProvidersFilter, useSetProviders } from '@/stores/filters-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { useQuery } from '@apollo/client'

export const SourcesByUserQuery = graphql(`
  query SourcesByUser {
    me {
      id
      sources {
        nodes {
          provider
        }
      }
    }
  }
`)

export function useProviders() {
  const shouldSupportDynamicProviders = useFeatureFlag(
    FEATURE_FLAG_VALUES.dynamicProviders,
  )
  const selectedProviders = useProvidersFilter()
  const setProviders = useSetProviders()
  const { data } = useQuery(SourcesByUserQuery, {
    skip: !shouldSupportDynamicProviders,
  })
  const providersList =
    data?.me?.sources.nodes.map((source) => source.provider) || []
  const providers = providerObject(providersList)

  const updateProviders = (selectedProviders: string[]) => {
    const providersArray = selectedProviders.map((provider) => {
      const providerKey = Object.keys(providers).find((key) => key === provider)
      return providerKey || ''
    })
    setProviders(providersArray)
  }

  return {
    providers,
    updateProviders,
    selectedProviders,
  }
}
