import {
  formatDatetimeWithWordMonths as formatDatetime,
  fromNow,
} from '@/utils/format-datetime'
import type { ShipResult } from '@/utils/types/result-types.ts'
import { wordBreak } from '@/utils/ui-helpers'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { startCase } from 'lodash'
import { getShipDetails } from './ship'

const formatString = (value?: string | null) =>
  value ? wordBreak(startCase(value)) : undefined

export const getShipDetailsDisplayFields = (ship: ShipResult) => {
  const details = getShipDetails(ship)
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
  const regionName = details.flag ? regionNames.of(details.flag) : 'Unknown'
  const unicodeFlagIcon = details.flag ? getUnicodeFlagIcon(details.flag) : ''
  return {
    name: startCase(details.name),
    regionName,
    callSign: details.callSign,
    imo: details.imo,
    mmsi: details.mmsi,
    shipType: formatString(details.shipType),
    unicodeFlagIcon,
  }
}

export const getShipDataFields = (ship: ShipResult) => {
  const details = getShipDetails(ship)
  return [
    { title: 'Location', value: `${details.latitude} ${details.longitude}` },
    {
      title: 'Predicted ETA',
      value: details.eta ? formatDatetime(details.eta) : '--',
    },
    { title: 'destination', value: details.destination || '--' },
    {
      title: 'Navigational Status',
      value: details.navigationalStatus
        ? formatString(details.navigationalStatus)
        : '--',
    },
    {
      title: 'speed',
      value: details.draught ? `${details.draught} kts` : '--',
    },
    {
      title: 'Last Update Timestamp',
      value: details.lastUpdateTimestamp
        ? fromNow(details.lastUpdateTimestamp)
        : '--',
    },
    {
      title: 'type',
      value: details.shipType ? formatString(details.shipType) : '--',
    },
  ]
}

export const getShipDetailCardProps = (ship: ShipResult) => {
  const details = getShipDetails(ship)
  return {
    geometry: ship.geometry,
    title: formatString(details.name) || 'Unknown Vessel',
    subtitle: details.shipType ? formatString(details.shipType) : '--',
    datetime: ship.authoredOn,
    longitude: details.longitude,
    latitude: details.latitude,
  }
}
