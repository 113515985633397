import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from 'react-router-dom'
import MainLayout from '@/components/layout/MainLayout'
import {
  Authenticated,
  DantiRoute,
  RequireSubscription,
} from '@/features/AppRouting/components'
import {
  APP_ROUTES,
  ROOT_ROUTES,
  ROUTES_BY_PATH,
} from '@/features/AppRouting/constants'
import { AccountRoutes } from '@/pages/Account/routes'
import { ROUTES } from '@/utils/constants'
import { wrapCreateBrowserRouter } from '@sentry/react'

export const renderRoute = (path: string) => {
  const route = ROUTES_BY_PATH[path]
  return (
    <Route
      key={path}
      data-testid={path}
      path={path}
      element={<DantiRoute {...route} />}
    />
  )
}

export const router = wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <Route data-testid="main-layout-route" element={<MainLayout />}>
      {ROOT_ROUTES.map(renderRoute)}
      <Route
        data-testid="authenticated-routes"
        element={
          <Authenticated>
            <RequireSubscription>
              <Outlet />
            </RequireSubscription>
          </Authenticated>
        }
      >
        {APP_ROUTES.map(renderRoute)}
        {AccountRoutes}
      </Route>
      <Route
        data-testid="authenticated-no-subscription-routes"
        element={
          <Authenticated>
            <Outlet />
          </Authenticated>
        }
      >
        {renderRoute(ROUTES.planSelection)}
      </Route>
    </Route>,
  ),
)
