import { UPLOADED_FEATURE_ID } from '@/stores/map-draw-store/constants'
import { createStore } from '@/utils/create-store.ts'
import type MapboxDraw from '@mapbox/mapbox-gl-draw'
import type { Feature, Polygon } from 'geojson'
import type { Marker } from 'mapbox-gl'

export interface MapDrawValues {
  drawController: MapboxDraw | null
  currentSearchPolygon: Polygon | null
  lastSearchPolygon: Polygon | null
  uploadedFeature: Feature | null
  searchHereMarker: Marker | null
  isDrawing: boolean
}
export interface MapDrawActions {
  setDrawController: (drawController: MapboxDraw) => void
  setIsDrawing: (isDrawing: boolean) => void
  setCurrentSearchPolygon: (searchPolygon: Polygon | null) => void
  setLastSearchPolygon: (lastSearchPolygon: Polygon | null) => void
  setSearchHereMarker: (marker: Marker | null) => void
  setUploadedFeature: (feature: Feature) => void
}
export interface MapDrawState extends MapDrawValues, MapDrawActions {}

export const initialState = {
  drawController: null,
  currentSearchPolygon: null,
  lastSearchPolygon: null,
  uploadedFeature: null,
  searchHereMarker: null,
  isDrawing: false,
}

export const useMapDrawStore = createStore<MapDrawState>({
  initialState,
  actions: (set) => ({
    setCurrentSearchPolygon: (currentSearchPolygon: Polygon | null) => {
      set({ currentSearchPolygon })
    },
    setDrawController: (drawController: MapboxDraw) => {
      set({ drawController, isDrawing: false })
    },
    setIsDrawing: (isDrawing: boolean) => set({ isDrawing }),
    setLastSearchPolygon: (lastSearchPolygon) => set({ lastSearchPolygon }),

    setSearchHereMarker: (searchHereMarker: Marker | null) =>
      set({ searchHereMarker }),
    setUploadedFeature: (feature: Feature) => {
      set({
        uploadedFeature: { ...feature, id: UPLOADED_FEATURE_ID },
        currentSearchPolygon: feature.geometry as Polygon,
      })
    },
  }),
})
