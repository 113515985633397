import { type LinkProps, NavLink } from 'react-router-dom'
import { Group, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import type { SymbolName } from '@/utils/types/material-icons'
import { classes } from '@/utils/ui-helpers.tsx'

import styles from './header.module.css'

export function HeaderLink({
  to,
  title,
  icon,
  ...props
}: LinkProps & { icon: SymbolName; title: string }) {
  return (
    <NavLink
      to={to}
      onClick={() =>
        captureEvent(POSTHOG_EVENTS.HEADER.CLICKED, { headerItem: title })
      }
      title={title}
      {...props}
      className={({ isActive }) =>
        classes(styles['link'], isActive && styles['active'])
      }
    >
      <Group gap={5}>
        <Icon name={icon} style={{ color: 'white' }} />
        <Text>{title}</Text>
      </Group>
    </NavLink>
  )
}
