import { Box, Radio, Stack, Text } from '@mantine/core'
import { useAddToCollection } from '@/hooks/collection/use-add-to-collection'
import type React from 'react'

export interface CollectionChooserProps {
  collections: { id: string; name: string }[]
  selectedCollectionId?: string
  setSelectedCollectionId?: (id: string) => void
  size?: string | number
}

export function CollectionChooser({
  collections,
  selectedCollectionId,
  setSelectedCollectionId,
  size,
}: CollectionChooserProps) {
  const { isLoading, hasMore, loadMore } = useAddToCollection()
  const handleScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    const bottom =
      Math.floor(
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop,
      ) === event.currentTarget.clientHeight

    if (bottom && !isLoading && hasMore) {
      loadMore().catch((error) => {
        console.error(error)
      })
    }
  }

  return (
    collections.length > 0 && (
      <Box>
        <Text fz="sm" fw="bold">
          Choose a collection
        </Text>
        <Box
          style={{
            border: '1px solid var(--mantine-color-gray-3)',
            borderRadius: 'var(--mantine-radius-sm)',
            overflowY: 'auto',
          }}
          mah={size ?? 150}
          px="sm"
          py="xs"
          onScroll={handleScroll}
        >
          <Radio.Group
            value={selectedCollectionId}
            onChange={setSelectedCollectionId}
          >
            <Stack gap="sm">
              {collections?.map(({ id, name }) => (
                <Radio
                  key={id}
                  value={id}
                  label={name}
                  data-cy="collection-option"
                />
              ))}
            </Stack>
          </Radio.Group>
        </Box>
      </Box>
    )
  )
}
