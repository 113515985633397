import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { ImageReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { useResultNavigation } from '@/features/SingleObjectView/hooks'
import {
  formatImageQuality as formatQuality,
  getImageMetaDetails,
} from '@/stores/results-store'
import { RESULT_CATEGORIES, ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { isVexcel } from '@/utils/types/result-type-checkers'
import type { ImageryResult, ResultWithId } from '@/utils/types/result-types'
import { ImageDetailsModal } from './ImageDetailsModal'

const category = RESULT_CATEGORIES.IMAGE
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function ImageObjectView({ item: rawItem }: { item: ResultWithId }) {
  const item = rawItem as ImageryResult
  const { toPrevious, toNext, selectItem, items } =
    useResultNavigation<ImageryResult>(item)

  const metaDetails = getImageMetaDetails(item)

  return (
    <SingleObjectViewTemplate<ImageryResult, ImageryResult>
      {...{
        item,
        hideActions,
        hero: {
          ...(isVexcel(item) ? { vexcelImage: item } : { mapImage: item }),
          toNext,
          toPrevious,
          metaDetails: [
            formatDatetime(item.authoredOn),
            item.properties._dSource || 'Unknown Source',
            formatQuality(item.properties._dQuality) || '',
          ],
          metaAction: (
            <ImageDetailsModal details={metaDetails} geometry={item.geometry} />
          ),
        },
        content: {},
        carousel: {
          title: 'Related Images',
          items,
          selectItem,
          renderCard: (image: ImageryResult) => (
            <ImageReferenceCard image={image} />
          ),
          categoryUrl: ROUTES.images,
          categoryLabel: 'All Images',
        },
      }}
    />
  )
}
