import { Divider, Modal, NavLink, Stack, Text } from '@mantine/core'
import { useCollectionItems } from '@/api/collection-items'
import { handleCollectionLocationDownload } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import { openDeleteCollectionModal } from '@/components/modals/openers'
import type { Collection } from '@/utils/types/collection-types'

export interface CollectionListItemModalMenuProps {
  collection: Collection
  onClose: () => void
}

export function CollectionListItemModalMenu({
  collection,
  onClose,
}: CollectionListItemModalMenuProps) {
  const { id, name } = collection
  const { totalCount, filter } = useCollectionItems(id)

  return (
    <Modal.Root opened={true} onClose={onClose} centered>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Text c="#000000DE" size="18px" style={{ fontWeight: 500 }}>
              {name}
            </Text>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack gap={0}>
            <Divider mb="md" />
            <Text c="#00000099" size="xs" style={{ letterSpacing: 1 }}>
              MANAGE COLLECTION
            </Text>
            <NavLink
              leftSection={<Icon name="delete" />}
              label="Delete Collection"
              px={0}
              pb={0}
              c="#D32F2F"
              onClick={(event) => {
                event.stopPropagation()
                openDeleteCollectionModal(collection.id)
              }}
            />

            <Divider my="sm" />
            <Text c="#00000099" size="xs" style={{ letterSpacing: 1 }}>
              ACTIONS
            </Text>

            <NavLink
              leftSection={<Icon name="download" />}
              label="Download Location Data"
              px={0}
              c="#000000DE"
              onClick={(event) => {
                event.stopPropagation()
                void handleCollectionLocationDownload({
                  collectionId: collection.id,
                  collectionName: collection.name,
                  totalCount,
                  filter,
                })
              }}
            />
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
