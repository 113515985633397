import { Box, Table } from '@mantine/core'
import { CameraAngleSelect } from '@/components/filters/CameraAngleSelect'
import { CloudCoverSelect } from '@/components/filters/CloudCoverSelect'
import { OrientationSelect } from '@/components/filters/OrientationSelect'
import { QualitySelect } from '@/components/filters/QualitySelect'
import { SensorsSelect } from '@/components/filters/SensorsSelect'
import type { ConfigurationTablePropsType } from './use-configure-saved-search'

const settingsComponentMap = {
  'Sensor type': SensorsSelect,
  'Camera Angle': CameraAngleSelect,
  'Cloud Cover': CloudCoverSelect,
  Quality: QualitySelect,
  Orientation: OrientationSelect,
}

export function ConfigureImageOptions({
  tableProps,
}: {
  tableProps: ConfigurationTablePropsType
}) {
  return (
    <Box
      p="sm"
      pt="xs"
      style={{
        border: '1px solid var(--mantine-color-gray-3)',
        borderRadius: 'var(--mantine-radius-sm)',
      }}
    >
      <Table>
        <Table.Thead>
          <Table.Tr style={tableProps.rowStyle}>
            <Table.Th colSpan={2} px={0}>
              Image options
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody px="sm" style={tableProps.rowStyle}>
          {Object.entries(settingsComponentMap).map(
            ([filterName, Component]) => (
              <Table.Tr key={filterName}>
                <Table.Td {...tableProps.firstColumn}>{filterName}</Table.Td>
                <Table.Td {...tableProps.secondColumn}>
                  <Component />
                </Table.Td>
              </Table.Tr>
            ),
          )}
        </Table.Tbody>
      </Table>
    </Box>
  )
}
