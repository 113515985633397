import * as React from 'react'
import { useCurrentQuery, useCurrentQueryStart } from '@/stores/queries-store'
import { useCurrentQueryId } from '@/stores/results-store'

export function useOverviewUrl() {
  const currentQuery = useCurrentQuery()
  const currentQueryStart = useCurrentQueryStart()
  const currentQueryId = useCurrentQueryId()

  const [overviewUrl, setOverviewUrl] = React.useState('/')

  React.useEffect(() => {
    if (currentQuery || currentQueryStart || currentQueryId) {
      setOverviewUrl(
        `/overview?${new URLSearchParams({
          search: currentQuery,
        }).toString()}`,
      )
    } else {
      setOverviewUrl('/')
    }
  }, [currentQuery, currentQueryStart, currentQueryId])
  return overviewUrl
}
