import { type MutableRefObject, useEffect } from 'react'
import { usePrevious } from '@mantine/hooks'
import { ZOOM_BUFFER_FACTOR } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useOrthoImagerySources } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import { useZoomOrthoLayerGroupId } from '@/stores/map-store'
import * as turf from '@turf/turf'
import type mapboxgl from 'mapbox-gl'

export const useZoomToNewOrtholayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const focusedOrthoLayerGroupId = useZoomOrthoLayerGroupId()
  const previousFocusedOrthoLayerGroupId = usePrevious(focusedOrthoLayerGroupId)
  const { orthoLayerGroups } = useOrthoImagerySources()

  useEffect(() => {
    if (previousFocusedOrthoLayerGroupId !== focusedOrthoLayerGroupId) {
      const olg = orthoLayerGroups.find(
        (olg) => olg.id === focusedOrthoLayerGroupId,
      )
      if (olg) {
        const bbox = turf.bbox(
          turf.transformScale(olg.geometry, ZOOM_BUFFER_FACTOR, {
            origin: 'centroid',
          }),
        ) as mapboxgl.LngLatBoundsLike
        map.current?.fitBounds(bbox)
      }
    }
  }, [
    map,
    orthoLayerGroups,
    focusedOrthoLayerGroupId,
    previousFocusedOrthoLayerGroupId,
  ])
}
