import { useDrawController, useSetIsDrawing } from './hooks'
import { useClearSearchPolygon } from './use-clear'

export const useStartDrawingPolygon = () => {
  const clear = useClearSearchPolygon()
  const setIsDrawing = useSetIsDrawing()
  const drawController = useDrawController()
  return () => {
    clear()
    setIsDrawing(true)
    drawController?.changeMode('draw_polygon')
  }
}
export const useStopDrawingPolygon = () => {
  const setIsDrawing = useSetIsDrawing()
  const drawController = useDrawController()
  return () => {
    setIsDrawing(false)
    drawController?.changeMode('simple_select', { featureIds: [] })
  }
}
