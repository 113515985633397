import React, {
  type MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { addInteractionListeners } from '@/components/lib/map-search/hooks/util/add-interaction-listeners.ts'
import { addLayers } from '@/components/lib/map-search/hooks/util/add-layers.ts'
import { addSources } from '@/components/lib/map-search/hooks/util/add-sources.ts'
import type { HexGrid } from '@/components/lib/map-search/hooks/util/make-bare-hex-grid.ts'
import { makePolygonsIntoPoint } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { useOrthoImagerySources } from '@/hooks/ortho-imagery/use-ortho-imagery-sources'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import {
  useSetHoveredExtentIds,
  useSetHoveredHexGridId,
  useSetHoveredLayerId,
  useSetSelectedExtentIds,
  useToggleOrthoLayerGroupId,
  useToggleSelectedHexGridId,
} from '@/stores/map-store'
import {
  isRegridResult,
  type RegridResult,
} from '@/utils/types/result-types.ts'
import * as turf from '@turf/turf'
import type mapboxgl from 'mapbox-gl'

type UseStyleLoadedCallbackProps = {
  map: MutableRefObject<mapboxgl.Map | null>
  hexGrid?: HexGrid
  showExtents: boolean
  showGrid: boolean
}

export const useStyleLoadedCallback: (
  props: UseStyleLoadedCallbackProps,
) => void = ({ map, hexGrid, showExtents, showGrid }) => {
  const toggleOrthoLayerGroupId = useToggleOrthoLayerGroupId()
  const toggleSelectedHexGridId = useToggleSelectedHexGridId()
  const setHoveredHexGridId = useSetHoveredHexGridId()
  const setHoveredExtentIds = useSetHoveredExtentIds()
  const setSelectedExtentIds = useSetSelectedExtentIds()
  const setHoveredLayerId = useSetHoveredLayerId()

  const imageResults = usePostfilterImageResults()
  const propertyResults =
    useTimelineFilteredResults().categorized.propertyResults
  const regridResults: RegridResult[] = propertyResults.filter(isRegridResult)

  const { orthoLayerGroups } = useOrthoImagerySources()

  const idToGroupKey = useMemo(
    () =>
      orthoLayerGroups.reduce(
        (accumulator, group) => {
          group.layers.forEach((layer) => {
            accumulator[layer.imageResult.documentId] = layer.groupKey
          })
          return accumulator
        },
        {} as Record<string, string>,
      ),
    [orthoLayerGroups],
  )

  const regridCollection = makePolygonsIntoPoint(regridResults)

  const extentsData = turf.featureCollection(
    [...imageResults, ...regridResults]
      .filter((r) => r.geometry.type === 'Polygon')
      .map((r) =>
        turf.feature(r.geometry, {
          documentId: r.id,
          groupKey: idToGroupKey[r.id],
        }),
      ),
  )

  useEffect(() => {
    if (map.current?.isStyleLoaded()) {
      addSources({
        map,
        hexGrid,
        extentsData,
        regridCollection,
      })
    }
  }, [
    extentsData,
    hexGrid,
    map,
    regridCollection,
    setHoveredExtentIds,
    setHoveredHexGridId,
    setHoveredLayerId,
    setSelectedExtentIds,
    toggleOrthoLayerGroupId,
    toggleSelectedHexGridId,
  ])

  const styleDataCallback = useCallback(() => {
    addSources({
      map,
      hexGrid,
      extentsData,
      regridCollection,
    })
    addLayers(map, showExtents, showGrid)
    addInteractionListeners({
      map,
      toggleSelectedHexGridId,
      setHoveredHexGridId,
      setSelectedExtentIds,
      setHoveredExtentIds,
      setHoveredLayerId,
      toggleOrthoLayerGroupId,
    })
  }, [
    extentsData,
    hexGrid,
    map,
    regridCollection,
    setHoveredExtentIds,
    setHoveredHexGridId,
    setHoveredLayerId,
    setSelectedExtentIds,
    showExtents,
    showGrid,
    toggleOrthoLayerGroupId,
    toggleSelectedHexGridId,
  ])

  React.useEffect(() => {
    map.current?.on('styledata', styleDataCallback)
  }, [map, styleDataCallback])
}
