import { formatGeocodedAddress } from '@/api/google-maps/format-geocoded-address'
import { geocodeAddress } from '@/api/google-maps/geocode-address'
import type { AutocompleteOption } from '@/api/google-maps/types'

export const separatorString = ' $#$#$# '

export async function resolveLocation(
  value: string,
  callback: (options?: AutocompleteOption) => void,
) {
  if (!value) {
    return
  }
  const results = await geocodeAddress(value)
  callback(results.length > 0 ? formatGeocodedAddress(results[0]) : undefined)
}
