import { em,Group } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { useSearch, useSearchInterface } from '@/features/Search/hooks'
import type { CSSProperties } from 'react'
import { SplitSearch } from './SplitSearch'
import { UnifiedSearch } from './UnifiedSearch'

interface SearchInputProps {
  placeholder?: string
  style?: CSSProperties
  className?: string
}

export function SearchBar({ placeholder, className }: SearchInputProps) {
  const { doTextSearch, doSplitSearch } = useSearch()
  const { handleInlineHotkeyPress, isSplit, searchRef } = useSearchInterface()
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`)

  return (
    <Group className={className}>
      {isSplit || isMobile ? (
        <SplitSearch
          ref={searchRef}
          {...{ doSplitSearch, handleInlineHotkeyPress }}
        />
      ) : (
        <UnifiedSearch
          ref={searchRef}
          {...{ doTextSearch, handleInlineHotkeyPress, placeholder }}
        />
      )}
    </Group>
  )
}

export default SearchBar
