export const AUTOCOMPLETE_TOOLTIP_PROPS = {
  color: 'red',
  position: 'top-start',
  withArrow: true,
} as const

export const AUTOCOMPLETE_LOCATION_PLACEHOLDER =
  'What location do you want to search?'
export const AUTOCOMPLETE_SUBJECT_PLACEHOLDER = 'What do you want to know?'

export const AUTOCOMPLETE_INPUT_PROPS = {
  radius: '1rem',
  size: 'md',
}

export enum SEARCH_STATUSES {
  NOT_STARTED = 'NOT_STARTED',
  STARTED_RECENTLY = 'STARTED_RECENTLY',
  STARTED_LONG_AGO = 'STARTED_LONG_AGO',
  EDS_IN_PROGRESS = 'EDS_IN_PROGRESS',
  NO_RESULTS = 'NO_RESULTS',
  READY = 'READY',
}

export enum SEARCH_STYLE {
  General = 'General',
  Location = 'Location',
}
