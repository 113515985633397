import React, { type PropsWithChildren } from 'react'
import { Box, Button, Collapse, Flex, Grid, Group, Text } from '@mantine/core'
import { CameraAngle } from '@/components/filters/CameraAngle'
import { CloudCover } from '@/components/filters/CloudCover'
import { Orientation } from '@/components/filters/Orientation'
import { Providers } from '@/components/filters/providers/Providers'
import { ProvidersStatic } from '@/components/filters/providers/ProvidersStatic'
import { Quality } from '@/components/filters/Quality'
import { Sensors } from '@/components/filters/Sensors'
import { Timeframe } from '@/components/filters/Timeframe'
import { useSearch } from '@/features/Search/hooks'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import {
  useIsFilterExpanded,
  useResetFilters,
  useToggleFilterPanel,
} from '@/stores/filters-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'

function FilterSubPanel({
  children,
  title,
  span,
  id,
}: PropsWithChildren<{ title: string; span: number; id: string }>) {
  return (
    <Grid.Col span={span} id={id}>
      <Box bg="#fff" p="sm" style={{ height: '100%' }}>
        <Text size="xs" mt="-0.5rem" mb="xs">
          {title}
        </Text>
        {children}
      </Box>
    </Grid.Col>
  )
}

export function FilterPanel() {
  const resetFilters = useResetFilters()
  const { doLastSearchWithFilters } = useSearch()
  const isOpen = useIsFilterExpanded()
  const toggle = useToggleFilterPanel()
  const showDynamicProviders = useFeatureFlag(
    FEATURE_FLAG_VALUES.dynamicProviders,
  )

  return (
    <Collapse in={isOpen}>
      <Box bg="gray.1" p="sm" c="gray">
        <Grid columns={4} gutter="sm" m={0}>
          <Grid.Col span={2}>
            <Grid columns={1} gutter="sm">
              <FilterSubPanel title="Timeframe" span={1} id="timeframe">
                <Timeframe />
              </FilterSubPanel>
              <FilterSubPanel title="Sensor Types" span={1} id="sensorTypes">
                <Sensors />
              </FilterSubPanel>
            </Grid>
          </Grid.Col>
          <FilterSubPanel title="Quality" span={1} id="quality">
            <Quality />
          </FilterSubPanel>
          <FilterSubPanel title="Orientation" span={1} id="orientation">
            <Orientation />
          </FilterSubPanel>
          <FilterSubPanel title="Providers" span={1} id="providers">
            {showDynamicProviders ? <Providers /> : <ProvidersStatic />}
          </FilterSubPanel>
          <FilterSubPanel title="Camera Angle" span={1} id="cameraAngle">
            <CameraAngle />
          </FilterSubPanel>
          <FilterSubPanel title="Cloud Cover" span={2} id="cloudCover">
            <CloudCover />
          </FilterSubPanel>
        </Grid>
        <Flex>
          <Group mt="xs" ml="auto">
            <Button
              size="xs"
              fw="normal"
              tt="uppercase"
              variant="subtle"
              color="gray"
              onClick={() => resetFilters()}
            >
              Clear
            </Button>
            <Button
              size="xs"
              fw="normal"
              tt="uppercase"
              variant="filled"
              onClick={() => {
                void doLastSearchWithFilters()
                toggle()
              }}
            >
              Apply
            </Button>
          </Group>
        </Flex>
      </Box>
    </Collapse>
  )
}

export const MemoFilterPanel = React.memo(FilterPanel)
