import { DantiNoResultMark } from '@/components/lib/DantiNoResultMark'
import { Icon } from '@/components/lib/Icon'
import { ResultImage, type ResultImageObject } from '@/features/ResultImage'
import type { SymbolName } from '@/utils/types/material-icons'
import type { ImageryResult } from '@/utils/types/result-types'
import { SingleImageMap } from './SingleImageMap'
import { StaticImageHero } from './StaticImageHero'

import styles from './hero.module.css'

export interface SOVHeroImageProps {
  staticImage?: string | null
  imageAlt?: string
  mapImage?: ImageryResult | null
  vexcelImage?: ImageryResult | null
  thumbnailImage?: ResultImageObject | null
  fallbackIcon?: SymbolName
}

export function SOVHeroImage({
  staticImage,
  imageAlt = 'Image Result',
  mapImage,
  vexcelImage,
  thumbnailImage,
  fallbackIcon,
}: SOVHeroImageProps) {
  if (staticImage !== undefined) {
    return staticImage ? (
      <StaticImageHero image={staticImage} title={imageAlt} />
    ) : (
      <div className={styles['no-image']}>
        <Icon
          className={styles['no-image-icon']}
          name={fallbackIcon || 'camera'}
          size="xl"
        />
      </div>
    )
  }
  if (thumbnailImage) {
    return (
      <ResultImage
        item={thumbnailImage}
        className={styles['thumbnail-image']}
        data-cy="single-image"
      />
    )
  }
  if (vexcelImage) {
    return vexcelImage.properties._dPreview ? (
      <ResultImage
        item={vexcelImage}
        style={{
          width: '100%',
          height: '600px',
        }}
        data-cy="single-image"
      />
    ) : (
      <DantiNoResultMark />
    )
  }
  if (mapImage) {
    return <SingleImageMap key={mapImage.id} item={mapImage} />
  }
  return (
    <div className={styles['no-image']}>
      <Icon className={styles['no-image-icon']} name="camera" size="xl" />
    </div>
  )
}
