import * as React from 'react'
import dayjs from 'dayjs'

const messages = {
  none: 'None',
}

export function formatTimeDisplay(timeIn24Hour: string | null) {
  if (timeIn24Hour) {
    const [hoursString, minutes] = timeIn24Hour.split(':')
    const hours = Number(hoursString)
    if (hours >= 12) {
      const formattedHours = hours === 12 ? hours : hours - 12

      return `Daily at ${formattedHours}:${minutes} PM`
    }
    return `Daily at ${hours || 12}:${minutes} AM`
  }
  return messages.none
}

export function formatLocalNotificationSchedule(
  emailNotification: string | null,
) {
  if (emailNotification) {
    // Format from 'DAILY(12:00)' to '12:00'
    const formattedFrequency = emailNotification.replace(
      /^.*(\d{2}:\d{2}).*$/,
      '$1',
    )
    const [hours, minutes] = formattedFrequency.split(':').map(Number)
    // Format from UTC (server) to local time
    const timeAsDate = dayjs.utc().hour(hours).minute(minutes)
    const localScheduledTime = timeAsDate.local().format('HH:mm')
    return localScheduledTime
  }
  return null
}

export interface ConfigureEmailNotificationsProps {
  emailNotification: string | null
  setEmailNotification: (value: string | null) => void
}

export interface UseConfigureEmailNotificationsHookResponse {
  messages: { none: string }
  formattedTime: string
  handleSelectNone: () => void
  timeInputRef: React.RefObject<HTMLInputElement> | null
  handleTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  showPlatformTimePicker: () => void
}

export function useConfigureEmailNotifications({
  emailNotification,
  setEmailNotification,
}: ConfigureEmailNotificationsProps): UseConfigureEmailNotificationsHookResponse {
  const timeInputRef = React.useRef<HTMLInputElement>(null)

  const showPlatformTimePicker = () => timeInputRef.current?.showPicker()

  const handleSelectNone = React.useCallback(() => {
    setEmailNotification(null)
  }, [setEmailNotification])

  const handleTimeChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setEmailNotification(value)
    },
    [setEmailNotification],
  )

  const formattedTime = formatTimeDisplay(emailNotification)

  return {
    messages,
    formattedTime,
    handleSelectNone,
    timeInputRef,
    handleTimeChange,
    showPlatformTimePicker,
  }
}
