import type { TimelineDoctypes } from '@/components/lib/results/results-timeline/results-tooltip-data.ts'

export const BAR_FILL_COLOR: {
  [key in TimelineDoctypes]: { normal: string; selected: string }
} = {
  IMAGE: { normal: '#42A5F5', selected: '#2b5ba9' },
  ORTHO: { normal: '#42A5F5', selected: '#2b5ba9' },
  DATA: { normal: '#7E57C2', selected: '#3c268e' },
  PUBLICATION: { normal: '#FFA726', selected: '#FFA726' },
  SOCIAL_MEDIA: {
    normal: 'rgba(94, 53, 177, 1)',
    selected: 'rgba(94, 53, 177, 1)',
  },
  FIRE: { normal: 'rgba(211, 47, 47, 1)', selected: 'rgba(211, 47, 47, 1)' },
  SHIP: { normal: '#00A5B0', selected: '#00A5B0' },
  PROPERTY: { normal: '#C2185B', selected: '#C2185B' },
}
