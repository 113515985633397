import { RESULT_CATEGORIES } from '@/utils/constants'
import { AttomObjectView } from './AttomObjectView'
import { FireObjectView } from './FireObjectView'
import { HazardPropertyObjectView } from './HazardPropertyObjectView'
import { HazardRiskObjectView } from './HazardRiskObjectView'
import { ImageObjectView } from './ImageObjectView'
import { NewsObjectView } from './NewsObjectView'
import { RegridObjectView } from './RegridObjectView'
import { ShipObjectView } from './ShipObjectView'
import { SocialMediaObjectView } from './SocialMediaObjectView'

export const renderers = {
  [RESULT_CATEGORIES.ATTOM]: AttomObjectView,
  [RESULT_CATEGORIES.FIRE]: FireObjectView,
  [RESULT_CATEGORIES.HAZARD_PROPERTY]: HazardPropertyObjectView,
  [RESULT_CATEGORIES.HAZARD_RISK]: HazardRiskObjectView,
  [RESULT_CATEGORIES.IMAGE]: ImageObjectView,
  [RESULT_CATEGORIES.PUBLICATION]: NewsObjectView,
  [RESULT_CATEGORIES.REGRID]: RegridObjectView,
  [RESULT_CATEGORIES.SHIP]: ShipObjectView,
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: SocialMediaObjectView,
  [RESULT_CATEGORIES.ANALYTIC]: ImageObjectView,
} as { [key: string]: React.FC<any> }
