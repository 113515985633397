import React from 'react'
import { list } from '@/api/collections'
import type { Collection } from '@/utils/types/collection-types.ts'
import { singletonHook } from 'react-singleton-hook'

interface UseCollectionsHookResponse {
  collections: Collection[]
  isLoading: boolean
  hasMore: boolean
  loadMore: () => Promise<void>
  refresh: () => void
}

const useAddToCollectionImpl = (): UseCollectionsHookResponse => {
  const [collections, setCollections] = React.useState<Collection[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [nextToken, setNextToken] = React.useState<string>()
  const [hasMore, setHasMore] = React.useState(true)

  const loadMore = React.useCallback(async () => {
    if (isLoading || !hasMore) {
      return
    }

    setIsLoading(true)
    const { collections, next } = await list({
      limit: 100,
      next: nextToken,
    })

    setCollections(collections)
    setNextToken(next)
    setHasMore(next !== '')
    setIsLoading(false)
  }, [nextToken, isLoading, hasMore])

  React.useEffect(() => {
    loadMore().catch((error) => {
      console.error(error)
    })
  }, [loadMore])

  function refresh() {
    setNextToken(undefined)
    setHasMore(true)
    loadMore().catch((error) => {
      console.error(error)
    })
  }

  return {
    collections,
    isLoading,
    hasMore,
    loadMore,
    refresh,
  }
}

export const useAddToCollection = singletonHook<UseCollectionsHookResponse>(
  () => ({
    collections: [],
    isLoading: false,
    hasMore: false,
    loadMore: async () => {},
    refresh: () => {},
  }),
  useAddToCollectionImpl,
)
