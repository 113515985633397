import { ModalsProvider } from '@mantine/modals'
import { FileUploadModal } from '@/components/lib/FileUpload'
import {
  AddToCollectionModal,
  AnalyzeCollectionModal,
  AnalyzeItemModal,
  CreateCollectionModal,
  DeleteCollectionModal,
  DownloadItemModal,
  InviteUserModal,
  RemoveAllHistoryModal,
  RemoveFromCollectionModal,
  ResultDetailsModal,
  SaveSearchModal,
  SessionConfirmationModal,
  ShareCollectionModal,
  ShareModal,
  UpdateCollectionModal,
} from '@/components/modals'
import type React from 'react'

export function DantiModalsProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <ModalsProvider
      modals={{
        addToCollection: AddToCollectionModal,
        removeFromCollection: RemoveFromCollectionModal,
        removeAllHistory: RemoveAllHistoryModal,
        createCollection: CreateCollectionModal,
        updateCollection: UpdateCollectionModal,
        shareCollection: ShareCollectionModal,
        deleteCollection: DeleteCollectionModal,
        analyzeCollection: AnalyzeCollectionModal,
        analyzeItem: AnalyzeItemModal,
        downloadItem: DownloadItemModal,
        uploadFile: FileUploadModal,
        share: ShareModal,
        resultDetails: ResultDetailsModal,
        saveSearch: SaveSearchModal,
        sessionConfirmation: SessionConfirmationModal,
        inviteUser: InviteUserModal,
      }}
    >
      {children}
    </ModalsProvider>
  )
}
