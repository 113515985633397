import { getUserAuthToken } from '@/api/danti-requests/get-user-auth-token'
import { apiUrl } from '@/utils/constants'
import { captureException } from '@sentry/react'
import axios, { type AxiosError } from 'axios'

export const axiosClient = axios.create({
  baseURL: apiUrl,
})

axiosClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    captureException(error)
    return Promise.reject(error)
  },
)
axiosClient.interceptors.request.use(
  (request) => {
    const token = getUserAuthToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)
