import { Footer } from '@/components/layout/Footer'
import { FlaggedRoute } from '@/features/AppRouting/components'
import type { FeatureLock } from '@/features/AppRouting/constants'
import type { FeatureFlagKeys } from '@/utils/constants'

export interface RouteProps {
  Component: React.ComponentType<any>
  isFullscreen?: boolean
  feature?: FeatureLock | FeatureFlagKeys
  isSearchable?: boolean
}

export const DantiRoute = ({
  isFullscreen,
  Component,
  feature,
  isSearchable = false,
  ...route
}: RouteProps) => {
  let element = <Component isFullscreen={isFullscreen} />
  element = isSearchable ? <Footer>{element}</Footer> : element
  element = feature ? (
    <FlaggedRoute
      isProtected
      {...{ feature: feature as FeatureFlagKeys, element, ...route }}
    />
  ) : (
    element
  )
  return element
}
