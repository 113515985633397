import React, { type MutableRefObject, useCallback, useEffect } from 'react'
import { addLayers } from '@/components/lib/map-search/hooks/util/add-layers.ts'
import { addSources } from '@/components/lib/map-search/hooks/util/add-sources.ts'
import {
  makeImageryResultPolygons,
  makePolygonsIntoPoint,
} from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { usePostfilterImageResults } from '@/hooks/results/use-postfilter-image-results.ts'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { useSortPreference } from '@/stores/queries-store'
import { isRegridResult, type RegridResult } from '@/utils/types/result-types'
import { orderBy } from 'lodash'
import type mapboxgl from 'mapbox-gl'

type UseStyleLoadedCallbackProps = {
  map: MutableRefObject<mapboxgl.Map | null>
}

export const useImageryExtents: (
  props: UseStyleLoadedCallbackProps,
) => void = ({ map }) => {
  const sortPreference = useSortPreference()

  const imageResults = usePostfilterImageResults()
  // const { filteredPropertyResults } = usePropertyMapData()
  const propertyResults =
    useTimelineFilteredResults().categorized.propertyResults
  const regridResults: RegridResult[] = propertyResults.filter(isRegridResult)
  const sortedResults = React.useMemo(() => {
    return orderBy(imageResults, sortPreference || undefined, 'desc')
  }, [imageResults, sortPreference])
  const extentsData = makeImageryResultPolygons([
    ...sortedResults.slice(0, 6),
    ...regridResults.slice(0, 6),
  ])
  const regridCollection = makePolygonsIntoPoint(regridResults)

  const styleDataCallback = useCallback(() => {
    addSources({ map, extentsData, regridCollection })
    addLayers(map, true, false)
  }, [extentsData, map, regridCollection])

  useEffect(() => {
    if (map.current?.isStyleLoaded()) {
      addSources({ map, extentsData, regridCollection })
    }
  }, [extentsData, map, regridCollection])

  useEffect(() => {
    map.current?.on('styledata', styleDataCallback)
  }, [map, styleDataCallback])
}
