import { useNavigate } from 'react-router-dom'
import { ActionIcon, Menu } from '@mantine/core'
import { downloadFile } from '@/api/collection-items'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  actionMenuOptions,
  type ActionMenuType,
  getHideActionsForResult,
} from '@/components/lib/results/Result/action-menu-options'
import { openResultDetailsModal } from '@/components/modals/openers'
import { makeOrthoLayer } from '@/hooks/ortho-imagery/use-ortho-imagery-sources'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useRelatedImagerySearch } from '@/hooks/use-related-imagery-search'
import {
  useClearOrthoLayerGroupIds,
  useToggleOrthoLayerGroupId,
} from '@/stores/map-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import type { CollectionItem } from '@/utils/types/collection-types'
import { isFileResult } from '@/utils/types/result-type-checkers'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'
import { captureException } from '@sentry/react'

import styles from './result-base.module.css'

export type ResultMenuContainer = 'grid' | 'table'

export interface ResultMenuProps {
  container?: ResultMenuContainer
  item?: CollectionItem
  result: ResultWithId
  disableNavigate?: boolean
}

export const ResultMenu = ({
  item,
  container = 'grid',
  result,
  disableNavigate,
}: ResultMenuProps) => {
  const navigate = useNavigate()
  const { doRelatedImagerySearch } = useRelatedImagerySearch()
  const toggleOrthoLayerGroupId = useToggleOrthoLayerGroupId()
  const clearOrthoLayerGroupIds = useClearOrthoLayerGroupIds()
  const { data: isDownloadable } = useIsDownloadable(result)

  // FIXME: Remove after demo recording
  const isFauxAnalyticEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.fauxAnalytic)

  const adjustedActionMenuOptions: Record<string, ActionMenuType> = {
    ...actionMenuOptions,
    downloadImage: {
      ...actionMenuOptions['downloadImage'],
      disabled: !isDownloadable,
      title: isDownloadable ? undefined : 'Not available for download',
    },
    findRelatedImages: {
      ...actionMenuOptions['findRelatedImages'],
      onClick: (clickedResult: ResultWithId) =>
        void doRelatedImagerySearch(clickedResult.thumbnail ?? ''),
    },
    viewOnMap: {
      ...actionMenuOptions['viewOnMap'],
      onClick: (clickedResult: ResultWithId) => {
        if (isImageryResult(clickedResult)) {
          const layer = makeOrthoLayer(clickedResult)
          if (layer) {
            clearOrthoLayerGroupIds()
            toggleOrthoLayerGroupId(layer.groupKey)
            navigate(`/map/${clickedResult.id}`)
          } else {
            captureException(
              `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
            )
          }
        }
      },
    },
    viewDetails: {
      ...actionMenuOptions['viewDetails'],
      onClick: (clickedResult: ResultWithId) => {
        if (item && !disableNavigate) {
          navigate(`/collections/${item.collectionId}/items/${item.id}`)
        } else if (isImageryResult(clickedResult)) {
          const layer = makeOrthoLayer(clickedResult)
          if (layer) {
            clearOrthoLayerGroupIds()
            toggleOrthoLayerGroupId(layer.groupKey)
            navigate(`/results/${clickedResult.id}`)
          } else {
            captureException(
              `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
            )
          }
        } else {
          openResultDetailsModal(clickedResult)
        }
      },
    },
    ...(item &&
      isFileResult(result) &&
      !!item.name && {
        downloadFile: {
          label: 'Download File',
          icon: 'download',
          onClick: () => {
            void downloadFile(
              `/collection/${item.collectionId}/items/${item.id}/download`,
              item.name,
            )
          },
          disabled: false,
        },
      }),
    // FIXME: Remove after demo recording
    ...(isFauxAnalyticEnabled && {
      sendToAnalytics: {
        ...actionMenuOptions['sendToAnalytics'],
        title: 'Analyze',
        disabled: false,
      },
    }),
  }

  const hiddenActions = getHideActionsForResult(result)
  const actions = Object.keys(adjustedActionMenuOptions)
    .filter((option) => !hiddenActions.includes(option))
    .map((action) => adjustedActionMenuOptions[action])

  return (
    <Menu trigger="hover" shadow="md">
      <Menu.Target data-cy="resultActionMenu">
        <ActionIcon
          variant="white"
          color="black"
          className={container === 'grid' ? styles['clickable'] : undefined}
          onClick={(event) => event.stopPropagation()}
        >
          <Icon
            name={container === 'grid' ? 'more_vert' : 'more_horiz'}
            size="xl"
          />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {actions.map(
          ({ label, icon, onClick: actionOnClick, ...passProps }) => {
            return label === 'Download' && !isDownloadable ? null : (
              <Menu.Item
                key={label}
                {...passProps}
                leftSection={<Icon name={icon} />}
                onClick={(event) => {
                  event.stopPropagation()
                  captureEvent(POSTHOG_EVENTS.RESULT_MENU.CLICKED, {
                    actionLabel: label,
                    resultCategory:
                      result.properties.resultCategory ?? result.category,
                  })
                  void actionOnClick(result)
                }}
                p="xs"
                className={styles['menu-hover']}
              >
                {label}
              </Menu.Item>
            )
          },
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
