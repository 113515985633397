export const separatorString = ' $#$#$# '

export function formatGeocodedAddress(result: {
  formatted_address: string
  place_id: string
  address_components: {
    short_name: string
    types: string[]
  }[]
}) {
  const formattedAddress = result.formatted_address
  const placeId = result.place_id

  const findField = (check: string) =>
    result.address_components.find((component) =>
      component.types.includes(check),
    )?.short_name
  const country = findField('country')
  const adminAreaLevel1 = findField('administrative_area_level_1')
  const adminAreaLevel2 = findField('administrative_area_level_2')

  return {
    label: result.formatted_address,
    value: [
      formattedAddress,
      placeId,
      country,
      adminAreaLevel1,
      adminAreaLevel2,
    ].join(separatorString),
    isUsAddress: country === 'United States',
    formattedAddress,
    placeId,
    country,
    adminAreaLevel1,
    adminAreaLevel2,
  }
}
