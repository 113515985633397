import React from 'react'
import { Container } from '@mantine/core'
import { CollectionGrid } from './CollectionGrid'
import { CollectionHeader, type ViewType } from './CollectionHeader'
import { CollectionList } from './CollectionList'
import { CollectionTable } from './CollectionTable'

export function Collections() {
  const [viewType, setViewType] = React.useState<ViewType>('grid')

  return (
    <Container size="900px" p={{ xs: 0 }}>
      <CollectionHeader viewType={viewType} setViewType={setViewType} />
      {viewType === 'grid' ? (
        <CollectionGrid />
      ) : (
        <>
          <CollectionList />
          <CollectionTable />
        </>
      )}
    </Container>
  )
}
