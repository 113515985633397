import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { postRequest } from '@/api/danti-requests'
import { useDantiAuth } from '@/hooks/use-danti-auth.ts'
import type { AxiosError } from 'axios'

export function AcceptShare() {
  const { user } = useDantiAuth()
  const { collectionId, shareToken } = useParams()
  const [isAccepted, setIsAccepted] = React.useState(false)
  const [hasError, setHasError] = React.useState<AxiosError | null>()

  React.useEffect(() => {
    if (collectionId && shareToken && user?.access_token) {
      postRequest(
        `/collection/${collectionId}/accept`,
        {
          sharingToken: shareToken,
        },
        { headers: { Authorization: `Bearer ${user.access_token}` } },
      )
        .then(() => {
          setIsAccepted(true)
        })
        .catch((error: AxiosError) => {
          console.error(error)
          setHasError(error)
        })
    }
  }, [collectionId, shareToken, user?.access_token])

  if (!collectionId || !shareToken) {
    return <Navigate to="/error" />
  }

  return isAccepted ? (
    <Navigate to={`/collections/${collectionId}`} />
  ) : (
    <h1>{hasError ? hasError.message : 'Accepting shared collection...'}</h1>
  )
}

export default AcceptShare
