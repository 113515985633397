import { Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { ResultImage } from '@/features//ResultImage'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { SymbolName } from '@/utils/types/material-icons'

import styles from './reference-cards.module.css'

export interface ThumbnailFallbackCardProps {
  iconColor?: string
  icon?: React.ReactNode
  iconName?: SymbolName
  datetime?: string
  title: string
  highlightIcon?: boolean
  image: {
    thumbnail?: string
    title: string
    source: string
  } | null
}

export function ThumbnailFallbackCard({
  image,
  iconColor,
  icon,
  iconName,
  datetime,
  title,
  highlightIcon = false,
}: ThumbnailFallbackCardProps) {
  const iconDisplay = (
    <>
      {icon && <div className={styles['icon-wrapper']}>{icon}</div>}
      {iconName && (
        <div className={styles['icon-wrapper']}>
          {highlightIcon ? (
            <div className={styles['highlight-icon']}>
              <Icon name={iconName} className={styles['icon']} size={24} />
            </div>
          ) : (
            icon
          )}
        </div>
      )}
    </>
  )
  return (
    <div className={styles['sov-icon-category-card']}>
      <div
        className={styles['icon-container']}
        style={{ backgroundColor: iconColor }}
      >
        {image ? (
          <div className={styles['image-fallback-container']}>
            <ResultImage item={image} className={styles['fallback-image']} />
            {iconDisplay}
          </div>
        ) : (
          iconDisplay
        )}
      </div>
      <div className={styles['details-container']}>
        {datetime && (
          <div className={styles['listing-datetime-row']}>
            <Text className={styles['date']}>{formatDate(datetime)}</Text>
            <Text className={styles['time']}>{formatTime(datetime)}</Text>
          </div>
        )}
        <Text className={styles['title']} lineClamp={3}>
          {title}
        </Text>
      </div>
    </div>
  )
}
