import { Button, Divider, Group, Image, Stack, Text } from '@mantine/core'
import { createStaticMapUrl } from '@/api/mapbox'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  getLocationType,
  getLocationValue,
  TerrapinLocationType,
} from '@/components/modals/SaveSearchModal/TerrapinLocationPanel/TerrapinLocation.ts'
import type { TerrapinLocation } from '@/hooks/use-terrapin.tsx'
import type { Point, Polygon } from 'geojson'
import { toPoint } from 'mgrs'

export interface TerrapinStaticMapDisplayProps {
  onClick?: () => void
  location: TerrapinLocation
  fullScreen?: boolean
  onBack?: () => void
}

export const TerrapinStaticMapDisplay = ({
  location,
  onClick,
  fullScreen,
  onBack,
}: TerrapinStaticMapDisplayProps) => {
  const type = getLocationType(location)
  const value = getLocationValue(location)
  const coordinateHeader = value ?? 'N/A'

  const formatGeojson = () => {
    switch (type) {
      case TerrapinLocationType.latLong:
        return {
          type: 'Point',
          coordinates: value ? JSON.parse(value) : [0, 0],
        }
      case TerrapinLocationType.polygon:
        return {
          type: 'Polygon',
          coordinates: value
            ? JSON.parse(value)
            : [
                [0, 0],
                [1, 0],
              ],
        }
      case TerrapinLocationType.mgrs: {
        const point = value ? toPoint(value) : [0, 0]
        return {
          type: 'Point',
          coordinates: point,
        }
      }
      default:
        return {
          type: 'Point',
          coordinates: [0, 0],
        }
    }
  }

  const staticMapUrl = createStaticMapUrl({
    geometry: formatGeojson() as Point | Polygon,
    size: fullScreen ? [500, 400] : [125, 80],
    attribution: false,
    zoom: fullScreen ? 5 : 1,
  })

  return (
    <div>
      {fullScreen ? (
        <Stack>
          <Text>{coordinateHeader}</Text>
          <Divider />
          <Image src={staticMapUrl} alt="Geometry" />
          <Group justify="space-between" mt="md">
            <Button
              size="xs"
              leftSection={<Icon name="navigate_before" />}
              variant="outline"
              onClick={onBack}
            >
              GO BACK
            </Button>
          </Group>
        </Stack>
      ) : (
        <Image src={staticMapUrl} alt="Geometry" onClick={onClick} />
      )}
    </div>
  )
}
