import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Box,
  Divider,
  Group,
  Pill,
  Stack,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { Collection } from '@/utils/types/collection-types'
import dayjs from 'dayjs'

export interface CollectionListItemProps {
  collection: Collection
  onSelect: (collection: Collection) => void
}

export function CollectionListItem({
  collection,
  onSelect,
}: CollectionListItemProps) {
  const navigate = useNavigate()
  const { id, name, lastActivity, status } = collection
  const isTerrapin = Boolean(collection.refId)

  return (
    <Stack gap="xs" px="sm" onClick={() => navigate(`/collections/${id}`)}>
      <Group gap={6} wrap="nowrap">
        <Icon name={isTerrapin ? 'folder_special' : 'space_dashboard'} filled />
        <Text c="#000000DE" size="16px" lineClamp={1}>
          {name}
        </Text>
        <Box flex={1} />
        <ActionIcon
          variant="white"
          onClick={(event) => {
            event.stopPropagation()
            onSelect(collection)
          }}
          data-cy="collection-menu"
          bg="transparent"
        >
          <Icon name="more_vert" style={{ color: '#000000' }} />
        </ActionIcon>
      </Group>
      <Group gap="xs" align="center">
        <Text c="#00000099" size="12px">
          {dayjs(lastActivity).format('MM/DD/YY hh:mm A')}
        </Text>
        {status === 'NEW' && (
          <Pill key="NEW" size="xs" bg="#F1C21B" fw="bold">
            NEW
          </Pill>
        )}
      </Group>
      <Divider my="xs" />
    </Stack>
  )
}
