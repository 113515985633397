import { searchApiUrl, serviceApiUrl } from '@/utils/constants.ts'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import { axiosClient } from './axios-client'
import { axiosClientNoAuth } from './axios-client-no-auth'

export function getRequest<ResponseType>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.get(url, config)
}

export function getRequestNoAuth<ResponseType>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClientNoAuth.get(url, config)
}

export function postServiceRequest<ResponseType>(
  url: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.post(url, payload, { ...config, baseURL: serviceApiUrl })
}

export function postSearchRequest<ResponseType>(
  url: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.post(url, payload, { ...config, baseURL: searchApiUrl })
}

export function postRequest<ResponseType>(
  url: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.post(url, payload, config)
}

export function putRequest<ResponseType>(
  url: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.put(url, payload, config)
}

export function putRequestNoAuth<ResponseType>(
  url: string,
  payload: object,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClientNoAuth.put(url, payload, config)
}

export function deleteRequest<ResponseType>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<ResponseType>> {
  return axiosClient.delete(url, config)
}
