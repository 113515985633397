import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AppShell, Container } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Notifications } from '@mantine/notifications'
import { useHistory } from '@/api/history'
import { DantiModalsProvider } from '@/components/layout/DantiModalsProvider'
import { Header } from '@/components/layout/Header/Header'
import { useSentryMetadataSyncing } from '@/hooks/use-sentry-metadata-syncing'
import { posthog } from 'posthog-js'
import { Navbar } from './NavBar/NavBar'

import '@mantine/notifications/styles.css'
import './main-layout.css'
import styles from './main-layout.module.css'

export default function MainLayout() {
  useHistory()
  useSentryMetadataSyncing()

  const [opened, { toggle }] = useDisclosure()
  const location = useLocation()
  React.useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  const headerHeight = 60
  const footerHeight = 100
  const padding = 30

  return (
    <DantiModalsProvider>
      <AppShell
        navbar={{
          width: 300,
          breakpoint: 'sm',
          collapsed: { mobile: !opened, desktop: true },
        }}
      >
        <AppShell.Header>
          <Header opened={opened} toggle={toggle} />
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <Navbar opened={opened} toggle={toggle} />
        </AppShell.Navbar>
        <AppShell.Main>
          <Container
            size="lg"
            pt={headerHeight + padding}
            pb={footerHeight + padding}
            px="xl"
            maw="100%"
            mih="100%"
            className={styles.container}
          >
            <Notifications
              position="top-left"
              mt="calc(var(--danti-head-height) - 1rem)"
            />
            <Outlet />
          </Container>
        </AppShell.Main>
      </AppShell>
    </DantiModalsProvider>
  )
}
