import { useEffect, useMemo, useState } from 'react'
import { usePrevious } from '@mantine/hooks'
import { makeImageryResultsBoundingBox } from '@/components/lib/map-search/hooks/util/result-geo-utils.ts'
import { SEARCH_STATUSES } from '@/features/Search/constants'
import { useMapBbox, useSetMapBbox } from '@/stores/map-store'
import { useCurrentQueryStart, useQueryStatus } from '@/stores/queries-store'
import { useAllResults, useCurrentQueryId } from '@/stores/results-store'
import {
  EventDrivenQuerySourceKeys,
  QuerySourceState,
} from '@/utils/types/message-types.ts'
import { isImageryResult } from '@/utils/types/result-types'

const RESULT_MINIMUM_FOR_INTERACTION = 5
const TAKING_A_WHILE_THRESHOLD_MS = 12000
const QUERY_TIMEOUT_MS = 60000
export const useSearchStatus = () => {
  const currentQueryId = useCurrentQueryId()
  const results = useAllResults()
  const [isTakingAWhile, setIsTakingAWhile] = useState(false)
  const [isTimedOut, setIsTimedOut] = useState(false)
  const queryStatus = useQueryStatus()
  const currentQueryStart = useCurrentQueryStart()
  const mapBbox = useMapBbox()
  const setMapBbox = useSetMapBbox()

  useEffect(() => {
    setIsTakingAWhile(false)
    setIsTimedOut(false)
    const takingAWhile = setTimeout(() => {
      setIsTakingAWhile(true)
    }, TAKING_A_WHILE_THRESHOLD_MS)
    const timeout = setTimeout(() => {
      setIsTimedOut(true)
    }, QUERY_TIMEOUT_MS)

    return () => {
      clearTimeout(takingAWhile)
      clearTimeout(timeout)
    }
  }, [currentQueryId])

  const imageResults = useMemo(() => results.filter(isImageryResult), [results])
  const previousImageResults = usePrevious(imageResults)

  useEffect(() => {
    if (
      (!previousImageResults || previousImageResults.length < 3) &&
      imageResults &&
      imageResults.length >= 3 &&
      !mapBbox
    ) {
      setMapBbox(makeImageryResultsBoundingBox(imageResults) || null)
    }
  }, [imageResults, mapBbox, previousImageResults, setMapBbox])

  const isSearching = currentQueryStart !== null
  const hasEnoughResults = results.length >= RESULT_MINIMUM_FOR_INTERACTION
  const allSourcesCompleted = Object.values(queryStatus).every((v) =>
    [QuerySourceState.COMPLETED, QuerySourceState.ERROR].includes(v),
  )

  const isReadyForInteraction =
    !isSearching || hasEnoughResults || allSourcesCompleted || isTimedOut

  const isEdsInProgress = Object.values({ ...EventDrivenQuerySourceKeys }).some(
    (k) => queryStatus[k] === QuerySourceState.IN_PROGRESS,
  )

  // Communicate specific states that can't collide
  let status = SEARCH_STATUSES.NOT_STARTED
  if (isSearching) {
    if (results.length === 0) {
      if (allSourcesCompleted || isTimedOut) {
        status = SEARCH_STATUSES.NO_RESULTS
      } else if (isTakingAWhile) {
        status = isEdsInProgress
          ? SEARCH_STATUSES.EDS_IN_PROGRESS
          : SEARCH_STATUSES.STARTED_LONG_AGO
      } else {
        status = SEARCH_STATUSES.STARTED_RECENTLY
      }
    } else {
      status = SEARCH_STATUSES.READY
    }
  }

  return {
    isSearching,
    isReadyForInteraction,
    status,
  }
}
