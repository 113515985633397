import { addIdToResult, filterAndGroupResults } from '@/api/results'
import { createStore } from '@/utils/create-store'
import {
  type CategorizedResults,
  type CategoryTypes,
  isRelatedImageryResult,
  type RawSearchResult,
} from '@/utils/types/result-types'
import { camelCase, orderBy } from 'lodash'

export interface Results {
  currentQueryId: string | null
  categorized: CategorizedResults
  ids: string[]
}

export interface ResultsActions {
  setCurrentQueryId: (queryId: string | null) => void
  clearResults: () => void
  addResults: (results: RawSearchResult[]) => void
  addRelatedImageryResults: (results: RawSearchResult[]) => void
  clearResultsOfTypes: (types: CategoryTypes[]) => void
}

export const initialResultsState: Results = {
  currentQueryId: null,
  categorized: {
    imageResults: [],
    dataResults: [],
    insightResults: [],
    socialMediaResults: [],
    propertyResults: [],
    analyticResults: [],
    publicationResults: [],
  } as CategorizedResults,
  ids: [],
}

export const useResultsStore = createStore<Results & ResultsActions>({
  storageKey: 'Results',
  version: 0.06,
  initialState: initialResultsState,
  actions: (set, get) => ({
    setCurrentQueryId: (queryId: string | null) => {
      console.log(`Got queryId ${queryId}`)
      set({ currentQueryId: queryId })
    },
    clearResults: () => {
      set({ ...initialResultsState })
    },
    addResults: (results: RawSearchResult[]) => {
      const { categorized } = get()
      if (categorized) {
        const {
          ids: newIds,
          // Separate Insights to exclude them from total count
          insightResults,
          ...categories
        } = filterAndGroupResults(results, categorized)

        set({
          categorized: { ...categories, insightResults },
          ids: newIds,
        })
      }
    },
    addRelatedImageryResults: (results: RawSearchResult[]) => {
      const { categorized } = get()
      const resultsWithIds = results
        .map(addIdToResult)
        .filter(isRelatedImageryResult)

      if (categorized) {
        set({
          categorized: {
            ...categorized,
            imageResults: orderBy(
              [...resultsWithIds, ...categorized.imageResults],
              ['category', 'score'],
              ['desc', 'desc'],
            ),
          },
        })
      }
    },
    clearResultsOfTypes: (types: CategoryTypes[]) => {
      const { categorized } = get()
      if (types.length > 0 && categorized) {
        const formatted = types.reduce((final, type) => {
          const key = camelCase(type) + 'Results'
          if (key in categorized) {
            final[key as keyof CategorizedResults] = []
          }
          return final
        }, {} as Partial<CategorizedResults>)
        set({ categorized: { ...categorized, ...formatted } })
      }
    },
  }),
})
