import React from 'react'
import { SimpleGrid } from '@mantine/core'
import { FireResult } from '@/components/lib/results/Result'
import { useTimelineFilteredResults } from '@/hooks/results/use-timeline-filtered-results.ts'
import { useSortPreference } from '@/stores/queries-store'
import { useFireResults } from '@/stores/results-store'
import { ROUTES } from '@/utils/constants.ts'
import { isFireResult } from '@/utils/types/result-types.ts'
import { orderBy } from 'lodash'
import { PanelBase } from './'

interface FirePanelProps {
  isFullscreen?: boolean
  position?: 'left' | 'center' | 'right'
}

export function FirePanel({ isFullscreen = false, position }: FirePanelProps) {
  const sortPreference = useSortPreference()
  const unfilteredFireResults = useFireResults()
  const filteredFireResults =
    useTimelineFilteredResults().categorized.dataResults.filter(isFireResult)
  const fireResults = isFullscreen ? filteredFireResults : unfilteredFireResults

  const sortedResults = React.useMemo(() => {
    return orderBy(fireResults, sortPreference, 'desc')
  }, [fireResults, sortPreference])

  const shouldDisplay = fireResults.length > 0 || isFullscreen

  return (
    shouldDisplay && (
      <PanelBase
        titleShort="Fire"
        titleLong="All Fire Data"
        isFullscreen={isFullscreen}
        isolateType={isFireResult}
        link={ROUTES.fires}
        position={position}
      >
        <SimpleGrid
          cols={isFullscreen ? { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 } : 3}
          spacing={isFullscreen ? 'xl' : 'lg'}
          verticalSpacing={isFullscreen ? 'md' : 70}
        >
          {sortedResults
            .slice(0, isFullscreen ? undefined : 3)
            .map((result) => (
              <FireResult
                key={result.documentId}
                result={result}
                isFullscreen
              />
            ))}
        </SimpleGrid>
      </PanelBase>
    )
  )
}
