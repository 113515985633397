import React from 'react'
import { Button, Flex, Popover, Stack, Text } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon.tsx'
import { useSetTimelineSelectedIds } from '@/stores/results-filter-store'
import { useAllResults } from '@/stores/results-store'
import { Group } from '@visx/group'

export const ResultsDateFilter = () => {
  const setTimelineSelectedIds = useSetTimelineSelectedIds()
  const [selectedDates, setSelectedDates] = React.useState<
    [Date | null, Date | null]
  >([null, null])
  const allResults = useAllResults()
  const dates = allResults.map((result) =>
    new Date(result.properties.datetime).getTime(),
  )
  const maximumDate = new Date(Math.max(...dates))
  const minimumDate = new Date(Math.min(...dates))

  const handleDateSelection = (value: [Date | null, Date | null]) => {
    setSelectedDates(value)
    const selectedIds: string[] = []
    if (value[0] && value[1] === null) {
      return setTimelineSelectedIds(null)
    }

    allResults.filter((result) => {
      const date = new Date(result.properties.datetime)
      if (value[0] && value[1] && date >= value[0] && date <= value[1]) {
        selectedIds.push(result.id)
      }
    })
    setTimelineSelectedIds(selectedIds)
  }

  const handleClear = () => {
    setSelectedDates([null, null])
    setTimelineSelectedIds(null)
  }

  return (
    <Popover position="top-end">
      <Popover.Target>
        <Flex justify="space-between" style={{ cursor: 'pointer' }}>
          <Icon name="calendar_clock" />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack gap="xs">
          <Group>
            <DatePicker
              size="xs"
              type="range"
              value={selectedDates}
              onChange={handleDateSelection}
              minDate={minimumDate ?? undefined}
              maxDate={maximumDate ?? undefined}
            />
            <Group>
              <Button onClick={handleClear} fullWidth ta="center">
                <Text>Clear</Text>
              </Button>
            </Group>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
