import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ActionIcon, Container, Group } from '@mantine/core'
import { useCollectionItems } from '@/api/collection-items'
import { getRequest } from '@/api/danti-requests'
import { Icon } from '@/components/lib/Icon.tsx'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import type { Collection } from '@/utils/types/collection-types.ts'
import type { CategoryTypes } from '@/utils/types/result-types'
import { useQuery } from '@tanstack/react-query'
import type { ColumnFiltersState } from '@tanstack/react-table'
import { CollectionDetailGrid } from './CollectionDetailGrid'
import { CollectionDetailHeader, type ViewType } from './CollectionDetailHeader'
import { CollectionDetailList } from './CollectionDetailList'
import { CollectionDetailTable } from './CollectionDetailTable'

export function CollectionDetail() {
  const { isAuthenticated } = useDantiAuth()
  const { collectionId } = useParams()
  const navigate = useNavigate()

  const [viewType, setViewType] = React.useState<ViewType>('list')

  const { data: collection, isLoading: isLoadingCollection } = useQuery({
    queryKey: ['collection', collectionId],
    queryFn: async () => {
      const response = await getRequest<Collection>(
        `/collection/${collectionId}`,
      )
      return response.data
    },
    enabled: isAuthenticated && !!collectionId,
  })

  const {
    items,
    setSort,
    currentPage,
    pageSize,
    totalCount,
    selectedCategories,
    nextPage,
    previousPage,
    search,
    toggleSelectedCategory,
    sort,
    filter,
  } = useCollectionItems(collectionId)

  const handleToggleSelectedCategory = (category: CategoryTypes) => {
    if (!collection?.name) {
      return
    }

    let updatedCategories = [...selectedCategories]

    if (selectedCategories.includes(category)) {
      updatedCategories = updatedCategories.filter((item) => item != category)
    } else {
      updatedCategories.push(category)
    }

    captureEvent(POSTHOG_EVENTS.COLLECTIONS.FILTER, {
      filterType: 'type',
      filterValue: updatedCategories.join(','),
    })

    toggleSelectedCategory(category)
  }

  const columnFilters: ColumnFiltersState = React.useMemo(
    () => [
      {
        id: 'kind',
        value: selectedCategories,
      },
    ],
    [selectedCategories],
  )

  if (isLoadingCollection || !collection) {
    return <></>
  }

  // Defined as const to avoid hoisting, so we know `collection` is defined
  const handleClick = (id: string) => {
    navigate(`/collections/${collection.id}/items/${id}`)
  }

  return (
    <>
      <Group pos="absolute" left={16} visibleFrom="md">
        <ActionIcon
          onClick={() => navigate(-1)}
          radius="xl"
          left={5}
          p="0"
          variant="outline"
          color="#0000008A"
          style={{
            zIndex: 1,
            color: '#666',
            borderColor: '#BDBDBD',
          }}
        >
          <Icon name="arrow_back" />
        </ActionIcon>
      </Group>
      <Container size="lg">
        <CollectionDetailHeader
          collection={collection}
          viewType={viewType}
          setViewType={setViewType}
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={totalCount}
          filter={filter}
          nextPage={nextPage}
          previousPage={previousPage}
          search={search}
          selectedCategories={selectedCategories}
          toggleSelectedCategory={handleToggleSelectedCategory}
          sort={sort}
          setSort={setSort}
        />
        {viewType === 'grid' ? (
          <CollectionDetailGrid
            items={selectedCategories.length === 0 ? [] : items}
            onClick={handleClick}
          />
        ) : (
          <>
            <CollectionDetailList
              items={selectedCategories.length === 0 ? [] : items}
            />
            <CollectionDetailTable
              items={selectedCategories.length === 0 ? [] : items}
              columnFilters={columnFilters}
              setSort={setSort}
              onCategoryToggle={handleToggleSelectedCategory}
              onClick={handleClick}
            />
          </>
        )}
      </Container>
    </>
  )
}
