/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Suspense } from 'react'
import { Container } from '@mantine/core'
import { UserAdminHeader } from '@/pages/Account/UserAdmin/UserAdminHeader.tsx'
import { UserAdminTable } from './UserAdminTable'

export interface UserAdminProps {}

export const UserAdmin = () => {
  return (
    <Container size="lg" w="100%" p={0} pb="lg">
      <UserAdminHeader />
      <Suspense fallback="Loading...">
        <UserAdminTable />
      </Suspense>
    </Container>
  )
}
