import { type MutableRefObject, useEffect } from 'react'
import { safeSetLayoutProperty } from '@/components/lib/map-search/hooks/util/safe-map-setters.ts'

export const useLayerToggling = (
  map: MutableRefObject<mapboxgl.Map | null>,
  showExtents: boolean,
  showGrid: boolean,
) => {
  useEffect(() => {
    const visValue = showExtents ? 'visible' : 'none'
    void safeSetLayoutProperty(map, 'extents-fills', 'visibility', visValue)
    void safeSetLayoutProperty(map, 'extent-point', 'visibility', visValue)
    void safeSetLayoutProperty(map, 'extents-outlines', 'visibility', visValue)
  }, [map, showExtents])

  useEffect(() => {
    const visValue = showGrid ? 'visible' : 'none'
    void safeSetLayoutProperty(map, 'hexgrid-fills', 'visibility', visValue)
    void safeSetLayoutProperty(map, 'hexgrid-outlines', 'visibility', visValue)
  }, [map, showGrid])
}
