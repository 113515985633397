import { Anchor } from '@mantine/core'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { NewsReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate'
import { useResultNavigation } from '@/features/SingleObjectView/hooks'
import { getNewsImages } from '@/stores/results-store'
import { RESULT_CATEGORIES, ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type {
  PublicationResult,
  ResultWithId,
} from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.PUBLICATION
const hideActions = HIDE_ACTIONS_BY_CATEGORY[category]

export function NewsObjectView({ item: rawItem }: { item: ResultWithId }) {
  const item = rawItem as PublicationResult
  const { items, toNext, toPrevious, selectItem } = useResultNavigation(item)

  const images = getNewsImages(item)

  const metaDetails = [formatDatetime(item.authoredOn)]
  if (item.properties._dSource) {
    metaDetails.push(item.properties._dSource)
  }
  return (
    <SingleObjectViewTemplate<PublicationResult, PublicationResult>
      {...{
        item,
        hideActions,
        hero: {
          staticImage: images.length > 0 ? images[0].url : null,
          fallbackIcon: 'newspaper',
          imageAlt: images.length > 0 ? images[0].caption || '' : undefined,
          toPrevious,
          toNext,
          metaDetails,
          metaAction: (
            <Anchor
              href={item.properties.link}
              target="_blank"
              rel="noreferrer"
            >
              Source
            </Anchor>
          ),
        },
        content: {
          title: item.title,
          details: item.properties.summary,
          detailsLabel: 'Danti Summary',
        },
        carousel: {
          items,
          title: 'Related Articles',
          selectItem,
          renderCard: (item: PublicationResult) => (
            <NewsReferenceCard post={item} />
          ),
          categoryUrl: ROUTES.news,
          categoryLabel: 'All Articles',
        },
      }}
    />
  )
}
