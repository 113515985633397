import { MultiSelect } from '@mantine/core'
import { useProviders } from '@/components/filters/providers/use-providers.ts'

export function Providers() {
  const { providers, updateProviders, selectedProviders } = useProviders()

  const providerLabels = Object.entries(providers).map(([value, label]) => ({
    value,
    label,
  }))

  return (
    <MultiSelect
      mt="sm"
      placeholder={selectedProviders.length === 0 ? 'Any' : ''}
      value={selectedProviders}
      onChange={updateProviders}
      data={providerLabels}
      searchable
      multiple
    />
  )
}
