import {
  type FC,
  type MouseEventHandler,
  type MutableRefObject,
  useEffect,
  useState,
} from 'react'
import { ActionIcon, Button, Group, Menu, Text } from '@mantine/core'
import { MemoFilterPanel } from '@/components/lib/FilterPanel'
import { Icon } from '@/components/lib/Icon'
import { openSaveSearchModal } from '@/components/modals/SaveSearchModal/opener.tsx'
import { useSearch } from '@/features/Search/hooks'
import { useClearAll } from '@/hooks/use-clear-all'
import { useToggleFilterPanel } from '@/stores/filters-store'
import { useIsDrawing, useStartDrawingPolygon } from '@/stores/map-draw-store'
import {
  MAP_LAYER_OPTIONS,
  useActiveLayers,
  useBaseLayer,
  useSetActiveLayers,
  useSetBaseLayer,
} from '@/stores/map-store'
import { useCurrentQuery } from '@/stores/queries-store'
import { useImageResults } from '@/stores/results-store'
import { MAP_BASE_LAYERS } from '@/utils/constants.ts'
import type { ResultWithId } from '@/utils/types/result-types'
import type mapboxgl from 'mapbox-gl'

import styles from './map-control-panel.module.css'

const METERS_PER_MILE = 1609.34
const SEARCH_IN_WINDOW_MILE_THRESHOLD = 1000

type MapControlPanelProps = {
  openFileDialog: MouseEventHandler<HTMLElement>
  recomputeHexGrid: (results: ResultWithId[]) => void
  map: MutableRefObject<mapboxgl.Map | null>
}

export const MapControlPanel: FC<MapControlPanelProps> = ({
  map,
  openFileDialog,
  recomputeHexGrid,
}) => {
  const currentQuery = useCurrentQuery()
  const baseLayer = useBaseLayer()
  const setBaseLayer = useSetBaseLayer()
  const activeLayers = useActiveLayers()
  const setActiveLayers = useSetActiveLayers()
  const isDrawing = useIsDrawing()
  const startDrawingPolygon = useStartDrawingPolygon()
  const imageResults = useImageResults()
  const { doLocationSearch } = useSearch()
  const clearAll = useClearAll()
  const toggleFilterPanel = useToggleFilterPanel()

  const [isSearchInWindowEnabled, setSearchInWindowEnabled] = useState(false)

  const handleClearClick = () => {
    clearAll()
    recomputeHexGrid([])
  }

  const doSearchInWindow = async () => {
    const b = map.current?.getBounds()
    if (b) {
      const poly = [
        [b.getNorthWest().lng, b.getNorthWest().lat],
        [b.getNorthEast().lng, b.getNorthEast().lat],
        [b.getSouthEast().lng, b.getSouthEast().lat],
        [b.getSouthWest().lng, b.getSouthWest().lat],
        [b.getNorthWest().lng, b.getNorthWest().lat],
      ]
      await doLocationSearch(JSON.stringify(poly), 'polygon')
      recomputeHexGrid(imageResults)
    }
  }

  useEffect(() => {
    map.current?.on('moveend', () => {
      if (map && map.current && map.current?.getBounds())
        setSearchInWindowEnabled(
          map?.current
            ?.getBounds()
            .getNorthWest()
            .distanceTo(map.current?.getBounds().getSouthEast()) <
            SEARCH_IN_WINDOW_MILE_THRESHOLD * METERS_PER_MILE,
        )
    })
  }, [map])

  return (
    <Group
      className={styles['mapControls']}
      justify="space-between"
      bg="white"
      px="sm"
      py="xs"
      pos="fixed"
      top="4.25rem"
      w="100%"
      mx="-xl"
      data-cy="map-controls"
    >
      <style>{`body { --danti-head-height: 120px; }`}</style>
      <Group gap="xs">
        <Text fz="xs">Search By:</Text>
        <Button.Group>
          <Button
            size="xs"
            leftSection={<Icon name="upload" />}
            onClick={openFileDialog}
            data-cy="map-controls-file-upload"
          >
            File
          </Button>
          <Button
            size="xs"
            leftSection={<Icon name="polyline" />}
            onClick={() => {
              if (isDrawing) {
                handleClearClick()
              } else {
                startDrawingPolygon()
              }
            }}
            data-cy="map-controls-polygon-draw"
            {...(isDrawing ? { 'data-active': true } : {})}
          >
            Polygon
          </Button>
          <Button
            size="xs"
            leftSection={<Icon name="pageview" />}
            onClick={() => void doSearchInWindow()}
            {...(isSearchInWindowEnabled ? {} : { 'data-disabled': false })}
            data-cy="map-controls-search-in-window"
          >
            View
          </Button>
          <Button
            size="xs"
            leftSection={<Icon name="delete" />}
            onClick={handleClearClick}
            data-cy="map-controls-clear"
          >
            Clear
          </Button>
        </Button.Group>
      </Group>

      <Group gap="xs">
        <Text fz="xs">LAYERS:</Text>
        <Button.Group>
          <Button
            size="xs"
            data-active={activeLayers.includes(MAP_LAYER_OPTIONS.EXTENTS)}
            onClick={() => setActiveLayers(MAP_LAYER_OPTIONS.EXTENTS)}
            leftSection={<Icon name="rectangle" />}
            data-cy="map-controls-layer-extents-toggle"
          >
            EXTENTS
          </Button>

          {/*<Button*/}
          {/*  size="xs"*/}
          {/*data-active={activeLayers.includes('Grid')}*/}
          {/*  onClick={() => {*/}
          {/*    recomputeHexGrid(imageResults)*/}
          {/*    setActiveLayers('Grid')*/}
          {/*  }}*/}
          {/*  leftSection={<Icon name="hexagon" />}*/}
          {/*  data-cy="map-controls-layer-grid-toggle"*/}
          {/*>*/}
          {/*  GRID*/}
          {/*</Button>*/}
          <Button
            size="xs"
            data-active={activeLayers.includes(MAP_LAYER_OPTIONS.POINT)}
            onClick={() => setActiveLayers(MAP_LAYER_OPTIONS.POINT)}
            leftSection={<Icon name="location_pin" />}
          >
            POINT
          </Button>
          <Button
            size="xs"
            data-active={activeLayers.includes(MAP_LAYER_OPTIONS.EXTRACTIONS)}
            onClick={() => setActiveLayers(MAP_LAYER_OPTIONS.EXTRACTIONS)}
            leftSection={<Icon name="my_location" />}
          >
            Extracted Locations
          </Button>
        </Button.Group>
      </Group>

      <Group gap="xs">
        <Text fz="xs">Map Mode:</Text>
        <Button.Group>
          <Button
            size="xs"
            data-active={baseLayer === MAP_BASE_LAYERS.dantiStreets}
            onClick={() => setBaseLayer(MAP_BASE_LAYERS.dantiStreets)}
            data-cy="map-controls-mode-light"
          >
            <Icon name="wb_sunny" />
          </Button>
          <Button
            size="xs"
            data-active={baseLayer === MAP_BASE_LAYERS.satellite}
            onClick={() => setBaseLayer(MAP_BASE_LAYERS.satellite)}
            data-cy="map-controls-mode-satellite"
          >
            <Icon name="public" />
          </Button>
        </Button.Group>
      </Group>
      <Menu trigger="click">
        <Menu.Target>
          <ActionIcon variant="subtle" c="gray">
            <Icon name="more_horiz" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={toggleFilterPanel}>Filters</Menu.Item>
          <Menu.Item
            onClick={() =>
              openSaveSearchModal({
                isNew: true,
                params: {
                  mode: 'feed',
                  name: currentQuery,
                  query: currentQuery,
                  locations: [],
                },
              })
            }
          >
            Save this feed
          </Menu.Item>
        </Menu.Dropdown>
        <Menu.Dropdown>
          <Menu.Item onClick={toggleFilterPanel}>Filters</Menu.Item>
          <Menu.Item
            onClick={() =>
              openSaveSearchModal({
                isNew: true,
                params: {
                  mode: 'search',
                  name: currentQuery,
                  query: currentQuery,
                  locations: [],
                },
              })
            }
          >
            Save this search
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <MemoFilterPanel data-cy="map-controls-filter-panel" />
    </Group>
  )
}
