import { createSelector } from 'reselect'
import { useResultsStore } from '../store'
import { getSocialImages } from './get-social-images'
import { categorized } from './results'

export const socialMediaResults = createSelector(
  [categorized],
  (c) => c.socialMediaResults,
)

export const socialWithImages = createSelector(
  [socialMediaResults],
  (results) => results.filter((result) => getSocialImages(result).length > 0),
)

export const socialWithoutImages = createSelector(
  [socialMediaResults],
  (results) => results.filter((result) => getSocialImages(result).length === 0),
)

export const useSocialMediaResults = () => useResultsStore(socialMediaResults)
export const useSocialWithImages = () => useResultsStore(socialWithImages)
export const useSocialWithoutImages = () => useResultsStore(socialWithoutImages)
