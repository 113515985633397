import { useSingleImageMap } from '@/features/SingleImageMap/hooks'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import { classes } from '@/utils/ui-helpers'

import styles from './single-image-map.module.css'

interface SingleImageMapProps {
  item: ImageryResult
  className?: string
  style?: React.CSSProperties
}

export function SingleImageMap({
  item,
  className,
  style,
}: SingleImageMapProps) {
  const { imgSource, toggleLayer, mapContainer } = useSingleImageMap(item)

  return (
    <div
      className={classes(styles['single-image-map'], className)}
      style={style}
    >
      <div
        ref={mapContainer}
        className={`map-container ${styles['map-container']}`}
      />
      <img
        src={imgSource}
        alt="Map"
        className={styles['map']}
        onClick={toggleLayer}
      />
    </div>
  )
}
