import React from 'react'
import { useParams } from 'react-router-dom'
import type { MantineStyleProps } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { useCollections } from '@/api/collections'
import {
  type CreateTerrapinParams,
  type TerrapinConfigParams,
  type TerrapinLocation,
  useTerrapin,
} from '@/hooks/use-terrapin'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'

export interface ConfigurationTablePropsType {
  rowStyle: React.CSSProperties
  firstColumn: MantineStyleProps
  secondColumn: MantineStyleProps
}

export const ConfigurationTableProps: ConfigurationTablePropsType = {
  rowStyle: { borderBottom: '1px solid var(--table-border-color)' },
  firstColumn: {
    fw: 'bold',
    fz: 'sm',
    pl: 'sm',
    py: 'xs',
    w: '50%',
  },
  secondColumn: {
    pr: 'sm',
    w: '50%',
    px: 0,
  },
}

export interface UseConfigureSavedSearchResponse {
  backfill: boolean
  setBackfill: (value: boolean) => void
  queryString: string
  setQueryString: (value: string) => void
  name: string
  setName: (value: string) => void
  emailNotification: string | null
  setEmailNotification: (value: string | null) => void
  expirationDate: string
  setExpirationDate: (value: string) => void
  locations: TerrapinLocation[]
  setLocations: (locations: TerrapinLocation[]) => void
  isSaving: boolean
  handleSave: (closeModal: () => void) => void
}

export interface ConfigureSavedSearchProps {
  savedConfig?: TerrapinConfigParams
  refId?: string
}

// This hook takes arguments, and therefore can't be used with `singletonHook`.
// As such, it's necessary to invoke this hook once and pass the props down.
export function useConfigureSavedSearch({
  savedConfig,
  refId,
}: ConfigureSavedSearchProps): UseConfigureSavedSearchResponse {
  const { createTerrapin, updateTerrapin } = useTerrapin()
  const { refresh, update } = useCollections()
  const { collectionId } = useParams()
  const [queryString, setQueryString] = React.useState(savedConfig?.query ?? '')
  const [name, setName] = React.useState(
    savedConfig?.name ?? savedConfig?.query ?? '',
  )
  const [emailNotification, setEmailNotification] = React.useState(
    savedConfig?.emailNotification ?? null,
  )
  const [expirationDate, setExpirationDate] = React.useState(
    savedConfig?.expiration ?? '',
  )

  const [backfill, setBackfill] = React.useState(savedConfig?.backfill ?? false)

  const [locations, setLocations] = React.useState(savedConfig?.locations ?? [])

  const [isSaving, setIsSaving] = React.useState(false)

  const isNew = !refId

  function handleSave(closeModal: () => void) {
    setIsSaving(true)
    const requestBody: Partial<CreateTerrapinParams> = {
      // Only apply changes if they are different from the saved config
      ...((name !== savedConfig?.name || isNew) && { name }),
      ...(emailNotification !== savedConfig?.emailNotification && {
        emailNotification,
      }),
      ...((queryString !== savedConfig?.query || isNew) && {
        query: queryString,
      }),
      ...(expirationDate !== savedConfig?.expiration && {
        expiration: expirationDate,
      }),
      ...(backfill !== savedConfig?.backfill && {
        backfill: backfill,
      }),
      mode: savedConfig?.mode,
      locations,
    }

    const saveFunction = () =>
      isNew
        ? createTerrapin(requestBody as CreateTerrapinParams)
        : updateTerrapin({
            refId,
            name,
            query: queryString,
            emailNotification,
            expiration: expirationDate,
            backfill: backfill,
            mode: savedConfig?.mode ?? 'feed',
            locations,
            collectionId,
          })

    saveFunction()
      .then(() => {
        if (refId && collectionId && name !== savedConfig?.name) {
          // Sync `collection.name` to `feed.name` if the name has changed
          void update({
            id: collectionId,
            name,
          })
        }
        captureEvent(POSTHOG_EVENTS.EXPLORE.SAVE_SEARCH)
        if (refId) {
          notifications.show({
            title: 'Success',
            color: 'green',
            message: 'Feed configuration updated.',
            autoClose: 5000,
          })
        }
        refresh()
        closeModal()
      })
      .catch((error) => {
        if (refId) {
          notifications.show({
            title: 'Unsuccessful',
            color: 'red',
            message: 'Feed configuration failed to update. Please try again.',
            autoClose: 5000,
          })
        }
        console.error('Error saving search:', error)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  return {
    queryString,
    setQueryString,
    name,
    setName,
    emailNotification,
    setEmailNotification,
    expirationDate,
    setExpirationDate,
    backfill,
    setBackfill,
    locations,
    setLocations,
    isSaving,
    handleSave,
  }
}
