import type { PublicationResult } from '@/utils/types/result-types.ts'
import type { EmbeddedImage } from './types'

export const getNewsImages = (result: PublicationResult) => {
  const embedded = result.properties._dEmbedded as
    | EmbeddedImage[]
    | { _dEmbedded: EmbeddedImage[] }

  // GDELT images are not supported/relevant at the moment
  if (result.source === 'GDELT') {
    return []
  }
  if (Array.isArray(embedded)) {
    return embedded
  }
  return embedded._dEmbedded
}
