import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Checkbox,
  Group,
  Menu,
  Paper,
  Stack,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  openAddToCollectionModal,
  openDownloadItemModal,
  openResultDetailsModal,
} from '@/components/modals/openers.tsx'
import { makeOrthoLayer } from '@/hooks/ortho-imagery/use-ortho-imagery-sources.ts'
import {
  extractAssetName,
  extractSensorName,
} from '@/hooks/results/result-filter-utilities.ts'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable.ts'
import {
  useActiveOrthoLayerGroupIds,
  useClearOrthoLayerGroupIds,
  useHoveredExtentIds,
  useSetHoveredLayerId,
  useToggleOrthoLayerGroupId,
  useZoomToOrthoLayer,
} from '@/stores/map-store'
import type { SymbolName } from '@/utils/types/material-icons.ts'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types.ts'
import {
  isImageryResult,
  type ResultWithId,
} from '@/utils/types/result-types.ts'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import pluralize from 'pluralize'

import styles from '@/components/lib/thumbnail.module.css'

export interface ThumbnailProps extends React.HTMLAttributes<HTMLImageElement> {
  item: OrthoImageryLayerGroup
}

dayjs.extend(LocalizedFormat)
dayjs.extend(utc)

interface ActionMenuType {
  label: string
  icon: SymbolName
  onClick: (result: ResultWithId) => void
  title?: string
  disabled?: boolean
}

const actionMenuOptions: Record<string, ActionMenuType> = {
  addToCollection: {
    label: 'Add to Collection',
    icon: 'add',
    onClick: (result: ResultWithId) => {
      openAddToCollectionModal(result)
    },
  },
  viewDetails: {
    label: 'View Details',
    icon: 'info',
    onClick: (result: ResultWithId) => {
      openResultDetailsModal(result)
    },
  },
  downloadImage: {
    label: 'Download',
    icon: 'download',
    onClick: (result: ResultWithId) => {
      if (isImageryResult(result)) {
        openDownloadItemModal(result)
      }
    },
    disabled: true,
  },
}

export const OrthoThumbnail = ({ item }: ThumbnailProps) => {
  const scrollRef = React.useRef<Record<string, HTMLDivElement>>({})
  const { id, layers, authoredOn, formattedSource } = item
  const navigate = useNavigate()
  const activeOrthoLayerIds = useActiveOrthoLayerGroupIds()
  const setHoveredLayerId = useSetHoveredLayerId()
  const hoveredExtentIds = useHoveredExtentIds()
  const clearOrthoLayerGroupIds = useClearOrthoLayerGroupIds()
  const toggleOrthoLayerId = useToggleOrthoLayerGroupId()
  const zoomToOrthoLayer = useZoomToOrthoLayer()

  const result = layers[0].imageResult
  const { data: isDownloadable } = useIsDownloadable(result)

  React.useEffect(() => {
    const extentId = hoveredExtentIds[0]
    const current = scrollRef.current[extentId]
    if (current) {
      current.scrollIntoView()
    }
  }, [hoveredExtentIds])

  const adjustedActionMenuOptions: Record<string, ActionMenuType> = {
    ...actionMenuOptions,
    downloadImage: {
      ...actionMenuOptions['downloadImage'],
      disabled: !isDownloadable,
      title: isDownloadable ? undefined : 'Not available for download',
    },
    viewDetails: {
      ...actionMenuOptions['viewDetails'],
      onClick: (clickedResult: ResultWithId) => {
        if (isImageryResult(clickedResult)) {
          const layer = makeOrthoLayer(clickedResult)
          if (layer) {
            clearOrthoLayerGroupIds()
            toggleOrthoLayerId(layer.groupKey)
            navigate(`/results/${clickedResult.id}`)
          } else {
            captureException(
              `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
            )
          }
        } else {
          openResultDetailsModal(clickedResult)
        }
      },
    },
  }

  const actions = Object.values(adjustedActionMenuOptions)

  return (
    <Paper
      onMouseEnter={() => setHoveredLayerId(id)}
      onMouseLeave={() => setHoveredLayerId(null)}
      p="xs"
      m="-1"
      ref={(ref) => {
        if (ref) {
          scrollRef.current[result.documentId] = ref
        }
      }}
    >
      <Group
        pos="relative"
        pl="sm"
        pt="xs"
        pb="xs"
        style={{
          boxShadow:
            '0px -1px 1px 0px rgba(12, 44, 125, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.27) inset',
          border: hoveredExtentIds.includes(result.documentId)
            ? '1px solid #0091EA'
            : 'none',
        }}
      >
        <Checkbox
          checked={activeOrthoLayerIds.includes(id)}
          onChange={() => {
            if (!activeOrthoLayerIds.includes(id)) {
              zoomToOrthoLayer(id)
            }
            toggleOrthoLayerId(id)
          }}
          size="xs"
        />

        <Stack gap="xs">
          <Text size="xs">
            <Text fw="bold">{formattedSource.toUpperCase()}</Text>
            <Text c="#666666">
              {layers.length > 1 &&
                ` - ${layers.length} ${pluralize('IMAGE', layers.length)}`}
              {'  '}
              {extractSensorName(result).toUpperCase() ?? ''}
              {'  '}
              {extractAssetName(result).toUpperCase() ?? ''}
            </Text>
          </Text>

          <Group gap="xs">
            <Text size="xs">
              {dayjs(authoredOn)
                .utc()
                .format('MMM D, YYYY HH:mm')
                .toUpperCase()}{' '}
              Z
            </Text>
            <Text size="xs">
              QUALITY: {result.properties._dQuality}m CLOUD COVER:
              {'  '}
              {result.properties._dCloudCover}%
            </Text>
          </Group>
        </Stack>
        <Menu trigger="hover" shadow="md">
          <Menu.Target>
            <ActionIcon
              variant="white"
              className={styles['clickable']}
              onClick={(event) => event.stopPropagation()}
            >
              <Icon name="more_vert" size="xl" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {actions.map(
              ({ label, icon, onClick: actionOnClick, ...passProps }) => {
                return label === 'Download' && !isDownloadable ? null : (
                  <Menu.Item
                    key={label}
                    {...passProps}
                    leftSection={<Icon name={icon} />}
                    onClick={(event) => {
                      event.stopPropagation()
                      void actionOnClick(result)
                    }}
                    p="xs"
                    className={styles['menu-hover']}
                  >
                    {label}
                  </Menu.Item>
                )
              },
            )}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Paper>
  )
}
