import { graphql } from '@/gql'
import { isGov } from '@/utils/constants'
import { useQuery } from '@apollo/client'

export const HAS_ACTIVE_SUBSCRIPTION_QUERY = graphql(`
  query HasActiveSubscription {
    me {
      id
      org {
        id
        owner {
          id
          hasActiveSubscription
        }
      }
    }
  }
`)

export const useHasActiveSubscription = () => {
  const { data, loading } = useQuery(HAS_ACTIVE_SUBSCRIPTION_QUERY, {
    skip: isGov,
  })

  if (loading) {
    return {
      hasActiveSubscription: false,
      isOwner: false,
      loading: true,
    }
  }

  // Disable subscription requirement for government users who are under contract
  if (isGov) {
    return {
      hasActiveSubscription: true,
      isOwner: false,
      loading: false,
    }
  }

  const hasActiveSubscription = data?.me?.org?.owner?.hasActiveSubscription
  const isOwner = data?.me?.id === data?.me?.org?.owner?.id

  return {
    hasActiveSubscription,
    isOwner,
    loading: false,
  }
}
