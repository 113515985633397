import type {
  AnalyticProperty,
  ImageryResult,
  ResultWithId,
} from '@/utils/types/result-types.ts'
import * as turf from '@turf/turf'
import type { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson'

export const ZOOM_BUFFER_FACTOR = 1.25

type PinProperties = {
  resultId: string
  category: string
}

export const makeImageryResultPolygons = (
  results: ResultWithId[],
): FeatureCollection<Polygon | Point | MultiPolygon> => {
  return turf.featureCollection(
    results
      .filter(
        (r) =>
          r.geometry.type === 'Polygon' || r.geometry.type === 'MultiPolygon',
      )
      .map((r) =>
        turf.feature(r.geometry, { documentId: r.id, category: r.category }),
      ),
  )
}

export const makeAnalyticResultPolygons = (
  results: AnalyticProperty[],
): FeatureCollection<Polygon> => {
  return turf.featureCollection(
    results
      .filter((r) => r.geometry.type === 'Polygon')
      .map((r) => turf.feature(r.geometry)),
  )
}

export const makeImageBounds = (geometry: turf.helpers.AllGeoJSON) => {
  const [minX, minY, maxX, maxY] = turf.bbox(geometry)
  return [
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
  ]
}

export const makeResultPinPoints = (results: ResultWithId[]) => {
  const features = results
    .filter((r) => r.geometry.type === 'Point')
    .map((result) =>
      turf.feature<Point, PinProperties>(result.geometry as Point, {
        resultId: result.id,
        category: result.category,
      }),
    )
  return turf.featureCollection(features)
}

export const makePolygonsIntoPoint = (results: ResultWithId[]) => {
  const features = results.map((result) => turf.center(result.geometry))
  return turf.featureCollection(features)
}

export const makeImageryResultsBoundingBox = (
  imageResults: ImageryResult[],
) => {
  // TODO: This will currently break since makeImageryResultPolygons is missing the groupKey property
  return turf.bbox(
    turf.transformScale(
      makeImageryResultPolygons(imageResults),
      ZOOM_BUFFER_FACTOR,
      { origin: 'centroid' },
    ),
  )
}

export const makePolygonBoundingBox = (result: ResultWithId) => {
  return turf.bbox(result.geometry)
}

export const makeImageryResultsCentroid = (imageResults: ImageryResult[]) => {
  const polygons = makeImageryResultPolygons(imageResults)
  try {
    return turf.centroid(polygons).geometry
  } catch {
    console.error(`Error creating centroid`, { imageResults })
  }
}
