import { Table, type TableProps, Text } from '@mantine/core'
import type { JSONValue } from '@/utils/types/result-types.ts'
import { wordBreak } from '@/utils/ui-helpers.tsx'
import dayjs from 'dayjs'
import { camelCase, startCase } from 'lodash'

interface DataDisplayProps extends Omit<TableProps, 'data'> {
  data: JSONValue
}

function valueToString(value: JSONValue) {
  if (typeof value === 'string') {
    if (dayjs(value).isValid()) {
      const date = new Date(value)
      return date.toLocaleString('en-US', {
        dateStyle: 'full',
        timeStyle: 'medium',
        hour12: true,
      })
    } else {
      return value
    }
  } else if (value != undefined) {
    return JSON.stringify(value)
      .replaceAll(/["[\]]/g, '')
      .replaceAll(',', ', ')
  }
  return ''
}

export function DataDisplay({ data }: DataDisplayProps) {
  return (
    <Table
      p={0}
      style={{ wordBreak: 'break-word', border: 'none' }}
      withRowBorders
    >
      <Table.Tbody>
        {Object.entries(data ?? {}).map(
          ([key, value]: [string, JSONValue], index) => (
            <Table.Tr key={key + index}>
              <Text
                component="td"
                fz="sm"
                maw="100%"
                pt={index === 0 ? 0 : 'md'}
                pr="xl"
                pb="xs"
                c="gray.8"
                style={{ whiteSpace: 'nowrap' }}
              >
                {startCase(camelCase(key))}
              </Text>
              <Text
                component="td"
                fz="sm"
                pt={index === 0 ? 0 : 'md'}
                pb="xs"
                c="gray"
              >
                {wordBreak(valueToString(value))}
              </Text>
            </Table.Tr>
          ),
        )}
      </Table.Tbody>
    </Table>
  )
}
