import { MAP_LAYER_OPTIONS } from '@/stores/map-store/constants'
import type {
  BaseLayerOption,
  GridCellProperties,
  MapState,
  MapStateValues,
} from '@/stores/map-store/types'
import { MAP_BASE_LAYERS } from '@/utils/constants'
import { createStore } from '@/utils/create-store.ts'
import type mapboxgl from 'mapbox-gl'

export const initialState = {
  activeLayers: [
    MAP_LAYER_OPTIONS.EXTENTS,
    MAP_LAYER_OPTIONS.POINT,
    MAP_LAYER_OPTIONS.EXTRACTIONS,
  ],
  activeOrthoLayerGroupIds: [],
  baseLayer: MAP_BASE_LAYERS.dantiStreets,
  currentMapBounds: null,
  gridCellProperties: {},
  hoveredExtentIds: [],
  hoveredHexGridId: null,
  hoveredLayerId: null,
  mapBbox: null,
  pointViewPort: null,
  selectedExtentIds: [],
  selectedHexGridId: null,
  selectedPointId: null,
  zoomOrthoLayerGroupId: null,
} as MapStateValues

export const useMapStore = createStore<MapState>({
  initialState,
  actions: (setState, getState) => ({
    clearActiveLayers: () => setState({ activeLayers: [] }),
    clearOrthoLayerGroupIds: () => setState({ activeOrthoLayerGroupIds: [] }),
    setActiveLayers: (layer: string) => {
      const activeLayers = new Set(getState().activeLayers)
      activeLayers?.has(layer)
        ? activeLayers?.delete(layer)
        : activeLayers?.add(layer)
      setState({
        activeLayers: [...activeLayers],
      })
    },
    setActiveOrthoLayerGroupIds: (ids: string[]) =>
      setState({ activeOrthoLayerGroupIds: [...ids] }),
    setBaseLayer: (baseLayer: BaseLayerOption) => setState({ baseLayer }),
    setCurrentMapBounds: (currentMapBounds: mapboxgl.LngLatBounds | null) =>
      setState({ currentMapBounds }),
    setGridCellProperties: (gridCellProperties: GridCellProperties) =>
      setState({ gridCellProperties }),
    setHoveredExtentIds: (extentIds: string[]) =>
      setState({ hoveredExtentIds: extentIds }),
    setHoveredHexGridId: (hoveredHexGridId: number | null) =>
      setState({ hoveredHexGridId }),
    setHoveredLayerId: (hoveredLayerIdPrefix) => {
      const { hoveredLayerId } = getState()
      if (hoveredLayerId !== hoveredLayerIdPrefix) {
        setState({ hoveredLayerId: hoveredLayerIdPrefix })
      }
    },
    setMapBbox: (bbox) => setState({ mapBbox: bbox }),
    setPointViewPort: (viewPort) => setState({ pointViewPort: viewPort }),
    setSelectedExtentIds: (extentIds: string[]) =>
      setState({ selectedExtentIds: extentIds }),
    setSelectedPointId: (selectedPointId: string | null) =>
      setState({ selectedPointId }),
    toggleOrthoLayerGroupId: (id: string) => {
      const activeOrthoLayerIds = new Set(getState().activeOrthoLayerGroupIds)
      activeOrthoLayerIds?.has(id)
        ? activeOrthoLayerIds?.delete(id)
        : activeOrthoLayerIds?.add(id)
      setState({
        activeOrthoLayerGroupIds: [...activeOrthoLayerIds],
      })
    },
    toggleSelectedHexGridId: (selectedHexGridId: number | null) =>
      setState({
        selectedHexGridId:
          getState().selectedHexGridId === selectedHexGridId
            ? null
            : selectedHexGridId,
      }),
    zoomToOrthoLayer: (layerId: string) => {
      setState({
        zoomOrthoLayerGroupId: layerId,
      })
    },
  }),
})
