import { Button, Menu } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'

export interface HeaderSortCellProps {
  label: string
  sortItems: {
    value?: CollectionSortType
    label: string
  }[]
  setSort: (sort: CollectionSortType) => void
}

export function HeaderSortCell({
  label,
  sortItems,
  setSort,
}: HeaderSortCellProps) {
  const handleClick = (value?: CollectionSortType) => {
    if (value) {
      setSort(value)
    }
  }

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button size="xs" radius="xl" bg="#FAFAFA" style={{ color: 'black' }}>
          {label}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {sortItems.map(({ value, label }) => (
          <Menu.Item key={label} onClick={() => handleClick(value)}>
            {label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
