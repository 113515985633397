import { type MutableRefObject, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  useSetUploadedFeature,
  useUploadedFeature,
} from '@/stores/map-draw-store'
import * as turf from '@turf/turf'
import type mapboxgl from 'mapbox-gl'
import { useDropzone } from 'react-dropzone'
import { processDroppedFiles } from './util/process-dropped-files.ts'

const sleep = (ms: number) =>
  new Promise((r) => {
    setTimeout(r, ms)
  })

export const useFileDragging = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const setUploadedFeature = useSetUploadedFeature()

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop: async (files: File[]) => {
      if (!location.pathname.toLowerCase().includes('map')) {
        navigate('/map')
        // Yarrrr, here be hacks
        await sleep(500)
      }
      const droppedFeature = await processDroppedFiles(files)
      if (droppedFeature) {
        console.log('extracted feature', { droppedFeature })
        setUploadedFeature(droppedFeature)
      }
    },
    noClick: true,
    multiple: false,
    accept: { 'application/geo+json': ['.geojson'] },
  })

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  }
}

export const useMapFlyToOnDrag = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const uploadedFeature = useUploadedFeature()
  useEffect(() => {
    if (map.current && uploadedFeature && uploadedFeature.geometry) {
      try {
        const center = turf.centroid(uploadedFeature).geometry.coordinates
        if (
          Array.isArray(center) &&
          center.length >= 2 &&
          center.every(Number.isFinite)
        ) {
          map.current.flyTo({
            center: center as [number, number],
            zoom: 10,
          })
        } else {
          console.error('Invalid centroid coordinates:', center)
        }
      } catch (error) {
        console.error('Error calculating centroid:', error)
      }
    }
  }, [map, uploadedFeature])
}
