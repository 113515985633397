import type { Filters, FilterValues } from '@/stores/filters-store/types'
import { createStore } from '@/utils/create-store'
import { hourlyFrequency } from '@/utils/format-datetime'
import { type DateValue, TimePreset } from '@/utils/types/filter-types'

export const initialFilterState: FilterValues = {
  timePreset: TimePreset.None,
  customTimeStart: null,
  customTimeEnd: null,
  sensors: [],
  providers: [],
  quality: '5000',
  cloudCover: 100,
  angle: '50',
  orientations: [],
  formattedAddress: '',
  placeId: '',
  country: '',
  adminAreaLevel1: '',
  adminAreaLevel2: '',
  location: '',
  locationType: '',
  keywords: [],
  isFilterExpanded: false,
  frequency: hourlyFrequency(7),
}

export const useFiltersStore = createStore<Filters>({
  initialState: initialFilterState,
  actions: (set, get): Partial<Filters> => ({
    setFilters: (filters: Partial<Filters>) => {
      set(filters)
    },
    setTimePreset: (
      timePreset: TimePreset,
      start: DateValue,
      end: DateValue,
    ) => {
      set({
        timePreset,
        customTimeStart: start,
        customTimeEnd: end,
      })
    },
    toggleSensors: (sensor: string) => {
      const currentSensors = new Set(get().sensors)
      if (currentSensors.has(sensor)) {
        currentSensors.delete(sensor)
      } else {
        currentSensors.add(sensor)
      }
      set({ sensors: [...currentSensors] })
    },
    setProviders: (providers: string[]) => {
      set({ providers })
    },
    setLocation: (location: string, locationType: string) => {
      set({ location, locationType })
    },
    resetFilters: () => {
      set(initialFilterState)
    },
    toggleFilterPanel: () => {
      const currentState = get()
      set({ isFilterExpanded: !currentState.isFilterExpanded })
    },
    setEmailUpdateFrequency: (frequency: string) => {
      set({ frequency: frequency })
    },
  }),
})
