import type { BentoCardData } from '@/features/BentoResults/types'
import type { ResultWithId } from '@/utils/types/result-types'

export const addResultToCardData = (
  cardData: BentoCardData[],
  category: string,
  index: number,
  effectiveResults: ResultWithId[],
  results: ResultWithId[],
  includeTotal: boolean = true,
) => {
  if (effectiveResults.length > index) {
    cardData.push({
      type: category,
      result: effectiveResults[index],
      ...(includeTotal && { totalResults: results.length }),
    })
  }
}

export default addResultToCardData
